import Button from "components/Button/button.component";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { showStaticModal } from "state/feature/risk-assessment/risk-assessment.slice";
import "./summary-footer.styles.scss";

const SummaryFooter = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className="footer-button-container">
      <div className="footer-button-section">
        <div>
          <Button
            text="Show Tasks for Risk Level"
            className="show-task-button"
            onClick={() => dispatch(showStaticModal(true))}
          />
          <Button
            text="Show Assessment"
            className="next-button"
            onClick={() => {
              history.push("result");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SummaryFooter;
