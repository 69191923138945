import ButtonLoader from "components/ButtonLoader/button-loader.component";
import ConversationLoading from "components/ConversationLoading/conversation-loading.component";
import TableLoader from "components/TableLoader/table-loader.component";
import { TooltipItems } from "components/Tooltip/props";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAgeFromDate, getDateInMMDDYYYYFormat, isEmptyString } from "shared/methods/utilityFunctions";
import { getCallState } from "state/feature/call/call.slice";
import { getParticipantsDetailsAsync } from "state/feature/navigator/navigator.action";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import "./call-participants.styles.scss";
import { getCommonState } from "state/feature/common/common.slice";

//${isHovered ? "tooltipHovered" : ""} ${align === "end" ? "" : "br-40"}
const CallParticipants = () => {
  const callState = useSelector(getCallState);
  const { featureFlags } = useSelector(getCommonState);
  const { participants: participantsOnCall, isLoading } = useSelector(getNavigatorState);
  const dispatch = useDispatch();

  const getStatus = (value: any) => {
    if (value === "OnTrack") {
      return "On-Track";
    } else if (value === "OffTrack") {
      return "Off-Track";
    } else {
      return value;
    }
  };

  useEffect(() => {
    if (callState.participants) {
      const getParticipantsDetailsData = async () => {
        const data = callState.participants
          .filter((item: any) => item && item.id !== null && item.type.toLowerCase() !== "navigator")
          .map((item: any) => item.id);
        dispatch(
          getParticipantsDetailsAsync({ participantsIds: data, isInboundCallsEnabled: featureFlags.inboundCalls })
        );
      };
      getParticipantsDetailsData();
    }
  }, [callState.participants, featureFlags.inboundCalls, dispatch]);

  const getTooltipItems = () => {
    if (participantsOnCall?.patient) {
      const {
        patientFirstName,
        patientLastName,
        location,
        patientDOB,
        procedureName,
        procedureDate,
        surgerySide,
        surgerySite,
        primaryNavigator,
        status,
      } = participantsOnCall?.patient;

      let phoneNumber = null;
      let phoneNumberDetails = null;

      if (callState?.participants) {
        const participant = callState?.participants?.filter((item) => item.isCaller === true);
        if (participant.length > 0) {
          phoneNumber = participant[0]?.phoneNumber;
          phoneNumberDetails = participant[0]?.phoneNumberDetails;
        }
      }
      return [
        {
          name: "Patient",
          value: `${patientFirstName ?? ""} ${patientLastName ?? ""}`,
          isHighlighted: true,
          className: "patient",
        },
        {
          name: "Phone No.",
          value: phoneNumber ? (
            <div>
              <div>{phoneNumber}</div>
              {phoneNumberDetails && <div className="phone-number-details">{phoneNumberDetails}</div>}
            </div>
          ) : (
            "-"
          ),
        },
        {
          name: "Location",
          value: `${location && !isEmptyString(location) ? location : "-"}`,
        },
        {
          name: "DoB (Age)",
          value: `${
            patientDOB
              ? `${getDateInMMDDYYYYFormat(new Date(patientDOB))} (${getAgeFromDate(new Date(patientDOB))} years)`
              : "-"
          } `,
        },
        {
          name: "Procedure",
          value: `${
            procedureName
              ? `${procedureName} ${procedureDate ? `(${getDateInMMDDYYYYFormat(new Date(procedureDate))})` : ""}`
              : "-"
          } `,
        },
        {
          name: "Laterality",
          value: surgerySide && surgerySide !== "N/A" ? `${surgerySide} - ${surgerySite}` : "None",
        },
        {
          name: "90 Days",
          value: `${
            participantsOnCall?.patient?._90Days
              ? getDateInMMDDYYYYFormat(new Date(participantsOnCall?.patient?._90Days))
              : "-"
          }`,
        },
        {
          name: "Primary Navigator",
          value: `${primaryNavigator && !isEmptyString(primaryNavigator) ? primaryNavigator : "-"}`,
          className: "primary-navigator",
        },
        {
          name: "Status",
          value: status ? `${getStatus(status)}` : "",
          className: `${status === "OnTrack" ? "on-track" : "off-track"}`,
        },
      ] as TooltipItems[];
    }
    return [];
  };

  const getTooltipFooterItems = () => {
    if (participantsOnCall?.navigator) {
      const { navigatorFirstName, navigatorLastName } = participantsOnCall?.navigator;
      return [
        {
          name: "On Call Navigator",
          value: `${`${navigatorFirstName} ${navigatorLastName}`}`,
          isHighlighted: true,
          className: "",
        },
        {
          name: "Physician",
          value: `${
            participantsOnCall?.physician
              ? `${participantsOnCall?.physician?.physicianFirstName} ${participantsOnCall?.physician?.physicianLastName}`
              : "-"
          }`,
          isHighlighted: true,
          className: "",
        },
      ];
    }

    return [];
  };

  return (
    <div className="call-participants-container">
      <div>
        {isLoading && (
          <div className="loading">
            <ConversationLoading />
          </div>
        )}
        {!isLoading && getTooltipItems()?.length !== 0 && (
          <div className="participant-grid">
            {getTooltipItems()?.map((item, index) => (
              <React.Fragment key={"item_" + index}>
                <div className={`item-name ${item?.className}-name`}>{item?.name}</div>
                {item?.value && item?.value !== "" && item.className === "patient" && (
                  <div>
                    <div className={`item-value ${item.isHighlighted ? "highlighted" : ""} ${item.className ?? ""}`}>
                      {item.value}
                    </div>
                  </div>
                )}
                {item?.value && item?.value !== "" && item.className !== "patient" && (
                  <div className={`item-value ${item.isHighlighted ? "highlighted" : ""} ${item.className ?? ""}`}>
                    {item.value}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
        {getTooltipFooterItems()?.length !== 0 && (
          <>
            <div className="border-line" />
            <div className="participant-grid">
              {getTooltipFooterItems()?.map((item, index) => (
                <React.Fragment key={"item_" + index}>
                  <div className="item-name">{item.name}</div>
                  <div className={`item-value ${item.isHighlighted ? "highlighted" : ""} ${item.className ?? ""}`}>
                    {item.value}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CallParticipants;
