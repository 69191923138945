import { useEffect, useState } from "react";
import GenericTabs from "components/GenericTabs/generic-tabs.component";
import { ActiveTabName } from "components/GenericTabs/props";
import { TaskInfoTab } from "shared/enums/task-management-tabs.enum";
import "./task-info-tabs.styles.scss";
import { useSelector, useDispatch } from "react-redux";
import PatientAndEpisodeDetails from "./patient-and-episode-details/patient-and-episode-details.component";
import Icon from "components/Icon/icon.component";
import CallParticipantsDropdown from "components/call-participants-dropdown/call-participants-dropdown.component";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";
import { getCallState } from "state/feature/call/call.slice";
import { getAssignPhoneNumberListAsync } from "state/feature/navigator/navigator.action";
import { toast } from "react-toastify";
import ClickAwayListener from "react-click-away-listener";
import { getAuth } from "state/feature/auth/auth.slice";
import { getCommonState, setIsOpenCallModal, setIsOpenChatModal } from "state/feature/common/common.slice";
import {
  addPatientToPatientArray,
  getMessagingState,
  saveConversationParticipants,
  setUserIdforMessage,
} from "state/feature/messaging/messaging.slice";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { CALL_MESSAGE_ERROR_MESSAGE } from "../constant";
import { OpenFrom } from "shared/enums/chat.enum";
import { CallSidePopup } from "components/SidePopup/CallSidePopup/call-side-popup.component";
import { ContactDetail } from "state/types/task-management-slice.type";
import { Participant } from "shared/types/participant.type";
import { ContactType } from "shared/enums/contact-type.enum";
interface TaskInfoProps {
  activeTabName: ActiveTabName;
  onClickTabName: (tabName: ActiveTabName) => void;
}

const TaskInfoTabs = (props: TaskInfoProps) => {
  const { activeTabName, onClickTabName } = props;
  const [showCallParticipants, setShowCallParticipants] = useState(false);
  const { assignedPhoneNumbers } = useSelector(getNavigatorState);
  const callState = useSelector(getCallState);
  const { twilioErrorCode, modal } = useSelector(getCommonState);
  const { user } = useSelector(getAuth);
  const { episode, patientDetails } = useSelector(getTaskManagementState);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [conversation, setConversation] = useState<any>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedParticipantsForCall, setSelectedParticipantsForCall] = useState<Array<Participant>>();

  const messagingState = useSelector(getMessagingState);

  const getHeading = () => {
    if (conversation && conversation.name) {
      return messagingState.selectedConversation?.participants;
    } else if (messagingState.isLoading) {
      return "Loading conversation";
    } else {
      return "New Message";
    }
  };

  useEffect(() => {
    if (assignedPhoneNumbers.length === 0) {
      dispatch(getAssignPhoneNumberListAsync());
    }
  }, [assignedPhoneNumbers.length, dispatch]);

  const handleCall = () => {
    if (!twilioErrorCode) {
      if (
        !episode?.patientAndEpisodeDetails?.contactDetails ||
        !episode?.patientAndEpisodeDetails?.contactDetails.length
      ) {
        toast.error("No Phone Number Exists.", {
          toastId: "phone-number-error",
          containerId: "main",
        });
      } else {
        setShowCallParticipants(true);
      }
    }
  };

  const onParticipantSelectionForCall = (contact: ContactDetail) => {
    dispatch(setIsOpenCallModal(true));
    let userId = "";
    let contactType;

    if (contact.type === ContactType.PHYSICIAN) {
      userId = episode?.patientAndEpisodeDetails.physicianUserId
        ? episode?.patientAndEpisodeDetails.physicianUserId.toString()
        : "";
      contactType = ContactType.PHYSICIAN;
    } else {
      userId = episode?.patientAndEpisodeDetails.patientUserId
        ? episode?.patientAndEpisodeDetails.patientUserId.toString()
        : "";
      contactType = ContactType.PATIENT;
    }
    setSelectedParticipantsForCall([
      {
        firstName: contact.firstName,
        lastName: contact.lastName,
        phoneNumber: contact.phoneNumber,
        patientContactDetails: [],
        userId,
        clientId: contact.clientId,
        contactType,
      },
    ]);
  };

  const handleMessage = () => {
    if (episode?.patientAndEpisodeDetails.patientUserId) {
      if (episode?.id && episode?.id !== "" && patientDetails?.patientName) {
        dispatch(setUserIdforMessage(episode.id));
        dispatch(
          addPatientToPatientArray({
            patientID: episode.patientAndEpisodeDetails.patientId,
            patientUserID: episode.patientAndEpisodeDetails.patientUserId,
            patientFirstName: episode.patientAndEpisodeDetails.patientFirstName,
            patientLastName: episode.patientAndEpisodeDetails.patientLastName,
          })
        );
        dispatch(saveConversationParticipants(patientDetails.patientName));
        dispatch(setIsOpenChatModal({ isOpen: true, openFrom: OpenFrom.TASK_MANAGEMENT }));
      }
    }
  };

  return (
    <div className="task-details">
      <div className="task-details-header">
        <div className="task-details-header-text">Contact Details</div>
        {user.navigatorId ? (
          <div className="task-details-header-icons">
            <div
              className={`call-icon task-info-icon ${showCallParticipants ? "call-icon-active" : ""}`}
              onClick={() => handleCall()}
            >
              <Icon icon="call-icon" size={20} />
              {showCallParticipants ? (
                <ClickAwayListener
                  onClickAway={() => setShowCallParticipants(false)}
                  children={
                    <div>
                      <CallParticipantsDropdown
                        patient={patientDetails!.patientName}
                        id={patientDetails!.patientId.toString()}
                        contactDetails={episode?.patientAndEpisodeDetails?.contactDetails ?? []}
                        onClick={(contact: ContactDetail) => {
                          setShowCallParticipants(false);
                          if (!callState.currentCall) {
                            onParticipantSelectionForCall(contact);
                          } else {
                            toast.error("Another call is ongoing", {
                              toastId: "call-ongoing",
                              containerId: "main",
                            });
                          }
                        }}
                        key={patientDetails?.patientId}
                        className="down"
                      />
                    </div>
                  }
                ></ClickAwayListener>
              ) : null}
            </div>
            <div className={`task-info-icon`} onClick={handleMessage}>
              <Icon icon="navigator-message" size={17} />
            </div>
          </div>
        ) : null}
      </div>
      {!episode?.patientAndEpisodeDetails.patientUserId && (
        <div className="call-message-error-state">
          <Icon icon="error-message-icon" size={20} />
          <div>{CALL_MESSAGE_ERROR_MESSAGE}</div>
        </div>
      )}
      <CallSidePopup
        isOpen={modal.isOpenCallModal}
        heading="Make a Call"
        isDropdownVisible={isDropdownVisible}
        setIsDropdownVisible={setIsDropdownVisible}
        participants={selectedParticipantsForCall}
        setParticipants={(participants) => {
          setSelectedParticipantsForCall(participants);
        }}
      />
      <GenericTabs
        tabsList={[
          {
            name: TaskInfoTab.EPISODE,
          },
          {
            name: TaskInfoTab.TASKS,
          },
          {
            name: TaskInfoTab.ATTACHMENTS,
          },
          {
            name: TaskInfoTab.ACTIVITY,
          },
        ]}
        activeTabName={activeTabName}
        setActiveTabName={onClickTabName}
      />
      {activeTabName === TaskInfoTab.EPISODE && <PatientAndEpisodeDetails />}
    </div>
  );
};

export default TaskInfoTabs;
