export const getInitialsIfPictureNotPresent = (personName: {
  name?: string;
  firstName?: string;
  lastName?: string;
}): string => {
  const { firstName, lastName, name } = personName;
  if (firstName && lastName) {
    const newFirstName = firstName.trim()[0];
    const newLastName = lastName.trim().length > 0 ? lastName.trim()[0] : "";
    const nameInitals = newFirstName + newLastName;
    return nameInitals;
  } else if (name) {
    const arrayOfNames = name
      .trim()
      .split(" ")
      .filter((nameStr) => nameStr !== "");

    // if name is present and surname is also present
    if (name && arrayOfNames.length > 1) {
      const nameIntials = arrayOfNames[0][0] + arrayOfNames[1][0];
      return nameIntials.toUpperCase();
    } else {
      return arrayOfNames[0][0].toUpperCase();
    }
  } else {
    return "N";
  }
};
