import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { axiosInstance } from "shared/axios-instance";
import { getGender, isEmptyString } from "shared/methods/utilityFunctions";
import moment from "moment";
import { ContactDetail, ICurrentPatientDetails } from "state/types/task-management-slice.type";
import { ContactType } from "shared/enums/contact-type.enum";

export const getIsIntakeExists = createAsyncThunk(
  "task-management/getIsIntakeExists",
  async (requestPayload: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().efusionBase}/api/v2/ExtPatient/getPatientDetails/${requestPayload.intakeId}`
      );
      if (response?.status === 200) {
        return {
          intakeId: response.data.CurrentIntakeId,
          patientId: response.data.PatientId,
          patientName: `${response.data.FirstName} ${response.data.LastName}`,
          age: response.data.Age,
          isPlannedEpisode: response.data.IsPlannedEpisode,
          isRiskAssessmentTaskAdded: response.data.IsRiskAssessmentTaskAdded,
          clientId: response.data.ClientID,
          gender: getGender(response.data.Gender),
          dob: response.data.DOB,
        } as ICurrentPatientDetails;
      }
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const getTasksAsync = createAsyncThunk("task-management/getTasksAsync", async (requestPayload: any) => {
  const response = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/tasks`, {
    params: requestPayload,
  });
  if (response.status === 200) {
    response.data.data = response.data.data.map((task: any) => {
      return {
        id: task.taskDetailID,
        patient: {
          id: "",
          name: task.patientName,
          gender: task.gender ? task.gender : "",
          age: task.dob ? moment().diff(task.dob, "years", false) : -1,
          procedureDate: task.procedureDate ? moment(task.procedureDate).format("MM/DD/yyyy") : "",
          phoneNumber: "",
          conversationId: "",
          procedureName: task.procedureName ?? "",
        },
        name: task.taskName,
        dueDate: task.dueDate ? moment(task.dueDate).format("MM/DD/yyyy") : "-",
        isSnoozed: task.isSnoozed,
        episodeId: "",
        taskStatus: task.taskStatus,
        intakeId: task.intakeId,
        taskId: task.taskId,
        snoozeCount: task.snoozeCount,
      };
    });
  } else {
    response.data.data = [];
  }
  return response.data;
});

export const getPatientAndEpisodeDetailsAysnc = createAsyncThunk(
  "task-management/getPatientAndEpisodeDetailsAysnc",
  async (requestPayload: any) => {
    const response = await axiosInstance.get(
      `${getConfig().taskManagementBase}/api/v1/Tasks/patient-and-episode-details?intakeId=${requestPayload.intakeId}`
    );
    const contactDetails: Array<ContactDetail> = response.data.data.patientContacts;
    if (response.data.data.providerPhone) {
      contactDetails.push({
        id: response.data.data.physicianId,
        name: response.data.data.physician,
        isPreferred: false,
        phoneNumber: response.data.data.providerPhone,
        type: ContactType.PHYSICIAN,
        firstName: response.data.data.physicianFirstName,
        lastName: response.data.data.physicianLastName,
        clientId: response.data.data.clientId.toString(),
      });
    }
    const patientAndEpisodeDetailsData = {
      id: response.data.data.patientUserId,
      intakeId: requestPayload.intakeId,
      clientId: response.data.data.clientId.toString(),
      patientAndEpisodeDetails: {
        patientId: response.data.data.patientId.toString(),
        patientUserId: response.data.data.patientUserId,
        physicianUserId: response.data.data.physicianUserId,
        patient: response.data.data.patient,
        episodeId: "24",
        dateOfBirth: response.data.data.dob,
        gender: response.data.data.gender,
        practice: response.data.data.practice,
        procedure: response.data.data.procedureName,
        location: response.data.data.locationName,
        contactDetails: contactDetails,
        patientPhoneNumber: response.data.data.patientPhone ? response.data.data.patientPhone : "",
        coach: response.data.data.coachName ? response.data.data.coachName.toString() : "N/A",
        episode: response.data.data.episode,
        physician: response.data.data.physician,
        toc: response.data.data.toc,
        episodeSetting: response.data.data.episodeSetting,
        episodeCare: response.data.data.episodeCare,
        episodeType: response.data.data.episodeType,
        surgerySite: response.data.data.surgerySite,
        surgeryDate:
          response.data.data.surgeryDate && !isEmptyString(response.data.data.surgeryDate)
            ? response.data.data.surgeryDate
            : null,
        admitDate:
          response.data.data.admitDate && !isEmptyString(response.data.data.admitDate)
            ? response.data.data.admitDate
            : null,
        programDischargeDate:
          response.data.data.programDischargeDate && !isEmptyString(response.data.data.programDischargeDate)
            ? response.data.data.programDischargeDate
            : null,
        meds: "",
        description: "",
        patientFirstName: response.data.data.patientFirstName,
        patientLastName: response.data.data.patientLastName,
        navigator: {
          id: response.data.data.navigatorUserId,
          careManagerId: response.data.data.careManagerid,
          firstName: response.data.data.navigatorFirstName,
          lastName: response.data.data.navigatorLastName,
        },
      },
      statusAndAlerts: {
        alert: response.data.data.alert,
        status: response.data.data.status,
        offTrack: response.data.data.offTrackStartDate,
        riskInfo: response.data.data.offtrackReason,
      },
    };
    return patientAndEpisodeDetailsData;
  }
);

export const calculateRisk = createAsyncThunk(
  "task-management/calculateRisk",
  async (requestPayload: { intakeId: string }) => {
    const response = await axiosInstance.get(
      `${getConfig().taskManagementBase}/api/v1/RiskAssessment/calculateRisk/${requestPayload.intakeId}`
    );
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return "Incomplete";
    } else {
      return null;
    }
  }
);
