import { render } from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./state/store";
import "./index.scss";
import { enableMapSet } from "immer";

enableMapSet();

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))                    sdsdsdsdsds                                                sdsdsds
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
