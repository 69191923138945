import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { axiosInstance } from "shared/axios-instance";
import { clearUser, getAuth, getTokensFromCookies, logout } from "state/feature/auth/auth.slice";
import { resetCommon } from "state/feature/common/common.slice";
import { resetContacts } from "state/feature/contact/contact.slice";
import { useAppDispatch } from "state/store";

const useAxiosAuthenticated = () => {
  const authState = useSelector(getAuth);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    const requestIntercept = axiosInstance.interceptors.request.use(
      async (request: any) => {
        const { jwtToken } = getTokensFromCookies();
        if (jwtToken && !request._retry) {
          request.headers = {
            ...request.headers,
            Authorization: `Bearer ${jwtToken}`,
          };
        }
        return request;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?._retry) {
          dispatch(clearUser());
          dispatch(resetCommon());
          dispatch(resetContacts());
          dispatch(logout());
          sessionStorage.removeItem("riskAssessmentAnswers");
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [authState, dispatch, history]);

  return axiosInstance;
};

export default useAxiosAuthenticated;
