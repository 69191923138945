import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import axios from "axios";
import { axiosInstance } from "shared/axios-instance";
import { USER_ROLE } from "shared/constant/role-constant";

export const loginUserAsync = createAsyncThunk(
  "auth/loginUserAsync",
  async (requestPayload: { username: string; password: string }) => {
    const response = await axiosInstance.post(`${getConfig().authenticationBase}/api/v1/Account/login`, {
      username: requestPayload.username,
      password: requestPayload.password,
    });
    return response.data;
  }
);

export const getNavigatorRoleAsync = createAsyncThunk("auth/getNavigatorRoleAsync", async () => {
  const response = await axiosInstance.get(`${getConfig().navigatorBase}/api/v1/navigators/navigator-role`);
  return response.data.isNavigatorManager ? USER_ROLE.MANAGER : USER_ROLE.NAVIGATOR;
});

export const refreshTokenAsync = createAsyncThunk(
  "auth/refreshTokenAsync",
  async (requestPayload: { token: string; url: string }) => {
    const response = await axiosInstance.post(
      `${getConfig().authenticationBase}/api/v1/Account/refresh-token?${requestPayload.url}`,
      {
        token: requestPayload.token,
      }
    );
    return response.data;
  }
);

export const requestSendOtpAsync = createAsyncThunk("auth/requestSendOtpAsync", async (username: string) => {
  const response = await axiosInstance.post(
    `${getConfig().authenticationBase}/api/v1/Account/request-reset-with-verification-code`,
    {
      username: username,
    }
  );
  return response.data;
});

export const requestVerifyOtpAsync = createAsyncThunk(
  "auth/requestVerifyOtpAsync",
  async (requestPayload: { verificationCode: string; phoneNumber: string }) => {
    const response = await axiosInstance.post(`${getConfig().messagingBase}/api/v1/Messaging/verify-otp`, {
      verificationCode: requestPayload.verificationCode,
      phoneNumber: requestPayload.phoneNumber,
    });
    return response.data;
  }
);

export const requestUpdatePasswordAsync = createAsyncThunk(
  "auth/requestUpdatePasswordAsync",
  async (requestPayload: { userName: string; password: string; confirmPassword: string; code: string }) => {
    const response = await axiosInstance.post(`${getConfig().authenticationBase}/api/v1/Account/password`, {
      userName: requestPayload.userName,
      password: requestPayload.password,
      confirmPassword: requestPayload.confirmPassword,
      code: requestPayload.code,
    });
    return response.data;
  }
);
