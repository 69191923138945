import React, { useState } from "react";
import SidePopup from "../side-popup.component";
import { useDispatch, useSelector } from "react-redux";
import "./compose-message.styles.scss";
import {
  getMessagingState,
  saveConversationId,
  saveConversationParticipants,
  saveParticipantsDetails,
  saveTwilioConversationId,
  setMessagePhysician,
  setUserIdforMessage,
} from "state/feature/messaging/messaging.slice";
import { resetAllContacts } from "state/feature/contact/contact.slice";

import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { useAppDispatch } from "state/store";
import ChatSidePanel from "components/ChatSidePanel/chat-side-panel.component";
import { ComposeMessageProps } from "./type";
import { getTextWithElipsis, isEmptyString } from "shared/methods/utilityFunctions";
import moment from "moment";
import { setIsOpenChatModal } from "state/feature/common/common.slice";
import { OpenFrom } from "shared/enums/chat.enum";
import { GENERAL } from "components/ChatSidePanel/components/Compose/compose.constant";
import { getAuth } from "state/feature/auth/auth.slice";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";

export const ComposeMessage = ({ isOpen, onClose, className, openFrom }: ComposeMessageProps) => {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { user } = useSelector(getAuth);
  const [selectedList, setSelectedList] = useState<any[]>([]);
  const [conversation, setConversation] = useState<any>(null);
  const messageState = useSelector(getMessagingState);

  const getPatientDOB = () => {
    if (
      conversation &&
      conversation.patientDOB &&
      !isEmptyString(conversation.patientDOB) &&
      conversation.subject.toLowerCase() !== GENERAL.toLowerCase()
    ) {
      return `(${moment(conversation.patientDOB).format("MM/DD/YYYY")})`;
    } else if (
      messageState.selectedConversation &&
      messageState.selectedConversation.conversationId !== -1 &&
      messageState.selectedConversation.patientDOB &&
      messageState.selectedConversation.subject.toLowerCase() !== GENERAL.toLowerCase()
    ) {
      return `(${moment(messageState.selectedConversation.patientDOB).format("MM/DD/YYYY")})`;
    }

    return "";
  };

  const getHeading = () => {
    if (
      conversation ||
      (messageState.selectedConversation && messageState.selectedConversation.conversationId !== -1)
    ) {
      const subject = conversation
        ? getTextWithElipsis(conversation.subject, 25)
        : getTextWithElipsis(messageState.selectedConversation.subject, 25);
      const participants = conversation
        ? conversation.name
            .split(",")
            .filter((name: string) => {
              return name.trim() !== `${user.firstName} ${user.lastName[0]}`;
            })
            .toString()
        : messageState.selectedConversation.participants;
      return (
        <div className="two-liner-heading">
          <div data-tip data-for={participants} className="participants">
            {participants}
          </div>
          <CustomToolTip text={participants} place={"bottom"} />
          {!isEmptyString(subject) && (
            <div className="subject">
              Sub: {subject} {getPatientDOB()}
            </div>
          )}
        </div>
      );
    } else {
      return "New Message";
    }
  };

  const handleSidePopupClose = () => {
    setSelectedList([]);
    setConversation(null);
    appDispatch(saveConversationId(-1));
    dispatch(setUserIdforMessage(""));
    dispatch(setMessagePhysician(null));
    appDispatch(saveConversationParticipants(""));
    appDispatch(saveParticipantsDetails([]));
    appDispatch(saveTwilioConversationId(""));
    appDispatch(setIsOpenChatModal({ isOpen: false, openFrom: OpenFrom.COMPOSE }));
    onClose();
  };

  const clearDetails = () => {
    setConversation(null);
    dispatch(resetAllContacts());
  };

  const addItemInSelectedList = (items: any) => {
    clearDetails();
    setSelectedList(items);
  };

  return (
    <SidePopup
      isOpen={isOpen}
      onClose={() => {
        handleSidePopupClose();
      }}
      heading={getHeading()}
      className={`new-message ${className ? className : ""}`}
      openFrom={openFrom}
      isBackArrowInMobile={true}
    >
      <ChatSidePanel
        addItemInSelectedList={addItemInSelectedList}
        selectedList={selectedList}
        conversationState={conversation}
        setConversation={setConversation}
        openFrom={openFrom}
        isOpen={isOpen}
      />
    </SidePopup>
  );
};
