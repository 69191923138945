import { ChangeEvent, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/Button/button.component";
import Checkbox from "components/Checkbox/checkbox.component";
import "./toc-revision.styles.scss";
import FacilityDropdown from "../facility-dropdown";

import GenericPopupModal from "components/Modal/GenericPopupModal/generic-popup-modal.component";
import { useAppDispatch } from "state/store";
import ErrorIcon from "shared/assets/images/error.png";
import Icon from "components/Icon/icon.component";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import {
  ACUTE_LOS_ERROR,
  FACILITY_ERROR,
  ILocation,
  LOS_LIMIT,
  LOS_VISIT_ERROR,
  NOTE_LIMIT,
  PAC_TYPE,
  TOC_ITEMS,
  TocStatusEnum,
} from "../constants/index.constant";
import { validateNumber } from "shared/methods/utilityFunctions";
import {
  getTOCManagementState,
  setIsTocsLoading,
  updateTocList,
} from "state/feature/toc-management/toc-management.slice";
import {
  createTocAsync,
  getTocAsync,
  editTocAsync,
  getTocHistory,
} from "state/feature/toc-management/toc-management.action";
import {
  ITransitionOfCare,
  ITransitionOfCareItems,
  ICreateNewTocPayload,
  ICreateTransitionOfCareItem,
  IEditTocPayload,
  IEditTransitionOfCareItem,
} from "state/types/toc-management-slice.type";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import TocChangeModal from "components/Modal/toc-change-modal/toc-change-modal.component";

interface TocRevisionProps {
  toc: ITransitionOfCare;
}

const TocRevision = (props: TocRevisionProps) => {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { facilities, tocs, intake } = useSelector(getTOCManagementState);

  const { toc } = props;
  const {
    id,
    intakeId,
    clientId,
    version,
    noteCareManager,
    notePhysician,
    anticipatedAcuteLOS,
    createdAt,
    approvedAt,
    isExpanded,
    transitionOfCareItems,
    status,
    isModified,
    history,
    additionalNote,
    approvedBy,
  } = toc;
  const [isRowHovered, setIsRowHovered] = useState(false);
  const [acuteLos, setAcuteLos] = useState<{ value: string; error: string }>({ value: "", error: "" });
  const [navigatorNotes, setNavigatorNotes] = useState<string | null>(noteCareManager);
  const [physicianNotes] = useState<string | null>(notePhysician);
  const [additionalNotes, setAdditionalNotes] = useState<string | null>(additionalNote);
  const [isHomeWithNoServicesEnabled, setIsHomeWithNoServicesEnabled] = useState(false);
  const [tocItemsForm, setTocItemsForm] = useState(Object.assign({}, TOC_ITEMS));
  const [facilityError, setFacilityError] = useState<string>("");
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [isClearModalOpen, setIsClearModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isTocItemsFormDirty, setIsTocItemsFormDirty] = useState(false);
  const [isFormEmpty, setIsFormEmpty] = useState(false);
  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);

  const getProviderName = useCallback(
    (providerId: number) => {
      if (facilities && facilities.length > 0 && providerId !== -1) {
        return facilities.find((el) => el.ID === providerId)?.ProviderName;
      } else {
        return "";
      }
    },
    [facilities]
  );
  const getTargetLos = useCallback((los: number) => {
    if (los === -1) {
      return "";
    }
    return los.toString();
  }, []);

  const initializeTocItemsForm = useCallback(() => {
    if (transitionOfCareItems && transitionOfCareItems.length > 0) {
      transitionOfCareItems.forEach(({ id: tocItemId, providerId, targetLos, pacTypeName }: ITransitionOfCareItems) => {
        if (pacTypeName === PAC_TYPE.SNF) {
          setTocItemsForm((prev: any) => {
            return {
              ...prev,
              snf: {
                id: tocItemId,
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
                pacTypeName: PAC_TYPE.SNF,
                isLosValid: true,
                isProviderValid: true,
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.IRF) {
          setTocItemsForm((prev: any) => {
            return {
              ...prev,
              irf: {
                id: tocItemId,
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
                pacTypeName: PAC_TYPE.IRF,
                isLosValid: true,
                isProviderValid: true,
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.HH) {
          setTocItemsForm((prev: any) => {
            return {
              ...prev,
              hh: {
                id: tocItemId,
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
                pacTypeName: PAC_TYPE.HH,
                isLosValid: true,
                isProviderValid: true,
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.OPT) {
          setTocItemsForm((prev: any) => {
            return {
              ...prev,
              opt: {
                id: tocItemId,
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
                pacTypeName: PAC_TYPE.OPT,
                isLosValid: true,
                isProviderValid: true,
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.MPT) {
          setTocItemsForm((prev: any) => {
            return {
              ...prev,
              mpt: {
                id: tocItemId,
                providerId,
                providerName: getProviderName(providerId),
                los: getTargetLos(targetLos),
                pacTypeName: PAC_TYPE.MPT,
                isLosValid: true,
                isProviderValid: true,
              },
            };
          });
        }
        if (pacTypeName === PAC_TYPE.HOME_SERVICE && providerId === 0 && targetLos === 0) {
          setIsHomeWithNoServicesEnabled(true);
        }
      });
    }
  }, [transitionOfCareItems, getProviderName, getTargetLos, setTocItemsForm, setIsHomeWithNoServicesEnabled]);

  useEffect(() => {
    if (anticipatedAcuteLOS) {
      setAcuteLos({ value: anticipatedAcuteLOS.toString(), error: "" });
    }
    initializeTocItemsForm();
  }, [setAcuteLos, initializeTocItemsForm, anticipatedAcuteLOS]);

  useEffect(() => {
    if (toc) {
      if (status === TocStatusEnum.NEW) {
        setIsEditModeOn(true);
      }
    }
  }, [toc, status, isExpanded, setIsEditModeOn]);

  const onNavigatorNotesChange = (val: string) => {
    setIsTocItemsFormDirty(true);
    setIsFormEmpty(false);
    setNavigatorNotes(val);
  };

  const onAdditionalNotesChange = (val: string) => {
    setIsTocItemsFormDirty(true);
    setIsFormEmpty(false);
    setAdditionalNotes(val);
  };

  const onChangeAcuteLos = (e: ChangeEvent<HTMLInputElement>) => {
    setIsTocItemsFormDirty(true);
    setIsFormEmpty(false);
    setAcuteLos({
      ...acuteLos,
      value: e.target.value,
      error: "",
    });
  };

  const checkForLocationError = (providerId: number, los: string) => {
    let isProviderValid = false;
    let isLosValid = false;
    if (!los && providerId === -1) {
      isProviderValid = true;
      isLosValid = true;
    } else if (los && providerId === -1) {
      isProviderValid = false;
      isLosValid = true;
    } else if (!los && providerId !== -1) {
      isProviderValid = true;
      isLosValid = false;
    } else if (los && providerId !== -1) {
      isProviderValid = true;
      isLosValid = true;
    }

    return [isProviderValid, isLosValid];
  };

  const onFacilityChange = (key: string, value: { providerId: number; providerName: string }) => {
    setIsTocItemsFormDirty(true);
    setIsFormEmpty(false);
    setFacilityError("");

    const location = tocItemsForm[key];
    let [isProviderValid, isLosValid] = [true, true];
    if (!location.isLosValid || !location.isProviderValid) {
      [isProviderValid, isLosValid] = checkForLocationError(value.providerId, location.los);
    }
    setTocItemsForm((prev: any) => {
      return {
        ...prev,
        [key]: {
          ...prev[key],
          ...value,
          isProviderValid,
          isLosValid,
        },
      };
    });
  };

  const onChangeVisitCount = (key: string, value: string) => {
    setIsTocItemsFormDirty(true);
    setIsFormEmpty(false);
    setFacilityError("");

    const location = tocItemsForm[key];
    let [isProviderValid, isLosValid] = [true, true];
    if (!location.isLosValid || !location.isProviderValid) {
      [isProviderValid, isLosValid] = checkForLocationError(tocItemsForm[key].providerId, value);
    }
    setTocItemsForm((prev: any) => {
      return {
        ...prev,
        [key]: {
          ...prev[key],
          los: value,
          isLosValid,
          isProviderValid,
        },
      };
    });
  };

  const resetLocationValidation = () => {
    const formClone = { ...tocItemsForm };
    Object.values(formClone).forEach((location) => {
      location.isLosValid = true;
      location.isProviderValid = true;
    });
  };

  const checkIfAcuteLosValid = (value: string) => {
    if (!value) {
      return false;
    }
    const acuteLosNum = parseInt(value, 10);
    if (acuteLosNum < 1 || acuteLosNum > 50) {
      return false;
    }
    return true;
  };

  const checkIfNoteValid = () => {
    if (navigatorNotes) {
      return navigatorNotes.length <= NOTE_LIMIT;
    }
    return true;
  };

  const checkIfLocationsValid = () => {
    let isValid = false;
    Object.values(tocItemsForm).forEach((location) => {
      isValid = isValid || (!!location.providerId && !!location.providerName);
    });
    return isValid;
  };

  const checkIfLosForLocationsValid = () => {
    let isValid = true;
    Object.entries(tocItemsForm).forEach(([key, location]: [string, ILocation]) => {
      if (location.providerId && location.providerName) {
        const isLosValid =
          !!location.los &&
          0 < parseInt(location.los, 10) &&
          parseInt(location.los, 10) <= LOS_LIMIT[key.toUpperCase()];
        isValid = isValid && isLosValid;
        if (!isLosValid) {
          setTocItemsForm((prev: any) => {
            return {
              ...prev,
              [key]: {
                ...prev[key],
                isLosValid: false,
              },
            };
          });
        } else {
          setTocItemsForm((prev: any) => {
            return {
              ...prev,
              [key]: {
                ...prev[key],
                isLosValid: true,
              },
            };
          });
        }
      }
    });
    return isValid;
  };

  const checkIfTocItemsValid = () => {
    let isValid = true;
    Object.entries(tocItemsForm).forEach(([key, location]) => {
      if (location.providerId !== -1 || !!location.los) {
        const bothExists = location.providerId !== -1 && !!location.los;
        isValid = isValid && bothExists;
        if (!bothExists) {
          setTocItemsForm((prev: any) => {
            return {
              ...prev,
              [key]: {
                ...prev[key],
                isProviderValid: location.providerId !== -1,
                isLosValid: location.los.length > 0,
              },
            };
          });
        }
      }
    });

    return isValid;
  };

  const checkMandatoryValues = () => {
    const isActuteLosValid = checkIfAcuteLosValid(acuteLos.value);
    setAcuteLos({
      ...acuteLos,
      error: isActuteLosValid ? "" : ACUTE_LOS_ERROR,
    });

    const isNoteValid = checkIfNoteValid();
    if (isHomeWithNoServicesEnabled) {
      return isActuteLosValid && isNoteValid;
    }

    let isTocFormEmpty = true;
    Object.values(tocItemsForm).forEach((location) => {
      isTocFormEmpty = isTocFormEmpty && !location.los && location.providerId === -1;
    });
    if (isTocFormEmpty) {
      setFacilityError(FACILITY_ERROR);
      return false;
    } else {
      setFacilityError("");
    }

    const isLocationsValid = checkIfLocationsValid();
    const areLosForLocationsValid = checkIfLosForLocationsValid();
    const isTocItemsValid = checkIfTocItemsValid();

    return isNoteValid && isActuteLosValid && isLocationsValid && isTocItemsValid && areLosForLocationsValid;
  };

  useEffect(() => {
    if (isExpanded && !!approvedAt && isModified && !history) {
      dispatch(getTocHistory(id));
    }
  }, [id, isExpanded, approvedAt, isModified, history, dispatch]);

  const submitNewToc = () => {
    setIsSubmitModalOpen(false);
    const payload = {
      intakeId,
      clientId,
      anticipatedAcuteLOS: parseInt(acuteLos.value, 10),
      noteCareManager: navigatorNotes ? navigatorNotes.trim() : null,
      additionalNote: additionalNotes ? additionalNotes.trim() : null,
      version,
    } as ICreateNewTocPayload;

    const newTocItems: ICreateTransitionOfCareItem[] = [];

    if (isHomeWithNoServicesEnabled) {
      const tocItem = {
        pacTypeName: PAC_TYPE.HOME_SERVICE,
        providerID: 0,
        targetLOS: 0,
      } as ICreateTransitionOfCareItem;
      newTocItems.push(tocItem);
    } else {
      Object.values(tocItemsForm).forEach(({ pacTypeName, providerId, los }: ILocation) => {
        if (providerId !== -1 && los) {
          const item: ICreateTransitionOfCareItem = {
            pacTypeName,
            providerID: providerId,
            targetLOS: parseInt(los, 10),
          };
          newTocItems.push(item);
        }
      });
    }

    payload.transitionOfCareItems = newTocItems;

    dispatch(setIsTocsLoading(true));
    appDispatch(createTocAsync(payload))
      .unwrap()
      .then(() => {
        dispatch(getTocAsync(intakeId.toString()));
      });
  };

  const editToc = () => {
    setIsSubmitModalOpen(false);
    const payload = {
      id,
      anticipatedAcuteLOS: parseInt(acuteLos.value, 10),
      noteCareManager: navigatorNotes,
      additionalNote: additionalNotes ? additionalNotes.trim() : null,
    } as IEditTocPayload;

    const updateTocItems: IEditTransitionOfCareItem[] = [];

    if (isHomeWithNoServicesEnabled) {
      const existingTocItem = transitionOfCareItems?.find((el) => el.pacTypeName === PAC_TYPE.HOME_SERVICE);
      const tocItem = {
        pacTypeName: PAC_TYPE.HOME_SERVICE,
        providerID: 0,
        targetLOS: 0,
        id: existingTocItem ? existingTocItem.id : -1,
      } as IEditTransitionOfCareItem;
      updateTocItems.push(tocItem);
    } else {
      Object.values(tocItemsForm).forEach(({ id: tocItemId, pacTypeName, providerId, los }: ILocation) => {
        if (providerId !== -1 && los) {
          const item: IEditTransitionOfCareItem = {
            pacTypeName,
            providerID: providerId,
            targetLOS: parseInt(los, 10),
            id: !tocItemId ? "-1" : tocItemId,
          };
          updateTocItems.push(item);
        }
      });
    }

    payload.transitionOfCareItems = updateTocItems;
    dispatch(setIsTocsLoading(true));
    appDispatch(editTocAsync(payload))
      .unwrap()
      .then(() => {
        dispatch(getTocAsync(intakeId.toString()));
      });
  };

  const clearFormDetails = () => {
    setIsClearModalOpen(false);
    setIsTocItemsFormDirty(false);
    setIsFormEmpty(true);
    setNavigatorNotes("");
    setAcuteLos({ value: "", error: "" });
    setTocItemsForm(Object.assign({}, TOC_ITEMS));
    setFacilityError("");
    setIsHomeWithNoServicesEnabled(false);
  };

  const resetFormDetails = () => {
    setIsCancelModalOpen(false);
    setIsTocItemsFormDirty(false);
    setIsFormEmpty(true);
    setNavigatorNotes(noteCareManager);
    if (anticipatedAcuteLOS) {
      setAcuteLos({ value: anticipatedAcuteLOS.toString(), error: "" });
    }
    setTocItemsForm(Object.assign({}, TOC_ITEMS));
    initializeTocItemsForm();
    setFacilityError("");
    setIsEditModeOn(false);
    setIsHomeWithNoServicesEnabled(false);
    transitionOfCareItems?.forEach(({ pacTypeName, providerId, targetLos }: ITransitionOfCareItems) => {
      if (pacTypeName === PAC_TYPE.HOME_SERVICE && providerId === 0 && targetLos === 0) {
        setIsHomeWithNoServicesEnabled(true);
      }
    });
  };

  const getLosVisitsError = (facilityName: string) => {
    return (
      <span>
        <img src={ErrorIcon} alt="Error" />
        {LOS_VISIT_ERROR + LOS_LIMIT[facilityName]}
      </span>
    );
  };

  const getProviderError = (facilityName: string) => {
    return (
      <span>
        <img src={ErrorIcon} alt="Error" />
        {`Enter a facility for ${facilityName}`}
      </span>
    );
  };

  const handleTocExpand = () => {
    const tocId = id;
    const newTocs: ITransitionOfCare[] = [];
    if (toc.approvedAt) {
      tocs.forEach((item: any) => {
        const updatedItem = { ...item };
        if (item.approvedAt) {
          if (item.id === tocId) {
            updatedItem.isExpanded = !item.isExpanded;
          } else {
            updatedItem.isExpanded = false;
          }
        }

        newTocs.push(updatedItem as ITransitionOfCare);
      });
    } else {
      tocs.forEach((item: any) => {
        const updatedItem = { ...item };
        if (!item.approvedAt) {
          if (item.id === tocId) {
            updatedItem.isExpanded = !item.isExpanded;
          }
        }

        newTocs.push(updatedItem as ITransitionOfCare);
      });
    }
    dispatch(updateTocList(newTocs));
  };

  const enableEditMode = () => {
    if (!isEditModeOn) {
      setIsEditModeOn(true);
    }
    if (!isExpanded) {
      handleTocExpand();
    }
  };

  const renderTocDetailsInReadMode = () => {
    let facilityName = "-";
    const facility = facilities.find((el) => el.ID === intake?.facilityId);
    if (facility) {
      facilityName = facility.ProviderName;
    }

    const { irf, snf, hh, opt, mpt } = tocItemsForm;
    return (
      <>
        <div className={`toc-approve-info-readonly `}>
          <div className="toc-content">
            <div className="toc-plan">
              <div className="episode-details">
                <div className="toc-plan-heading">ToC Plan</div>
                <div className="toc-plan-los-container">
                  <div className="acute-los-heading">Acute LOS</div>
                  <div className="acute-los-heading-sep">-</div>
                  <div className="acute-los-readonly">{acuteLos.value}</div>
                </div>
              </div>
            </div>
            <div className="location-grid-container">
              <div className="location-dropdowns">
                <div className="location-row-header">
                  <div className="location">Location</div>
                  <div className="location-name">Location Name</div>
                  <div className="los">LOS/Visits</div>
                </div>

                <div className="location-row">
                  <div className="location-value">IRF</div>
                  <div className="toc-dropdown-readonly">{irf.providerName.length ? irf.providerName : "-"}</div>
                  <div className="toc-visits-readonly">{irf?.los?.length ? irf.los : "-"}</div>
                </div>

                <div className="location-row">
                  <div className="location-value">SNF</div>
                  <div className="toc-dropdown-readonly">{snf.providerName.length ? snf.providerName : "-"}</div>
                  <div className="toc-visits-readonly">{snf?.los?.length ? snf.los : "-"}</div>
                </div>

                <div className="location-row">
                  <div className="location-value">HH</div>
                  <div className="toc-dropdown-readonly">{hh.providerName.length ? hh.providerName : "-"}</div>
                  <div className="toc-visits-readonly">{hh?.los?.length ? hh.los : "-"}</div>
                </div>

                <div className="location-row">
                  <div className="location-value">M-PT</div>
                  <div className="toc-dropdown-readonly">{mpt.providerName.length ? mpt.providerName : "-"}</div>
                  <div className="toc-visits-readonly">{mpt?.los?.length ? mpt.los : "-"}</div>
                </div>

                <div className="location-row">
                  <div className="location-value">OPT</div>
                  <div className="toc-dropdown-readonly">{opt.providerName.length ? opt.providerName : "-"}</div>
                  <div className="toc-visits-readonly">{opt?.los?.length ? opt.los : "-"}</div>
                </div>
              </div>

              {isHomeWithNoServicesEnabled ? (
                <div className="checkbox-container">
                  <div className="checkboxes" key="Incoming-checkox">
                    <Checkbox
                      name="homeWithoutServices"
                      value="Home w/No services"
                      id="Incoming-checkox"
                      onChange={() => {
                        resetLocationValidation();
                        setIsHomeWithNoServicesEnabled(!isHomeWithNoServicesEnabled);
                      }}
                      isDisabled
                      isChecked={isHomeWithNoServicesEnabled}
                    />
                  </div>
                </div>
              ) : null}

              {!!approvedAt && isModified ? (
                <div className="view-changes-container">
                  <span className="cta" onClick={() => setIsChangeModalOpen(true)}>
                    View Changes
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          <div className="note-container">
            <div className="external-notes">
              <div className="navigator-notes">
                <div className="note-heading">Navigator Notes</div>
                <div className="note-textarea-readonly">
                  {!!navigatorNotes && navigatorNotes.length ? navigatorNotes : "-"}
                </div>
              </div>
              {approvedAt ? (
                <div className="physician-notes">
                  <div className="note-heading">Physician Notes</div>
                  <div className="note-textarea-readonly">
                    {!!physicianNotes && physicianNotes.length ? physicianNotes : "-"}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="internal-notes">
              <div className="note-heading">Additional Notes {"(Internal)"}</div>
              <div className="note-textarea-readonly">
                {!!additionalNotes && additionalNotes.length ? additionalNotes : "-"}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderTocDetailsInEditMode = () => {
    let facilityName = "-";
    const facility = facilities.find((el) => el.ID === intake?.facilityId);
    if (facility) {
      facilityName = facility.ProviderName;
    }
    const { irf, snf, hh, opt, mpt } = tocItemsForm;
    return (
      <>
        <div className={`toc-approve-info `}>
          <div className="toc-plan">
            <div className="episode-details">
              <div className="toc-plan-heading">ToC Plan</div>
              <div className="toc-plan-los-container">
                <div className="acute-los-heading">Acute LOS</div>
                <div className="acute-los">
                  <input
                    className={`actue-los-input ${!acuteLos.error ? null : "input-error"}`}
                    type="string"
                    placeholder="0"
                    name="acute-los"
                    value={acuteLos.value}
                    maxLength={2}
                    onChange={(e: any) => {
                      if ((validateNumber(e.target.value) || e.target.value === "") && e.target.value !== "0") {
                        onChangeAcuteLos(e);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (["e", ".", "-", "+"].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="toc-plan-error">
              {!acuteLos.error ? null : (
                <span>
                  <img src={ErrorIcon} alt="Error" />
                  {acuteLos.error}
                </span>
              )}
              {!facilityError ? null : (
                <span>
                  <img src={ErrorIcon} alt="Error" />
                  {facilityError}
                </span>
              )}
            </div>
          </div>
          <div className="note-container">
            <div className="navigator-notes">
              <div className="note-heading">Navigator Notes</div>
              <textarea
                className="note-textarea"
                value={navigatorNotes ?? ""}
                onChange={(e: any) => {
                  onNavigatorNotesChange(e.target.value);
                }}
                maxLength={2000}
                placeholder="Write your notes here for the physician..."
                cols={5}
              />
              {navigatorNotes && navigatorNotes.length > 0 && (
                <div className="note-hint">
                  <div className="note-limit">{`${navigatorNotes.length}/${2000}`}</div>
                </div>
              )}
            </div>
            <div className="additinal-notes">
              <div className="note-heading">Additional Notes {"(Internal)"}</div>
              <textarea
                className="note-textarea"
                value={additionalNotes ?? ""}
                onChange={(e: any) => {
                  onAdditionalNotesChange(e.target.value);
                }}
                maxLength={2000}
                placeholder="Write your notes here for internal use..."
                cols={5}
              />
              {additionalNotes && additionalNotes?.length > 0 && (
                <div className="note-hint">
                  <div className="note-limit">{`${additionalNotes?.length}/${2000}`}</div>
                </div>
              )}
            </div>
          </div>
          <div className="location-grid-container">
            <div className="location-dropdowns">
              <div className="location-row-header">
                <div className="location">Location</div>
                <div className="location-name">Location Name</div>
                <div className="los">LOS/Visits</div>
              </div>

              <div className="location-row">
                <div className="location-content">
                  <div className="location-value">IRF</div>
                  <div className="toc-dropdown">
                    <FacilityDropdown
                      selectedOptionId={tocItemsForm.irf.providerId}
                      isError={!tocItemsForm.irf.isProviderValid}
                      onChange={(providerId: number, providerName: string) => {
                        onFacilityChange("irf", { providerId, providerName });
                      }}
                      isDisabled={isHomeWithNoServicesEnabled}
                    />
                  </div>
                  <div className="toc-visits">
                    <input
                      type="string"
                      placeholder="0"
                      name={PAC_TYPE.IRF}
                      value={tocItemsForm.irf.los}
                      maxLength={2}
                      onChange={(e: any) => {
                        if ((validateNumber(e.target.value) || e.target.value === "") && e.target.value !== "0") {
                          onChangeVisitCount("irf", e.target.value);
                        }
                      }}
                      disabled={isHomeWithNoServicesEnabled}
                      className={`visits-input ${tocItemsForm.irf.isLosValid ? "" : "input-error"}`}
                      onKeyDown={(e) => {
                        if (["e", ".", "-", "+"].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="location-error">
                  {!irf.isProviderValid ? getProviderError("IRF") : null}
                  {!irf.isLosValid ? getLosVisitsError("IRF") : null}
                </div>
              </div>

              <div className="location-row">
                <div className="location-content">
                  <div className="location-value">SNF</div>
                  <div className="toc-dropdown">
                    <FacilityDropdown
                      selectedOptionId={tocItemsForm.snf.providerId}
                      isError={!tocItemsForm.snf.isProviderValid}
                      onChange={(providerId: number, providerName: string) => {
                        onFacilityChange("snf", { providerId, providerName });
                      }}
                      isDisabled={isHomeWithNoServicesEnabled}
                    />
                  </div>
                  <div className="toc-visits">
                    <input
                      type="string"
                      placeholder="0"
                      name={PAC_TYPE.SNF}
                      value={tocItemsForm.snf.los}
                      maxLength={2}
                      onChange={(e: any) => {
                        if ((validateNumber(e.target.value) || e.target.value === "") && e.target.value !== "0") {
                          onChangeVisitCount("snf", e.target.value);
                        }
                      }}
                      className={`visits-input ${tocItemsForm.snf.isLosValid ? "" : "input-error"}`}
                      disabled={isHomeWithNoServicesEnabled}
                      onKeyDown={(e) => {
                        if (["e", ".", "-", "+"].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="location-error">
                  {!snf.isProviderValid ? getProviderError("SNF") : null}
                  {!snf.isLosValid ? getLosVisitsError("SNF") : null}
                </div>
              </div>

              <div className="location-row">
                <div className="location-content">
                  <div className="location-value">HH</div>
                  <div className="toc-dropdown">
                    <FacilityDropdown
                      selectedOptionId={tocItemsForm.hh.providerId}
                      isError={!tocItemsForm.hh.isProviderValid}
                      onChange={(providerId: number, providerName: string) => {
                        onFacilityChange("hh", { providerId, providerName });
                      }}
                      isDisabled={isHomeWithNoServicesEnabled}
                    />
                  </div>
                  <div className="toc-visits">
                    <input
                      type="string"
                      placeholder="0"
                      name={PAC_TYPE.HH}
                      value={tocItemsForm.hh.los}
                      maxLength={2}
                      onChange={(e: any) => {
                        if ((validateNumber(e.target.value) || e.target.value === "") && e.target.value !== "0") {
                          onChangeVisitCount("hh", e.target.value);
                        }
                      }}
                      className={`visits-input ${tocItemsForm.hh.isLosValid ? "" : "input-error"}`}
                      disabled={isHomeWithNoServicesEnabled}
                      onKeyDown={(e) => {
                        if (["e", ".", "-", "+"].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="location-error">
                  {!hh.isProviderValid ? getProviderError("HH") : null}
                  {!hh.isLosValid ? getLosVisitsError("HH") : null}
                </div>
              </div>

              <div className="location-row">
                <div className="location-content">
                  <div className="location-value">M-PT</div>
                  <div className="toc-dropdown">
                    <FacilityDropdown
                      selectedOptionId={tocItemsForm.mpt.providerId}
                      isError={!tocItemsForm.mpt.isProviderValid}
                      onChange={(providerId: number, providerName: string) => {
                        onFacilityChange("mpt", { providerId, providerName });
                      }}
                      isDisabled={isHomeWithNoServicesEnabled}
                    />
                  </div>
                  <div className="toc-visits">
                    <input
                      type="string"
                      placeholder="0"
                      name={PAC_TYPE.MPT}
                      value={tocItemsForm.mpt.los}
                      maxLength={2}
                      onChange={(e: any) => {
                        if ((validateNumber(e.target.value) || e.target.value === "") && e.target.value !== "0") {
                          onChangeVisitCount("mpt", e.target.value);
                        }
                      }}
                      className={`visits-input ${tocItemsForm.mpt.isLosValid ? "" : "input-error"}`}
                      disabled={isHomeWithNoServicesEnabled}
                      onKeyDown={(e) => {
                        if (["e", ".", "-", "+"].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="location-error">
                  {!mpt.isProviderValid ? getProviderError("M-PT") : null}
                  {!mpt.isLosValid ? getLosVisitsError("MPT") : null}
                </div>
              </div>

              <div className="location-row">
                <div className="location-content">
                  <div className="location-value">OPT</div>
                  <div className="toc-dropdown">
                    <FacilityDropdown
                      selectedOptionId={tocItemsForm.opt.providerId}
                      isError={!tocItemsForm.opt.isProviderValid}
                      onChange={(providerId: number, providerName: string) => {
                        onFacilityChange("opt", { providerId, providerName });
                      }}
                      isDisabled={isHomeWithNoServicesEnabled}
                    />
                  </div>
                  <div className="toc-visits">
                    <input
                      type="string"
                      placeholder="0"
                      name={PAC_TYPE.OPT}
                      value={tocItemsForm.opt.los}
                      maxLength={2}
                      onChange={(e: any) => {
                        if ((validateNumber(e.target.value) || e.target.value === "") && e.target.value !== "0") {
                          onChangeVisitCount("opt", e.target.value);
                        }
                      }}
                      className={`visits-input ${tocItemsForm.opt.isLosValid ? "" : "input-error"}`}
                      disabled={isHomeWithNoServicesEnabled}
                      onKeyDown={(e) => {
                        if (["e", ".", "-", "+"].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="location-error">
                  {!opt.isProviderValid ? getProviderError("OPT") : null}
                  {!opt.isLosValid ? getLosVisitsError("OPT") : null}
                </div>
              </div>
            </div>

            <div className="checkbox-container">
              <div className="checkboxes" key="Incoming-checkox">
                <Checkbox
                  name="homeWithoutServices"
                  value="Home w/No services"
                  id="Incoming-checkox"
                  onChange={() => {
                    resetLocationValidation();
                    setIsTocItemsFormDirty(true);
                    setIsFormEmpty(false);
                    setIsHomeWithNoServicesEnabled(!isHomeWithNoServicesEnabled);
                  }}
                  isChecked={isHomeWithNoServicesEnabled}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="last-row-with-approve-button">
          <div className="cta-container">
            {status === TocStatusEnum.PENDING ? (
              <Button
                text="Cancel"
                className={`white-button cancel-button`}
                showLoader={false}
                disabled={!isEditModeOn}
                onClick={() => {
                  setIsCancelModalOpen(true);
                }}
              />
            ) : null}
            <Button
              text="Clear"
              className={`white-button clear-button`}
              showLoader={false}
              disabled={!isTocItemsFormDirty || isFormEmpty}
              onClick={() => {
                setIsClearModalOpen(true);
              }}
            />
            <Button
              text="Submit"
              className={`green-button submit-button `}
              showLoader={false}
              disabled={!isTocItemsFormDirty}
              onClick={() => {
                if (checkMandatoryValues()) {
                  setIsSubmitModalOpen(true);
                }
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isSubmitModalOpen ? (
        <GenericPopupModal
          submitButtonTitle={"Confirm"}
          content={"Are you sure, you want to Submit?"}
          isOpen={isSubmitModalOpen}
          handleCancelClick={() => {
            setIsSubmitModalOpen(false);
          }}
          handleSubmitClick={() => {
            if (toc.id) {
              editToc();
            } else {
              submitNewToc();
            }
          }}
          cancelButtonVisible
        ></GenericPopupModal>
      ) : null}

      {isClearModalOpen && isTocItemsFormDirty ? (
        <GenericPopupModal
          submitButtonTitle={"Clear"}
          content={"Are you sure, you want to Clear?"}
          isOpen={isClearModalOpen}
          handleCancelClick={() => {
            setIsClearModalOpen(false);
          }}
          handleSubmitClick={() => {
            clearFormDetails();
          }}
          cancelButtonVisible
        ></GenericPopupModal>
      ) : null}
      {isCancelModalOpen ? (
        <GenericPopupModal
          submitButtonTitle={"OK"}
          content={"Are you sure, you want to Cancel?"}
          isOpen={isCancelModalOpen}
          handleCancelClick={() => {
            setIsCancelModalOpen(false);
          }}
          handleSubmitClick={() => {
            handleTocExpand();
            resetFormDetails();
          }}
          cancelButtonVisible
        ></GenericPopupModal>
      ) : null}
      {isChangeModalOpen && !!history ? (
        <TocChangeModal
          isOpen={isChangeModalOpen}
          facilities={facilities}
          onClose={(isOpen) => setIsChangeModalOpen(isOpen)}
          historyOfToc={history}
          toc={toc}
        />
      ) : null}
      <div
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
        className={`toc-row ${isRowHovered ? "hovered" : ""} ${isExpanded ? "opened" : ""}`}
      >
        <div className="toc-cell">{version === 1 ? "Original TOC" : `Revision ${version - 1}`}</div>
        <div className="toc-cell">{createdAt ? moment(createdAt).format("MM/DD/YYYY") : "-"}</div>
        <div className={`toc-cell ${status === TocStatusEnum.PENDING ? "pending-status" : null}`}>{status}</div>
        {approvedAt ? (
          <div className="toc-cell">
            <div className="approved-date">{moment(approvedAt).format("MM/DD/YYYY : hh:mm A")} </div>
            <div className="approved-by">{"by " + (approvedBy ? approvedBy : "-")}</div>
          </div>
        ) : (
          <div className="toc-cell">{"-"}</div>
        )}
        <div className={`toc-cell icons ${isRowHovered || isExpanded ? "visible" : "hidden"}`}>
          {isRowHovered && status === TocStatusEnum.PENDING ? (
            <>
              {!isEditModeOn ? (
                <>
                  <span
                    className="icon-background edit-button"
                    data-tip
                    data-for={"Edit"}
                    onClick={() => enableEditMode()}
                  >
                    <Icon icon="edit" size={10} />
                    <CustomToolTip text="Edit" place="left" />
                  </span>
                </>
              ) : null}
            </>
          ) : null}
          {isExpanded ? (
            <Button
              icon="up-arrow"
              onClick={() => {
                handleTocExpand();
              }}
              buttonSize={10}
              className="icon-background"
            />
          ) : (
            <Button
              icon="down-arrow"
              onClick={() => {
                handleTocExpand();
              }}
              buttonSize={10}
              className="icon-background"
            />
          )}
        </div>
      </div>
      {isExpanded ? (
        <>
          {approvedAt ? (
            renderTocDetailsInReadMode()
          ) : (
            <>{isEditModeOn ? renderTocDetailsInEditMode() : renderTocDetailsInReadMode()}</>
          )}
        </>
      ) : null}
    </>
  );
};

export default TocRevision;
