import { createSlice } from "@reduxjs/toolkit";
import { ICommonState } from "state/types/common-slice.type";
import { checkIfFeatureEnabledAsync, getConfigurationSettings } from "./common.action";
import { OpenFrom } from "shared/enums/chat.enum";

const initialState: ICommonState = {
  isLoading: false,
  isAppInitialized: false,
  isError: false,
  featureFlagsLoading: {
    riskAssessment: false,
    inboundCalls: false,
    userManagement: false,
    taskManagement: true,
    taskManagementListView: false,
    inAppNotifications: false,
    navigatorEpisodeList: false,
  },
  modal: {
    isOpenChatModal: { isOpen: false, openFrom: OpenFrom.COMPOSE },
    isOpenMobileMenuNav: false,
    isOpenFilterModal: false,
    isOpenTranscriptionModal: false,
    isOpenLogoutModal: false,
    isOpenCallModal: false,
    isSessionTimeoutModalVisible: false,
    isOpenProfileEditor: false,
    isOpenNumberSelectorModal: false,
    isOpenTwilioPopup: false,
    isOpenApproveToc: false,
    isOpenDownloadFileModal: false,
    isOpenAudioModal: false,
    isOpenChatModalForNotification: false,
    isOpenForceCallQuitModal: false,
  },
  isInboundCalled: false,
  featureFlags: {
    riskAssessment: false,
    inboundCalls: false,
    userManagement: false,
    taskManagement: false,
    taskManagementListView: false,
    inAppNotifications: false,
    navigatorEpisodeList: false,
  },
  globalValues: {
    twilioClientRef: null,
  },
  twilioErrorCode: null,
  audioPlayerVolume: 1,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setTwilioErrorCode: (state, action) => {
      state.twilioErrorCode = action.payload;
    },
    setIsOpenLogoutModal: (state, action) => {
      state.modal.isOpenLogoutModal = action.payload;
    },
    setIsOpenChatModal: (state: any, action) => {
      state.modal.isOpenChatModal = { isOpen: action.payload.isOpen, openFrom: action.payload.openFrom };
    },
    setIsOpenMobileMenuNav: (state: any, action) => {
      state.modal.isOpenMobileMenuNav = action.payload;
    },
    setIsFilterModal: (state: any, action) => {
      state.modal.isOpenFilterModal = action.payload;
    },
    setIsOpenCallModal: (state: any, action) => {
      state.modal.isOpenCallModal = action.payload;
    },
    setIsSessionTimeoutModalVisible: (state: any, action) => {
      state.modal.isSessionTimeoutModalVisible = action.payload;
    },
    setIsOpenTranscriptionModal: (state: any, action) => {
      state.modal.isOpenTranscriptionModal = action.payload;
    },
    setIsOpenProfileEditor: (state: any, action) => {
      state.modal.isOpenProfileEditor = action.payload;
    },
    setIsOpenNumberSelectorModal: (state: any, action) => {
      state.modal.isOpenNumberSelectorModal = action.payload;
    },
    setTwilioClientRef: (state: any, action) => {
      state.globalValues.twilioClientRef = action.payload;
    },
    setShowTwilioPopup: (state, action) => {
      state.modal.isOpenTwilioPopup = action.payload;
    },
    resetCommon: (state: any) => initialState,
    setIsInboundCalled: (state, action) => {
      state.isInboundCalled = action.payload;
    },
    setIsOpenApproveToc: (state: any, action) => {
      state.modal.isOpenApproveToc = action.payload;
    },
    setIsOpenDownloadFileModal: (state: any, action) => {
      state.modal.isOpenDownloadFileModal = action.payload;
    },
    setIsOpenAudioModal: (state, action) => {
      state.modal.isOpenAudioModal = action.payload;
    },
    setPlayerVolume: (state, action) => {
      state.audioPlayerVolume = action.payload;
    },
    setIsOpenChatModalForNotification: (state: any, action) => {
      state.modal.isOpenChatModalForNotification = action.payload;
    },
    setIsOpenForceCallQuitModal: (state: any, action) => {
      state.modal.isOpenForceCallQuitModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(checkIfFeatureEnabledAsync.pending, (state: any, action) => {
        state.featureFlagsLoading[action.meta.arg.featureFlagName] = true;
        state.isLoading = true;
      }),
      builder.addCase(checkIfFeatureEnabledAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.featureFlagsLoading[action.meta.arg.featureFlagName] = false;
        state.featureFlags = { ...state.featureFlags, [action.meta.arg.featureFlagName]: action.payload.data };
      }),
      builder.addCase(checkIfFeatureEnabledAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
        state.featureFlagsLoading[action.meta.arg.featureFlagName] = false;
      }),
      builder.addCase(getConfigurationSettings.pending, (state: any, action) => {
        state.isAppInitialized = false;
      }),
      builder.addCase(getConfigurationSettings.fulfilled, (state: any, action) => {
        state.isAppInitialized = true;
        state.isError = false;
      }),
      builder.addCase(getConfigurationSettings.rejected, (state: any, action) => {
        state.isAppInitialized = true;
        state.isError = true;
      })
    );
  },
});

export default commonSlice;
export const {
  setIsOpenNumberSelectorModal,
  setIsOpenChatModal,
  setIsOpenMobileMenuNav,
  setIsFilterModal,
  setIsOpenCallModal,
  setIsOpenTranscriptionModal,
  setIsSessionTimeoutModalVisible,
  setIsOpenProfileEditor,
  setTwilioClientRef,
  resetCommon,
  setIsOpenLogoutModal,
  setShowTwilioPopup,
  setIsInboundCalled,
  setTwilioErrorCode,
  setIsOpenApproveToc,
  setIsOpenDownloadFileModal,
  setIsOpenAudioModal,
  setPlayerVolume,
  setIsOpenChatModalForNotification,
  setIsOpenForceCallQuitModal,
} = commonSlice.actions;
export const getCommonState = (state: any): ICommonState => state.common;
