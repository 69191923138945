import Task from "../task/task.component";
import { useEffect } from "react";
import { useParams } from "react-router";
import lodash from "lodash";
import { useSelector } from "react-redux";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";
import InfiniteScroll from "react-infinite-scroll-component";
import TableLoader from "components/TableLoader/table-loader.component";
import { Task as TaskType } from "state/types/task-management-slice.type";

interface TaskListProps {
  tasks: TaskType[];
  incrementPage: () => void;
  isLoading: boolean;
}

const TaskList = ({ tasks, incrementPage, isLoading }: TaskListProps) => {
  const { intakeId } = useParams<{ intakeId: string }>();
  const { isTaskListExpanded, isValidatingIntake } = useSelector(getTaskManagementState);

  useEffect(() => {
    if (!isValidatingIntake && isTaskListExpanded && intakeId) {
      const parseIntakeId = lodash.parseInt(intakeId);
      if (!lodash.isNaN(parseIntakeId)) {
        const taskId = document.getElementById(intakeId);
        if (taskId) {
          setTimeout(() => taskId.scrollIntoView({}), 100);
        }
      }
    }
  }, [intakeId, isTaskListExpanded, isValidatingIntake]);

  return (
    <div className="task-list">
      <InfiniteScroll
        dataLength={tasks.length}
        next={() => {
          incrementPage();
        }}
        hasMore={tasks.length > 0}
        loader={isLoading && <TableLoader />}
        height="100%"
      >
        {tasks.map((item) => {
          return <Task key={item.id} {...item} />;
        })}
      </InfiniteScroll>
    </div>
  );
};

export default TaskList;
