import "./button-loader.styles.scss";
import loader from "shared/assets/images/WhiteLoader.gif";

const ButtonLoader = () => {
  return (
    <div className="buttonloader">
      <img className="button-loader-img" src={loader} alt="loader" />
    </div>
  );
};

export default ButtonLoader;
