export enum PageName {
  RISK_ASSESSMENT = "risk-assessment",
  TRANSITION_OF_CARE = "toc",
  ASSESSMENT = "assessment",
}

export enum PageSection {
  RESULT = "result",
  SUMMARY = "summary",
}
