import NoTaskImage from "shared/assets/images/no-task-found.svg";
import "./no-task-found.styles.scss";

const NoTaskFound = () => {
  return (
    <div className="no-task-found-container">
      <img src={NoTaskImage} alt="No task found" className="no-task-found-image" />
      <div className="text">Oops! No tasks found</div>
    </div>
  );
};

export default NoTaskFound;
