import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { TocStatusEnum } from "pages/task-management/transition-of-care/constants/index.constant";
import { axiosInstance } from "shared/axios-instance";
import {
  ICreateNewTocPayload,
  IEditTocPayload,
  IIntake,
  ITransitionOfCare,
} from "state/types/toc-management-slice.type";

export const getTocAsync = createAsyncThunk(
  "toc-management/getTocAsync",
  async (intakeId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/TransitionOfCare/${intakeId}`);

      if (response.status === 200) {
        return response.data.data.map(
          (item: any) =>
            ({
              id: item.id,
              intakeId: item.intakeId,
              clientId: item.clientId,
              noteCareManager: item.noteCareManager,
              notePhysician: item.notePhysician,
              version: item.version,
              anticipatedAcuteLOS: item.anticipatedAcuteLOS,
              createdAt: item.createdAt,
              approvedAt: item.reviewedAt,
              transitionOfCareItems: item.transitionOfCareItems,
              isExpanded: !item.reviewedAt,
              status: !item.reviewedAt ? TocStatusEnum.PENDING : TocStatusEnum.APPROVED,
              isModified: item.isModified,
              additionalNote: item.additionalNote,
              approvedBy: item.approvedBy,
            } as ITransitionOfCare)
        );
      }
      return [];
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  }
);

export const getAllTocFacilitiesAsync = createAsyncThunk(
  "toc-management/getAllTocFacilitiesAsync",
  async (intakeId: string) => {
    const response = await axiosInstance.get(
      `${getConfig().efusionBase}/api/v2/ExtProvider/getallByIntake/${intakeId}/false/true/false/false/true`,
      { params: {} }
    );
    return response.data;
  }
);

export const createTocAsync = createAsyncThunk(
  "toc-management/createTocAsync",
  async (payload: ICreateNewTocPayload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${getConfig().taskManagementBase}/api/v1/TransitionOfCare`, payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  }
);

export const editTocAsync = createAsyncThunk(
  "toc-management/editTocAsync",
  async (payload: IEditTocPayload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axiosInstance.put(`${getConfig().taskManagementBase}/api/v1/TransitionOfCare`, payload);
      return fulfillWithValue(res.data);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  }
);

export const getIntakeAsync = createAsyncThunk("toc-management/getIntakeAsync", async (intakeId: string) => {
  const result = await axiosInstance.get(`${getConfig().efusionBase}/api/v2/ExtIntake/${intakeId}`);
  if (result.status === 200) {
    return {
      id: result.data.ID,
      facilityId: result.data.FacilityID,
      episodeName: result.data.EpisodeLongName,
      clientId: parseInt(result.data.ClientID, 10),
    } as IIntake;
  }
  return null;
});

export const getTocHistory = createAsyncThunk("toc-management/getTocHistory", async (tocId: string) => {
  const response = await axiosInstance.get(
    `${getConfig().taskManagementBase}/api/v1/TransitionOfCare/${tocId}/history`
  );
  const item = response.data.data;
  return {
    id: item.id,
    intakeId: item.intakeId,
    clientId: item.clientId,
    noteCareManager: item.noteCareManager,
    notePhysician: item.notePhysician,
    version: item.version,
    anticipatedAcuteLOS: item.anticipatedAcuteLOS,
    createdAt: item.createdAt,
    approvedAt: item.reviewedAt,
    transitionOfCareItems: item.transitionOfCareItems,
    isExpanded: !item.reviewedAt,
    status: !item.reviewedAt ? TocStatusEnum.PENDING : TocStatusEnum.APPROVED,
    isModified: item.isModified,
    approvedBy: item.approvedBy,
  } as ITransitionOfCare;
});
