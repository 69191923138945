import { createSlice } from "@reduxjs/toolkit";
import { getEpisodeDetailsById, getEpisodesAsync, getEpisodesFilterStatusMenuAsync } from "./episodes.action";
import { IEpisodeState } from "state/types/episodes-slice.type";

const initialState: IEpisodeState = {
  isLoading: true,
  isLoadingEpisodeFilterStatus: true,
  isError: false,
  episodes: [],
  episodeDetails: null,
  isLoadingEpisodeDetails: true,
};

const episodesSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    resetEpisodesState: (state: any) => initialState,
    resetEpisodesList: (state: any) => {
      state.episodes = [];
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getEpisodesAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(getEpisodesAsync.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      }),
      builder.addCase(getEpisodesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.episodes = action.meta.arg.offset === 0 ? action.payload : [...state.episodes, ...action.payload];
      }),
      builder.addCase(getEpisodesFilterStatusMenuAsync.pending, (state, action) => {
        state.isLoadingEpisodeFilterStatus = true;
        state.isError = false;
      }),
      builder.addCase(getEpisodesFilterStatusMenuAsync.fulfilled, (state, action) => {
        state.isLoadingEpisodeFilterStatus = false;
        state.isError = false;
      }),
      builder.addCase(getEpisodesFilterStatusMenuAsync.rejected, (state, action) => {
        state.isLoadingEpisodeFilterStatus = false;
        state.isError = false;
      }),
      builder.addCase(getEpisodeDetailsById.pending, (state) => {
        state.isLoadingEpisodeDetails = true;
        state.isError = false;
      }),
      builder.addCase(getEpisodeDetailsById.rejected, (state) => {
        state.isLoadingEpisodeDetails = false;
        state.isError = true;
      }),
      builder.addCase(getEpisodeDetailsById.fulfilled, (state, action) => {
        state.isLoadingEpisodeDetails = false;
        state.isError = false;
        state.episodeDetails = action.payload;
      })
    );
  },
});

export default episodesSlice;
export const { resetEpisodesState, resetEpisodesList } = episodesSlice.actions;
export const getEpisodesSlice = (state: any): IEpisodeState => state.episodes;
