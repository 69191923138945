import "./task-not-selected.styles.scss";
import ClickOnFirstTaskImage from "shared/assets/images/click-on-first-task.svg";

const TaskNotSelected = () => {
  return (
    <div className="task-not-selected">
      <div className="task-not-selected-content">
        <img src={ClickOnFirstTaskImage} alt="Click on first task" />
        <div className="heading">To get started, click on the first task</div>
        <div className="sub-heading">Make sure you have episodes to see related tasks here</div>
      </div>
    </div>
  );
};

export default TaskNotSelected;
