import { useEffect } from "react";
import "./episode-details.style.scss";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { getEpisodeDetailsById } from "state/feature/episodes/episodes.action";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import EpisodeDetailsPresentation from "./episode-details.component";

const EpisodeDetails = () => {
  useAxiosAuthenticated();
  const { intakeId } = useParams<{ intakeId: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEpisodeDetailsById({ intakeId: intakeId }));
  }, [dispatch, intakeId]);

  return (
    <div id="episode-details-container">
      <EpisodeDetailsPresentation />
    </div>
  );
};
export default EpisodeDetails;
