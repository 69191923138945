import Icon from "../Icon/icon.component";
import { useDispatch } from "react-redux";
import "./modal-header.styles.scss";
import { setIsOpenTranscriptionModal } from "state/feature/common/common.slice";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";

const ModalHeader = ({
  heading,
  onCloseModal,
  icon,
  iconClassName,
}: {
  heading: string;
  onCloseModal?: () => void;
  icon?: React.ReactNode;
  iconClassName?: string;
}) => {
  const dispatch = useDispatch();
  const onClose = () => {
    // history.back();
    if (onCloseModal) {
      onCloseModal();
      return;
    }
    dispatch(setIsOpenTranscriptionModal(false));
  };
  return (
    <div id="modal-header">
      <header>
        {icon}
        {heading}
      </header>
      <div
        data-tip
        data-for={"Close"}
        className={`close-icon-container ${iconClassName ? iconClassName : ""}`}
        onClick={onClose}
      >
        <Icon icon="close" size={16} className="close-icon" />
        <CustomToolTip text={"Close"} place={"bottom"} />
      </div>
    </div>
  );
};

export default ModalHeader;
