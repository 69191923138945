import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import Header from "components/Header/header.component";
import Button from "components/Button/button.component";
import Toggle from "components/Toggle/toggle.component";
import "./contact.styles.scss";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { contactsPatientTableColumns, contactsPracticeTableColumns } from "shared/constant/table";
import SearchBox from "components/SearchBox/search-box.component";
import { getAuth } from "state/feature/auth/auth.slice";
import { getContactState, resetContacts } from "state/feature/contact/contact.slice";
import { getCallState } from "state/feature/call/call.slice";
import {
  getNavigatorPatientContactAsync,
  getNavigatorPhysicianContactAsync,
} from "state/feature/contact/contact.action";

import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import { getBackUpNavigatorListAsync } from "state/feature/navigator/navigator.action";
import { INavigator } from "state/types/navigator.type";
import { useAppDispatch } from "state/store";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { BrowserView, MobileView } from "react-device-detect";
import { debounce } from "lodash";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { getNavigatorState, setSelectedNavigator } from "state/feature/navigator/navigator.slice";
import { setIsOpenChatModal } from "state/feature/common/common.slice";
import { OpenFrom } from "shared/enums/chat.enum";
import { CONTACT_LIMIT } from "./common/constant";
import PatientTable from "./components/patient-table/patient-table.component";
import PracticeTable from "./components/practice-table/practice-table.component";
import { ColumnType } from "shared/types/table.type";
import { IGetContactsAsyncRequestPayload } from "state/types/contact-slice.type";

const Contact = () => {
  useAxiosAuthenticated();
  const { user } = useSelector(getAuth);
  const { twilioAcessToken } = useSelector(getCallState);
  const { backupNavigators } = useSelector(getNavigatorState);
  const { navigatorContacts, paginationOffset } = useSelector(getContactState);
  const { isPatientContactsLoading, isPhysicianContactsLoading } = navigatorContacts;
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [activeToggleTitle, setActiveToggleTitle] = useState("Patient");
  const [isSearching, setIsSearching] = useState(false);
  const [navigatorTypeaheadList, setNavigatorTypeaheadList] = useState<{ key: string; name: string }[]>([]);
  const [selectedBackupNavigator, setSelectedBackupNavigator] = useState<{ key: string; name: string }>();
  const { isLoading } = useSelector(getContactState);
  const [requestPayload, setRequestPayload] = useState<IGetContactsAsyncRequestPayload>({
    id: "",
    isCareManagerId: true,
    pageSize: CONTACT_LIMIT,
    pageNumber: 1,
    searchText: "",
    sortColumn: "name",
    sortOrder: SortingOrderType.ASC,
    checkValidity: true,
  });
  const [patientColumnsHeader, setPatientColumnsHeader] = useState<ColumnType[]>(contactsPatientTableColumns);
  const [practiceColumnsHeader, setPracticeColumnsHeader] = useState<ColumnType[]>(contactsPracticeTableColumns);
  const abortControllerForGetNavigatorPatientContactAsync = useRef<() => void>();
  const abortControllerForGetNavigatorPhysicianContactAsync = useRef<() => void>();

  const resetRequestPayload = () => {
    setRequestPayload({
      id: requestPayload.id,
      isCareManagerId: true,
      pageSize: CONTACT_LIMIT,
      pageNumber: 1,
      searchText: "",
      sortColumn: "name",
      sortOrder: SortingOrderType.ASC,
      checkValidity: true,
    });
  };

  useEffect(() => {
    setPatientColumnsHeader(JSON.parse(JSON.stringify(contactsPatientTableColumns)));
    setPracticeColumnsHeader(JSON.parse(JSON.stringify(contactsPracticeTableColumns)));
  }, []);

  const cancelRequest = () => {
    if (abortControllerForGetNavigatorPatientContactAsync.current) {
      abortControllerForGetNavigatorPatientContactAsync.current();
    }
    if (abortControllerForGetNavigatorPhysicianContactAsync.current) {
      abortControllerForGetNavigatorPhysicianContactAsync.current();
    }
  };

  /**
   * When the state of the backup navigators changes we need to populate the
   * navigator dropdown list with all options to be able to load other navigator's contacts
   */
  const getAllNavigators = useCallback(async () => {
    appDispatch(
      getBackUpNavigatorListAsync({
        types: ["PNAV"],
      })
    );
  }, [appDispatch]);

  useEffect(() => {
    if (backupNavigators && backupNavigators.length > 0) {
      const allNavigators = backupNavigators.map((m: INavigator) => {
        return {
          key: m.careManagerId,
          name: `${m.firstName} ${m.lastName}`,
        };
      });
      const allNavigatorsIncludingCurrent = allNavigators.sort((item1, item2) =>
        item1.name.split(" ")[0].localeCompare(item2.name.split(" ")[0])
      );
      setNavigatorTypeaheadList(allNavigatorsIncludingCurrent);
      const navigatorSelected = allNavigators.find((x) => x.key === user.navigatorId);
      setSelectedBackupNavigator(navigatorSelected);
      dispatch(setSelectedNavigator(navigatorSelected));
    } else {
      setSelectedBackupNavigator({
        key: user.navigatorId,
        name: `${user.firstName} ${user.lastName}`,
      });
      dispatch(
        setSelectedNavigator({
          key: user.navigatorId,
          name: `${user.firstName} ${user.lastName}`,
        })
      );
    }
  }, [backupNavigators, user.navigatorId, user.firstName, user.lastName, dispatch]);

  useEffect(() => {
    getAllNavigators();
  }, [getAllNavigators]);

  const handleMessageSidePopup = (isPhysician: boolean) => {
    if (isPhysician) {
      dispatch(setIsOpenChatModal({ isOpen: true, openFrom: OpenFrom.CONTACT_PHYSICIAN }));
    } else {
      dispatch(setIsOpenChatModal({ isOpen: true, openFrom: OpenFrom.CONTACT }));
    }
  };

  const onChangeToggleTitle = () => {
    setActiveToggleTitle(activeToggleTitle === "Patient" ? "Practice" : "Patient");
    dispatch(resetContacts());
    setIsSearching(false);
    setPatientColumnsHeader(JSON.parse(JSON.stringify(contactsPatientTableColumns)));
    setPracticeColumnsHeader(JSON.parse(JSON.stringify(contactsPracticeTableColumns)));
    resetRequestPayload();
  };

  const areContactsLoading = useCallback(
    () => isPatientContactsLoading || isPhysicianContactsLoading,
    [isPatientContactsLoading, isPhysicianContactsLoading]
  );

  const handleSearchInput = useCallback(
    (search: string) => {
      dispatch(resetContacts());

      if (areContactsLoading()) {
        cancelRequest();
      }
      setRequestPayload((prev) => {
        return {
          ...prev,
          pageNumber: 1,
          searchText: search,
        };
      });
    },
    [dispatch, areContactsLoading]
  );

  const handlePatientColumnSort = (selectedColumn: ColumnType) => {
    if (isLoading) cancelRequest();
    dispatch(resetContacts());
    const index = patientColumnsHeader.findIndex((x) => x.key === selectedColumn.key);
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (patientColumnsHeader[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeaders = [...patientColumnsHeader];
    tempHeaders.forEach((header) => (header.sortOrder = SortingOrderType.DEFAULT));
    tempHeaders[index].sortOrder = sort;
    setPatientColumnsHeader(tempHeaders);
    setRequestPayload((prev) => {
      return {
        ...prev,
        pageNumber: 1,
        sortColumn: selectedColumn.key,
        sortOrder: sort,
      };
    });
  };

  const handlePracticeColumnSort = (selectedColumn: ColumnType) => {
    if (isLoading) cancelRequest();
    dispatch(resetContacts());
    const index = practiceColumnsHeader.findIndex((x) => x.key === selectedColumn.key);
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (practiceColumnsHeader[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeaders = [...practiceColumnsHeader];
    tempHeaders.forEach((header) => (header.sortOrder = SortingOrderType.DEFAULT));
    tempHeaders[index].sortOrder = sort;
    setPracticeColumnsHeader(tempHeaders);
    setRequestPayload((prev) => {
      return {
        ...prev,
        pageNumber: 1,
        sortColumn: selectedColumn.key,
        sortOrder: sort,
      };
    });
  };

  const handleIncrementPagePhysician = () => {
    setRequestPayload((prev) => {
      return {
        ...prev,
        pageNumber: paginationOffset.practiceOffset + 1,
      };
    });
  };

  const handleIncrementPagePatient = () => {
    setRequestPayload((prev) => {
      return {
        ...prev,
        pageNumber: paginationOffset.patientOffset + 1,
      };
    });
  };

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearchInput, 300);
  }, [handleSearchInput]);

  const handleBackupNavigatorChange = (option: any) => {
    if (option !== selectedBackupNavigator) {
      dispatch(resetContacts());
      setSelectedBackupNavigator(option);
      dispatch(setSelectedNavigator(option));
      document.getElementById("scrollableDiv")?.scrollTo({ top: 0 });
      setIsSearching(false);
      setRequestPayload({
        id: option.key,
        isCareManagerId: true,
        pageSize: CONTACT_LIMIT,
        pageNumber: 1,
        searchText: "",
        sortColumn: "name",
        sortOrder: SortingOrderType.ASC,
      });
    }
  };

  useEffect(() => {
    if (requestPayload.id) {
      let payloadAction;
      if (activeToggleTitle === "Patient") {
        payloadAction = appDispatch(getNavigatorPatientContactAsync(requestPayload));
        abortControllerForGetNavigatorPatientContactAsync.current = payloadAction.abort;
      } else {
        payloadAction = appDispatch(getNavigatorPhysicianContactAsync(requestPayload));
        abortControllerForGetNavigatorPhysicianContactAsync.current = payloadAction.abort;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, requestPayload]);

  useEffect(() => {
    setRequestPayload((prev) => {
      return {
        ...prev,
        id: user?.navigatorId,
      };
    });
  }, [dispatch, user?.navigatorId]);

  return (
    <div id="contact-container">
      <Header className="contact">
        <BrowserView className="contact-desktop-header">
          <div className="header-title">Contacts</div>
          <div className="right-section">
            {isSearching ? (
              <SearchBox
                icon="cross"
                className="search-box"
                iconClassName="search-icon"
                showIcon={!!requestPayload.searchText}
                SearchBoxSize={22}
                onClick={() => {
                  handleSearchInput("");
                  setIsSearching(false);
                }}
                onTextChange={debouncedSearch}
                placeholder="Search"
              />
            ) : (
              <Button
                icon="search"
                buttonSize={14}
                className="search-icon-container"
                iconClassName="search-icon"
                onClick={() => setIsSearching(true)}
              />
            )}
            <CustomDropDown
              value={selectedBackupNavigator}
              dropDownMenuItems={navigatorTypeaheadList}
              handleValueChanges={handleBackupNavigatorChange}
              idFieldName="key"
              dropDownBoxClass="navigator-contact-dropdown"
              selectionClass="navigator-contact-selection"
              dropDownContainerClass="navigator-dropdown-container"
              isDisabled={navigatorTypeaheadList.length === 0}
            ></CustomDropDown>
            <Toggle
              toggleLeftTitle="Patient"
              toggleRightTitle="Practice"
              activeToggleTitle={activeToggleTitle}
              changeActiveToggle={onChangeToggleTitle}
            />
          </div>
        </BrowserView>
        <MobileView className="contact-mobile-header">
          <div className="title-with-toggle">
            <div className="header-title-mobile">Contacts</div>
            <Toggle
              toggleLeftTitle="Patient"
              toggleRightTitle="Practice"
              activeToggleTitle={activeToggleTitle}
              changeActiveToggle={onChangeToggleTitle}
            />
          </div>
          <CustomDropDown
            value={selectedBackupNavigator}
            dropDownMenuItems={navigatorTypeaheadList}
            handleValueChanges={handleBackupNavigatorChange}
            idFieldName="key"
            dropDownBoxClass="navigator-contact-dropdown"
            selectionClass="navigator-contact-selection"
            dropDownContainerClass="navigator-dropdown-container"
          ></CustomDropDown>
          <SearchBox
            icon="cross"
            className="search-box"
            iconClassName="search-icon"
            showIcon={!!requestPayload.searchText}
            SearchBoxSize={22}
            onClick={() => {
              handleSearchInput("");
              setIsSearching(false);
            }}
            onTextChange={(value) => debouncedSearch(value)}
            placeholder="Search by name"
            autoFocus={false}
          />
        </MobileView>
      </Header>
      {twilioAcessToken !== "" && (
        <div className="table-container">
          {activeToggleTitle === "Patient" ? (
            <PatientTable
              tableClassName="patient-contact-table"
              columns={patientColumnsHeader}
              handleMessageSidePopup={() => handleMessageSidePopup(false)}
              handleColumnSort={handlePatientColumnSort}
              searchText={requestPayload.searchText}
              columnsData={navigatorContacts}
              sortingType={requestPayload.sortOrder}
              incrementPage={handleIncrementPagePatient}
            />
          ) : (
            <PracticeTable
              tableClassName="practice-contact-table"
              columns={practiceColumnsHeader}
              handleMessageSidePopup={() => handleMessageSidePopup(true)}
              columnsData={navigatorContacts}
              handleColumnSort={handlePracticeColumnSort}
              incrementPage={handleIncrementPagePhysician}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Contact;
