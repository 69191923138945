import moment from "moment";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getAssessmentState } from "state/feature/assessment/assessment.slice";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";
import Header from "../header/header.component";
import "./assessment.styles.scss";
import CommonNote from "components/risk-assessment-form-inputs/common-note/common-note.component";
import AssessmentFormInputs from "./assessment-form-inputs/assessment-form-inputs.components";
import InvalidTaskImage from "shared/assets/images/invalid-task.svg";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import Icon from "components/Icon/icon.component";
import { Prompt } from "react-router";
import { UNSAVED_MESSAGE } from "shared/constant/commonConstants";

const AssessmentPresentation = ({ isBlocking }: { isBlocking: boolean }) => {
  const { workflowName, episodeType, questions, error, isLoading, isSubmitted, isSubmitButtonClicked } =
    useSelector(getAssessmentState);
  const { patientDetails } = useSelector(getTaskManagementState);

  const patientDetailsDisplay = useCallback(() => {
    if (patientDetails) {
      return (
        <>
          <div className="patient-name">{patientDetails.patientName}</div>
          <div className="patient-other-details">
            <div>{patientDetails.gender}</div>
            <div className="dash">|</div>
            <div>
              <span className="dob">DOB:</span> {moment(patientDetails.dob).format("MM/DD/YYYY")}{" "}
              {patientDetails.age ? `(${patientDetails.age} years)` : ""}
            </div>
          </div>
        </>
      );
    }
  }, [patientDetails]);

  return (
    <div className="assessment-presentation-container">
      <Header
        className="assessment"
        patientDetails={patientDetailsDisplay()}
        title={`${episodeType} ${workflowName}`}
      />
      <Prompt when={isBlocking} message={() => UNSAVED_MESSAGE} />
      {isSubmitted && !isSubmitButtonClicked && (
        <div className="submitted-note">This assessment is already completed</div>
      )}

      <div className={`assessment-question-container ${isSubmitted ? "small-height" : "big-height"}`}>
        {questions.length === 0 && error && (
          <div className="error-state">
            <img src={InvalidTaskImage} alt="invalid task" />
            <div className="no-task-found">No tasks found</div>
            <div className="error-sub-title">
              The link might be broken or invalid. Please refresh the list page and try again.
            </div>
          </div>
        )}
        {!isLoading &&
          questions &&
          questions.length > 0 &&
          !error &&
          questions.map((item, index) => {
            const questionNumber = index + 1;
            return (
              <div
                key={item.questionId}
                className={`${item.isConclusion ? "conclusion-question" : "assessment-questions"}`}
              >
                {item.isConclusion ? (
                  <div className="conclusion-line" />
                ) : (
                  <div className="number">{questionNumber}.</div>
                )}
                <div className="question-section">
                  <div className="question-title">{item.title}</div>
                  <AssessmentFormInputs
                    options={item.selectionValues}
                    inputType={item.questionType}
                    selectionType={item.selectionType}
                    className={`${item.questionType}-${item.questionId}`}
                    questionId={item.questionId}
                    questions={item.questions}
                    maxLength={item.maxLength}
                    qTitle={item.title}
                    order={item.order}
                    selectedRange={item.selectedRange}
                    stepCount={item.stepCount}
                    placeHolder={item.placeHolder}
                    subTitle={item.subTitle}
                    isRequired={item.isRequired}
                    isConclusion={item.isConclusion}
                  />
                  {item.note && (
                    <CommonNote
                      className="assessment-note"
                      reminder={item.note}
                      image={<Icon icon="assessment-note" size={16} />}
                    />
                  )}
                </div>
              </div>
            );
          })}
        {isLoading && (
          <div className="loading-container">
            <CommonState type={CommonStateType.LOADING} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AssessmentPresentation;
