import { NavigatorType } from "./user-management.enum";

export const NAVIGATOR_TYPE_DROPDOWN_ITEMS = [
  {
    key: "patient-navigator",
    name: NavigatorType.PATIENT_NAVIGATOR,
  },
  {
    key: "transition-navigator",
    name: NavigatorType.TRANSITION_NAVIGATOR,
  },
];

export const EMAIL_ERROR = "Enter a valid email address";
export const FIRSTNAME_ERROR = "Enter first name";
export const LASTNAME_ERROR = "Enter last name";
export const PHONE_ERROR = "Enter a valid phone number";
