import { useHistory, useParams } from "react-router";
import { PageName } from "shared/enums/page-name.enum";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import TaskManagement from "./task-management.component";
import "./task-management.styles.scss";
import { createContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import RiskAssessmentContainer from "./risk-assessment/risk-assessment.parent-container";
import { useDispatch, useSelector } from "react-redux";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { removeLastDashFromUrl } from "shared/methods/utilityFunctions";
import {
  expandSection,
  getTaskManagementState,
  resetTasks,
  saveTaskId,
} from "state/feature/task-management/task-management.slice";
import { getIsIntakeExists } from "state/feature/task-management/task-management.action";
import TaskInfoContainer from "./task-info-tabs/task-info-tabs.container";
import TransitionOfCareContainer from "./transition-of-care/transition-of-care.container";
import { getCommonState } from "state/feature/common/common.slice";
import TaskFooter from "./footer/task-footer.component";
import { FooterTypes } from "./footer/footer.types";
import Assessment from "./assessment/assessment.container";
import { checkIfFeatureEnabledAsync } from "state/feature/common/common.action";
import { useAppDispatch } from "state/store";

export const TaskManagementContext = createContext<{ ref: null | React.MutableRefObject<any> }>({
  ref: null,
});

type FooterProps = {
  onNext: (value: any) => void;
  onPrevious: (value: any) => void;
  isClearDisable: boolean;
  isSaveDraftDisable: boolean;
  handleClearPageConfirmationModal: () => void;
  handleSaveDraftConfirmationModal: () => void;
  handleSubmitConfirmationModal: () => void;
};

const TaskManagementContainer = () => {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const history = useHistory();
  const { isTaskListExpanded, isValidIntake, isValidatingIntake, allowIntakeAcess } =
    useSelector(getTaskManagementState);
  const { featureFlags, featureFlagsLoading } = useSelector(getCommonState);
  const { taskType, intakeId } = useParams<{ taskType: string; intakeId: string; sectionName: string }>();
  const [isTaskManagementListViewFeatureFlagRequestCalled, setIsTaskManagementListViewFeatureFlagRequestCalled] =
    useState(false);

  useLayoutEffect(() => {
    const element = document.querySelector(".app-main-content");
    element?.setAttribute("style", "padding:0");

    return () => {
      element?.setAttribute("style", "padding:2.5rem 3.2rem 2.5rem 3.2rem");
    };
  }, []);

  const getTaskFromId = () => {
    if (!taskType) {
      return null;
    }
    switch (taskType) {
      case PageName.RISK_ASSESSMENT:
        return <RiskAssessmentContainer />;
      case PageName.TRANSITION_OF_CARE:
        return <TransitionOfCareContainer intakeId={intakeId} />;
      case PageName.ASSESSMENT:
        return <Assessment intakeId={intakeId} />;
      default:
        return <CommonState type={CommonStateType.PAGE_NOT_FOUND} />;
    }
  };

  useEffect(() => {
    if (intakeId) {
      dispatch(getIsIntakeExists({ intakeId }));
      appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "taskManagement" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intakeId]);

  useEffect(() => {
    if (isValidIntake && !isValidatingIntake) {
      setTimeout(() => dispatch(expandSection(false)), 700);
      dispatch(saveTaskId(intakeId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidIntake, intakeId, isValidatingIntake]);

  useEffect(() => {
    if (intakeId && isValidIntake && !isValidatingIntake && taskType === undefined) {
      history.push(`${removeLastDashFromUrl(location.pathname)}/${PageName.RISK_ASSESSMENT.toLowerCase()}`);
    } else if (
      isTaskManagementListViewFeatureFlagRequestCalled &&
      !featureFlagsLoading.taskManagementListView &&
      !featureFlagsLoading.taskManagement &&
      (intakeId === undefined || (!isValidIntake && !isValidatingIntake)) &&
      ((!featureFlags.taskManagement && !featureFlags.taskManagementListView) ||
        (!featureFlags.taskManagement && featureFlags.taskManagementListView) ||
        (featureFlags.taskManagement && !featureFlags.taskManagementListView))
    ) {
      history.push("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isValidIntake,
    taskType,
    intakeId,
    isValidatingIntake,
    featureFlagsLoading.taskManagement,
    featureFlags.taskManagement,
    isTaskManagementListViewFeatureFlagRequestCalled,
    featureFlagsLoading.taskManagementListView,
    featureFlags.taskManagementListView,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetTasks());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (!featureFlagsLoading.taskManagement && !featureFlags.taskManagement) ||
      featureFlagsLoading.taskManagementListView
    ) {
      setIsTaskManagementListViewFeatureFlagRequestCalled(true);
    }
  }, [featureFlagsLoading.taskManagementListView, featureFlagsLoading.taskManagement, featureFlags.taskManagement]);

  const getFooterType = () => {
    switch (taskType) {
      case PageName.ASSESSMENT:
        return FooterTypes.AssessmentFooter;
      case PageName.RISK_ASSESSMENT:
        return FooterTypes.RiskFooter;
      default:
        return FooterTypes.None;
    }
  };

  return (
    <div className="task-management-container">
      {featureFlags.taskManagement && featureFlags.taskManagementListView ? <TaskManagement /> : null}
      {intakeId && !allowIntakeAcess && !isValidatingIntake ? (
        history.push(`/permission-denied`)
      ) : (
        <>
          {intakeId && !isValidIntake && !isValidatingIntake && featureFlags.taskManagement && (
            <CommonState type={CommonStateType.INTAKE_NOT_FOUND} />
          )}
          {!intakeId && featureFlags.taskManagement && featureFlags.taskManagementListView && (
            <div className={`task-not-selected-container ${isTaskListExpanded ? "expand" : "collapse"}`}>
              <CommonState type={CommonStateType.NO_TASK_SELECTED} />
            </div>
          )}
          {intakeId && isValidIntake && allowIntakeAcess ? (
            <div className="task-render-container">
              <div className="task-from-id">{getTaskFromId()}</div>
              {featureFlags.taskManagement ? <TaskInfoContainer /> : null}
            </div>
          ) : null}
          {isValidIntake && !isValidatingIntake && <TaskFooter footerType={getFooterType()} />}
        </>
      )}
    </div>
  );
};

export default TaskManagementContainer;
