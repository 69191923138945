import Button from "components/Button/button.component";
import "./assessment-footer.styles.scss";
import { useAppDispatch } from "state/store";
import { postAsessmentDataAsync } from "state/feature/assessment/assessment.action";
import { useSelector } from "react-redux";
import { getAssessmentState } from "state/feature/assessment/assessment.slice";
import { useParams } from "react-router";
import { getAuth } from "state/feature/auth/auth.slice";

const AssessmentFooter = () => {
  const dispatch = useAppDispatch();
  const { intakeId, sectionName } = useParams<{ intakeId: string; sectionName: string }>();
  const { answers, formId, version, isSubmitted, isLoading, patientId, questions } = useSelector(getAssessmentState);
  const { user } = useSelector(getAuth);
  const submit = () => {
    dispatch(
      postAsessmentDataAsync({
        patientId: patientId,
        loggedInUserEmail: user.email,
        formId,
        version,
        workflowId: sectionName,
        intakeId: parseInt(intakeId),
        answers: answers.map(({ selectedValues, questionId }) => {
          return {
            questionId,
            selectionValues: Object.entries(selectedValues).flatMap((item) => {
              if (item[1].length > 1) {
                const selectedValue: any = [];
                item[1].forEach((value: any, index: number) => {
                  selectedValue[index] = {
                    id: value.id,
                    value: value.value,
                    nextQuestionId: value.nextQuestionId,
                  };
                });
                return selectedValue;
              }
              return { id: item[1][0].id, value: item[1][0].value, nextQuestionId: item[1][0].nextQuestionId };
            }),
          };
        }),
      })
    );
  };
  return (
    <div className="assessment-footer-button-container">
      <div className="questions-footer-button-section">
        <div className="footer-buttons-div-container">
          {/* <Button text="Cancel" className="cancel-button" disabled={false} onClick={() => {}} /> */}
          <Button
            text="Submit"
            className="submit-button"
            onClick={() => submit()}
            disabled={isLoading || isSubmitted || questions?.length === 0 || answers.length === 0}
          />
        </div>
      </div>
    </div>
  );
};

export default AssessmentFooter;
