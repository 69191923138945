import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import moment from "moment";
import { axiosInstance } from "shared/axios-instance";
import { getFullName, isEmptyString } from "shared/methods/utilityFunctions";
import { ContactDetailsType, EpisodesRequestPayloadProps } from "state/types/episodes-slice.type";

export const getEpisodesAsync = createAsyncThunk(
  "episodes/getEpisodesAsync",
  async (requestPayload: EpisodesRequestPayloadProps) => {
    const response = await axiosInstance.post(
      `${getConfig().navigatorBase}/api/v1/episodes/episode-list`,
      requestPayload
    );
    if (response.status === 200) {
      response.data = response.data.map((res: any) => {
        const {
          status,
          admitDate,
          careManagerId,
          dateOfBirth,
          episodeId,
          episodeName,
          firstName,
          intakeId,
          lastName,
          middleName,
          pnavFirstName,
          pnavLastName,
          physicianFirstName,
          physicianLastName,
          surgeonId,
          surgeryDate,
          tNav,
        } = res;
        return {
          id: episodeId,
          name:
            firstName && !isEmptyString(firstName)
              ? `${firstName}${middleName && !isEmptyString(middleName) ? ` ${middleName}` : ""}${
                  lastName && !isEmptyString(lastName) ? ` ${lastName}` : ""
                }`
              : "-",
          dob: dateOfBirth ? moment(dateOfBirth).format("MM/DD/YYYY") : "-",
          episodeName: episodeName && !isEmptyString(episodeName) ? episodeName : "-",
          admitDate: admitDate ? moment(admitDate).format("MM/DD/YYYY") : "-",
          surgeryDate: surgeryDate ? moment(surgeryDate).format("MM/DD/YYYY") : "-",
          status: status && !isEmptyString(status) ? status : "-",
          physician:
            physicianFirstName && !isEmptyString(physicianFirstName)
              ? `${physicianFirstName}${
                  physicianLastName && !isEmptyString(physicianLastName) ? ` ${physicianLastName}` : ""
                }`
              : "-",
          pnav:
            pnavFirstName && !isEmptyString(pnavFirstName)
              ? `${pnavFirstName}${pnavLastName && !isEmptyString(pnavLastName) ? ` ${pnavLastName}` : ""}`
              : "-",
          tnav: tNav && !isEmptyString(tNav) ? tNav : "-",
          intakeId,
          surgeonId,
          careManagerId,
        };
      });
    } else {
      response.data = [];
    }

    return response.data;
  }
);

export const getEpisodesFilterStatusMenuAsync = createAsyncThunk(
  "episodes/getEpisodesFilterStatusMenuAsync",
  async () => {
    const response = await axiosInstance.get(`${getConfig().navigatorBase}/api/v1/episodes/intake-statuses`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  }
);

export const getEpisodeDetailsById = createAsyncThunk(
  "episodes/getEpisodeDetailsById",
  async (requestPayload: { intakeId: string }) => {
    const response = await axiosInstance.get(`${getConfig().navigatorBase}/api/v1/episodes/episode-detail`, {
      params: requestPayload,
    });

    if (response.data && !isEmptyString(response.data)) {
      const {
        patientFirstName,
        patientMiddleName,
        patientLastName,
        patientId,
        patientUserId,
        clientId,
        episodeId,
        programName,
        phoneNumber,
        preferredPhone,
        episodeName,
        episodeSite,
        episodeSide,
        physicianName,
        status,
        riskValue,
        tocAdherence,
        dateOfBirth,
        gender,
        programDischargeDate,
        surgeryDate,
        surgeryDischargeDate,
        admitDate,
        isOffTrack,
      } = response.data;
      const filterContacts: any = Object.fromEntries(
        Object.entries(response.data).filter(([key, value], index) => {
          return key !== "phoneNumber" && key.includes("phone") && value !== null && value !== "";
        })
      );

      const contactDetails: Array<ContactDetailsType> = [];
      for (const phone in filterContacts) {
        contactDetails.push({
          isPreferred: response.data.preferredPhone === phone?.replace("phone", ""),
          phoneNumber: filterContacts && filterContacts?.[phone] ? filterContacts[phone] : "",
          type: phone?.replace("phone", ""),
        });
      }
      const newResponse = {
        patientName:
          patientFirstName && !isEmptyString(patientFirstName)
            ? getFullName({ firstName: patientFirstName, lastName: patientLastName, middleName: patientMiddleName })
            : "-",
        episodeId,
        patientId: patientId ? patientId.toString() : "",
        patientUserId,
        clientId: clientId.toString() ?? "",
        programName: programName ?? "-",
        episodeName: episodeName ?? "-",
        episodeSite: episodeSite ?? "N/A",
        episodeSide: episodeSide ?? "N/A",
        physicianName: physicianName && !isEmptyString(physicianName.trim()) ? physicianName : "-",
        status: status ?? "-",
        riskValue: riskValue && !isEmptyString(riskValue) ? riskValue : "-",
        tocAdherence: tocAdherence ?? "-",
        dob: dateOfBirth,
        gender: gender ?? "-",
        programDischargeDate: programDischargeDate,
        surgeryDate: surgeryDate,
        surgeryDischargeDate: surgeryDischargeDate,
        admitDate: admitDate,
        isOffTrack: isOffTrack === null ? "-" : isOffTrack ? "Off-Track" : "On-Track",
        patientPhoneNumber: phoneNumber,
        preferredPhone,
        contactDetails,
      };
      return newResponse;
    }
    return null;
  }
);
