import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../modal.component";
import Button from "components/Button/button.component";
import { getCommonState, setIsOpenCallModal, setIsSessionTimeoutModalVisible } from "state/feature/common/common.slice";
import { useHistory } from "react-router";
import { logout } from "state/feature/auth/auth.slice";
import useIdleTimerContainer from "components/IdleTimeout";
import { useEffect } from "react";
import { getCallState } from "state/feature/call/call.slice";
import { toast } from "react-toastify";
import { setTwilioErrorCode } from "state/feature/common/common.slice";

const SessionTimeOutModal = () => {
  const { isIdle } = useIdleTimerContainer();
  const commonState = useSelector(getCommonState);
  const callState = useSelector(getCallState);

  const dispatch = useDispatch();
  const history = useHistory();
  const handleOkClick = () => {
    const params = new URLSearchParams();
    toast.dismiss("twilio-error");
    dispatch(setTwilioErrorCode(null));
    params.append("redirect", location.pathname);
    if (callState.currentCall) {
      callState.currentCall.reject();
    }
    dispatch(logout());
    dispatch(setIsSessionTimeoutModalVisible(false));
    dispatch(setIsOpenCallModal(false));
    history.push({ pathname: "/", search: params.toString() });
  };

  useEffect(() => {
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/reset-password" &&
      window.location.pathname !== "/forgot-password" &&
      isIdle
    ) {
      dispatch(setIsSessionTimeoutModalVisible(true));
    }
  }, [dispatch, isIdle]);

  if (
    window.location.pathname !== "/" &&
    window.location.pathname !== "/reset-password" &&
    window.location.pathname !== "/forgot-password" &&
    commonState?.modal.isSessionTimeoutModalVisible
  ) {
    return (
      <Modal isOpen={commonState?.modal.isSessionTimeoutModalVisible} className="center session-timedout-popup">
        <div className="session-timedout-container">
          <div className="header">Session Timed Out</div>
          <div className="content">Please log in again</div>
          <div className="button-section">
            <Button text="OK" className="green-button reload-button" onClick={handleOkClick} />
          </div>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};

export default SessionTimeOutModal;
