import CommonNote from "components/risk-assessment-form-inputs/common-note/common-note.component";
import RiskAssessmentFormInputs from "components/risk-assessment-form-inputs/risk-assessment-form-inputs";
import { result } from "lodash";
import { Pages, Question } from "pages/task-management/risk-assessment/common/risk-assessment.types";
import { useEffect, useRef, useState } from "react";
import Collapsible from "react-collapsible";
import { useDispatch, useSelector } from "react-redux";
import { getExtraQuestionsBySectionsAsync } from "state/feature/risk-assessment/risk-assessment.action";
import { getRiskAssessmentState, setIsDirty } from "state/feature/risk-assessment/risk-assessment.slice";
import { useAppDispatch } from "state/store";
import AlarmIcon from "shared/assets/images/clock-alarm.svg";
import "./collapse.styles.scss";

type CollapseProps = {
  heading: string;
  className?: string;
  conditionalSectionId?: string | null;
  pageId?: Pages;
};

const Collapse = (props: CollapseProps) => {
  const appDispatch = useAppDispatch();
  const [sectionData, setSectionData] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const { collapsibleSections } = useSelector(getRiskAssessmentState);
  const { heading, className, conditionalSectionId, pageId } = props;

  useEffect(() => {
    if (conditionalSectionId && collapsibleSections.length > 0) {
      if (collapsibleSections.some((x: any) => x.sectionId === conditionalSectionId)) {
        if (!isOpen) {
          appDispatch(getExtraQuestionsBySectionsAsync({ sections: [conditionalSectionId as string], pageId })).then(
            (res: any) => {
              setSectionData(res.payload[0]);
              setIsOpen(true);
              setIsDisabled(false);
              appDispatch(setIsDirty({ pageId: pageId, isDirty: true }));
            }
          );
        }
      } else {
        setSectionData({});
        setIsOpen(false);
        setIsDisabled(true);
      }
    } else {
      setSectionData({});
      setIsOpen(false);
      setIsDisabled(true);
    }
  }, [collapsibleSections, conditionalSectionId, pageId, isOpen, appDispatch]);

  return (
    <Collapsible
      className={`collapse-container ${className ? className : ""}`}
      trigger={heading}
      triggerDisabled={isDisabled}
      open={isOpen}
      handleTriggerClick={() => {
        setIsOpen((prev) => {
          return !prev;
        });
      }}
    >
      {sectionData &&
        sectionData.questions &&
        sectionData.questions.length > 0 &&
        sectionData.questions.map((item: Question, index: number) => {
          return (
            <div key={item.questionId + "_" + index} className="question-section">
              <div className="question-section-title">{item.title}</div>
              <RiskAssessmentFormInputs
                options={item.selectionValues}
                inputType={item.questionType}
                selectionType={item.selectionType}
                className={`conditional-questions-${item.questionId}`}
                questionId={item.questionId}
                questions={item.questions}
                sectionId={sectionData.sectionId}
                pageId={pageId}
                order={item.order}
                sectionOrder={sectionData.order}
                noteText={item.note}
                alertText={item.alert}
              />
            </div>
          );
        })}
    </Collapsible>
  );
};

export default Collapse;
