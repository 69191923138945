import AssessmentFooter from "../assessment/assessment-footer/assessment-footer.component";
import RiskAssessmentFooter from "../risk-assessment/footer/risk-footer.component";
import { FooterTypes } from "./footer.types";

const TaskFooter = (props: { footerType: FooterTypes }) => {
  switch (props.footerType) {
    case FooterTypes.RiskFooter:
      return <RiskAssessmentFooter></RiskAssessmentFooter>;

    case FooterTypes.AssessmentFooter:
      return <AssessmentFooter />;
  }
  return <></>;
};

export default TaskFooter;
