import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import SideBar from "components/SideBar/side-bar.component";
import "./styles.scss";
import Profile from "pages/profile/profile.container";
import LogoutModal from "components/Modal/LogoutModal/logout-modal.component";
import { useDispatch, useSelector } from "react-redux";
import { clearUser, getAuth } from "state/feature/auth/auth.slice";
import { getNavigatorRoleAsync } from "state/feature/auth/auth.action";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import {
  getCommonState,
  resetCommon,
  setIsInboundCalled,
  setIsOpenLogoutModal,
} from "state/feature/common/common.slice";
import { TwilioClient } from "shared/services/twilio/twilioClient";
import { clearTwilioToken, getCallState, saveCurrentCall, saveTwilioCallDevice } from "state/feature/call/call.slice";
import UserManagement from "pages/user-management/user-management.container";
import { checkIfFeatureEnabledAsync } from "../state/feature/common/common.action";
import { resetContacts } from "state/feature/contact/contact.slice";
import {
  getNavigatorState,
  resetToInitialNavigatorState,
  toggleStatusModal,
} from "state/feature/navigator/navigator.slice";
import { useAppDispatch } from "state/store";
import { OnlineOfflineStatus } from "shared/enums/online-offline.enum";
import { postNavigatorStatus } from "state/feature/navigator/navigator.action";
import { isMobile } from "react-device-detect";
import { getUserManagementState } from "state/feature/user-management/user-management.slice";
import OnlineOfflineModal from "components/Modal/online-offline-modal/online-offline-modal.component";
import { UNSAVED_MESSAGE } from "shared/constant/commonConstants";
import { getNavigatorAvailabilityStatus } from "shared/methods/utilityFunctions";
import { updateIncomingCallStatusAsync } from "state/feature/call/call.action";
import TaskManagementContainer from "pages/task-management/task-management.container";
import { useSignalRService } from "shared/services/signalr/signalr.service";
import SidePopup from "components/SidePopup/side-popup.component";
import ChatSidePanel from "components/ChatSidePanel/chat-side-panel.component";
import {
  getMessagingState,
  saveConversationId,
  saveConversationParticipants,
  saveParticipantsDetails,
  saveTwilioConversationId,
  setUserIdforMessage,
} from "state/feature/messaging/messaging.slice";
import { getAssessmentState } from "state/feature/assessment/assessment.slice";
import { ComposeMessage } from "components/SidePopup/ComposeMessage/compose-message.component";
import Episodes from "pages/episodes/episodes.container";
import ManagerContact from "pages/manager-contact/manager-contact.container";
import ManagerDashboard from "pages/manager-dashboard/manager-dashboard.container";
import NavigatorManagement from "pages/navigator-management/navigator-management.container";
import Dashboard from "pages/dashboard/dashboard.container";
import Contact from "pages/contact/contact.container";

const SideBarNav = ({ children }: { children?: React.ReactNode }) => {
  useAxiosAuthenticated();
  const { featureFlags, modal } = useSelector(getCommonState);
  const signalRClient = useSignalRService();
  const history = useHistory();
  const dispatch = useDispatch();
  const authState = useSelector(getAuth);
  const location = useLocation();
  const { isUserValueChanged } = useSelector(getUserManagementState);
  const { isCalenderValueChanged, isProfileValueChanged } = useSelector(getNavigatorState);
  const { isValueChanged } = useSelector(getAssessmentState);
  const { auth, user } = useSelector(getAuth);
  const appDispatch = useAppDispatch();
  const callState = useSelector(getCallState);
  const { isOpenStatusModal, isOnlineOfflineButtonLoading, currentUserProfile, holidayList, onCallLNavigators } =
    useSelector(getNavigatorState);

  const messagingState = useSelector(getMessagingState);

  const getHeading = () => {
    if (messagingState.selectedConversation?.participants) {
      return messagingState.selectedConversation?.participants;
    } else if (messagingState.isLoading) {
      return "Loading conversation";
    } else {
      return "New Message";
    }
  };
  const getStatusText = useCallback(() => {
    const statusText = getNavigatorAvailabilityStatus(currentUserProfile, holidayList, onCallLNavigators);
    return statusText === OnlineOfflineStatus.OFFLINE ? OnlineOfflineStatus.ONLINE : OnlineOfflineStatus.OFFLINE;
  }, [holidayList, onCallLNavigators, currentUserProfile]);

  useEffect(() => {
    if (auth.isAuthenticated && user.navigatorId) {
      setTimeout(() => {
        dispatch(checkIfFeatureEnabledAsync({ featureFlagName: "userManagement" }));
        appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "inboundCalls" })).then(() =>
          dispatch(setIsInboundCalled(true))
        );
        appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "taskManagement" })).then((response: any) => {
          if (response && response.payload && response.payload.data) {
            dispatch(checkIfFeatureEnabledAsync({ featureFlagName: "taskManagementListView" }));
          }
        });
        appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "inAppNotifications" }));
        appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "navigatorEpisodeList" }));
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appDispatch, auth.isAuthenticated, user.navigatorId]);

  const onClickStatusChange = async (value: number) => {
    const currentStatus = getNavigatorAvailabilityStatus(currentUserProfile, holidayList, onCallLNavigators);
    if (currentStatus === OnlineOfflineStatus.ONLINE) {
      await appDispatch(postNavigatorStatus({ status: OnlineOfflineStatus.OFFLINE, expirationMinutes: value }));
    } else if (currentStatus === OnlineOfflineStatus.OFFLINE) {
      await appDispatch(postNavigatorStatus({ status: OnlineOfflineStatus.ONLINE, expirationMinutes: value }));
    }
    dispatch(toggleStatusModal(false));
  };

  const [routesForNavigatorManager, setRoutesForNavigatorManager] = useState<any[]>([
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: "dashboard-icon",
      className: "nav-item-dashboard",
      size: 24,
      main: () => <ManagerDashboard />,
      exact: true,
      enableMobileView: true,
      order: 1,
    },
    {
      name: "Navigators",
      path: "/navigator-management",
      icon: "navigator-management-icon",
      className: "nav-item-management",
      size: 24,
      main: () => <NavigatorManagement />,
      exact: false,
      enableMobileView: true,
      order: 2,
    },
    {
      name: "Contacts",
      path: "/contact",
      icon: "contact-icon",
      className: "nav-item-contact",
      size: 24,
      main: () => <ManagerContact />,
      exact: true,
      enableMobileView: true,
      order: 3,
    },
  ]);
  const [routesForNavigator, setRoutesForNavigator] = useState<any[]>([
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: "dashboard-icon",
      className: "nav-item-dashboard",
      size: 24,
      main: () => <Dashboard />,
      exact: true,
      enableMobileView: true,
      order: 1,
    },
    {
      name: "My Profile",
      path: "/profile",
      icon: "navigator-management-icon",
      className: "nav-item-profile",
      size: 24,
      main: () => <Profile />,
      exact: true,
      enableMobileView: true,
      order: 2,
    },
    {
      name: "Contacts",
      path: "/contact",
      icon: "contact-icon",
      className: "nav-item-contact",
      size: 24,
      main: () => <Contact />,
      exact: true,
      enableMobileView: true,
      order: 3,
    },
  ]);

  useEffect(() => {
    if (featureFlags.userManagement) {
      const userManagementRoute = {
        name: "User Management",
        path: "/user-management",
        icon: "user-management-icon",
        className: "nav-item-user-management",
        size: 24,
        main: () => <UserManagement />,
        exact: true,
        enableMobileView: false,
        order: 4,
      };

      if (!routesForNavigatorManager.find((x) => x.name === "User Management")) {
        setRoutesForNavigatorManager(
          [...routesForNavigatorManager, userManagementRoute].sort((item1, item2) =>
            item1.order < item2.order ? -1 : 1
          )
        );
      }
      if (!routesForNavigator.find((x) => x.name === "User Management")) {
        setRoutesForNavigator(
          [...routesForNavigator, userManagementRoute].sort((item1, item2) => (item1.order < item2.order ? -1 : 1))
        );
      }
    }
    if (featureFlags.taskManagement && featureFlags.taskManagementListView) {
      const taskManagementRoute = {
        name: "Task Management",
        path: "/task-management",
        icon: "task-management-icon",
        className: "nav-item-contact",
        size: 24,
        main: () => <TaskManagementContainer />,
        exact: false,
        enableMobileView: false,
        order: 6,
      };
      if (!routesForNavigatorManager.find((x) => x.name === "Task Management")) {
        setRoutesForNavigatorManager(
          [...routesForNavigatorManager, taskManagementRoute].sort((item1, item2) =>
            item1.order < item2.order ? -1 : 1
          )
        );
      }
      if (!routesForNavigator.find((x) => x.name === "Task Management")) {
        setRoutesForNavigator(
          [...routesForNavigator, taskManagementRoute].sort((item1, item2) => (item1.order < item2.order ? -1 : 1))
        );
      }
    }
    if (featureFlags.navigatorEpisodeList) {
      const episodesListRoute = {
        name: "Episodes",
        path: "/episodes",
        icon: "episodes-icon",
        className: "nav-item-episodes",
        size: 24,
        main: () => <Episodes />,
        exact: true,
        enableMobileView: true,
        order: 5,
      };
      if (!routesForNavigatorManager.find((x) => x.name === "Episodes")) {
        setRoutesForNavigatorManager(
          [...routesForNavigatorManager, episodesListRoute].sort((item1, item2) => (item1.order < item2.order ? -1 : 1))
        );
      }
      if (!routesForNavigator.find((x) => x.name === "Episodes")) {
        setRoutesForNavigator(
          [...routesForNavigator, episodesListRoute].sort((item1, item2) => (item1.order < item2.order ? -1 : 1))
        );
      }
    }
  }, [
    featureFlags.userManagement,
    featureFlags.taskManagement,
    routesForNavigator,
    routesForNavigatorManager,
    featureFlags.taskManagementListView,
    featureFlags.navigatorEpisodeList,
  ]);

  const getDataBasedOnUrl = useCallback(() => {
    if (user.email && user.navigatorId && auth.isAuthenticated) {
      dispatch(getNavigatorRoleAsync());
    }
  }, [user.email, user.navigatorId, auth.isAuthenticated, dispatch]);

  useEffect(() => {
    getDataBasedOnUrl();
  }, [getDataBasedOnUrl]);

  const publicRoutes = ["/", "/forgot-password"];

  const logOutUser = () => {
    if (callState.currentCall) {
      if (callState.currentCall?.status() === "pending") {
        appDispatch(
          updateIncomingCallStatusAsync({
            callSid: callState.currentCall?.parameters?.CallSid,
            status: "missed",
            currentUserProfile,
          })
        ).then(() => {
          callState.currentCall?.reject();
        });
      } else {
        callState.currentCall?.reject();
      }
    }
    const globalWindow = window as any;
    if (globalWindow && globalWindow.ReactNativeWebView) {
      globalWindow.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "loggedOutUser",
          loggedInUser: null,
        })
      );
    }
    if (callState.twilioCallDevice) {
      callState.twilioCallDevice?.disconnectAll();
      callState.twilioCallDevice?.unregister();
    }
    TwilioClient.removeInstance();
    signalRClient?.disconnectSignalrOnLogout(user.email);
    dispatch(clearTwilioToken());
    dispatch(saveCurrentCall(null));
    dispatch(saveTwilioCallDevice(null));
    dispatch(clearUser());
    dispatch(resetCommon());
    dispatch(resetContacts());
    dispatch(resetToInitialNavigatorState());
    history.replace("/");
    sessionStorage.removeItem("riskAssessmentAnswers");
  };

  const handleLogoutClick = () => {
    if (
      (isCalenderValueChanged ||
        isUserValueChanged ||
        isProfileValueChanged ||
        callState.currentCall !== null ||
        isValueChanged) &&
      modal.isOpenLogoutModal
    ) {
      dispatch(setIsOpenLogoutModal(false));
      if (window.confirm(UNSAVED_MESSAGE)) {
        logOutUser();
      }
    } else {
      logOutUser();
    }
  };

  if (!publicRoutes.includes(location.pathname)) {
    return (
      <div className={`app-routes ${isMobile ? "mobileView" : ""}`}>
        <OnlineOfflineModal
          className="online-offline-modal"
          isOpen={isOpenStatusModal}
          submitButtonTitle="Yes"
          heading={`Temporary set me ${getStatusText().toLowerCase()}`}
          handleCancelClick={() => dispatch(toggleStatusModal(false))}
          isButtonLoading={isOnlineOfflineButtonLoading}
          handleSubmitClick={onClickStatusChange}
          isOnline={
            getNavigatorAvailabilityStatus(currentUserProfile, holidayList, onCallLNavigators) ===
            OnlineOfflineStatus.ONLINE
          }
          currentProfile={currentUserProfile}
        />

        <LogoutModal
          isOpen={modal.isOpenLogoutModal}
          handleCancelClick={() => dispatch(setIsOpenLogoutModal(false))}
          handleReloadClick={() => handleLogoutClick()}
        />

        <ComposeMessage
          isOpen={modal.isOpenChatModal.isOpen}
          openFrom={modal.isOpenChatModal.openFrom}
          onClose={() => {
            // setConversationRequestPayload({ ...converationRequestPayload.current, offset: 0 });
          }}
        />

        {authState.user && authState.user.roles && authState.user.roles.length > 0 && (
          <SideBar
            routes={authState.user.roles[0] === "Navigator Manager" ? routesForNavigatorManager : routesForNavigator}
          />
        )}
        {children}
      </div>
    );
  } else return null;
};

export default SideBarNav;
