import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import moment from "moment";
import { axiosInstance } from "shared/axios-instance";
import {
  ICreateNavigatorProps,
  IGetOnCallNavigatorProps,
  IPostOnCallNavigatorProps,
  IUpdateNavigator,
} from "shared/dtos/navigator";
import { INavigator } from "../../types/navigator.type";
import { SortingOrderType } from "shared/enums/sorting-types.enums";

const getParticipantDetailsUrlBasedOnFeatureFlag = (isInboundCallsEnabled: boolean) => {
  if (isInboundCallsEnabled) {
    return `${getConfig().navigatorBase}/api/v1/inbound_calls/participant-details`;
  } else {
    return `${getConfig().messagingBase}/api/v1/Calling/ParticipantDetails`;
  }
};

export const getNavigatorsEpisodesAsync = createAsyncThunk(
  "navigator/getNavigatorsEpisodesAsync",
  async (requestPayload: { navigatorId: string }) => {
    const response = await axiosInstance.get(
      `${getConfig().navigatorBase}/api/v1/episodes/navigator-episodes/${requestPayload.navigatorId}`
    );
    return response.data;
  }
);

export const getNavigatorCallsAsync = createAsyncThunk(
  "navigator/getNavigatorCallsAsync",
  async (
    requestPayload: {
      navigatorId?: string;
      startDate: string | Date | null;
      endDate: string | Date | null;
      callDirection: string;
      callStatus: Array<any>;
      searchText: string;
      sortColumn: string;
      sortOrder: SortingOrderType;
      limit: number;
      offset: number;
      isDownloadCallsClicked?: boolean;
      isNavigatorManager: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { isDownloadCallsClicked, ...newRequestPayload } = requestPayload;
      const response = await axiosInstance.post(
        `${getConfig().telephonyBase}/api/v1/logs/get-calls`,
        newRequestPayload
      );
      if (response.data && response.data.length > 0) {
        response.data = response.data.map((call: any) => {
          call.callStartTime = `${moment(moment.utc(call.callStartTime).toDate()).format("MMMM Do, YYYY")} at ${moment(
            moment.utc(call.callStartTime).toDate()
          ).format("hh:mm A")}`;
          return call;
        });
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNavigatorsForManagerAsync = createAsyncThunk(
  "navigator/getNavigatorsForManagerAsync",
  async (requestPayload: { navigatorId: string }) => {
    const response = await axiosInstance.get(
      `${getConfig().navigatorBase}/api/v1/navigators/navigator-details/${requestPayload.navigatorId}`
    );
    return response.data;
  }
);

export const getNavigatorForManagerAsync = createAsyncThunk(
  "navigator/getNavigatorForManagerAsync",
  async (requestPayload: { navigatorId: string; startDate: string; endDate: string }) => {
    // TODO: change status code to 204 in case not found.
    const response = await axiosInstance.post(
      `${getConfig().navigatorBase}/api/v1/navigator-stats/navigator`,
      requestPayload
    );
    return response.data;
  }
);

export const getNavigatorStatsForManagerAsync = createAsyncThunk(
  "navigator/getNavigatorStatsForManagerAsync",
  async (requestPayload: {
    managerId: string;
    startDate: string;
    endDate: string;
    sortType?: SortingOrderType;
    sortColumn?: string;
  }) => {
    const { managerId, startDate, endDate, sortType, sortColumn } = requestPayload;
    const newRequestPayload = {
      managerId: managerId,
      startDate: startDate,
      endDate: endDate,
    };
    const response = await axiosInstance.post(
      `${getConfig().navigatorBase}/api/v1/navigator-stats/manager-list`,
      newRequestPayload
    );
    if (response && response.data) {
      if (sortColumn && sortType) {
        const type = requestPayload.sortColumn === "totalCallDuration";
        const compareFunction = (item1: any, item2: any) => {
          if (sortColumn === "name") {
            return item1.toLowerCase() > item2.toLowerCase() ? 1 : -1;
          }
          return item1 - item2;
        };
        const functionForSort = (item1: any, item2: any) => {
          const value1 = type ? Number(Math.round(item1[sortColumn])) : item1[sortColumn];
          const value2 = type ? Number(Math.round(item2[sortColumn])) : item2[sortColumn];
          if (sortType === SortingOrderType.ASC) {
            return compareFunction(value1, value2);
          } else {
            return compareFunction(value2, value1);
          }
        };
        const sortedData = response.data.sort(functionForSort);

        return sortedData;
      }
    }
    return response.data;
  }
);

export const getNavigatorDetailsAsync = createAsyncThunk(
  "navigator/getNavigatorDetailsAsync",
  async (requestPayload: { navigatorId?: string }) => {
    const url = requestPayload.navigatorId ? `navigators/${requestPayload.navigatorId}` : `navigators`;
    const response = await axiosInstance.get(`${getConfig().navigatorBase}/api/v1/${url}`);
    return response.data;
  }
);

export const getBackUpNavigatorListAsync = createAsyncThunk(
  "navigator/getBackUpNavigatorListAsync",
  async (requestPayload: { types: string[]; timeZone?: string }, { rejectWithValue }): Promise<INavigator[] | any> => {
    try {
      const response = await axiosInstance.post(`${getConfig().navigatorBase}/api/v1/navigators/list`, requestPayload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAssignPhoneNumberListAsync = createAsyncThunk(
  "navigator/getAssignPhoneNumberListAsync",
  async (navigatorType: string | void): Promise<INavigator[]> => {
    const response = await axiosInstance.get(
      `${getConfig().navigatorBase}/api/v1/configuration/assignedPhoneNumbers${
        navigatorType ? `/${navigatorType}` : ""
      }`
    );
    return response.data;
  }
);

export const updateNavigatorAsync = createAsyncThunk(
  "navigator/updateNavigatorAsync",
  async (requestPayload: IUpdateNavigator, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`${getConfig().navigatorBase}/api/v1/navigators`, { ...requestPayload });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createNavigatorAsync = createAsyncThunk(
  "navigator/createNavigatorAsync",
  async (requestPayload: ICreateNavigatorProps) => {
    const response = await axiosInstance.post(`${getConfig().navigatorBase}/api/v1/navigators`, { ...requestPayload });
    return response.data;
  }
);
export const getOnCallNavigatorsListAsync = createAsyncThunk(
  "navigator/getOnCallNavigatorsListAsync",
  async (requestPayload: IGetOnCallNavigatorProps) => {
    const response = await axiosInstance.post(`${getConfig().navigatorBase}/api/v1/on-call/list`, {
      startDate: requestPayload.startDate,
      endDate: requestPayload.endDate,
    });
    return response;
  }
);

export const postOnCallNavigatorListAsync = createAsyncThunk(
  "navigator/postOnCallNavigatorListAsync",
  async (requestPayload: IPostOnCallNavigatorProps[]) => {
    const response = await axiosInstance.put(`${getConfig().navigatorBase}/api/v1/on-call`, requestPayload);
    return response;
  }
);
export const getWhiteLabelPhoneNumberAsync = createAsyncThunk(
  "navigator/getWhiteLabelPhoneNumberAsync",
  async (requestPayload: { clientId: string }) => {
    const response = await axiosInstance.get(
      `${getConfig().navigatorBase}/api/v1/navigators/forwarding-phone-number/${requestPayload.clientId}`
    );
    return response;
  }
);

export const getOutboundPhoneNumbersAsync = createAsyncThunk("navigator/getOutboundPhoneNumbersAsync", async () => {
  const response = await axiosInstance.get<
    null,
    { status: number; data: Array<{ id: string; name: string; systemId: number; whiteLabelPhoneNumber: string }> }
  >(`${getConfig().navigatorBase}/api/v1/navigators/forwarding-phone-numbers`);
  return response;
});

export const getParticipantsDetailsAsync = createAsyncThunk(
  "navigator/getParticipantsDetailsAsync",
  async (requestPayload: { participantsIds: Array<string>; isInboundCallsEnabled: boolean }) => {
    const response = await axiosInstance.post(
      getParticipantDetailsUrlBasedOnFeatureFlag(requestPayload.isInboundCallsEnabled),
      {
        participantId: requestPayload.participantsIds,
      }
    );
    return { navigator: response.data.navigator, patient: response.data.patient, physician: response.data.physician };
  }
);

export const getHolidayListAsync = createAsyncThunk(
  "navigator/getHolidayListAsync",
  async (requestPayload: { year: number }) => {
    const response = await axiosInstance.get(
      `${getConfig().navigatorBase}/api/v1/configuration/holiday-list?year=${requestPayload.year}`
    );

    return response;
  }
);

export const postNavigatorStatus = createAsyncThunk(
  "navigator/postNavigatorStatus",
  async (requestPayload: { status: string; expirationMinutes: number; revertStatus?: boolean }) => {
    const response = await axiosInstance.post(`${getConfig().navigatorBase}/api/v1/navigators/status`, {
      status: requestPayload.status,
      expirationMinutes: requestPayload.expirationMinutes,
      revertStatus: requestPayload.revertStatus ? requestPayload.revertStatus : false,
    });

    return response;
  }
);

export const getCurrentUserProfile = createAsyncThunk("navigator/getCurrentUserProfile", async () => {
  const response = await axiosInstance.get(`${getConfig().navigatorBase}/api/v1/navigators`, {});
  return response.data;
});

export const acknowledgeMissedCall = createAsyncThunk(
  "navigator/acknowledgeMissedCall",
  async (requestPayload: { callId: string; participantId?: string }) => {
    const response = await axiosInstance.post(`${getConfig().telephonyBase}/api/v1/logs/acknowledge-missed-call`, {
      callId: requestPayload.callId,
      participantId: requestPayload.participantId,
    });
    return response.data;
  }
);
