import { createSlice } from "@reduxjs/toolkit";
import { getAssessmentDataAsync, getPhysicianDataAsync, postAsessmentDataAsync } from "./assessment.action";
import { IAssessmentState } from "state/types/assessment.type";
import { getAllIndexes } from "shared/methods/utilityFunctions";
import { toast } from "react-toastify";
import { FORM_SUBMITTED_SUCCESSFULLY, PLEASE_FILL_ALL_REQUIRED_QUESTIONS } from "shared/constant/commonConstants";

const initialState: IAssessmentState = {
  id: "",
  assessmentId: "",
  episodeType: "",
  workflowName: "",
  order: 1,
  questions: [],
  answers: [],
  physicans: [],
  error: false,
  isLoading: false,
  isSubmitButtonClicked: false,
  isSubmitted: false,
  patientId: null,
  isValueChanged: false,
};

const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    setSelectedValue(state, action) {
      const payload = action.payload;
      const index = state.answers.findIndex((i) => i.questionId === payload.questionId);
      state.isValueChanged = true;
      if (index >= 0) {
        for (const v of Object.entries(state.answers[index])) {
          if (v.includes(`${payload.questionId}`)) {
            delete state.answers[index].selectedValues[payload.questionId];
          }
        }
        const filterAllQuestionsWithQuestionId = getAllIndexes(state.answers, payload.questionId);
        filterAllQuestionsWithQuestionId.forEach((filteredIndex) => {
          state.answers.splice(filteredIndex, 1);
        });
        const copyOfAnswer = { ...state.answers[index] };
        copyOfAnswer.selectedValues[payload.questionId] = action.payload.answer[payload.questionId];
        state.answers[index] = copyOfAnswer;
      } else {
        state.answers.push({
          pageId: state.episodeType,
          questionId: payload.questionId,
          selectedValues: payload.answer,
        });
      }
    },
    appendSelectedValue(state, action) {
      const payload = action.payload;
      const index = state.answers.findIndex((i) => i.questionId === payload.questionId);
      state.isValueChanged = true;
      if (index >= 0) {
        const copyOfAnswer = { ...state.answers[index] };
        const existingAnswers = copyOfAnswer.selectedValues[payload.questionId];
        if (existingAnswers.length > 0) {
          existingAnswers.push(payload.answer[payload.questionId][0]);
          state.answers[index] = copyOfAnswer;
        }
      } else {
        state.answers.push({
          pageId: state.episodeType,
          questionId: payload.questionId,
          selectedValues: payload.answer,
        });
      }
    },
    removeSelectedValue(state, action) {
      state.isValueChanged = true;
      const payload = action.payload;
      const index = state.answers.findIndex((i) => i.questionId === payload.questionId);
      const copy = [...state.answers];
      copy.splice(index, 1);
      state.answers = copy;
    },
    removeAppendedSelectedValue(state, action) {
      state.isValueChanged = true;
      const payload = action.payload;
      const index = state.answers.findIndex((i) => i.questionId === payload.questionId);
      const filteredAnswers = state.answers[index].selectedValues[payload.questionId].filter((i: any) => {
        return i.id !== payload.answerId;
      });
      const copy = [...state.answers];
      if (filteredAnswers.length > 0) {
        state.answers[index].selectedValues[payload.questionId] = filteredAnswers;
      } else {
        copy.splice(index, 1);
        state.answers = copy;
      }
    },
    setScaleValue(state, action) {
      state.isValueChanged = true;
      const payload = action.payload;
      const index = state.answers.findIndex((i) => i.questionId === payload.questionId);
      if (index >= 0) {
        state.answers[index].selectedValues = payload.answer;
      } else {
        state.answers.push({
          questionId: payload.questionId,
          pageId: payload.pageId,
          selectedValues: payload.answer,
        });
      }
    },
    resetAssessmentState() {
      return initialState;
    },
  },

  extraReducers(builder) {
    return (
      builder.addCase(getAssessmentDataAsync.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(getAssessmentDataAsync.fulfilled, (state, action) => {
        state.id = action.payload.id;
        state.assessmentId = action.payload.assessmentId;
        state.order = action.payload.order;
        state.workflowName = action.payload.workflowName;
        state.episodeType = action.payload.episodeType;
        state.questions = action.payload.questions;
        state.formId = action.payload.formId;
        state.version = action.payload.version;
        state.patientId = action.payload.patientId;
        state.error = false;
        state.isLoading = false;
        if (action.payload.answers && action.payload.answers.length > 0) {
          state.isSubmitted = true;
        } else {
          state.isSubmitted = false;
        }
        state.answers =
          action.payload.answers && action.payload.answers.length > 0
            ? action.payload.answers.map((item: any) => {
                const selectionValue = { [item.questionId]: item.selectionValues };
                return { questionId: item.questionId, selectedValues: selectionValue, pageId: "" };
              })
            : [];
      }),
      builder.addCase(getAssessmentDataAsync.rejected, (state, action) => {
        const payload: any = action.payload;
        state.workflowName = payload.workflowName;
        state.error = payload.error;
        state.questions = [];
        state.isLoading = false;
      }),
      builder.addCase(getPhysicianDataAsync.pending, () => {}),
      builder.addCase(getPhysicianDataAsync.fulfilled, (state, action) => {
        state.physicans = action.payload;
      }),
      builder.addCase(postAsessmentDataAsync.pending, (state, action) => {
        state.isSubmitButtonClicked = true;
      }),
      builder.addCase(postAsessmentDataAsync.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.isSubmitted = true;
          state.isValueChanged = false;
          toast.success(FORM_SUBMITTED_SUCCESSFULLY, {
            toastId: "form-success",
            containerId: "main",
          });
        } else {
          toast.error(
            action.payload.response.data.errors.length
              ? PLEASE_FILL_ALL_REQUIRED_QUESTIONS
              : action.payload.response.data,
            {
              toastId: "form-error",
              containerId: "main",
            }
          );
        }
      }),
      builder.addCase(postAsessmentDataAsync.rejected, (state, action) => {
        state.isSubmitButtonClicked = false;
        toast.error(PLEASE_FILL_ALL_REQUIRED_QUESTIONS, {
          toastId: "form-error",
          containerId: "main",
        });
        state.isLoading = false;
      })
    );
  },
});
export default assessmentSlice;
export const {
  setSelectedValue,
  appendSelectedValue,
  removeSelectedValue,
  removeAppendedSelectedValue,
  setScaleValue,
  resetAssessmentState,
} = assessmentSlice.actions;
export const getAssessmentState = (state: any): IAssessmentState => state.assessment;
