import Icon from "../../../../../components/Icon/icon.component";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTwilioClientService } from "shared/services/twilio/twilio-client.service";
import {
  saveConversationParticipants,
  setMessagePhysician,
  setUserIdforMessage,
} from "state/feature/messaging/messaging.slice";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { getAuth } from "state/feature/auth/auth.slice";
import { getCallState, saveCurrentCall, saveCurrentCallParticipants } from "state/feature/call/call.slice";
import { setIsOpenCallModal } from "state/feature/common/common.slice";
import { useAppDispatch } from "state/store";
import { getWhiteLabelPhoneNumberAsync } from "state/feature/navigator/navigator.action";
import { isMobile } from "react-device-detect";
import SidePopup from "components/SidePopup/side-popup.component";
import Button from "components/Button/button.component";
import Avatar from "components/Avatar/avatar.component";
import { NO_PHONE_NUMBER_FOR_PRACTICE, TOAST_MESSAGE_NO_PHONE_NUMBER_ASSIGNED } from "shared/constant/commonConstants";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { PhysicianContactType } from "state/types/contact-slice.type";

export interface PracticeTableRowProps {
  practice: string;
  practicePhoneNumber: string;
  noOfPatients: number;
  address?: string;
  physicianUserID: string;
  handleMessageSidePopup: () => void;
  clientID: string;
  practiceName: string;
  physicianDetails: PhysicianContactType;
}

const Row = ({
  practice,
  practicePhoneNumber,
  noOfPatients,
  physicianUserID,
  handleMessageSidePopup,
  clientID,
  practiceName,
  physicianDetails,
}: PracticeTableRowProps) => {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const twilioClient = useTwilioClientService();
  const [isCallButtonClicked, setIsCallButtonClicked] = useState(false);
  const [isRowHovered, setIsRowHovered] = useState(false);
  const authState = useSelector(getAuth);
  const callState = useSelector(getCallState);
  const navigatorState = useSelector(getNavigatorState);
  const handleSidePopupClose = () => {
    dispatch(setIsOpenCallModal(false));
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleActionPopupClose = () => {
    if (isMobile && !callState.twilioCallDevice) {
      twilioClient.registerDevice();
    }
    setIsOpen(!isOpen);
  };
  const makeACall = async () => {
    try {
      if (!isCallButtonClicked) {
        setIsCallButtonClicked(true);
        const navigatorWhiteLabelPhoneNumberData = await appDispatch(
          getWhiteLabelPhoneNumberAsync({ clientId: clientID })
        ).unwrap();
        if (navigatorWhiteLabelPhoneNumberData.status === 204) {
          toast.error(NO_PHONE_NUMBER_FOR_PRACTICE, {
            containerId: "main",
            toastId: "call",
          });
        } else if (
          navigatorWhiteLabelPhoneNumberData &&
          navigatorWhiteLabelPhoneNumberData.data.whiteLabelPhoneNumber
        ) {
          const callerDetails = {
            name: `${navigatorState.currentUserProfile?.firstName ? navigatorState.currentUserProfile?.firstName : ""}${
              navigatorState.currentUserProfile?.lastName ? ` ${navigatorState.currentUserProfile?.lastName}` : ""
            }`,
            id: navigatorState.currentUserProfile?.id ? navigatorState.currentUserProfile?.id : "",
            phoneNumber: navigatorState.currentUserProfile?.forwardingPhoneNumber
              ? navigatorState.currentUserProfile?.forwardingPhoneNumber
              : "",
            type: "Navigator",
          };
          const effectiveNavigator = {
            id: navigatorState.currentUserProfile?.id ? navigatorState.currentUserProfile?.id : "",
            name: `${navigatorState.currentUserProfile?.firstName ? navigatorState.currentUserProfile?.firstName : ""}${
              navigatorState.currentUserProfile?.lastName ? ` ${navigatorState.currentUserProfile?.lastName}` : ""
            }`,
            phoneNumber: navigatorState.currentUserProfile?.forwardingPhoneNumber
              ? navigatorState.currentUserProfile?.forwardingPhoneNumber
              : "",
          };
          await twilioClient.addParticipantsAndCreateConference(
            practicePhoneNumber,
            navigatorWhiteLabelPhoneNumberData.data.whiteLabelPhoneNumber,
            [
              {
                phoneNumber: practicePhoneNumber,
                id: physicianUserID,
                name: practice,
                type: "Physician",
              },
              callerDetails,
            ],
            clientID,
            effectiveNavigator,
            () => {
              dispatch(saveCurrentCall(null));
              toast.dismiss();
            }
          );
          dispatch(saveCurrentCallParticipants(practice));
          handleSidePopupClose();
        } else {
          toast.error(TOAST_MESSAGE_NO_PHONE_NUMBER_ASSIGNED, {
            containerId: "main",
            toastId: "call",
          });
        }
        setIsCallButtonClicked(false);
      }
    } catch (error: any) {
      console.log(error);
      toast.error(
        "The microphone is currently disabled in your browser. You must enable the microphone and reload the browser (which disconnects the current call) to resolve the issue",
        { containerId: "main", toastId: "twilio-error" }
      );
      callState.twilioCallDevice.disconnectAll();
      dispatch(saveCurrentCall(null));
      setIsCallButtonClicked(false);
      toast.dismiss("call");
    }
  };
  const handleMessageSidePopupClick = () => {
    dispatch(setUserIdforMessage(physicianUserID));
    dispatch(setMessagePhysician(physicianDetails));
    dispatch(saveConversationParticipants(practice));
    handleMessageSidePopup();
  };
  return !isMobile ? (
    <tr
      onMouseEnter={() => setIsRowHovered(true)}
      onMouseLeave={() => setIsRowHovered(false)}
      className={isRowHovered ? "hovered" : ""}
    >
      <td>{practice}</td>
      <td>{noOfPatients}</td>
      <td>{practiceName}</td>
      <td>
        <div className={`icons ${isRowHovered ? "visible" : "hidden"}`}>
          <div
            className="icon-background"
            onClick={() => {
              if (!callState.currentCall) {
                makeACall();
              } else {
                toast.error("Another call is ongoing", {
                  toastId: "call-ongoing",
                  containerId: "main",
                });
              }
            }}
          >
            <Icon icon="call-icon" size={17} />
          </div>
          <div className="icon-background message" onClick={handleMessageSidePopupClick}>
            <Icon icon="navigator-message" size={17} />
          </div>
        </div>
      </td>
    </tr>
  ) : (
    <tr onClick={handleActionPopupClose}>
      <td>
        <span className="identity-box">
          <Avatar personName={practice} />
        </span>
      </td>
      <td>
        <span className="practice-name">{practice}</span>
        <span className="number-of-patients">{noOfPatients} Patients</span>
        <span className="practice-description">{practiceName}</span>
      </td>
      <SidePopup
        isOpen={isOpen}
        onClose={() => {
          handleActionPopupClose();
        }}
        className="bottom call-or-message-popup"
      >
        <div className="contact-name">{practice}</div>
        <div className="practice-name">{practiceName}</div>
        <Button
          text="Message"
          className="green-button apply-button"
          icon="Message-generate"
          iconClassName="Message-generate-icon"
          buttonSize={16}
          onClick={handleMessageSidePopupClick}
        />
        <Button
          text="Call"
          className="white-button cancel-button"
          icon="Call-generate"
          iconClassName="phone-call-icon"
          buttonSize={18}
          onClick={() => {
            if (!callState.currentCall) {
              makeACall();
            } else {
              toast.error("Another call is ongoing", {
                toastId: "call-ongoing",
                containerId: "main",
              });
            }
          }}
        />
      </SidePopup>
    </tr>
  );
};

export default Row;
