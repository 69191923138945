export enum EpisodeDetailsTab {
  TASKS = "Tasks",
  PATIENT_DETAILS = "Patient Details",
  EPISODE_DETAILS = "Episode Details",
  ACTIVITY_HISTORY = "Activity History",
  MISCELLANEOUS = "Miscellaneous",
}

export enum EpisodesPageCommonMessages {
  COMING_SOON = "Coming Soon...",
  NO_EPISODES_FOUND = "Oops! No episodes found",
}
