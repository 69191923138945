import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { getPageIdByPage } from "pages/task-management/risk-assessment/common/risk-assessment.constant";
import { Pages, SaveRiskAssessmentPayload } from "pages/task-management/risk-assessment/common/risk-assessment.types";
import { axiosInstance } from "shared/axios-instance";

export const getSectionsByPageAsync = createAsyncThunk(
  "risk-assessment/getSectionsByPageAsync",
  async (requestPayload: { pageId: Pages }) => {
    const page = getPageIdByPage(requestPayload.pageId);
    const response = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/RiskAssessment/pages/${page}`);
    return response.data;
  }
);

export const getQuestionsBySectionsAsync = createAsyncThunk(
  "risk-assessment/getQuestionsBySectionsAsync",
  async (requestPayload: { sections: string[]; pageId: Pages }) => {
    const queryParams = requestPayload.sections.map((n) => `sectionIds=${n}`).join("&");
    const response = await axiosInstance.get(
      `${getConfig().taskManagementBase}/api/v1/RiskAssessment/sections?${queryParams}`
    );
    return response.data;
  }
);

export const getExtraQuestionsBySectionsAsync = createAsyncThunk(
  "risk-assessment/getExtraQuestionsBySectionsAsync",
  async (requestPayload: { sections: string[]; pageId?: Pages }) => {
    const queryParams = requestPayload.sections.map((n) => `sectionIds=${n}`).join("&");
    const response = await axiosInstance.get(
      `${getConfig().taskManagementBase}/api/v1/RiskAssessment/sections?${queryParams}`
    );
    return response.data;
  }
);

export const getRiskAssessmentByIntakeIdAsync = createAsyncThunk(
  "risk-assessment/getRiskAssessmentByIntakeIdAsync",
  async (requestPayload: { intakeId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().taskManagementBase}/api/v1/RiskAssessment/${requestPayload.intakeId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  }
);

export const getRiskAssessmentForResultByIntakeIdAsync = createAsyncThunk(
  "risk-assessment/getRiskAssessmentForResultByIntakeIdAsync",
  async (requestPayload: { intakeId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().taskManagementBase}/api/v1/RiskAssessment/${requestPayload.intakeId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  }
);

export const getRiskAssessmentSummaryByIntakeIdAsync = createAsyncThunk(
  "risk-assessment/getRiskAssessmentSummaryByIntakeIdAsync",
  async (requestPayload: { intakeId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().taskManagementBase}/api/v1/RiskAssessment/summary/${requestPayload.intakeId}`
      );
      if (response.status === 204) {
        return null;
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  }
);

export const saveRiskAssessmentAnswersAsync = createAsyncThunk(
  "risk-assessment/saveRiskAssessmentAnswersAsync",
  async (requestPayload: SaveRiskAssessmentPayload) => {
    const response = await axiosInstance.post(
      `${getConfig().taskManagementBase}/api/v1/RiskAssessment`,
      requestPayload
    );
    return response.data;
  }
);
