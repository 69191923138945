import { createSlice } from "@reduxjs/toolkit";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { toast } from "react-toastify";
import { IMessagingState } from "state/types/messaging-slice.type";
import {
  fetchAllMessagesOfAConversationAsync,
  getAllConversationsAsync,
  getConversationIfPresentAsync,
  getPatientContactAsync,
  getPatientNavigatorListAsync,
  getPhysicianContactAsync,
  verifyOtpAsync,
} from "./messaging.action";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { PatientContactType, PhysicianContactType } from "state/types/contact-slice.type";

const initialState: IMessagingState = {
  isLoading: false,
  isError: false,
  conversations: [],
  selectedConversation: {
    conversationId: -1,
    twilioConversationId: "",
    participants: "",
    patientDOB: null,
    subject: "",
  },
  participants: [],
  userIdforMessage: "",
  convRequestPayload: {
    userID: "",
    searchKeyword: "",
    limit: 0,
    offset: 0,
    convStartDate: new Date().toDateString(),
    convEndDate: new Date().toDateString(),
    sortColumn: "conversationTime",
    sortOrder: SortingOrderType.DESC,
  },
  messageContacts: {
    patientContacts: [],
    physicianContacts: [],
  },
  isLoadingPatientList: true,
  patientNavigators: [],
  currentMessagePhysician: null,
};

const messagingSlice = createSlice({
  name: "messaging",
  initialState,
  reducers: {
    saveConversationId: (state: any, action) => {
      state.selectedConversation.conversationId = action.payload;
    },
    saveTwilioConversationId: (state: any, action) => {
      state.selectedConversation.twilioConversationId = action.payload;
    },
    saveConversationParticipants: (state: any, action) => {
      state.selectedConversation.participants = action.payload;
    },
    saveConversationHeaderDetails: (state, action) => {
      state.selectedConversation.patientDOB = action.payload.patientDOB;
      state.selectedConversation.subject = action.payload.subject;
    },
    setUserIdforMessage: (state: any, action) => {
      state.userIdforMessage = action.payload;
    },
    resetConversations: (state: any) => {
      state.conversations = [];
    },
    saveParticipantsDetails: (state: any, action) => {
      state.participants = action.payload;
    },
    setLastMessageNotificationStatus: (state: any, action) => {
      state.conversations = state.conversations.map((conversation: any) => {
        if (conversation.conversationID?.toString() === action.payload?.toString()) {
          conversation.isLastNotificationRead = true;
        }
        return conversation;
      });
    },
    setNotificationStatusForAllConversations: (state: any) => {
      state.conversations = state.conversations.map((conversation: any) =>
        conversation.isLastNotificationRead === false ? { ...conversation, isLastNotificationRead: true } : conversation
      );
    },
    setConvRequestPayload: (state: any, action) => {
      state.convRequestPayload = action.payload;
    },
    addPatientToPatientArray(state, action) {
      state.messageContacts.patientContacts.unshift(action.payload);
    },
    setMessagePhysician(state, action) {
      state.currentMessagePhysician = action.payload;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(verifyOtpAsync.pending, (state: any) => {
        state.isLoading = true;
      }),
      builder.addCase(verifyOtpAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
      }),
      builder.addCase(verifyOtpAsync.rejected, (state: any) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "messaging-error",
        });
      }),
      builder.addCase(getAllConversationsAsync.pending, (state: any) => {
        state.isLoading = true;
      }),
      builder.addCase(getAllConversationsAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        if (state.conversations.length > 0 && action.meta.arg.offset > 0) {
          state.conversations = [...state.conversations, ...action.payload];
        } else {
          state.conversations = action.payload;
        }
      }),
      builder.addCase(getAllConversationsAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(fetchAllMessagesOfAConversationAsync.pending, (state: any) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchAllMessagesOfAConversationAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
      }),
      builder.addCase(fetchAllMessagesOfAConversationAsync.rejected, (state: any) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getConversationIfPresentAsync.pending, (state: any) => {
        state.isLoading = true;
      }),
      builder.addCase(getConversationIfPresentAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
      }),
      builder.addCase(getConversationIfPresentAsync.rejected, (state: any) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getPatientContactAsync.rejected, (state: any, action) => {
        if (!action.meta.aborted) {
          toast.error("Something went wrong", { containerId: "contactLoadingError" });
        }
      }),
      builder.addCase(getPatientContactAsync.fulfilled, (state, action) => {
        state.messageContacts.patientContacts = action.payload.data.patientContact;
      }),
      builder.addCase(getPhysicianContactAsync.rejected, (state, action) => {
        if (!action.meta.aborted) {
          toast.error("Something went wrong", { containerId: "contactLoadingError" });
        }
      }),
      builder.addCase(getPhysicianContactAsync.fulfilled, (state, action) => {
        state.messageContacts.physicianContacts = action.payload.data.physicianContact;
      }),
      builder.addCase(getPatientNavigatorListAsync.pending, (state) => {
        state.isLoadingPatientList = true;
        state.patientNavigators = [];
      }),
      builder.addCase(getPatientNavigatorListAsync.fulfilled, (state, action) => {
        if (action.payload.status === 204 || action.payload == "") {
          state.patientNavigators = [];
        } else {
          state.patientNavigators = action.payload;
        }
        state.isLoadingPatientList = false;
      })
    );
  },
});

export const {
  saveConversationId,
  saveTwilioConversationId,
  saveConversationParticipants,
  saveConversationHeaderDetails,
  setUserIdforMessage,
  addPatientToPatientArray,
  resetConversations,
  saveParticipantsDetails,
  setLastMessageNotificationStatus,
  setNotificationStatusForAllConversations,
  setConvRequestPayload,
  setMessagePhysician,
} = messagingSlice.actions;
export const getMessagingState = (state: any): IMessagingState => state.messaging;
export default messagingSlice;
