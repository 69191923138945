import Icon from "components/Icon/icon.component";
import { FilterPillProps } from "./filter-pill.type";
import "./filter-pill.style.scss";
const FilterPill = ({ title, text, onClear }: FilterPillProps) => {
  return (
    <div className="filter-pill">
      <div className="title">{title}</div>
      <div className="text-icon-container">
        <div className="text">{text}</div>
        <div className="close-icon" onClick={onClear}>
          <Icon icon="close" size={10} />
        </div>
      </div>
    </div>
  );
};
export default FilterPill;
