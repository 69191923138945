import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { axiosInstance } from "shared/axios-instance";
import { IAssessmentState } from "state/types/assessment.type";

export const getAssessmentDataAsync = createAsyncThunk(
  "assessment/getAssessmentDataAsync",
  async (queryParams: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().taskManagementBase}/api/v1/Assessment/form-questions?intakeId=${
          queryParams.intakeId
        }&workflowDetailId=${queryParams.workflowDetailId}`
      );
      if (response.status === 204) {
        return rejectWithValue({
          workflowName: "",
          error: true,
        });
      }
      return response.data as IAssessmentState;
    } catch (err: any) {
      return rejectWithValue({
        workflowName: "",
        error: true,
      });
    }
  }
);

export const getPhysicianDataAsync = createAsyncThunk("assessment/getPhysicianDataAsync", async () => {
  const response = await axiosInstance.get(
    `${getConfig().efusionBase}/api/v2/ExtProvider/getAll/false/true/false/false/true`
  );
  return response.data;
});

export const postAsessmentDataAsync = createAsyncThunk(
  "assessment/postAsessmentDataAsync",
  async ({ answers, intakeId, formId, version, workflowId, loggedInUserEmail, patientId }: any) => {
    try {
      const response = await axiosInstance.post(`${getConfig().taskManagementBase}/api/v1/Assessment`, {
        intakeId,
        workflowId,
        formId,
        createUser: loggedInUserEmail,
        version,
        answers,
        patientId,
      });
      return response;
    } catch (error: any) {
      return error;
    }
  }
);
