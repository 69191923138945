import { useSelector } from "react-redux";
import TagsSearchDropdown from "components/TagsSearchDropdown/tags-search-dropdown.component";
import { TagsSearchDropdownUseCase } from "components/TagsSearchDropdown/tags-search-dropdown.enum";
import { getAuth } from "state/feature/auth/auth.slice";
import { ISelectedDropdownValue } from "state/types/navigator.type";
import Checkbox from "components/Checkbox/checkbox.component";
import { ITagsSearchDropdownType } from "shared/types/dropdown.type";
import SingleSelectDropdownWithSearch from "components/single-select-dropdown-with-search/single-select-dropdown-with-search.component";
import SearchWithReset from "components/ChatSidePanel/components/search-with-reset/search-with-reset.component";
import { defaultPatientValue, GENERAL, PHYSICIAN_COUNT } from "./compose.constant";
import moment from "moment";
import { getMessagingState } from "state/feature/messaging/messaging.slice";
import { getCommonState } from "state/feature/common/common.slice";
import { OpenFrom } from "shared/enums/chat.enum";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { isEmptyString } from "shared/methods/utilityFunctions";

type ComposePresentationProps = {
  selectedNavigator: ISelectedDropdownValue;
  onChangeNavigator: (value: ISelectedDropdownValue) => void;
  selectedPatient: ISelectedDropdownValue;
  onChangePatient: (value: ISelectedDropdownValue | null) => void;
  isSearchable: boolean;
  onChangePatientSearch: (value: string) => Promise<any>;
  patientCheckbox: boolean;
  onChangePatientCheckox: (value: boolean) => void;
  selectedPhysicians: Array<ISelectedDropdownValue>;
  onChangeSelectedPhysicians: (value: Array<ISelectedDropdownValue>) => void;
  physicianSearchText: string;
  onChangePhysicianSearchText: (value: string) => void;
};

const ComposePresentation = (props: ComposePresentationProps) => {
  const {
    selectedNavigator,
    onChangeNavigator,
    selectedPatient,
    onChangePatient,
    isSearchable,
    onChangePatientSearch,
    patientCheckbox,
    onChangePatientCheckox,
    selectedPhysicians,
    onChangeSelectedPhysicians,
    physicianSearchText,
    onChangePhysicianSearchText,
  } = props;
  const { user } = useSelector(getAuth);
  const { modal } = useSelector(getCommonState);
  const { messageContacts, patientNavigators, isLoadingPatientList } = useSelector(getMessagingState);

  const getAllNavigators = () => {
    const navigators: any = patientNavigators.map((item) => {
      return {
        ...item,
        id: item.careManagerId,
        key: item.careManagerId,
        label: `${item.firstName} ${item.lastName}`,
        name: `${item.firstName} ${item.lastName}`,
        value: `${item.firstName} ${item.lastName}`,
      };
    });
    if (
      patientNavigators.length > 0 &&
      patientNavigators.findIndex((item) => item.careManagerId === user.navigatorId) === -1
    ) {
      navigators.unshift({
        ...user,
        key: user.navigatorId,
        label: `${user.firstName} ${user.lastName}`,
        name: `${user.firstName} ${user.lastName}`,
        value: `${user.firstName} ${user.lastName}`,
      });
    }
    return navigators;
  };

  const checkSearchText = (value1: string, value2: string): boolean => {
    return value1.toLowerCase().includes(value2.toLowerCase());
  };

  const filterPhysicianDropdown = (obj: ITagsSearchDropdownType): boolean => {
    return selectedPhysicians.length > 0
      ? !selectedPhysicians.some((val: ITagsSearchDropdownType) => val.key === obj.key) &&
          checkSearchText(obj.name, physicianSearchText)
      : checkSearchText(obj.name, physicianSearchText);
  };

  const getAllPhysicians = () => {
    const physicians = messageContacts.physicianContacts
      .map((item) => {
        return {
          key: item.physicianUserID,
          name: `${item.physicianFirstName} ${item.physicianLastName}`,
          value: `${item.practiceName}`,
          ...item,
        };
      })
      .filter((obj) => filterPhysicianDropdown(obj as ITagsSearchDropdownType));
    return physicians as ITagsSearchDropdownType[];
  };

  const getIsPatientDisabled = () => {
    if (selectedPatient === null) {
      return true;
    }
    return selectedPatient.key === GENERAL.toLowerCase();
  };

  const loadOptionsOnSearch = (val: string, callback: (options: ISelectedDropdownValue[]) => void) => {
    onChangePatientSearch(val).then((response) => {
      const patients = response.payload.data.patientContact.map((item: any) => {
        return {
          key: item.patientUserID,
          name: `${item.patientFirstName} ${item.patientLastName}`,
          value: `${
            item.patientDOB && !isEmptyString(item.patientDOB) ? moment(item.patientDOB).format("MM/DD/YYYY") : "-"
          }`,
          label: `${item.patientFirstName} ${item.patientLastName}`,
          ...item,
        };
      });
      callback(patients);
    });
  };

  if (!isLoadingPatientList) {
    return (
      <div className="compose-message-popup-container">
        <div className="row">
          <label>Navigator</label>
          <SingleSelectDropdownWithSearch
            value={selectedNavigator}
            options={getAllNavigators()}
            name="Navigator"
            isDisabled={
              modal.isOpenChatModal.openFrom === OpenFrom.CONTACT ||
              modal.isOpenChatModal.openFrom === OpenFrom.CONTACT_PHYSICIAN ||
              modal.isOpenChatModal.openFrom === OpenFrom.TASK_MANAGEMENT
            }
            onChange={(option) => {
              onChangeNavigator(option);
            }}
            defaultValue={[]}
            isSearchable={false}
          />
        </div>
        <div className="row">
          <label>Subject</label>
          <SearchWithReset
            inputId="subject-box"
            className="basic-single single-select-dropdown-with-search"
            value={selectedPatient}
            defaultValue={defaultPatientValue}
            onTextChange={() => {
              onChangePatient(null);
            }}
            placeholder="Add Patient"
            isSearchable={isSearchable}
            onClick={onChangePatient}
            defaultOptions={[defaultPatientValue]}
            notFoundMessage="No patients found"
            loadOptions={loadOptionsOnSearch}
            onClickReset={() => {
              if (
                selectedPatient === null ||
                (typeof selectedPatient === "object" &&
                  Object.hasOwn(selectedPatient, "key") &&
                  selectedPatient.key !== "general")
              ) {
                onChangePatient(defaultPatientValue);
              } else {
                onChangePatient(null);
              }
            }}
            buttonText={
              selectedPatient !== null && selectedPatient.key === "general" ? "Or Select Patient" : "Reset to Default"
            }
            isDisabled={
              modal.isOpenChatModal.openFrom === OpenFrom.CONTACT ||
              modal.isOpenChatModal.openFrom === OpenFrom.CONTACT_PHYSICIAN ||
              modal.isOpenChatModal.openFrom === OpenFrom.TASK_MANAGEMENT
            }
          />
        </div>
        <div className={`row ${selectedPatient === null ? "disabled-row" : ""}`}>
          <label>Recipient</label>
          <div className="recipient-and-patient">
            <div className="recipient-text">Physician</div>
            <div className="recipient-dropwdown-container">
              <TagsSearchDropdown
                idFieldName=""
                value={selectedPhysicians}
                dropDownContainerClass="backup-navigator-dropdown-container-profile"
                dropDownBoxClass=""
                selectionClass="backup-navigator-dropdown"
                onSearchTextChange={(val: string) => {
                  onChangePhysicianSearchText(val);
                }}
                handleValueChanges={(val: any) => {
                  onChangeSelectedPhysicians(val);
                }}
                useCaseType={TagsSearchDropdownUseCase.ALL_PHYSICIANS}
                dropDownMenuItems={getAllPhysicians()}
                placeholder="Add Physician"
                maximumRange={PHYSICIAN_COUNT}
                isOptionDisabled={selectedPhysicians.length > 0 ? selectedPhysicians[0].practiceId?.toString() : false}
                isDisabled={selectedPatient === null}
              />
              <div className="selected-text">
                Selected:<span>{`${selectedPhysicians.length}/${PHYSICIAN_COUNT}`}</span>
              </div>
            </div>
          </div>
          <div className="patient-checkbox">
            <Checkbox
              id="patient"
              value="Patient"
              name="patient"
              isDisabled={getIsPatientDisabled()}
              isChecked={patientCheckbox}
              onChange={(e) => onChangePatientCheckox(e.target.checked)}
            />
          </div>
        </div>
        <div className="row">
          <div className="note">NOTE: A message is never sent to more than one practice.</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="center-align-loading-container">
        <CommonState type={CommonStateType.LOADING} />
      </div>
    );
  }
};

export default ComposePresentation;
