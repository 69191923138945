export const PAGE_LIMIT = 30;
export const TOAST_MESSAGE_NO_PHONE_NUMBER_ASSIGNED = "No phone number is assigned";
export const UNSAVED_MESSAGE = "You have unsaved changes. Do you want to discard them?";
export const CALL_NOT_READ = "Unable to mark the call as addressed";
export const CALL_READ = "Successfuly marked call as addressed";
export const BACKUP_NAVIGATOR_LIMIT = 10;
export const NO_PHONE_NUMBER_FOR_PRACTICE = "No phone number entered for the practice";
export const FORM_SUBMITTED_SUCCESSFULLY = "Form successfully submitted";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const PLEASE_FILL_ALL_REQUIRED_QUESTIONS = "Please fill all the required questions";
