import React from "react";
import { ToogleProps } from "./props";
import "./toggle.styles.scss";

const Toggle = ({
  toggleLeftTitle,
  toggleRightTitle,
  activeToggleTitle,
  changeActiveToggle,
  showNoTitle,
}: ToogleProps) => {
  return (
    <div className="toggleContainer">
      <div className={`toggleTitle ${toggleLeftTitle === activeToggleTitle ? "active" : ""}`}>
        {showNoTitle != undefined && showNoTitle ? "" : toggleLeftTitle}
      </div>
      <label className="switch">
        <input type="checkbox" onChange={changeActiveToggle} checked={toggleRightTitle === activeToggleTitle} />
        <span className="slider round"></span>
      </label>
      <div className={`toggleTitle ${toggleRightTitle === activeToggleTitle ? "active" : ""}`}>
        {showNoTitle != undefined && showNoTitle ? "" : toggleRightTitle}
      </div>
    </div>
  );
};

export default Toggle;
