import ReactTooltip from "react-tooltip";
import { ToolTipProps } from "./custom-tooltip.types";
import "./custom-tooltip.styles.scss";

export const CustomToolTip = (props: ToolTipProps) => {
  const { text, place } = props;
  return (
    <ReactTooltip
      id={text}
      place={place}
      type="dark"
      effect="solid"
      className={`custom-tooltip ${place}`}
      delayShow={100}
    >
      <span>{text}</span>
    </ReactTooltip>
  );
};
