import { useEffect, useState } from "react";
import Icon from "components/Icon/icon.component";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "shared/assets/images/logo.png";
import "./side-bar.styles.scss";
import Avatar from "components/Avatar/avatar.component";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "state/feature/auth/auth.slice";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import SidePopup from "components/SidePopup/side-popup.component";
import Button from "components/Button/button.component";
import { getCommonState, setIsOpenLogoutModal, setIsOpenMobileMenuNav } from "state/feature/common/common.slice";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import StatusSelectorComponent from "components/StatusSelector/status-selector.component";
import ClickAwayListener from "react-click-away-listener";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import StatusPicker from "components/status-picker/status-picker.component";
import { getOnCallNavigatorsListAsync, getHolidayListAsync } from "state/feature/navigator/navigator.action";
import moment from "moment";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { getNavigatorAvailabilityStatus } from "shared/methods/utilityFunctions";
import NotificationWeb from "pages/notification/web/notification-web.container";
import { getNotificationState, setIsNotificationDrawerOpen } from "state/feature/notification/notification.slice";
import { getNotificationCountAsync } from "state/feature/notification/notification.action";
import NotificationBadgeWeb from "pages/notification/components/notification-badge-web/notification-badge-web.component";

type SideBarProps = {
  routes: any[];
};

const SideBar = ({ routes }: SideBarProps) => {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const authState = useSelector(getAuth);
  const location = useLocation();
  const [isAvatarModalVisible, setIsAvatarModalVisible] = useState(false);

  const { modal: modalState, featureFlags } = useSelector(getCommonState);
  const { isNotificationDrawerOpen, shouldRefreshNotifications } = useSelector(getNotificationState);
  const { currentUserProfile, onCallLNavigators, holidayList } = useSelector(getNavigatorState);

  const handleSidePopupClose = () => {
    dispatch(setIsOpenMobileMenuNav(false));
  };

  useEffect(() => {
    if (featureFlags.inboundCalls) {
      dispatch(getHolidayListAsync({ year: moment().year() }));
      dispatch(
        getOnCallNavigatorsListAsync({
          startDate: new Date(moment().subtract(1, "day").toString()).toDateString(),
          endDate: new Date(moment().toString()).toDateString(),
          isInvokedFromSidebar: true,
        })
      );
    }
  }, [dispatch, featureFlags.inboundCalls]);

  useEffect(() => {
    if (featureFlags.inAppNotifications) {
      dispatch(getNotificationCountAsync({ ownerUserId: authState.user.email }));
    }
  }, [
    dispatch,
    featureFlags.inAppNotifications,
    authState.user.email,
    isNotificationDrawerOpen,
    shouldRefreshNotifications,
  ]);

  return (
    <div className={`side-bar ${isMobile ? "mobileView" : ""}`}>
      <BrowserView className="desktop-side-bar">
        <div className="side-bar-top">
          <div className="side-bar-header">
            {/* As logo is not properly loading with icomoon we will be using an image for that*/}
            <img src={Logo} alt="logo" className="logo-image" />
          </div>
          <ul className="side-bar-body">
            {routes.map((route) => {
              const isActive = location.pathname.includes(route.path);
              return (
                <li key={route.path} className="nav-item" data-tip data-for={route.name}>
                  <NavLink exact to={route.path} className={isActive ? "is-active-nav-item" : ""}>
                    <Icon icon={route.icon} size={route.size} className={route.className} />
                    <CustomToolTip text={route.name} place="left" />
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="side-bar-bottom">
          {featureFlags.inAppNotifications ? (
            <>
              <NotificationBadgeWeb />
              <ClickAwayListener
                onClickAway={() => {
                  if (isNotificationDrawerOpen) {
                    dispatch(setIsNotificationDrawerOpen(false));
                  }
                }}
              >
                <NotificationWeb />
              </ClickAwayListener>
            </>
          ) : null}

          <div
            style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
            onClick={() => setIsAvatarModalVisible(!isAvatarModalVisible)}
          >
            <Avatar isPointerDisabled={false} firstName={authState.user.firstName} lastName={authState.user.lastName} />
            {isAvatarModalVisible ? (
              <ClickAwayListener
                onClickAway={() => {
                  if (isAvatarModalVisible) {
                    setIsAvatarModalVisible(false);
                  }
                }}
              >
                <StatusSelectorComponent
                  firstName={authState.user?.firstName}
                  lastName={authState.user?.lastName}
                  isOpen={isAvatarModalVisible}
                  setIsOpen={() => setIsAvatarModalVisible(false)}
                />
              </ClickAwayListener>
            ) : null}
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="mobile-side-bar">
          <SidePopup
            isOpen={modalState.isOpenMobileMenuNav}
            onClose={() => dispatch(setIsOpenMobileMenuNav(false))}
            className="bottom sidenav-mobile-menu"
          >
            <div onClick={() => dispatch(setIsOpenMobileMenuNav(false))}>
              {featureFlags.inboundCalls && (
                <StatusPicker
                  statusText={getNavigatorAvailabilityStatus(currentUserProfile, holidayList, onCallLNavigators)}
                />
              )}
            </div>
            <div className="mobile-logged-in-as">
              <div className="logged-in-as-text">
                Logged in as
                <div className="first-last-name">
                  {authState.user.firstName} {authState.user.lastName}
                </div>
              </div>
              <div>
                <Button
                  text="Sign Out"
                  className="green-button"
                  onClick={() => {
                    dispatch(setIsOpenMobileMenuNav(false));
                    dispatch(setIsOpenLogoutModal(true));
                  }}
                />
              </div>
            </div>
            <ul className="mobile-menu-list">
              <li>
                <Link
                  to={{
                    pathname: `/profile`,
                  }}
                  onClick={handleSidePopupClose}
                  className="mobile-menu-link"
                >
                  <span className="icon-with-title">
                    <Icon icon="User" size={15} className="left-icon-symbol" />
                    Profile
                  </span>
                  <Icon icon="position-change-right" size={12} className="right-arrow-icon" />
                </Link>
              </li>
            </ul>
          </SidePopup>
        </div>
        <div className={`mobile-sticky-nav ${location.pathname === "/profile" ? "hidden" : ""}`}>
          <ul className="mobile-sticky-menu-list">
            {routes.map((route) => {
              const isActive = location.pathname === route.path;
              return route.enableMobileView ? (
                <li key={route.path} className={`nav-item ${route.path === "/profile" ? "hidden" : ""}`}>
                  <NavLink exact to={route.path} className={isActive ? "is-active-nav-item" : ""}>
                    <Icon icon={route.icon} size={route.size} className={route.className} />
                    <span className={`nav-item-circle ${isActive ? "is-circle-shown" : ""}`}></span>
                    <span className="nav-item-text">{route.name}</span>
                  </NavLink>
                </li>
              ) : null;
            })}
          </ul>
        </div>
      </MobileView>
    </div>
  );
};

export default SideBar;
