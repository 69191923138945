import { createSlice } from "@reduxjs/toolkit";
import {
  createTocAsync,
  editTocAsync,
  getAllTocFacilitiesAsync,
  getIntakeAsync,
  getTocAsync,
  getTocHistory,
} from "./toc-management.action";
import { toast } from "react-toastify";
import { ITOCManagementState, ITransitionOfCare } from "state/types/toc-management-slice.type";

const initialState: ITOCManagementState = {
  isLoading: false,
  intake: null,
  isTocsLoading: true,
  tocs: [],
  facilities: [],
};
const tocManagementSlice = createSlice({
  name: "toc-management",
  initialState: Object.assign({}, initialState),
  reducers: {
    updateTocList(state, action) {
      state.tocs = action.payload;
    },
    addNewTocRevision(state, action) {
      state.tocs.unshift(action.payload);
    },
    setIsTocsLoading(state, action) {
      state.isTocsLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getTocAsync.pending, (state) => {
        state.isTocsLoading = true;
        state.tocs = [];
      }),
      builder.addCase(getTocAsync.fulfilled, (state, action) => {
        state.isTocsLoading = false;
        state.tocs = action.payload;
      }),
      builder.addCase(getTocAsync.rejected, (state, action: any) => {
        state.isTocsLoading = false;
        state.tocs = [];
      }),
      builder.addCase(getAllTocFacilitiesAsync.fulfilled, (state, action) => {
        state.facilities = action.payload;
      }),
      builder.addCase(getIntakeAsync.fulfilled, (state, action) => {
        state.intake = action.payload;
      }),
      builder.addCase(createTocAsync.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(createTocAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("TOC added successfully", {
          containerId: "main",
        });
      }),
      builder.addCase(createTocAsync.rejected, (state, action) => {
        state.isLoading = false;
        toast.error("Something went wrong", {
          containerId: "main",
        });
      }),
      builder.addCase(editTocAsync.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(editTocAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("TOC updated successfully", {
          containerId: "main",
        });
      }),
      builder.addCase(editTocAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        toast.error("Something went wrong", {
          containerId: "main",
        });
      }),
      builder.addCase(getTocHistory.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(getTocHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        const currentTocIdx = state.tocs.findIndex((el) => el.id === action.meta.arg);
        state.tocs[currentTocIdx].history = action.payload;
      }),
      builder.addCase(getTocHistory.rejected, (state, action) => {
        state.isLoading = false;
        toast.error("Something went wrong", {
          containerId: "main",
        });
      })
    );
  },
});

export const { addNewTocRevision, updateTocList, setIsTocsLoading } = tocManagementSlice.actions;
export const getTocs = (state: any): ITransitionOfCare[] => state.tocManagement.tocs;
export const getTOCManagementState = (state: any): ITOCManagementState => state.tocManagement;
export default tocManagementSlice;
