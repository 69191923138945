import CommonState from "components/common-state/common-state.component";
import CommonNote from "components/risk-assessment-form-inputs/common-note/common-note.component";
import RiskAssessmentFormInputs from "components/risk-assessment-form-inputs/risk-assessment-form-inputs";
import { Pages, Question } from "pages/task-management/risk-assessment/common/risk-assessment.types";
import Section from "pages/task-management/risk-assessment/section/section.component";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionsBySectionsAsync,
  getSectionsByPageAsync,
} from "state/feature/risk-assessment/risk-assessment.action";
import { getRiskAssessmentState } from "state/feature/risk-assessment/risk-assessment.slice";
import AlarmIcon from "shared/assets/images/clock-alarm.svg";
import { CommonStateType } from "components/common-state/common-state.enum";

const GeneralQuestions = () => {
  const dispatch = useDispatch();
  const { isLoading, pages, selectedValues } = useSelector(getRiskAssessmentState);

  useEffect(() => {
    if (!pages.general.sections || pages.general.sections.length === 0) {
      dispatch(getSectionsByPageAsync({ pageId: Pages.General }));
    }
  }, [dispatch, pages.general.sections]);

  useEffect(() => {
    const controller = new AbortController();
    return () => {
      controller?.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (pages.general.sections && pages.general.sections.length > 0 && pages.general.sectionDetails.length === 0) {
      dispatch(getQuestionsBySectionsAsync({ sections: pages.general.sections, pageId: Pages.General }));
    }
  }, [dispatch, pages.general.sections, pages.general.sectionDetails]);

  return (
    <div className="assessment-question-container">
      {(isLoading || pages.general.isLoading) && <CommonState type={CommonStateType.LOADING} />}
      {!isLoading &&
        pages.general &&
        pages.general.sectionDetails &&
        pages.general.sectionDetails.length > 0 &&
        pages.general.sectionDetails.map((data: any) => {
          return (
            <Section key={data.sectionId} sectionHeading={""}>
              <>
                {data.questions &&
                  data.questions.length > 0 &&
                  data.questions.map((item: Question, index: number) => {
                    const selected = selectedValues
                      .map((values) =>
                        Object.entries(values.answers).find((answer) => answer[0] === item.questionId && item.note)
                      )
                      .flat()
                      .filter((result) => result !== undefined || null);

                    const isNoteVisibible =
                      item.selectionValues && item.selectionValues.filter((select) => select.showNote === true);
                    return (
                      <div key={item.questionId + "_" + index} className="question-section">
                        {item.questionType !== "conditional-section" && item.questionType !== "section-title" && (
                          <div className="question-section-title">{item.title}</div>
                        )}
                        <RiskAssessmentFormInputs
                          options={item.selectionValues}
                          inputType={item.questionType}
                          selectionType={item.selectionType}
                          className={`${item.questionType}-${item.questionId}`}
                          questionId={item.questionId}
                          questions={item.questions}
                          sectionId={data.sectionId}
                          pageId={Pages.General}
                          maxLength={item.maxLength}
                          qTitle={item.title}
                          order={item.order}
                          sectionOrder={data.order}
                        />
                        {item.note &&
                          selected &&
                          isNoteVisibible &&
                          selected.length > 0 &&
                          isNoteVisibible.length > 0 &&
                          selected[1][0].id === isNoteVisibible[0].id &&
                          selected[1][0].value === isNoteVisibible[0].title && (
                            <div className="question-section-note">
                              <CommonNote reminder={item.note} />
                            </div>
                          )}
                      </div>
                    );
                  })}
              </>
            </Section>
          );
        })}
    </div>
  );
};

export default GeneralQuestions;
