import Header from "pages/task-management/header/header.component";
import "./risk-assessment-result.styles.scss";
import PreviewRiskAssessmentResults from "./preview-risk-assessment-results/preview-risk-assessment-results";
import { batch, useDispatch, useSelector } from "react-redux";
import { getRiskAssessmentState, saveLosDays } from "state/feature/risk-assessment/risk-assessment.slice";
import { useCallback, useEffect, useState } from "react";
import {
  getRiskAssessmentForResultByIntakeIdAsync,
  getRiskAssessmentSummaryByIntakeIdAsync,
  saveRiskAssessmentAnswersAsync,
} from "state/feature/risk-assessment/risk-assessment.action";
import Icon from "components/Icon/icon.component";
import CommonState from "components/common-state/common-state.component";
import {
  RiskType,
  SaveRiskAssessmentPayload,
} from "pages/task-management/risk-assessment/common/risk-assessment.types";
import { useAppDispatch } from "state/store";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { toNumber } from "lodash";
import {
  PreviewRiskAssessment,
  QuestionAnswers,
} from "./preview-risk-assessment-results/preview-risk-assessment.types";
import Button from "components/Button/button.component";
import { Link } from "react-router-dom";
import ResultModal from "components/Modal/result-modal/result-modal.component";
import GenericPopupModal from "components/Modal/GenericPopupModal/generic-popup-modal.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import moment from "moment";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";

const RiskAssessmentResult = () => {
  useAxiosAuthenticated();

  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { isLoading, result, resultMeta, selectedValues, collapsibleSections } = useSelector(getRiskAssessmentState);
  const { patientDetails, isValidatingIntake, isValidIntake } = useSelector(getTaskManagementState);
  const { intakeId } = useParams<{ intakeId: string }>();
  const history = useHistory();
  const [losDays, setLosDays] = useState("");
  const [isLosEditModeOn, setIsLosEditModeOn] = useState<boolean>(false);
  const [isUpdateLosModalOpen, setIsUpdateLosModalOpen] = useState<boolean>(false);
  const [isSuggestionModalOpen, setIsSuggestionModalOpen] = useState<boolean>(false);
  const [isLosExists, setIsLosExists] = useState<boolean>(false);
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [riskType, setRiskType] = useState<RiskType | null>();
  const [isScoreChangedToHighRisk, setIsScoreChangedToHighRisk] = useState<boolean>(false);

  useEffect(() => {
    if (isValidIntake && patientDetails) {
      if (!patientDetails.isRiskAssessmentTaskAdded) {
        history.push(`/task-management/${intakeId}/risk-assessment`);
      }
    } else {
      history.push(`/task-management/${intakeId}/risk-assessment`);
    }
  }, [intakeId, history, appDispatch, isValidIntake, patientDetails]);

  useEffect(() => {
    if (patientDetails?.intakeId) {
      appDispatch(getRiskAssessmentForResultByIntakeIdAsync({ intakeId })).then((res) => {
        if (!res.payload || (res.payload as any).status === "draft") {
          history.push(`/task-management/${intakeId}/risk-assessment`);
        }
      });
    }
  }, [appDispatch, history, patientDetails?.intakeId, intakeId]);

  const patientDetailsDisplay = useCallback(() => {
    let patientName = "";
    if (patientDetails && patientDetails?.patientName) {
      patientName =
        patientDetails?.patientName.length > 25
          ? `${patientDetails?.patientName.substring(0, 25)}...`
          : patientDetails?.patientName;
    }
    return (
      <>
        <div className="patient-name">{patientName}</div>
        <div className="patient-other-details">
          <div>{patientDetails?.gender}</div>
          <div className="dash">|</div>
          <div>
            <span className="dob">DOB:</span> {moment(patientDetails?.dob).format("MM/DD/YYYY")}{" "}
            {patientDetails?.age ? `(${patientDetails.age} years)` : ""}
          </div>
        </div>
      </>
    );
  }, [patientDetails]);

  const getLosDays = useCallback(() => {
    const section = result.find((sectionEl) => sectionEl.id === "lace");
    const days = section?.questionAnswers.find((questionEl: QuestionAnswers) => questionEl.id === "los")?.answer;
    if (days && days.length > 0) {
      setIsLosExists(true);
      setLosDays(days);
    }
  }, [result]);

  useEffect(() => {
    getLosDays();
  }, [getLosDays]);

  useEffect(() => {
    if (patientDetails?.intakeId) {
      appDispatch(getRiskAssessmentSummaryByIntakeIdAsync({ intakeId }))
        .unwrap()
        .then((res) => {
          const sections = res.sections;
          const highRiskSectionsCount = sections.filter((sec: any) => sec.score >= sec.riskScore).length;
          setRiskType(highRiskSectionsCount > 0 ? RiskType.HIGH : RiskType.STANDARD);
        });
    }
  }, [appDispatch, patientDetails?.intakeId, intakeId]);

  const changeLosDays = (inputValue: string) => {
    const section = result.find((sectionEl) => sectionEl.id === "lace");
    const days = section?.questionAnswers.find((questionEl: QuestionAnswers) => questionEl.id === "los")?.answer;
    setIsDirty(true);
    if (days && inputValue) {
      dispatch(saveLosDays(inputValue));
    }
  };

  const postSaveChanges = () => {
    batch(async () => {
      dispatch(getRiskAssessmentForResultByIntakeIdAsync({ intakeId }));

      const updatedSummary = await appDispatch(getRiskAssessmentSummaryByIntakeIdAsync({ intakeId })).unwrap();
      if (updatedSummary && updatedSummary.sections) {
        const highRiskSectionsCount = updatedSummary.sections.filter((sec: any) => sec.score >= sec.riskScore).length;
        if (riskType === RiskType.HIGH && highRiskSectionsCount === 0) {
          setIsScoreChangedToHighRisk(false);
          setIsSuggestionModalOpen(true);
        }
        if (riskType === RiskType.STANDARD && highRiskSectionsCount > 0) {
          setIsScoreChangedToHighRisk(true);
          setIsSuggestionModalOpen(true);
        }
        setRiskType(highRiskSectionsCount > 0 ? RiskType.HIGH : RiskType.STANDARD);
      }
    });
  };

  const saveChanges = () => {
    if (isLosEditModeOn && isUpdateLosModalOpen) {
      const sectionAnswers = selectedValues.map((selectedValue) => {
        return {
          sectionId: selectedValue.sectionId,
          pageId: selectedValue.pageId,
          order: selectedValue.sectionOrder,
          answers: Object.entries(selectedValue.answers).map(([questionId, selectionValues]) => ({
            questionId,
            order: selectionValues[0].order,
            selectionValues: selectionValues,
            collapsibleSectionId: selectionValues[0].collapsibleSectionId,
            isConclusion: selectionValues[0].isConclusion,
          })),
        };
      });
      const payload = {
        ...resultMeta,
        sectionAnswers,
        collapsibleSections: collapsibleSections.map((x: any) => x.sectionId),
      } as unknown as SaveRiskAssessmentPayload;
      appDispatch(saveRiskAssessmentAnswersAsync(payload))
        .unwrap()
        .then(() => {
          toast.success("Saved successfully.", {
            containerId: "main",
          });
          postSaveChanges();
        });
      setIsUpdateLosModalOpen(false);
      setIsLosEditModeOn(false);
      setIsDirty(false);
    }
  };

  const isValidLos = () => {
    return losDays.length > 0 && 1 <= toNumber(losDays) && toNumber(losDays) <= 50;
  };

  return (
    <>
      {isUpdateLosModalOpen && (
        <GenericPopupModal
          isOpen={isUpdateLosModalOpen}
          content="Updating LOS will recalculate the risk, are you sure you want to make the change?"
          submitButtonTitle="Proceed"
          handleSubmitClick={() => saveChanges()}
          handleCancelClick={() => setIsUpdateLosModalOpen(false)}
          cancelButtonVisible
        />
      )}
      {!isLoading && isSuggestionModalOpen && (
        <ResultModal
          isOpen={isSuggestionModalOpen}
          fromStandardToHigh={isScoreChangedToHighRisk}
          handleOkClick={() => {
            setIsSuggestionModalOpen(false);
          }}
        />
      )}
      {!isLoading && !isValidIntake && !isValidatingIntake && <CommonState type={CommonStateType.INTAKE_NOT_FOUND} />}
      {(isLoading || isValidatingIntake) && <CommonState type={CommonStateType.LOADING} />}
      {!isLoading && isValidIntake && !isValidatingIntake && result && result.length > 0 && (
        <div className="risk-assessment-result">
          <Header
            title="Risk Assessment Results"
            className="risk-assessment-result"
            patientDetails={
              <>
                <div className="left-section">{patientDetailsDisplay()}</div>
                <div className="right-section">
                  <div className="back-button-container">
                    <Link to={`/task-management/${intakeId}/risk-assessment/summary`}>
                      <Button text="Back to Summary" className="no-border-button back-button" onClick={() => {}} />
                    </Link>
                  </div>
                  <div className="los-container">
                    {isLosExists && (
                      <div
                        onMouseEnter={() => setErrorVisible(true)}
                        onMouseLeave={() => setErrorVisible(false)}
                        className={`los-days-container ${
                          parseInt(losDays) < 1 || parseInt(losDays) > 50 || losDays === ""
                            ? "los-error"
                            : "los-no-error"
                        }`}
                      >
                        <div className="los-days-container-title">LOS Days</div>
                        <div className="los-days-container-los-input">
                          <input
                            className="number-input"
                            type="number"
                            value={losDays}
                            readOnly={!isLosEditModeOn}
                            onKeyDown={(e) => {
                              if (e.key === "-") {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              setLosDays(e.target.value);
                              changeLosDays(e.target.value);
                            }}
                          />
                          {!isLosEditModeOn && (
                            <div className="icon-container-edit" onClick={() => setIsLosEditModeOn(true)}>
                              <Icon icon="edit-icon" size={32} />
                            </div>
                          )}
                          {isLosEditModeOn && (
                            <div
                              className={isValidLos() && isDirty ? "icon-container" : "icon-container-disabled"}
                              onClick={isValidLos() && isDirty ? () => setIsUpdateLosModalOpen(true) : () => {}}
                            >
                              <Icon icon="white-tick" size={18} />
                            </div>
                          )}
                        </div>

                        {errorVisible && (parseInt(losDays) < 1 || parseInt(losDays) > 50 || losDays === "") ? (
                          <div className="los-warning">
                            <span>LOS can be minimum 1 and maximum 50</span>
                          </div>
                        ) : (
                          <div className="los-warning-empty"></div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </>
            }
          ></Header>
          {riskType !== null && (
            <div
              className={
                "status-bar-container" +
                (!patientDetails?.isPlannedEpisode || riskType === RiskType.HIGH ? " high" : " standard")
              }
            >
              <div className="left-text">Risk Level :</div>
              <div className="right-text">
                {!patientDetails?.isPlannedEpisode || riskType === RiskType.HIGH ? "High" : "Standard"}
              </div>
            </div>
          )}
          <div className="spacer">
            {result.map((item: PreviewRiskAssessment) => {
              const isConclusionQuestions = item.questionAnswers.filter(
                (questionAnswer) => questionAnswer.isConclusion
              );
              return (
                <div key={item.id} className="risk-assessment-result-section-container">
                  <>
                    <PreviewRiskAssessmentResults
                      data={item.questionAnswers.filter((questionAnswer) => questionAnswer.isConclusion === null)}
                      title={item.sectionTitle}
                    />
                    <div className="risk-assessment-result-footer">
                      <div className="total-score-container">
                        <div className="total-heading">Total</div>
                        <div className="total-score">{item.ignoreScore ? "-" : item.totalScore}</div>
                      </div>
                    </div>
                  </>

                  <div className="conclusion-questions">
                    {isConclusionQuestions.map((questionAnswer) => {
                      return (
                        <>
                          <div className="conclusion-line"></div>
                          <div className="conclusion-question-container">
                            <div className="conclusion-title">{questionAnswer.question}</div>
                            <div className="conclusion-answer">{questionAnswer.answer}</div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default RiskAssessmentResult;
