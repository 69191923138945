import CommonState from "components/common-state/common-state.component";
import CommonNote from "components/risk-assessment-form-inputs/common-note/common-note.component";
import RiskAssessmentFormInputs from "components/risk-assessment-form-inputs/risk-assessment-form-inputs";
import { Pages } from "pages/task-management/risk-assessment/common/risk-assessment.types";
import Section from "pages/task-management/risk-assessment/section/section.component";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionsBySectionsAsync,
  getSectionsByPageAsync,
} from "state/feature/risk-assessment/risk-assessment.action";
import { getRiskAssessmentState } from "state/feature/risk-assessment/risk-assessment.slice";
import AlarmIcon from "shared/assets/images/clock-alarm.svg";
import { CommonStateType } from "components/common-state/common-state.enum";

const ClosingQuestions = () => {
  const dispatch = useDispatch();
  const { isLoading, pages } = useSelector(getRiskAssessmentState);

  useEffect(() => {
    if (!pages.closing.sections || pages.closing.sections.length === 0) {
      dispatch(getSectionsByPageAsync({ pageId: Pages.Closing }));
    }
  }, [dispatch, pages.closing.sections]);

  useEffect(() => {
    if (pages.closing.sections && pages.closing.sections.length > 0 && pages.closing.sectionDetails.length === 0) {
      dispatch(getQuestionsBySectionsAsync({ sections: pages.closing.sections, pageId: Pages.Closing }));
    }
  }, [dispatch, pages.closing.sections, pages.closing.sectionDetails]);

  return (
    <div className="assessment-question-container">
      {(isLoading || pages.closing.isLoading) && <CommonState type={CommonStateType.LOADING} />}
      {!isLoading &&
        pages.closing &&
        pages.closing.sectionDetails &&
        pages.closing.sectionDetails.length > 0 &&
        pages.closing.sectionDetails.map((data: any) => {
          return (
            <Section key={data.sectionId} sectionHeading={""}>
              <>
                {data.questions &&
                  data.questions.length > 0 &&
                  data.questions.map((item: any, index: any) => {
                    return (
                      <div className={`${item.isConclusion ? "conclusion-container" : ""}`}>
                        {item.isConclusion && <div className="conclusion-line" />}
                        <div key={item.questionId + "_" + index} className="question-section">
                          {item.questionType !== "conditional-section" && item.questionType !== "section-title" && (
                            <div className="question-section-title">{item.title}</div>
                          )}
                          <div className="question-section-checkbox-grid">
                            <RiskAssessmentFormInputs
                              questionId={item.questionId}
                              options={item.selectionValues}
                              inputType={item.questionType}
                              selectionType={item.selectionType}
                              sectionId={data.sectionId}
                              maxLength={item.maxLength}
                              className={`${item.questionType}-${item.questionId}`}
                              qTitle={item.title}
                              pageId={Pages.Closing}
                              order={item.order}
                              sectionOrder={data.order}
                              isConclusion={item.isConclusion}
                              placeHolder={item.placeHolder}
                            />
                          </div>
                          {item.note && (
                            <div className="question-section-note">
                              <CommonNote reminder={item.note} />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </>
            </Section>
          );
        })}
    </div>
  );
};

export default ClosingQuestions;
