export enum TaskManagementTab {
  ACTIVE = "Active",
  SNOOZED = "Snoozed",
}

export enum TaskInfoTab {
  EPISODE = "Episode",
  TASKS = "Tasks",
  ATTACHMENTS = "Attachments",
  ACTIVITY = "Activity",
}
