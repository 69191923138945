import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClickAwayListener from "react-click-away-listener";
import TickmarkIcon from "shared/assets/images/tickmark.png";
import Icon from "components/Icon/icon.component";
import HighlightedText from "components/HighLightedText/high-lighted-text.component";
import "./styles.scss";
import { getTOCManagementState } from "state/feature/toc-management/toc-management.slice";

type FacilityDropdownProps = {
  onChange: (id: number, name: string) => void;
  isError: boolean;
  isDisabled?: boolean;
  selectedOptionId?: number;
};
const FacilityDropdown = (props: FacilityDropdownProps) => {
  const { facilities } = useSelector(getTOCManagementState);
  const [isOptionsVisible, setIsOptionsVisible] = useState<boolean>(false);
  const { selectedOptionId, onChange, isDisabled, isError } = props;
  const [selectedOption, setSelectedOption] = useState<{ id: number; name: string }>();
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (facilities && facilities.length && selectedOptionId !== -1) {
        const facility = facilities.find((el) => el.ID === selectedOptionId)!;
        setSelectedOption({ id: facility.ID, name: facility.ProviderName });
      } else {
        setSelectedOption({ id: -1, name: "" });
      }
    }

    return () => {
      isMounted = false;
    };
  }, [selectedOptionId, facilities]);

  const onOptionClick = (id: number) => {
    if (facilities && facilities.length) {
      const facility = facilities.find((el) => el.ID === id)!;
      setSelectedOption({ id: facility.ID, name: facility.ProviderName });
      setIsOptionsVisible(false);
      onChange(facility.ID, facility.ProviderName);
      setSearch("");
    }
  };

  const clearSelection = () => {
    setSelectedOption({ id: -1, name: "" });
    onChange(-1, "");
  };

  const onSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOptionsVisible(false);
      }}
    >
      <div className="facility-dropdown-container">
        <div
          className={`current-selction-container ${isDisabled ? "disabled" : null} ${!isError ? null : "input-error"}`}
        >
          <div className="current-selection">{selectedOption?.name}</div>
          {selectedOption && selectedOption?.id !== -1 ? (
            <div className="cancel-selection-container">
              <div
                className="cancel-icon-container"
                onClick={() => {
                  if (isDisabled) {
                    return;
                  }
                  clearSelection();
                }}
              >
                <Icon
                  icon="cross-in-circle"
                  size={18}
                  className={`search-cross-icon ${isDisabled ? "search-cross-icon-disabled" : ""}`}
                />
              </div>
            </div>
          ) : null}
          <div
            className={`dropdown-icon-container ${isDisabled ? "dropdown-icon-container-disabled" : ""}`}
            onClick={() => {
              if (isDisabled) {
                return;
              }
              setIsOptionsVisible(!isOptionsVisible);
            }}
          >
            {isOptionsVisible ? (
              <Icon icon="up-arrow" className="select-box-up-arrow" size={11} />
            ) : (
              <Icon icon="down-arrow" className={`select-box-down-arrow `} size={11} />
            )}
          </div>
        </div>
        {isOptionsVisible ? (
          <div className="options-container">
            <div className="search-container">
              <div className="search-input-container">
                <input type="text" value={search} onChange={onSearchChange} name="search" id="search" />
              </div>
              <div className="cancel-icon-container">
                <span onClick={() => setSearch("")}>
                  <Icon icon="search-cross" size={16} className="search-cross-icon" />
                </span>
              </div>
            </div>
            <div className="dropdown-options-container">
              {facilities
                .filter((el) => el.ProviderName.toLowerCase().includes(search.toLowerCase()))
                .map((el) => (
                  <div
                    className={`dropdown-options-row`}
                    key={el.ID}
                    onClick={() => {
                      onOptionClick(el.ID);
                    }}
                  >
                    <div className="name">{HighlightedText(`${el.ProviderName}`, search)}</div>
                    <div className="distance">
                      {/* <span className="text">2.4 miles</span> */}
                      {el.ID === selectedOption?.id ? (
                        <img className="check" src={TickmarkIcon} alt="tick-mark" />
                      ) : null}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default FacilityDropdown;
