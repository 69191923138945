import React, { useState, useEffect, useContext } from "react";
import "./dropdown.styles.scss";
import ClickAwayListener from "react-click-away-listener";
import Icon from "components/Icon/icon.component";
import { RiskAssessmentContext } from "pages/task-management/risk-assessment/context/risk-assessment.context";
import { setIsValueChanged } from "state/feature/risk-assessment/risk-assessment.slice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { AssessmentContext } from "pages/task-management/assessment/context/assessment-context";
import { PageName } from "shared/enums/page-name.enum";

type DropDownProps = {
  placeholder?: string;
  idFieldName: string;
  sectionId?: string | null;
  questionId?: string;
  handleToastDismiss?: () => void;
  dropDownMenuItems: Record<string, unknown>[];
  defaultValue?: any;
  itemClassName?: string;
  optionClassName?: string;
  dropDownContainerClass?: string;
  dropDownBoxClass: string;
  selectionClass: string;
  isDisabled?: boolean;
  isError?: boolean;
  message?: string;
  onBlur?: () => void;
  pageId?: number | null;
  questionOrder?: number | null;
  sectionOrder?: number | null;
};

const DropDown = ({
  placeholder,
  idFieldName,
  questionId,
  sectionId,
  handleToastDismiss,
  dropDownMenuItems,
  defaultValue,
  itemClassName,
  optionClassName,
  dropDownContainerClass,
  dropDownBoxClass,
  selectionClass,
  isDisabled,
  isError,
  message,
  onBlur,
  pageId,
  questionOrder,
  sectionOrder,
}: DropDownProps) => {
  const { taskType } = useParams<{ taskType: string }>();
  const dispatch = useDispatch();
  const { onChangeDropdown } = useContext(
    taskType === PageName.RISK_ASSESSMENT ? RiskAssessmentContext : AssessmentContext
  );
  const [activeOption, setActiveOption] = useState<any>({
    name: "",
  });
  const [showOptionDropdown, setShowOptionDropdown] = useState(false);
  const [dropDownItems] = useState(dropDownMenuItems);

  useEffect(() => {
    setActiveOption(defaultValue);
  }, [defaultValue]);

  const handleDropdownChange = (state: any) => {
    if (handleToastDismiss) handleToastDismiss();
    setShowOptionDropdown((prev) => {
      const arrowEl = document.getElementById("select-box-bucket-down-arrow");
      if (prev) {
        arrowEl?.classList.remove("select-box-down-arrow-rotate");
        arrowEl?.classList.add("select-box-down-arrow-unrotate");
        return !prev;
      } else {
        if (state) {
          arrowEl?.classList.remove("select-box-down-arrow-unrotate");
          arrowEl?.classList.add("select-box-down-arrow-rotate");
          return !prev;
        } else return false;
      }
    });
  };

  const handleSelect = (selectedOption: any) => {
    dispatch(setIsValueChanged(true));
    setActiveOption(selectedOption);
    onChangeDropdown(selectedOption, sectionId ?? "", questionId ?? "", pageId, questionOrder, sectionOrder);
  };

  const handleOptionChange = (option: any) => {
    if (handleSelect) handleSelect(option);
    handleDropdownChange(false);
  };

  return (
    <ClickAwayListener onClickAway={() => handleDropdownChange(false)}>
      <div className={`dropdown-container ${dropDownContainerClass ? dropDownContainerClass : ""}`}>
        <span
          onBlur={onBlur}
          tabIndex={isDisabled ? -1 : 0}
          className={`select-span ${isError ? "error" : "no-error"} ${selectionClass ? selectionClass : ""} ${
            isDisabled ? "no-pointer-events" : ""
          }`}
          onClick={handleDropdownChange}
        >
          <div className="name-container">
            {activeOption?.value ? (
              <p className="selected-value">{activeOption?.value}</p>
            ) : (
              <p className="selected-value placeholder">{placeholder}</p>
            )}
          </div>
          <div className="icon-container">
            <Icon icon="down-arrow" className="select-box-down-arrow" size={11} />
          </div>
        </span>
        {isError && !showOptionDropdown && <div className="error-color">{message}</div>}
        {showOptionDropdown && (
          <div className={`option-box-wrapper ${dropDownBoxClass}`}>
            <ul>
              {dropDownItems?.map((option: any, key) => (
                <li
                  key={key}
                  className={`${itemClassName ? itemClassName : ""}
										${activeOption && activeOption[idFieldName] === option[idFieldName] ? "active-option" : ""}`}
                  onClick={() => handleOptionChange(option)}
                >
                  {activeOption && activeOption[idFieldName] === option[idFieldName] ? (
                    <p className={`option selected ${optionClassName ? optionClassName : ""}`}>{option.value}</p>
                  ) : (
                    <p className={`option ${optionClassName ? optionClassName : ""}`}>{option.value}</p>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default DropDown;
