import { useEffect, useState } from "react";
import { ActiveTabName } from "components/GenericTabs/props";
import { TaskInfoTab } from "shared/enums/task-management-tabs.enum";
import { useSelector } from "react-redux";
import { calculateRisk, getPatientAndEpisodeDetailsAysnc } from "state/feature/task-management/task-management.action";
import TaskInfoTabs from "./task-info-tabs.component";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";
import { useAppDispatch } from "state/store";

const TaskInfoContainer = () => {
  const dispatch = useAppDispatch();
  const { selectedTaskId, episode } = useSelector(getTaskManagementState);
  const [activeTabName, setActiveTabName] = useState<ActiveTabName>(TaskInfoTab.EPISODE);
  const onClickTabName = (tabName: ActiveTabName) => {
    // setActiveTabName(tabName);
  };

  useEffect(() => {
    if (selectedTaskId && !episode) {
      dispatch(getPatientAndEpisodeDetailsAysnc({ intakeId: selectedTaskId })).then(() => {
        dispatch(calculateRisk({ intakeId: selectedTaskId }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTaskId, episode, dispatch]);

  return <TaskInfoTabs activeTabName={activeTabName} onClickTabName={onClickTabName} />;
};

export default TaskInfoContainer;
