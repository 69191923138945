import "./transition-of-care.styles.scss";
import Header from "pages/task-management/header/header.component";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";
import moment from "moment";

import TocRevision from "./toc-revisions/toc-revision.component";
import Button from "components/Button/button.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { TOC_LISTING_HEADERS, TocStatusEnum } from "./constants/index.constant";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { getTOCManagementState, addNewTocRevision } from "state/feature/toc-management/toc-management.slice";
import { ITransitionOfCare } from "state/types/toc-management-slice.type";
import {
  getTocAsync,
  getAllTocFacilitiesAsync,
  getIntakeAsync,
} from "state/feature/toc-management/toc-management.action";
import { useHistory } from "react-router";
import { useAppDispatch } from "state/store";
import { getCommonState } from "state/feature/common/common.slice";

export interface ITransitionOfCareContainerProps {
  intakeId: string;
}

const TransitionOfCareContainer = (props: ITransitionOfCareContainerProps) => {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const history = useHistory();
  const { intakeId } = props;
  const { patientDetails, isValidIntake, isValidatingIntake } = useSelector(getTaskManagementState);
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const { isTocsLoading, tocs, intake } = useSelector(getTOCManagementState);
  const { featureFlags } = useSelector(getCommonState);
  const [isAllTocsApproved, setIsAllTocsApproved] = useState(true);
  const ref = useRef<any>(null);
  const prepareNewTocRevision = useCallback(() => {
    if (!isAllTocsApproved) {
      return;
    }
    const version = tocs.length;
    return {
      id: "",
      intakeId: parseInt(intakeId, 10),
      noteCareManager: "",
      version: version + 1,
      anticipatedAcuteLOS: 0,
      createdAt: moment().format("MM/DD/YYYY"),
      approvedAt: "",
      clientId: intake?.clientId,
      isExpanded: true,
      status: TocStatusEnum.NEW,
    } as ITransitionOfCare;
  }, [intakeId, intake, isAllTocsApproved, tocs.length]);

  useEffect(() => {
    if (intakeId) {
      dispatch(getAllTocFacilitiesAsync(intakeId));
    }
  }, [dispatch, intakeId]);

  useEffect(() => {
    if (intakeId && isValidIntake && !isValidatingIntake) {
      dispatch(getIntakeAsync(intakeId));
    }
  }, [dispatch, appDispatch, intakeId, isValidIntake, isValidatingIntake]);

  useEffect(() => {
    if (intakeId && isValidIntake && !isValidatingIntake) {
      appDispatch(getTocAsync(intakeId));
    }
  }, [dispatch, appDispatch, intakeId, isValidIntake, isValidatingIntake]);

  useEffect(() => {
    if (!isTocsLoading) {
      let isApproved = true;
      tocs.forEach((el) => {
        isApproved = isApproved && !!el.id && !!el.approvedAt;
      });
      setIsAllTocsApproved(isApproved);
    }
  }, [tocs, intakeId, isTocsLoading, dispatch]);

  useEffect(() => {
    if (!isTocsLoading && tocs.length === 0 && intake && isAllTocsApproved) {
      dispatch(addNewTocRevision(prepareNewTocRevision()));
    }
  }, [tocs, intake, isTocsLoading, isAllTocsApproved, prepareNewTocRevision, dispatch]);

  const updateState = () => {
    const InfiniteScrollInnerDivElement = document.querySelector(".revision-table-body");
    if (InfiniteScrollInnerDivElement) {
      setHasScrollBar(InfiniteScrollInnerDivElement.scrollHeight > InfiniteScrollInnerDivElement.clientHeight);
    }
  };

  useEffect(() => {
    if (tocs.length > 0 && !isTocsLoading) {
      setTimeout(() => {
        updateState();
      }, 100);
      updateState();
    } else {
      updateState();
    }
    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, [tocs, isTocsLoading]);

  useEffect(() => {
    return () => {
      if (ref.current) {
        clearTimeout(ref.current);
      }
    };
  }, []);

  const createNewRevision = () => {
    dispatch(addNewTocRevision(prepareNewTocRevision()));
  };

  const patientDetailsDisplay = useCallback(() => {
    if (patientDetails) {
      return (
        <>
          <div className="patient-name">{patientDetails.patientName}</div>
          <div className="patient-other-details">
            <div>{patientDetails.gender}</div>
            <div className="dash">|</div>
            <div>
              <span className="dob">DOB:</span> {moment(patientDetails.dob).format("MM/DD/YYYY")}{" "}
              {patientDetails.age ? `(${patientDetails.age} years)` : ""}
            </div>
          </div>
        </>
      );
    }
  }, [patientDetails]);

  const getTocsList = () => tocs.map((toc) => <TocRevision toc={toc} key={toc.id} />);

  return (
    <>
      <div className="container">
        <div className="revisons">
          <div className="heading">
            <div className="info-container">
              <div className="patient-info">
                {!patientDetails ? null : (
                  <>
                    <Header className="toc" patientDetails={patientDetailsDisplay()} title="Transition Of Care" />
                  </>
                )}
              </div>
            </div>
            <div className="cta-container">
              <div className="new-revision-container">
                {isTocsLoading ? null : (
                  <>
                    {tocs.length === 0 || (tocs.length === 1 && !tocs[0].approvedAt) ? null : (
                      <Button
                        text="Create New Revision"
                        className={`green-button new-revision-cta`}
                        showLoader={false}
                        disabled={!isAllTocsApproved || isTocsLoading}
                        onClick={() => {
                          createNewRevision();
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={`revision-table-container`}>
            <div
              className="revision-table"
              onClick={() => {
                ref.current = setTimeout(() => {
                  updateState();
                }, 100);
              }}
            >
              <div
                className={`revision-table-header ${
                  hasScrollBar
                    ? `revision-table-header-scrollbar-is-visible ${
                        featureFlags.taskManagement ? "task-details-visible" : ""
                      }`
                    : ""
                }`}
              >
                {TOC_LISTING_HEADERS.map(({ name }) => {
                  return (
                    <div className="revision-table-header-cell" key={name}>
                      <div className="revision-table-header-content">
                        <div className="name">{name}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="revision-table-body">
                {isTocsLoading ? <CommonState type={CommonStateType.LOADING} /> : getTocsList()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransitionOfCareContainer;
