import Loading from "shared/assets/images/loader.gif";
import "./common-state.styles.scss";
import NoResults from "shared/assets/images/patients-no-search-results.svg";
import { CommonStateType } from "./common-state.enum";
import AccessDeniedImage from "shared/assets/images/access-denied-illustration.svg";
import Button from "components/Button/button.component";
import { TwilioClient } from "shared/services/twilio/twilioClient";
import { clearUser } from "state/feature/auth/auth.slice";
import { clearTwilioToken } from "state/feature/call/call.slice";
import { resetCommon } from "state/feature/common/common.slice";
import { resetContacts } from "state/feature/contact/contact.slice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import NoSearchedTaskFound from "shared/assets/images/no-searched-task-found.svg";
import NoEpisodesFound from "shared/assets/images/no-episodes-found.svg";
import TaskNotSelected from "pages/task-management/task-not-selected/task-not-selected.component";
import NoTaskFound from "components/no-task-found/no-task-found.component";
import EpisodeTaskComingSoon from "shared/assets/images/episode-task-coming-soon.svg";
import { EpisodesPageCommonMessages } from "shared/enums/episodes-details.enum";
type CommonStateProps = {
  type?: CommonStateType;
};

const CommonState = (props: CommonStateProps) => {
  const { type } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  if (type === CommonStateType.LOADING) {
    return (
      <div className="common-state-container loader">
        <img src={Loading} alt="loading" />
      </div>
    );
  } else if (type == CommonStateType.PAGE_NOT_FOUND) {
    return (
      <div className="not-found-container">
        <img src={NoResults} alt="No Patients found" />
        <div className="button-text">Page not available</div>
      </div>
    );
  } else if (type === CommonStateType.INTAKE_NOT_FOUND) {
    return (
      <div className="empty-state-container">
        <img src={NoResults} />
        <div className="button-text">No Intake found</div>
      </div>
    );
  } else if (type === CommonStateType.TASK_NOT_ADDED) {
    return (
      <div className="empty-state-container">
        <img src={NoResults} />
        <div className="button-text">Risk assessment task has not been added yet.</div>
      </div>
    );
  } else if (type === CommonStateType.ACCESS_DENIED) {
    const handleBackToLogin = () => {
      TwilioClient.removeInstance();
      dispatch(clearTwilioToken());
      dispatch(clearUser());
      dispatch(resetCommon());
      dispatch(resetContacts());
      history.replace("/");
      sessionStorage.removeItem("riskAssessmentAnswers");
    };
    return (
      <div className="access-denied-container">
        <img src={AccessDeniedImage} alt="Access Denied" />
        <div className="button-text">You do not have permission to access this page</div>
        <Button
          text="Back to Login"
          onClick={() => handleBackToLogin()}
          className="forgot-password no-border-button icon-button"
        />
      </div>
    );
  } else if (type === CommonStateType.PERMISSION_DENIED) {
    return (
      <div className="permission-denied-container">
        <img src={AccessDeniedImage} alt="Permission Denied" />
        <div className="button-text">You do not have permission to access this page</div>
      </div>
    );
  } else if (type === CommonStateType.NO_TASK_SELECTED) {
    return <TaskNotSelected />;
  } else if (type === CommonStateType.NO_TASKS_AVAILABLE) {
    return <NoTaskFound />;
  } else if (type === CommonStateType.SEARCH_TASK_NOT_FOUND) {
    return (
      <div className="search-task-not-found-container">
        <div className="search-task-not-found">
          <img src={NoSearchedTaskFound} />
          <div className="search-task-not-found-heading">Oops! No tasks found</div>
          <div className="search-task-not-found-text">Please refine your search</div>
        </div>
      </div>
    );
  } else if (type === CommonStateType.NO_EPISODES_FOUND) {
    return (
      <div className="no-episodes-found-container">
        <div className="no-episodes-found">
          <img src={NoEpisodesFound} alt="No episodes found" />
          <div className="no-episodes-found-heading">{EpisodesPageCommonMessages.NO_EPISODES_FOUND}</div>
          <div className="no-episodes-found-text">There are no episodes in your records yet</div>
        </div>
      </div>
    );
  } else if (type === CommonStateType.EPISODE_NOT_FOUND_ON_SEARCH) {
    return (
      <div className="search-episodes-not-found-container">
        <div className="search-episodes-not-found">
          <img src={NoSearchedTaskFound} alt="Searched Episodes not found" />
          <div className="search-episodes-not-found-heading">{EpisodesPageCommonMessages.NO_EPISODES_FOUND}</div>
          <div className="search-episodes-not-found-text">Please refine your search and try again</div>
        </div>
      </div>
    );
  } else if (type === CommonStateType.NO_EPISODE_DETAIL_FOUND) {
    return (
      <div className="no-episode-details-found-container">
        <div className="no-episode-details-found">
          <img src={NoEpisodesFound} alt="Episode details not found" />
          <div className="no-episode-details-found-heading">{EpisodesPageCommonMessages.NO_EPISODES_FOUND}</div>
          <div className="no-episode-details-found-text">
            The link might be broken or invalid. Please refresh the list page and try again.
          </div>
          <div className="no-episode-details-found-button">
            <Button
              text="Back"
              onClick={() => history.push("/episodes")}
              className="back-button no-border-button icon-button"
            />
          </div>
        </div>
      </div>
    );
  }
  if (type === CommonStateType.EPISODE_TASK_COMING_SOON) {
    return (
      <div className="common-state-container episode-task-coming-soon">
        <div className="episode-task-coming-soon-content">
          <img src={EpisodeTaskComingSoon} alt="Episode task coming soon" />
          <div className="episode-task-coming-soon-text">
            <div className="episode-task-coming-soon-text-large">{EpisodesPageCommonMessages.COMING_SOON}</div>
            <div className="episode-task-coming-soon-text-small">{EpisodesPageCommonMessages.COMING_SOON}</div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default CommonState;
