import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { useDispatch } from "react-redux";
import callSlice from "state/feature/call/call.slice";
import commonSlice from "state/feature/common/common.slice";
import contactSlice from "state/feature/contact/contact.slice";
import messagingSlice from "state/feature/messaging/messaging.slice";
import navigatorSlice from "state/feature/navigator/navigator.slice";
import authSlice from "state/feature/auth/auth.slice";
import riskAssessmentSlice from "state/feature/risk-assessment/risk-assessment.slice";
import userManagementSlice from "state/feature/user-management/user-management.slice";
import taskManagementSlice from "state/feature/task-management/task-management.slice";
import tocManagementSlice from "state/feature/toc-management/toc-management.slice";
import assessmentSlice from "state/feature/assessment/assessment.slice";
import notificationSlice from "state/feature/notification/notification.slice";
import episodesSlice from "state/feature/episodes/episodes.slice";

export const CLEAR_ALL_STATE = "CLEAR_ALL_STATE";

const appReducer = combineReducers({
  auth: authSlice.reducer,
  call: callSlice.reducer,
  common: commonSlice.reducer,
  contact: contactSlice.reducer,
  messaging: messagingSlice.reducer,
  navigator: navigatorSlice.reducer,
  riskAssessment: riskAssessmentSlice.reducer,
  userManagement: userManagementSlice.reducer,
  taskManagement: taskManagementSlice.reducer,
  tocManagement: tocManagementSlice.reducer,
  assessment: assessmentSlice.reducer,
  notification: notificationSlice.reducer,
  episodes: episodesSlice.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === CLEAR_ALL_STATE) state = undefined;

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .prepend(thunk)
      .concat(logger),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
