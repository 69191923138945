import { useEffect, useRef, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { deleteAllCookies } from "shared/methods/utilityFunctions";
import { TwilioClient } from "shared/services/twilio/twilioClient";
import { clearTwilioToken, getCallState, saveCurrentCall, saveTwilioCallDevice } from "state/feature/call/call.slice";
import { getCommonState, setIsSessionTimeoutModalVisible } from "state/feature/common/common.slice";
import { useSignalRService } from "shared/services/signalr/signalr.service";
import * as workerTimers from "worker-timers";
import { getAuth } from "state/feature/auth/auth.slice";

const useIdleTimerContainer = () => {
  const timeout = 1000 * 60 * 30;
  const signalRClient = useSignalRService();
  const dispatch = useDispatch();
  const authState = useSelector(getAuth);
  const { modal, globalValues } = useSelector(getCommonState);
  const callState = useSelector(getCallState);
  const [timeRemaining, setRemaining] = useState(timeout);
  const [isIdle, setIsIdle] = useState(false);
  const isPaused = useRef(false);
  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => {
    if (!modal.isSessionTimeoutModalVisible) {
      dispatch(setIsSessionTimeoutModalVisible(true));
      deleteAllCookies();
      if (callState.twilioCallDevice) {
        callState.twilioCallDevice?.disconnectAll();
        callState.twilioCallDevice?.unregister();
      }
      TwilioClient.removeInstance();
      signalRClient?.disconnectSignalrOnLogout(authState.user.email);
      dispatch(clearTwilioToken());
      dispatch(saveCurrentCall(null));
      dispatch(saveTwilioCallDevice(null));
      localStorage.clear();
      sessionStorage.clear();
      dispatch(saveTwilioCallDevice(null));
      setIsIdle(true);
    }
  };
  const { getRemainingTime, pause, resume } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    timers: workerTimers,
    stopOnIdle: true,
    crossTab: true,
    name: "es-userInteractionTimer",
    syncTimers: 5000,
  });

  const pauseTimer = (): void => {
    if (!isPaused.current) {
      pause();
      isPaused.current = true;
      setRemaining(timeout);
    }
  };

  const resumeTimer = (): void => {
    if (isPaused.current) {
      resume();
      isPaused.current = false;
    }
  };

  useEffect(() => {
    setRemaining(getRemainingTime());

    const timerId = workerTimers.setInterval(() => {
      setRemaining(getRemainingTime());
    }, 1000);

    return () => {
      workerTimers.clearInterval(timerId);
    };
  }, [getRemainingTime]);

  return {
    isIdle,
    timeRemaining,
    pauseTimer,
    resumeTimer,
  };
};

export default useIdleTimerContainer;
