import { RiskAssessmentContext } from "pages/task-management/risk-assessment/context/risk-assessment.context";
import { SelectionType } from "pages/task-management/risk-assessment/common/risk-assessment.types";
import { useContext, useEffect, useState } from "react";
import { setIsValueChanged } from "state/feature/risk-assessment/risk-assessment.slice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { AssessmentContext } from "pages/task-management/assessment/context/assessment-context";
import { PageName } from "shared/enums/page-name.enum";

type PillType = {
  id: string;
  sectionId?: string | null;
  questionId?: string;
  order: number;
  className?: string;
  title: string;
  selectionType?: SelectionType;
  nextQuestionId?: string | null;
  showSection?: boolean | null;
  conditionalSectionId?: string | null;
  pageId?: number | null;
  currentSelectedOptions: any | null;
  questionOrder?: number | null;
  sectionOrder?: number | null;
  setNextQuestionIdValue: (questionId: any | null) => void;
  disabled?: boolean;
};

export const Pill = (props: PillType) => {
  const { taskType } = useParams<{ taskType: string; intakeId: string; sectionName: string }>();
  const { onChangePill, onSelectPill } = useContext(
    taskType === PageName.RISK_ASSESSMENT ? RiskAssessmentContext : AssessmentContext
  );
  const {
    id,
    sectionId,
    questionId,
    order,
    className,
    title,
    selectionType,
    nextQuestionId,
    showSection,
    conditionalSectionId,
    pageId,
    currentSelectedOptions,
    setNextQuestionIdValue,
    questionOrder,
    sectionOrder,
    disabled,
  } = props;
  const [selectedValueIndex, setSelectedValueIndex] = useState(-1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentSelectedOptions && currentSelectedOptions.length > 0) {
      const index = currentSelectedOptions.findIndex((x: any) => x.id === id);
      setSelectedValueIndex(index);
    } else {
      setSelectedValueIndex(-1);
    }
  }, [currentSelectedOptions, id]);

  return (
    <label
      className={`${
        currentSelectedOptions &&
        currentSelectedOptions.length > 0 &&
        selectedValueIndex > -1 &&
        currentSelectedOptions[selectedValueIndex].id === id
          ? "pill-checkbox-container checked"
          : "pill-checkbox-container"
      } ${disabled ? "disabled" : ""}`}
      htmlFor={`pill-${questionId}-${order}`}
    >
      <input
        id={`pill-${questionId}-${order}`}
        type="checkbox"
        className={`pill-checkbox ${className ? className : ""}`}
        disabled={disabled}
        name={`${questionId}`}
        onChange={(event) => {
          dispatch(setIsValueChanged(true));
          setNextQuestionIdValue(nextQuestionId ? nextQuestionId : null);
          return selectionType === "single-select"
            ? onChangePill(
                event,
                id,
                sectionId,
                questionId,
                nextQuestionId,
                conditionalSectionId,
                showSection,
                pageId,
                questionOrder,
                sectionOrder
              )
            : onSelectPill(
                event,
                id,
                sectionId,
                questionId,
                nextQuestionId,
                conditionalSectionId,
                showSection,
                pageId,
                questionOrder,
                sectionOrder
              );
        }}
        value={title}
        checked={
          currentSelectedOptions &&
          currentSelectedOptions.length > 0 &&
          selectedValueIndex > -1 &&
          currentSelectedOptions[selectedValueIndex].id === id
        }
      />
      <div className="pill-title">{title}</div>
    </label>
  );
};
