import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SidePopup from "../side-popup.component";
import Button from "components/Button/button.component";
import { useDispatch, useSelector } from "react-redux";
import "./call-side-popup.styles.scss";
import { TwilioClient } from "shared/services/twilio/twilioClient";
import { Client } from "@twilio/conversations";
import { Participant } from "shared/types/participant.type";
import { MultiSelectDropDown } from "components/MulltiSelectDropDown/multi-select-dropdown.component";
import getHighlightedText from "components/HighLightedText/high-lighted-text.component";
import { toast } from "react-toastify";
import { formatPhoneNumber, getFullName, validateNumber } from "shared/methods/utilityFunctions";
import { useTwilioClientService } from "shared/services/twilio/twilio-client.service";
import { getCommonState, setIsOpenCallModal } from "state/feature/common/common.slice";
import { getCallState, saveCurrentCall, saveCurrentCallParticipants } from "state/feature/call/call.slice";
import { getAuth } from "state/feature/auth/auth.slice";
import {
  getContactState,
  resetAllContacts,
  setIsPatientContactsLoading,
  setIsPhysicianContactsLoading,
} from "state/feature/contact/contact.slice";
import { getBackUpNavigatorListAsync, getWhiteLabelPhoneNumberAsync } from "state/feature/navigator/navigator.action";
import { useAppDispatch } from "state/store";
import { debounce, isEmpty } from "lodash";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import Icon from "../../Icon/icon.component";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import {
  getNavigatorPatientContactAsync,
  getNavigatorPhysicianContactAsync,
} from "state/feature/contact/contact.action";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { getConfig } from "../../../config/config";
import SingleSelectDropdownWithSearch, {
  OptionType,
} from "components/single-select-dropdown-with-search/single-select-dropdown-with-search.component";
import { INavigator } from "state/types/navigator.type";

interface ICallSidePopupProps {
  isOpen: boolean;
  isDropdownVisible: boolean;
  setIsDropdownVisible: (el: boolean) => void;
  heading: string;
  handleOnFocus?: () => void;
  participants?: Participant[];
  setParticipants?: (el: Participant[]) => void;
}
export const CallSidePopup = ({
  isOpen,
  isDropdownVisible,
  setIsDropdownVisible,
  heading,
  participants,
  setParticipants,
}: ICallSidePopupProps) => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const authState = useSelector(getAuth);
  const callState = useSelector(getCallState);
  const { assignedPhoneNumbers, currentUserProfile } = useSelector(getNavigatorState);
  const [selectedNavigator, setSelectedNavigator] = useState<any>(null);
  const [allNavigatorData, setAllNavigatorData] = useState<Array<Record<string, unknown>>>([]);
  const [disableOutboundDropdown, setDisableOutboundDropdown] = useState(false);
  const commonState = useSelector(getCommonState);
  const [searchText, setSearchText] = useState("");
  const [selectedList, setSelectedList] = useState<Participant[]>([]);
  const twilioClient = useRef<Client | null>(null);
  const twilioClientService = useTwilioClientService();
  const [searchResults, setSearchResults] = useState<Participant[]>(participants ? participants : []);
  const [isSearchListVisible, setIsSearchListVisible] = useState(false);
  const [isCallButtonClicked, setIsCallButtonClicked] = useState(false);
  const [outboundNumbers, setOutboundNumbers] = useState<Array<OptionType>>([]);
  const [selectedOutboundNumber, setSelectedOutboundNumber] = useState<{
    key: string;
    label: string;
    name: string;
    value: any;
  }>();
  const [defaultOutboundNumber, setDefaultOutboundNumber] = useState<{
    key: string;
    label: string;
    name: string;
    value: any;
  }>();
  const [isSavedParticipantPresent, setIsSavedParticipantPresent] = useState<boolean>(false);
  const { allContacts, navigatorContacts } = useSelector(getContactState);
  const { isPatientContactsLoading, isPhysicianContactsLoading } = navigatorContacts;
  const abortControllerForGetNavigatorPatientContactAsync = useRef<() => void>();
  const abortControllerForGetNavigatorPhysicianContactAsync = useRef<() => void>();

  useEffect(() => {
    if (participants && participants.length && !selectedList.length) {
      setSelectedList(participants);
    }
  }, [participants, selectedList.length]);
  useEffect(() => {
    if (callState && callState.twilioAcessToken) {
      twilioClient.current = TwilioClient.getInstance(callState.twilioAcessToken);
    }
  }, [callState, callState.twilioAcessToken, selectedList]);

  const getAllNavigators = useCallback(async () => {
    const data: INavigator[] = await appDispatch(
      getBackUpNavigatorListAsync({
        types: ["PNAV", "TNAV"],
      })
    ).unwrap();
    const defaultNavigator = data.filter((item) => {
      return item.careManagerId === authState.user.navigatorId;
    });
    if (defaultNavigator.length > 0) {
      setSelectedNavigator(
        defaultNavigator.map((item) => {
          return {
            ...item,
            key: item.id,
            name: `${item.firstName} ${item.lastName}`,
          };
        })[0]
      );
    }
    setAllNavigatorData(
      data.map((item) => ({
        ...item,
        key: item.id,
        name: `${item.firstName} ${item.lastName}`,
        value: `${item.firstName} ${item.lastName}`,
      }))
    );
  }, [appDispatch, authState.user.navigatorId]);

  useEffect(() => {
    getAllNavigators();
  }, [getAllNavigators, isOpen]);

  const filterAssignedNumbersAccordingToNavigator = useCallback(() => {
    if (assignedPhoneNumbers && assignedPhoneNumbers.length > 0) {
      const outboundNumbersData = assignedPhoneNumbers.map((el: any) => ({
        key: el.id,
        name: `${el.whiteLabelPhoneNumber} (${el.name})`,
        value: el,
        label: `${el.whiteLabelPhoneNumber} (${el.name})`,
      }));
      if (selectedNavigator !== null && outboundNumbersData && outboundNumbersData.length > 0) {
        const allOtherOutboundNumbers = outboundNumbersData
          .filter((item) => item.value?.assignedTo?.id !== selectedNavigator.id)
          .sort((a, b) => (a.value.name > b.value.name ? 1 : -1));
        const assignedToSelfNumbers = outboundNumbersData
          .filter((item) => item.value?.assignedTo?.id === selectedNavigator.id)
          .sort((a, b) => (a.value.name > b.value.name ? 1 : -1));
        setOutboundNumbers([...assignedToSelfNumbers, ...allOtherOutboundNumbers]);
      }
    }
  }, [selectedNavigator, assignedPhoneNumbers]);

  useEffect(() => {
    return () => {
      appDispatch(setIsOpenCallModal(false));
    };
  }, [appDispatch]);

  useEffect(() => {
    filterAssignedNumbersAccordingToNavigator();
  }, [selectedNavigator, assignedPhoneNumbers, filterAssignedNumbersAccordingToNavigator]);

  const handleSidePopupClose = () => {
    dispatch(resetAllContacts());
    setSelectedList([]);
    dispatch(setIsOpenCallModal(false));
    setSearchText("");
    setSearchResults([]);
    setIsSearchListVisible(false);
    setIsDropdownVisible(false);
    if (outboundNumbers.length > 1) {
      setSelectedOutboundNumber({ label: "From", name: "From", value: "", key: "" });
    }
  };

  const isParticipantPresentInSelectedList = (id: number | string, phoneNumber: string) => {
    const result = selectedList.filter((el) => el.userId === id || el.phoneNumber === phoneNumber);
    return result.length > 0;
  };

  const isUnsavedParticipantPresentInSelectedList = (phoneNumber: string) => {
    const result = selectedList.filter((el) => el.phoneNumber === phoneNumber);
    return result.length > 0;
  };

  const getClientId = useCallback(() => {
    const unsavedParticipants = selectedList.filter((participant) => !participant.clientId);
    const participantsWithClientId = selectedList.filter((el) => el.clientId);
    const clientIDs = participantsWithClientId.map((el) => el.clientId);

    if (unsavedParticipants && unsavedParticipants.length > 0) {
      setIsSavedParticipantPresent(false);
      return "-1";
    } else {
      if (participantsWithClientId && participantsWithClientId.length >= 1) {
        if (new Set(clientIDs).size === 1) {
          setIsSavedParticipantPresent(true);
          return participantsWithClientId[0].clientId;
        } else {
          setIsSavedParticipantPresent(false);
          return "-1";
        }
      } else {
        setIsSavedParticipantPresent(false);
        return "-1";
      }
    }
  }, [selectedList, setIsSavedParticipantPresent]);

  const handleContactsSearch = useCallback(
    (value: string) => {
      if (selectedNavigator) {
        const requestPayload = {
          id: selectedNavigator.careManagerId,
          isCareManagerId: true,
          searchText: "",
          pageNumber: 1,
          pageSize: 10,
          sortColumn: "name",
          sortOrder: SortingOrderType.ASC,
        };

        let payloadAction = appDispatch(getNavigatorPatientContactAsync({ ...requestPayload, searchText: value }));
        abortControllerForGetNavigatorPatientContactAsync.current = payloadAction.abort;

        payloadAction = appDispatch(getNavigatorPhysicianContactAsync({ ...requestPayload, searchText: value }));
        abortControllerForGetNavigatorPhysicianContactAsync.current = payloadAction.abort;
      }
    },
    [selectedNavigator, appDispatch]
  );

  const setOutboundDetailsForCall = useCallback(async () => {
    const clientId = getClientId();
    if (outboundNumbers.length > 1) {
      if (isSavedParticipantPresent && clientId && clientId !== "-1") {
        const { data } = await appDispatch(getWhiteLabelPhoneNumberAsync({ clientId })).unwrap();
        if (data) {
          setDefaultOutboundNumber({
            key: data.id,
            label: `${data.whiteLabelPhoneNumber} (${data.name})`,
            name: `${data.whiteLabelPhoneNumber} (${data.name})`,
            value: data,
          });
          setDisableOutboundDropdown(true);
        }
      } else {
        setDefaultOutboundNumber({ label: "From", name: "From", value: "", key: "" });
        setSelectedOutboundNumber({ label: "From", name: "From", value: "", key: "" });
        setDisableOutboundDropdown(false);
      }
    }
  }, [getClientId, outboundNumbers, isSavedParticipantPresent, appDispatch]);

  useEffect(() => {
    setOutboundDetailsForCall();
  }, [selectedList, setOutboundDetailsForCall]);

  const isValidPhoneNumber = (value: string) => {
    return value.length === 10 && validateNumber(value);
  };

  const handleOutboundNumberChanges = (selection: any) => {
    setDefaultOutboundNumber(selection);
    setSelectedOutboundNumber(selection);
  };

  const addItemInSelectedList = (participant: Participant) => {
    if (!participant.userId) {
      if (!isUnsavedParticipantPresentInSelectedList(participant.phoneNumber)) {
        setSelectedList([...selectedList, participant]);
        setSearchResults([]);
        setSearchText("");
        setIsSearchListVisible(false);
      } else {
        toast.error("Participant already selected.", {
          containerId: "main",
          toastId: "call",
        });
        setSearchResults([]);
        setSearchText("");
        setIsSearchListVisible(false);
      }
    } else {
      if (
        !participant.phoneNumber ||
        (participant.phoneNumber && !isParticipantPresentInSelectedList(participant.userId, participant.phoneNumber))
      ) {
        setSelectedList([...selectedList, participant]);
        setSearchResults([]);
        setSearchText("");
        setIsSearchListVisible(false);
      } else {
        toast.error("Participant already selected.", {
          containerId: "main",
          toastId: "call",
        });
        setSearchResults([]);
        setSearchText("");
        setIsSearchListVisible(false);
      }
    }
  };
  const deleteItemInSelectedList = (item: Participant) => {
    dispatch(resetAllContacts());

    const copyOfSelectedItems = [...selectedList];
    const index = copyOfSelectedItems.indexOf(item);
    copyOfSelectedItems.splice(index, 1);
    setSelectedList(copyOfSelectedItems);
    if (setParticipants) {
      setParticipants(copyOfSelectedItems);
    }
    if (!searchText.length && !copyOfSelectedItems.length) {
      setSearchResults([]);
      setIsDropdownVisible(false);
      if (outboundNumbers.length > 1) {
        handleOutboundNumberChanges({ label: "From", name: "From", value: "", key: "" });
        setDisableOutboundDropdown(false);
      }
    } else {
      setIsDropdownVisible(false);
      if (!isValidPhoneNumber(searchText)) {
        dispatch(setIsPatientContactsLoading(true));
        dispatch(setIsPhysicianContactsLoading(true));

        handleContactsSearch(searchText);
      }
    }
  };

  const filterParticipantsListWithSearch = useCallback(
    (searchTerm: string) => {
      searchTerm = searchTerm.toLowerCase();
      const selectedParticipantIds = selectedList.map((el) => el.userId);
      const contacts: any = [];
      allContacts.all.forEach((item: Participant) => {
        if (item.patientContactDetails && item.patientContactDetails.length > 0) {
          item.patientContactDetails.forEach((contact) => {
            contacts.push({
              ...item,
              phoneNumber: contact.phoneNumber,
              phoneNumberDetails: {
                isPreferred: contact.isPreferred,
                type: contact.type,
              },
            });
          });
        } else {
          contacts.push(item);
        }
      });
      contacts.sort((first: Participant, second: Participant) => {
        if (
          first.userId === second.userId &&
          first.phoneNumberDetails?.isPreferred &&
          !second.phoneNumberDetails?.isPreferred
        ) {
          return -1;
        } else if (
          first.userId === second.userId &&
          second.phoneNumberDetails?.isPreferred &&
          !first.phoneNumberDetails?.isPreferred
        ) {
          return 1;
        } else {
          return 0;
        }
      });
      const filteredContacts = contacts.filter((item: Participant) => {
        return !selectedParticipantIds.includes(item.userId);
      });
      return filteredContacts;
    },
    [allContacts.all, selectedList]
  );

  const handlePhoneNumberInput = useCallback((value: string) => {
    if (getConfig().countryCode) {
      const countryCode = getConfig().countryCode?.split(",");
      const result: Participant[] = [];
      countryCode?.forEach((code: any) => {
        result.push({ phoneNumber: `${code}${value}` } as Participant);
      });

      setSearchResults(result);
    }
  }, []);

  const debouncedSearch = useMemo(() => {
    return debounce(handleContactsSearch, 500);
  }, [handleContactsSearch]);

  const handleSearchInput = useCallback(
    (searchToken: string) => {
      setSearchText(searchToken);
      setSearchResults([]);
      dispatch(resetAllContacts());

      if (abortControllerForGetNavigatorPatientContactAsync.current) {
        abortControllerForGetNavigatorPatientContactAsync.current();
      }

      if (abortControllerForGetNavigatorPhysicianContactAsync.current) {
        abortControllerForGetNavigatorPhysicianContactAsync.current();
      }

      if (searchToken.length === 0) {
        setIsSearchListVisible(false);
      } else if (searchToken && isValidPhoneNumber(searchToken)) {
        setIsSearchListVisible(true);
        setIsDropdownVisible(false);

        dispatch(setIsPatientContactsLoading(false));
        dispatch(setIsPhysicianContactsLoading(false));

        handlePhoneNumberInput(searchToken);
      } else if (searchToken && isNaN(parseInt(searchToken))) {
        setIsSearchListVisible(true);
        setIsDropdownVisible(false);

        dispatch(setIsPatientContactsLoading(true));
        dispatch(setIsPhysicianContactsLoading(true));

        debouncedSearch(searchToken);
      } else {
        setIsSearchListVisible(false);
      }
    },
    [dispatch, debouncedSearch, setIsDropdownVisible, handlePhoneNumberInput]
  );

  useEffect(() => {
    if (
      allContacts.all.length > 0 &&
      !isPatientContactsLoading &&
      !isPhysicianContactsLoading &&
      searchText.length &&
      !isValidPhoneNumber(searchText)
    ) {
      const filteredParticipantsList = filterParticipantsListWithSearch(searchText);
      setSearchResults(filteredParticipantsList);
    }
  }, [
    allContacts.all,
    isPatientContactsLoading,
    isPhysicianContactsLoading,
    searchText,
    filterParticipantsListWithSearch,
  ]);

  const getCallerDetails = () => {
    return {
      name: `${currentUserProfile?.firstName ? currentUserProfile?.firstName : ""} ${
        currentUserProfile?.lastName ? currentUserProfile?.lastName : ""
      }`,
      id: currentUserProfile?.id ? currentUserProfile?.id : "",
      phoneNumber: currentUserProfile?.forwardingPhoneNumber ? currentUserProfile?.forwardingPhoneNumber : "",
      phoneNumberDetails: null,
      type: "Navigator",
    };
  };
  const getPreparedParticipantsForCall = () => {
    const preparedParticipantsForCall = selectedList.map((selectedItem) => {
      const name = selectedItem.userId ? getFullName(selectedItem) : selectedItem.phoneNumber;
      return {
        phoneNumber: selectedItem.phoneNumber,
        id: selectedItem.userId ? selectedItem.userId.toString() : "",
        name,
        type: selectedItem.contactType ?? "Unsaved",
        phoneNumberDetails: selectedItem.phoneNumberDetails
          ? `${selectedItem.phoneNumberDetails?.type}${
              selectedItem.phoneNumberDetails?.isPreferred ? "(preferred)" : ""
            }`
          : null,
      };
    });
    preparedParticipantsForCall.push(getCallerDetails());
    return preparedParticipantsForCall;
  };

  const makeACall = async () => {
    try {
      if (!isCallButtonClicked) {
        setIsCallButtonClicked(true);
        const clientId = isSavedParticipantPresent
          ? defaultOutboundNumber?.value.systemId
          : selectedOutboundNumber?.value.systemId;
        const from = isSavedParticipantPresent
          ? defaultOutboundNumber?.value.whiteLabelPhoneNumber
          : selectedOutboundNumber?.value.whiteLabelPhoneNumber;
        const everyParticipantHasNumber = selectedList.every(({ phoneNumber }) => phoneNumber && phoneNumber !== null);
        const to = selectedList.map(({ phoneNumber }) => phoneNumber).toString();
        const participantsOnCall = getPreparedParticipantsForCall();
        const effectiveNavigator = {
          phoneNumber: selectedNavigator.forwardingPhoneNumber ? selectedNavigator.forwardingPhoneNumber : "",
          id: selectedNavigator.id ? selectedNavigator.id : "",
          name: `${selectedNavigator.firstName ? selectedNavigator.firstName : ""}${
            selectedNavigator.lastName ? ` ${selectedNavigator.lastName}` : ""
          }`,
        };
        const postCallback = async () => {
          dispatch(saveCurrentCall(null));
          toast.dismiss();
        };
        if (everyParticipantHasNumber) {
          await twilioClientService.addParticipantsAndCreateConference(
            to,
            from,
            participantsOnCall,
            clientId,
            effectiveNavigator,
            postCallback
          );
          dispatch(
            saveCurrentCallParticipants(
              selectedList.map((participant) => ` ${participant.firstName} ${participant.lastName}`).toString()
            )
          );
        } else {
          toast.error("One of the selected participant does not have a number", {
            toastId: "invalid-phone",
            containerId: "main",
          });
        }
        handleSidePopupClose();
        setIsCallButtonClicked(false);
      }
    } catch (error: any) {
      console.log(error);
      toast.error(
        "The microphone is currently disabled in your browser. You must enable the microphone and reload the browser (which disconnects the current call) to resolve the issue",
        { containerId: "main", toastId: "twilio-error" }
      );
      callState.twilioCallDevice.disconnectAll();
      dispatch(saveCurrentCall(null));
      setIsCallButtonClicked(false);
      toast.dismiss("call");
    }
  };

  const handleOnFocusAction = () => {
    setIsDropdownVisible(false);

    if (searchText && isValidPhoneNumber(searchText)) {
      setIsSearchListVisible(true);
    } else if (searchText && isNaN(parseInt(searchText))) {
      setIsSearchListVisible(true);
    }
  };

  const handleNavigatorChange = (selection: any) => {
    setSelectedNavigator(selection);
    setSearchText("");
    setSelectedList([]);
    setSearchResults([]);
    setSelectedOutboundNumber({ label: "From", name: "From", value: "", key: "" });
  };

  const ifOutboundDetailNotPresent = () => {
    if (isSavedParticipantPresent) {
      return isEmpty(defaultOutboundNumber?.key);
    } else {
      return isEmpty(selectedOutboundNumber?.key);
    }
  };

  const getValueForFrom = () => {
    if (isSavedParticipantPresent) {
      if (defaultOutboundNumber?.value) {
        return defaultOutboundNumber;
      }
    } else {
      return selectedOutboundNumber;
    }
  };

  return (
    <SidePopup
      isOpen={isOpen}
      isBackArrowInMobile={true}
      onClose={() => {
        handleSidePopupClose();
      }}
      modalFooter={
        <Button
          text="Make a Call"
          disabled={
            commonState.twilioErrorCode !== null ||
            ifOutboundDetailNotPresent() ||
            (selectedList && selectedList.length === 0) ||
            (callState && callState?.currentCall)
              ? true
              : false
          }
          className="green-button make-a-call-button"
          onClick={() => {
            if (!callState.currentCall) {
              makeACall();
            } else {
              toast.error("Another call is ongoing", {
                toastId: "call-ongoing",
              });
            }
          }}
        />
      }
      heading={heading}
      className="new-call"
    >
      {isOpen && (
        <>
          <CustomDropDown
            placeholder="Navigator"
            isDisabled={false}
            value={selectedNavigator}
            dropDownMenuItems={allNavigatorData}
            handleValueChanges={handleNavigatorChange}
            idFieldName="key"
            dropDownBoxClass="outbound-number-dropdown"
            selectionClass="outbound-number-selection"
            dropDownContainerClass="outbound-number-dropdown-container"
          />
          <MultiSelectDropDown
            isSearchListVisible={isSearchListVisible}
            setIsSearchListVisible={setIsSearchListVisible}
            searchText={searchText}
            handleSearchInput={handleSearchInput}
            handleOnFocus={handleOnFocusAction}
            selectedList={selectedList}
            isDropdownVisible={isDropdownVisible}
            setIsDropdownVisible={setIsDropdownVisible}
            deleteItemInSelectedList={deleteItemInSelectedList}
            searchResults={searchResults}
            addItemInSelectedList={addItemInSelectedList}
            HighlightedText={getHighlightedText}
            placeholder="Add recipient"
            selectedDropdownHtml={selectedList.map((item: Participant, index: number) => (
              <div key={"dropdown-" + index} className="dropdown-single-item">
                <div className="detail-section">
                  {item.clientId && item.userId
                    ? `${item.firstName} ${item.lastName}`
                    : `${formatPhoneNumber(item.phoneNumber)}`}
                </div>
                <div
                  className="cross-button"
                  onClick={() => {
                    deleteItemInSelectedList(item);
                  }}
                >
                  <Icon icon="cross-in-circle" size={20} className="cross-in-circle-icon" />
                </div>
              </div>
            ))}
            resultsHtml={
              searchResults &&
              searchResults.length > 0 &&
              searchResults.map((item: Participant, index: number) => (
                <div
                  key={"search-items-" + index}
                  className="search-items"
                  onClick={() => {
                    addItemInSelectedList(item);
                  }}
                >
                  {item.userId && item.clientId ? (
                    getHighlightedText(`${item.firstName} ${item.lastName}`, searchText)
                  ) : (
                    <>
                      <span className="unsaved-add-tag">Add</span>
                      <span className="unsaved-add-tag">{formatPhoneNumber(item.phoneNumber).slice(0, -13)}</span>
                      {getHighlightedText(formatPhoneNumber(item.phoneNumber).slice(-13), searchText)}
                    </>
                  )}
                </div>
              ))
            }
          />
          <SingleSelectDropdownWithSearch
            value={getValueForFrom()}
            options={outboundNumbers}
            name="From"
            isDisabled={disableOutboundDropdown}
            onChange={handleOutboundNumberChanges}
            defaultValue={{ label: "From", name: "From", value: "", key: "" }}
          />
        </>
      )}
    </SidePopup>
  );
};
