import "./refresh.styles.scss";
import Button from "components/Button/button.component";

export const Refresh = () => {
  return (
    <div className="refresh-component-container">
      <div>Please refresh the page to enable calling once again</div>
      <Button text="Reload" className="no-border-button reload-link" onClick={() => window.location.reload()} />
    </div>
  );
};
