import Header from "pages/task-management/header/header.component";
import ActiveTabWithTopLine from "components/active-tab-wIth-topline/active-tab-with-topline.component";
import GeneralQuestions from "./tabs/general-assessment/general-assessment.component";
import "./risk-assessment.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getRiskAssessmentState,
  setActivePage,
  setShowPostAcuteTaskCompletionPopUp,
} from "state/feature/risk-assessment/risk-assessment.slice";
import LaceQuestions from "./tabs/lace-assessment/lace-assessment.component";
import ClosingQuestions from "./tabs/closing-assessment/closing-assessment.component";
import { useCallback, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import store, { useAppDispatch } from "state/store";
import { saveRiskAssessmentAnswersAsync } from "state/feature/risk-assessment/risk-assessment.action";
import { Pages, SaveRiskAssessmentPayload } from "./common/risk-assessment.types";
import { getAuth } from "state/feature/auth/auth.slice";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { toast } from "react-toastify";
import AdditionalQuestions from "./tabs/additional-questions/additional-questions.componenet";
import { removeLastDashFromUrl } from "shared/methods/utilityFunctions";
import moment from "moment";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";

const RiskAssessmentPresentation = () => {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const riskAssessmentState = useSelector(getRiskAssessmentState);
  const { patientDetails, isValidIntake } = useSelector(getTaskManagementState);
  const { user } = useSelector(getAuth);
  const { intakeId } = useParams<{ intakeId: string }>();
  const { isValueChanged } = riskAssessmentState;
  const setActiveTab = () => {};
  const handleExist = (event: any) => {
    event.returnValue = null;
  };

  useEffect(() => {
    window.removeEventListener("beforeunload", handleExist, true);
    if (isValueChanged) {
      window.addEventListener("beforeunload", handleExist, true);
    }

    return () => {
      window.removeEventListener("beforeunload", handleExist, true);
    };
  }, [isValueChanged]);

  const patientDetailsDisplay = useCallback(() => {
    if (patientDetails) {
      return (
        <>
          <div className="patient-name">{patientDetails.patientName}</div>
          <div className="patient-other-details">
            <div>{patientDetails.gender}</div>
            <div className="dash">|</div>
            <div>
              <span className="dob">DOB:</span> {moment(patientDetails.dob).format("MM/DD/YYYY")}{" "}
              {patientDetails.age ? `(${patientDetails.age} years)` : ""}
            </div>
          </div>
        </>
      );
    }
  }, [patientDetails]);

  const saveAnswers = useCallback(
    async (isPublish: boolean, saveAndClose?: boolean) => {
      if (isValidIntake && patientDetails) {
        const { selectedValues, id, collapsibleSections, status } = store.getState().riskAssessment;
        const sectionAnswers = selectedValues.map((selectedValue) => {
          return {
            sectionId: selectedValue.sectionId,
            pageId: selectedValue.pageId,
            order: selectedValue.sectionOrder,
            answers: Object.entries(selectedValue.answers).map(([questionId, selectionValues]) => ({
              questionId,
              order: selectionValues[0].order,
              selectionValues: selectionValues,
              collapsibleSectionId: selectionValues[0].collapsibleSectionId,
            })),
          };
        });

        if (sectionAnswers && sectionAnswers.length > 0) {
          const payload: SaveRiskAssessmentPayload = {
            id,
            patientID: patientDetails?.patientId ? patientDetails?.patientId : 0,
            intakeID: parseInt(intakeId, 10),
            createUser: user.username,
            isSaveDraft: isPublish ? false : !status || status === "draft",
            sectionAnswers,
            collapsibleSections: collapsibleSections.map((x: any) => x.sectionId),
          };

          await appDispatch(saveRiskAssessmentAnswersAsync(payload)).unwrap();
          if (saveAndClose) {
            toast.success("Saved successfully as a Draft.", {
              containerId: "main",
            });
          } else if (isPublish) {
            toast.success("Risk Assessment submitted successfully. Redirecting...", {
              containerId: "main",
            });

            dispatch(setShowPostAcuteTaskCompletionPopUp(true));
            setTimeout(() => history.push(`${removeLastDashFromUrl(location.pathname)}/summary`), 3000);
          }
        }
      }
    },
    [appDispatch, dispatch, history, intakeId, location.pathname, user.username, isValidIntake, patientDetails]
  );

  const onNext = useCallback(
    (nextPage: Pages) => {
      saveAnswers(false);
      dispatch(setActivePage(nextPage));
    },
    [saveAnswers, dispatch]
  );

  return (
    <>
      <div>
        <Header className="risk-assessment" patientDetails={patientDetailsDisplay()} title="Risk Assessment" />
        <ActiveTabWithTopLine
          onNext={onNext}
          tabList={Object.values(riskAssessmentState.pages)}
          changeActiveTabName={setActiveTab}
        />

        {riskAssessmentState.pages.general.isCurrent && <GeneralQuestions />}
        {riskAssessmentState.pages.lace.isCurrent && <LaceQuestions />}
        {riskAssessmentState.pages.additional.isCurrent && <AdditionalQuestions />}
        {riskAssessmentState.pages.closing.isCurrent && <ClosingQuestions />}
      </div>
    </>
  );
};

export default RiskAssessmentPresentation;
