import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import Header from "components/Header/header.component";
import DatePicker from "components/DatePicker/date-picker.component";
import GenericTabs from "components/GenericTabs/generic-tabs.component";
import Button from "components/Button/button.component";
import { batch, useDispatch, useSelector } from "react-redux";
import SidePopup from "components/SidePopup/side-popup.component";
import Checkbox from "components/Checkbox/checkbox.component";
import MessageTable from "./components/message-table/message-table.component";
import { data } from "components/Modal/TranscriptionModal/demoData";
import TranscriptionModal from "components/Modal/TranscriptionModal/transcription-modal.component";
import {
  inboundNavigatorDashboardCallTableColumns,
  navigatorDashboardCallTableColumns,
  navigatorDashboardMessageTableColumns,
} from "shared/constant/table";
import "./dashboard.styles.scss";
import SearchBox from "components/SearchBox/search-box.component";
import { Message as MessageType } from "components/ChatList/types";
import { CallSidePopup } from "components/SidePopup/CallSidePopup/call-side-popup.component";
import { getEndDate, getStartDate, isEmptyString } from "shared/methods/utilityFunctions";
import moment from "moment";
import { CommunicationTab } from "shared/enums";
import { ActiveTabName } from "components/GenericTabs/props";
import {
  getCommonState,
  setIsOpenCallModal,
  setIsOpenChatModal,
  setIsFilterModal,
} from "state/feature/common/common.slice";
import { getNavigatorState, resetCalls } from "state/feature/navigator/navigator.slice";
import { getAssignPhoneNumberListAsync, getNavigatorCallsAsync } from "state/feature/navigator/navigator.action";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { getAllConversationsAsync } from "state/feature/messaging/messaging.action";
import {
  getMessagingState,
  resetConversations,
  saveConversationId,
  saveConversationParticipants,
  saveParticipantsDetails,
  saveTwilioConversationId,
  setConvRequestPayload,
  setUserIdforMessage,
} from "state/feature/messaging/messaging.slice";
import { getAuth } from "state/feature/auth/auth.slice";
import ChatSidePanel from "components/ChatSidePanel/chat-side-panel.component";
import { ComposeMessage } from "components/SidePopup/ComposeMessage/compose-message.component";
import { getCallState, setOpenParticipantsPopup } from "state/feature/call/call.slice";
import CallParticipants from "components/call-participants/call-participants";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { debounce } from "lodash";
import { PAGE_LIMIT } from "shared/constant/commonConstants";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { useAppDispatch } from "state/store";
import { useTwilioClientService } from "shared/services/twilio/twilio-client.service";
import Status from "components/status/status.component";
import { Range } from "react-date-range";
import Icon from "components/Icon/icon.component";
import { OpenFrom } from "shared/enums/chat.enum";
import { IGetCallsAsyncRequestPayload, IGetConversationsAsyncRequestPayload } from "./common/types";
import CallLogsTable from "./components/call-logs-table/call-logs-table.component";
import { ColumnType } from "shared/types/table.type";

const Dashboard = () => {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const twilioClient = useTwilioClientService();
  const { modal: modalState, twilioErrorCode } = useSelector(getCommonState);
  const messagingState = useSelector(getMessagingState);
  const callState = useSelector(getCallState);
  const authState = useSelector(getAuth);
  const { currentUserProfile } = useSelector(getNavigatorState);
  const { calls: navigatorCalls } = useSelector(getNavigatorState);
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [activeTabName, setActiveTabName] = useState<ActiveTabName>(CommunicationTab.CALLS);
  const [activeToggleTitle] = useState("Patient");
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchListVisible, setIsSearchListVisible] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [allMessages, setAllMessages] = useState<MessageType[]>([]);
  const [filterList, setFilterList] = useState<Array<{ name: string; value: boolean; type: string }>>([
    { name: "", value: false, type: "Call Type" },
    { name: "", value: false, type: "Status" },
  ]);
  const [serverError, setServerError] = useState({ error: false, message: "" });
  const [isIncomingChecked, setIsInComingChecked] = useState(false);
  const [isOutgoingChecked, setIsOutgoingChecked] = useState(false);
  const [isMissedChecked, setIsMissedChecked] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [messageColumnsHeader, setMessageColumnsHeader] = useState<ColumnType[]>(navigatorDashboardMessageTableColumns);
  const [callsColumnsHeader, setCallsColumnsHeader] = useState<ColumnType[]>(navigatorDashboardCallTableColumns);
  const conversationInitialState = {
    userID: authState.user.id,
    searchKeyword: "",
    limit: PAGE_LIMIT,
    offset: 0,
    convStartDate: getStartDate(moment().subtract(7, "day").toDate()),
    convEndDate: getEndDate(new Date()),
    sortColumn: "conversationTime",
    sortOrder: SortingOrderType.DESC,
  };

  const callsInitialState = useMemo(() => {
    const startDate = getStartDate(moment().subtract(7, "day").toDate());
    const endDate = getEndDate(new Date());
    return {
      navigatorId: authState.user.id,
      startDate: startDate,
      endDate: endDate,
      callDirection: "",
      callStatus: [],
      searchText: "",
      sortColumn: "calltime",
      sortOrder: SortingOrderType.DESC,
      limit: PAGE_LIMIT,
      offset: 0,
      isNavigatorManager: false,
    };
  }, [authState.user.id]);

  const converationRequestPayload = useRef(conversationInitialState);
  const [callsRequestPayload, setCallsRequestPayload] = useState<IGetCallsAsyncRequestPayload>(callsInitialState);
  const defaultDateState = [
    {
      startDate: moment().subtract(7, "day").startOf("day").toDate(),
      endDate: moment().toDate(),
      key: "selection",
    },
  ];
  const [dateState, setDateState] = useState<Range[]>(defaultDateState);

  const setConversationRequestPayload = useCallback(
    async (params: IGetConversationsAsyncRequestPayload) => {
      const { convStartDate, convEndDate } = params;
      converationRequestPayload.current = params;
      dispatch(
        setConvRequestPayload({
          ...params,
          convStartDate: getStartDate(new Date(convStartDate)),
          convEndDate: getEndDate(new Date(convEndDate)),
        })
      );
      await dispatch(
        getAllConversationsAsync({
          ...params,
          convStartDate: getStartDate(new Date(convStartDate)),
          convEndDate: getEndDate(new Date(convEndDate)),
        })
      );
      setIsLoading(false);
    },
    [dispatch]
  );

  const getNavigatorCalls = () => {
    if (currentUserProfile) {
      const { startDate, endDate } = callsRequestPayload;

      dispatch(
        getNavigatorCallsAsync({
          ...callsRequestPayload,
          navigatorId: currentUserProfile.id,
          startDate: startDate,
          endDate: endDate,
        })
      );
    }
  };

  useEffect(() => {
    setCallsColumnsHeader(JSON.parse(JSON.stringify(inboundNavigatorDashboardCallTableColumns)));
    setMessageColumnsHeader(JSON.parse(JSON.stringify(navigatorDashboardMessageTableColumns)));
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    return () => {
      dispatch(resetConversations());
      dispatch(resetCalls());
      controller?.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    const controller = new AbortController();
    const { startDate, endDate } = callsRequestPayload;
    if (currentUserProfile) {
      dispatch(
        getNavigatorCallsAsync({
          ...callsRequestPayload,
          navigatorId: currentUserProfile?.id,
          startDate: startDate,
          endDate: endDate,
        })
      );
    }

    return () => {
      controller?.abort();
    };
  }, [callsRequestPayload, dispatch, currentUserProfile]);

  useEffect(() => {
    if (serverError.error) {
      const interval = setInterval(() => {
        setServerError({ message: "", error: false });
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [serverError]);

  const handleMakeACall = async () => {
    if (!callState.twilioCallDevice) {
      twilioClient.registerDevice();
    }
    const outboundPhoneData = await appDispatch(getAssignPhoneNumberListAsync()).unwrap();
    if (outboundPhoneData && outboundPhoneData.length > 0) {
      if (isMobile && !callState.twilioCallDevice) {
        twilioClient.registerDevice();
      }
      if (!callState.currentCall) {
        dispatch(setIsOpenCallModal(true));
      }
    }
  };

  const handleComposeAMessage = async () => {
    dispatch(setIsOpenChatModal({ isOpen: true, openFrom: OpenFrom.COMPOSE }));
  };

  const getCallStatus = () => {
    if (isMissedChecked) {
      return ["no-answer", "busy", "missed"];
    } else {
      return [];
    }
  };
  const handleFilterApplyClick = () => {
    dispatch(setIsFilterModal(false));
    dispatch(resetCalls());

    const callDirection = isIncomingChecked || isOutgoingChecked ? (isIncomingChecked ? "inbound" : "outbound") : "";
    const newFilteredList = filterList.map((filter) => {
      if (filter.type === "Status") {
        filter.value = isMissedChecked;
        filter.name = "Missed";
        return filter;
      }
      if (filter.type === "Call Type") {
        filter.value = isIncomingChecked || isOutgoingChecked;
        filter.name = isIncomingChecked ? "Incoming" : isOutgoingChecked ? "Outgoing" : "";
        return filter;
      }
      return filter;
    });

    setFilterList([...newFilteredList]);
    setCallsRequestPayload((prev: any) => {
      return {
        ...prev,
        offset: 0,
        callDirection: callDirection,
        callStatus: getCallStatus(),
      };
    });
    if (isMobile) {
      const startDate = dateState[0]?.startDate
        ? getStartDate(dateState[0]?.startDate)
        : getEndDate(moment().subtract(7, "day").toDate());
      const endDate = dateState[0].endDate ? getStartDate(dateState[0].endDate) : getEndDate(new Date());
      setCallsRequestPayload((prev: any) => {
        return {
          ...prev,
          startDate: startDate,
          endDate: endDate,
        };
      });
    }
    setIsFilterApplied(isOutgoingChecked || isIncomingChecked || isMissedChecked);
  };
  const removeFilterPill = (index: number) => {
    const filterDetails: { name?: string; value?: boolean; type?: string } = filterList[index];
    const newFilterList = [...filterList];
    newFilterList[index].name = "";
    newFilterList[index].value = false;
    setFilterList(newFilterList);

    if (filterDetails?.type === "Call Type") {
      setIsInComingChecked(false);
      setIsOutgoingChecked(false);
      setCallsRequestPayload((prev) => {
        return {
          ...prev,
          callDirection: "",
        };
      });
    }
    if (filterDetails?.type === "Status") {
      setIsMissedChecked(false);
      setCallsRequestPayload((prev) => {
        return {
          ...prev,
          callStatus: [],
        };
      });
    }
  };
  useEffect(() => {
    if (!filterList[0].value && !filterList[1].value) {
      setIsFilterApplied(false);
    }
  }, [filterList]);
  const handleFilterClearClick = () => {
    if (isMobile) {
      setDateState(defaultDateState);
    }
    dispatch(setIsFilterModal(false));
    dispatch(resetCalls());
    setIsInComingChecked(false);
    setIsOutgoingChecked(false);
    setIsMissedChecked(false);
    setIsFilterApplied(false);
    const newFilterList = filterList.map((filter) => {
      filter.name = "";
      filter.value = false;
      return filter;
    });
    setFilterList(newFilterList);
    setCallsRequestPayload((prev) => {
      return {
        ...prev,
        callDirection: "",
        callStatus: [],
      };
    });
  };

  const onClickTabName = (tabName: ActiveTabName) => {
    if (activeTabName !== tabName) {
      dispatch(resetConversations());
      dispatch(resetCalls());
      setActiveTabName(tabName);
      setIsSearching(false);
      handleFilterClearClick();
      const startDate = moment().subtract(7, "day").toISOString();
      const endDate = moment().toISOString();
      setDateState([
        {
          startDate: startDate ? moment(startDate).toDate() : undefined,
          endDate: endDate ? moment(endDate).toDate() : undefined,
          key: "selection",
        },
      ]);
      if (startDate !== null && endDate !== null) {
        if (tabName === CommunicationTab.CALLS) {
          setCallsColumnsHeader(JSON.parse(JSON.stringify(inboundNavigatorDashboardCallTableColumns)));
          setCallsRequestPayload({ ...callsInitialState });
        } else {
          setMessageColumnsHeader(JSON.parse(JSON.stringify(navigatorDashboardMessageTableColumns)));
          setConversationRequestPayload(conversationInitialState);
        }
      }
    }
  };

  const callAPIOnApply = (item: Range[]) => {
    setDateState(item);
    setIsLoading(true);
    if (item[0].startDate && item[0].endDate) {
      const startDate = getStartDate(item[0].startDate);
      const endDate = getEndDate(item[0].endDate);
      if (activeTabName === CommunicationTab.CALLS) {
        dispatch(resetCalls());
        setCallsRequestPayload((prev) => {
          return {
            ...prev,
            offset: 0,
            startDate: startDate,
            endDate: endDate,
          };
        });
      } else {
        dispatch(resetConversations());

        setConversationRequestPayload({
          ...converationRequestPayload.current,
          offset: 0,
          convStartDate: startDate,
          convEndDate: endDate,
        });
      }
    }
  };

  const handleClearButtonClick = (val: boolean) => {
    setDatePickerOpen(val);
    setIsLoading(true);
    const startDate = moment().subtract(7, "day");
    const endDate = moment();
    const startDateState = startDate.startOf("day").toISOString();
    const endDateState = endDate.toISOString();
    setDateState([
      {
        startDate: startDateState ? moment(startDateState).toDate() : undefined,
        endDate: endDateState ? moment(endDateState).toDate() : undefined,
        key: "selection",
      },
    ]);
    if (activeTabName === CommunicationTab.CALLS) {
      dispatch(resetCalls());
      dispatch(resetConversations());
      setCallsRequestPayload((prev) => {
        return {
          ...prev,
          offset: 0,
          startDate: getStartDate(startDate.toDate()),
          endDate: getEndDate(endDate.toDate()),
        };
      });
    } else {
      dispatch(resetConversations());
      setConversationRequestPayload({
        ...converationRequestPayload.current,
        offset: 0,
        convStartDate: getStartDate(startDate.toDate()),
        convEndDate: getEndDate(endDate.toDate()),
      });
    }
  };

  const handleDateRangeSelect = (item: any) => {
    setDateState(item);
  };

  const handleApplyClick = (item: any) => {
    dispatch(resetCalls());
    callAPIOnApply(item);
    setDatePickerOpen(false);
  };

  const handleIncomingClick = () => {
    if (!isIncomingChecked && isOutgoingChecked) {
      setIsOutgoingChecked(isIncomingChecked);
    }
    setIsInComingChecked(!isIncomingChecked);
  };

  const handleOutgoingClick = () => {
    if (!isOutgoingChecked && isIncomingChecked) {
      setIsInComingChecked(isOutgoingChecked);
    }
    setIsOutgoingChecked(!isOutgoingChecked);
  };

  const handleMissedClick = () => {
    setIsMissedChecked(!isMissedChecked);
  };

  const handleSearchInput = useCallback(
    (searchValue: string) => {
      converationRequestPayload.current = { ...converationRequestPayload.current, searchKeyword: searchValue };
      dispatch(
        setConvRequestPayload({
          ...converationRequestPayload.current,
          offset: 0,
          searchKeyword: searchValue,
        })
      );
      if (activeTabName === CommunicationTab.CALLS) {
        dispatch(resetCalls());
        setCallsRequestPayload((prevCallsState) => {
          return {
            ...prevCallsState,
            offset: 0,
            searchText: searchValue,
          };
        });
      } else {
        setConversationRequestPayload({
          ...converationRequestPayload.current,
          offset: 0,
          searchKeyword: searchValue,
        });
      }

      if (searchValue.length > 0) {
        setIsSearchListVisible(true);
      } else {
        setIsSearchListVisible(false);
      }
    },
    [activeTabName, dispatch, setConversationRequestPayload]
  );

  const conversations = messagingState.conversations?.map(
    ({
      name,
      lastMessageDateTime,
      lastMessageContent,
      conversationID,
      twilioConversationId,
      type,
      lastMessageSenderId,
      participantDetails,
      isLastNotificationRead,
    }) => {
      const filteredParticipantDetails = participantDetails.filter(
        (participant: any) => participant.userID !== authState.user.id
      );
      const newParticipantName = filteredParticipantDetails
        .map((participant: any) => `${participant?.fullName}`)
        .reverse()
        .join(", ")
        .trim();
      return {
        participants: newParticipantName,
        dateTime: lastMessageDateTime,
        message: lastMessageContent,
        conversationId: conversationID,
        twilioConversationId: twilioConversationId,
        type: type,
        lastMessageSenderId: lastMessageSenderId,
        participantDetails: filteredParticipantDetails,
        isLastNotificationRead: isLastNotificationRead,
      };
    }
  );

  const refreshMessageList = () => {
    setConversationRequestPayload(converationRequestPayload.current);
  };

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearchInput, 300);
  }, [handleSearchInput]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleMessagesSorting = (selectedColumn: ColumnType) => {
    dispatch(resetConversations());
    setIsLoading(true);
    const index = messageColumnsHeader.findIndex((x) => x.key === selectedColumn.key);
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (messageColumnsHeader[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeaders = [...messageColumnsHeader];
    tempHeaders.forEach((header) => (header.sortOrder = SortingOrderType.DEFAULT));
    tempHeaders[index].sortOrder = sort;
    setMessageColumnsHeader(tempHeaders);
    converationRequestPayload.current = {
      ...converationRequestPayload.current,
      sortColumn: selectedColumn.key,
      sortOrder: sort,
    };
    dispatch(
      setConvRequestPayload({
        ...converationRequestPayload.current,
        offset: 0,
        sortColumn: selectedColumn.key,
        sortOrder: sort,
      })
    );
    setConversationRequestPayload({
      ...converationRequestPayload.current,
      offset: 0,
      sortColumn: selectedColumn.key,
      sortOrder: sort,
    });
  };

  const handleCallsSorting = (selectedColumn: ColumnType) => {
    dispatch(resetCalls());
    const index = callsColumnsHeader.findIndex((x) => x.key === selectedColumn.key);
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (callsColumnsHeader[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeaders = [...callsColumnsHeader];
    tempHeaders.forEach((header) => (header.sortOrder = SortingOrderType.DEFAULT));
    tempHeaders[index].sortOrder = sort;
    setCallsColumnsHeader(tempHeaders);
    setCallsRequestPayload((prev) => {
      return {
        ...prev,
        offset: 0,
        sortColumn: selectedColumn.key,
        sortOrder: sort,
      };
    });
  };

  const handleMessageIncrementPage = () => {
    setConversationRequestPayload({
      ...converationRequestPayload.current,
      offset: converationRequestPayload.current.offset + PAGE_LIMIT,
    });
  };

  const handleCallsIncrementPage = () => {
    setCallsRequestPayload((prev) => {
      return {
        ...prev,
        offset: prev.offset + PAGE_LIMIT,
      };
    });
  };

  return (
    <>
      <div id="navigator-dashboard-container">
        <Header className="navigator-dashboard">
          <MobileView>
            <div className="header-title">Dashboard</div>
          </MobileView>
          <BrowserView>
            <div className="header-title ">
              Hi, {authState.user.firstName} {authState.user.lastName}
            </div>
          </BrowserView>
          <BrowserView>
            <div className="status-and-date-picker home-page-datepicker">
              <DatePicker
                onTap={(val) => {
                  setDatePickerOpen(val);
                }}
                isOpen={isDatePickerOpen}
                onDateRangeSelect={(item) => handleDateRangeSelect(item)}
                handleClearButtonClick={handleClearButtonClick}
                handleApplyClick={handleApplyClick}
                maxDate={new Date()}
                dateFormat="mm/dd/yyyy"
                initialSelectedDates={dateState}
              />
            </div>
          </BrowserView>
        </Header>
        <div className="all-filters-section">
          <GenericTabs
            tabsList={[
              {
                name: CommunicationTab.CALLS,
              },
              {
                name: CommunicationTab.MESSAGES,
              },
            ]}
            activeTabName={activeTabName}
            setActiveTabName={(tabName) => onClickTabName(tabName)}
          />
          <div className="right-section">
            {isSearching || isMobile ? (
              <SearchBox
                placeholder={
                  activeTabName === CommunicationTab.MESSAGES ? "Search by participant/keyword" : "Search By Name"
                }
                icon="cross"
                className="search-box"
                iconClassName="search-icon"
                SearchBoxSize={22}
                showIcon={
                  callsRequestPayload.searchText || converationRequestPayload.current.searchKeyword ? true : false
                }
                onClick={() => {
                  handleSearchInput("");
                  setIsSearching(false);
                }}
                onTextChange={(value) => {
                  debouncedSearch(value);
                }}
                autoFocus={isMobile ? false : true}
              />
            ) : (
              <Button
                icon="search"
                buttonSize={14}
                className="search-icon-container"
                iconClassName="search-icon"
                onClick={() => setIsSearching(true)}
              />
            )}
            {activeTabName === CommunicationTab.CALLS && (
              <div>
                <MobileView className="filter-status-container">
                  <Status status={isFilterApplied ? "filtered filter-status-mobile" : ""} />
                  <Button
                    icon="filter"
                    className="filter-icon-container"
                    iconClassName="filter-icon"
                    buttonSize={14}
                    onClick={() => dispatch(setIsFilterModal(true))}
                  />
                </MobileView>
                <BrowserView className="filter-status-container">
                  <Status status={isFilterApplied ? "filtered" : ""} />
                  <Button
                    icon="filter"
                    className="filter-icon-container"
                    iconClassName="filter-icon"
                    buttonSize={14}
                    onClick={() => dispatch(setIsFilterModal(true))}
                  />
                </BrowserView>
              </div>
            )}
            <BrowserView>
              <Button
                text={activeTabName === CommunicationTab.CALLS ? "Make a Call" : "Compose"}
                onClick={activeTabName === CommunicationTab.CALLS ? handleMakeACall : handleComposeAMessage}
                className={`green-button make-a-call-button ${
                  (twilioErrorCode || (callState && callState.currentCall)) && activeTabName === CommunicationTab.CALLS
                    ? "disabled"
                    : "enabled"
                }`}
              />
            </BrowserView>
            <MobileView>
              <Button
                icon={activeTabName === CommunicationTab.CALLS ? "Dial" : "Compose-message"}
                onClick={activeTabName === CommunicationTab.CALLS ? handleMakeACall : handleComposeAMessage}
                className={`green-button make-a-call-action ${
                  callState && callState.currentCall ? "disabled" : "enabled"
                }`}
                buttonSize={20}
              />
            </MobileView>
          </div>
        </div>
        {(filterList[0].value || filterList[1].value) && (
          <BrowserView>
            <div className="filters-applied-container">
              <div className="filters-applied-label-container">
                <div className="filters-applied-label">Applied Filters:</div>
                <div className="clear-filters-cta" onClick={handleFilterClearClick}>
                  Clear All
                </div>
              </div>
              <div className="filters-applied-pill-container">
                {filterList.map(({ name, value, type }, index) => {
                  if (value) {
                    return (
                      <div className="filter-pill">
                        <div className="filter-pill-content">
                          <div className="filter-pill-label">{type}</div>
                          <div className="filter-pill-value">{name}</div>
                        </div>
                        <div
                          className="remove-filter-pill-cta"
                          onClick={() => {
                            removeFilterPill(index);
                          }}
                        >
                          <Icon icon="close" size={10} />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </BrowserView>
        )}
        <div className="table-container">
          {activeTabName === CommunicationTab.CALLS ? (
            <CallLogsTable
              tableClassName="navigator-manager-patient-call-table"
              columns={callsColumnsHeader}
              currentTableData={navigatorCalls}
              searchText={callsRequestPayload.searchText}
              handleColumnSort={handleCallsSorting}
              incrementPage={() => {
                handleCallsIncrementPage();
              }}
              getNavigatorCalls={getNavigatorCalls}
            />
          ) : (
            <MessageTable
              tableClassName="NavigatorMessageTable"
              isLoading={isLoading}
              columns={messageColumnsHeader}
              conversations={messagingState?.conversations?.map(
                ({
                  lastMessageDateTime,
                  lastMessageContent,
                  conversationID,
                  twilioConversationId,
                  type,
                  lastMessageSenderId,
                  participantDetails,
                  isLastNotificationRead,
                  patientDOB,
                  subject,
                }) => {
                  const filteredParticipantDetails = participantDetails.filter(
                    (participant: any) => participant.userID !== authState.user.id
                  );
                  const newParticipantName = filteredParticipantDetails
                    .map((participant: any) => `${participant?.fullName}`)
                    .sort()
                    .join(", ")
                    .trim();
                  return {
                    participants: newParticipantName,
                    dateTime: lastMessageDateTime,
                    message: lastMessageContent,
                    conversationId: conversationID,
                    twilioConversationId: twilioConversationId,
                    type: type,
                    lastMessageSenderId: lastMessageSenderId,
                    participantDetails: participantDetails,
                    isLastNotificationRead: isLastNotificationRead,
                    patientDOB,
                    subject: !isEmptyString(subject ?? "") ? subject : "-",
                  };
                }
              )}
              activeToggleTitle={activeToggleTitle}
              searchText={converationRequestPayload.current.searchKeyword}
              getMoreMessages={refreshMessageList}
              handleColumnSort={handleMessagesSorting}
              incrementPage={() => {
                handleMessageIncrementPage();
              }}
            />
          )}
        </div>

        <SidePopup
          isOpen={callState.openParticipantsPopup}
          heading="Call Participants"
          contentClass="filter-content-container"
          className="filter-side-popup call-participants-popup"
          onClose={() => dispatch(setOpenParticipantsPopup(false))}
        >
          <CallParticipants />
        </SidePopup>

        <SidePopup
          isOpen={modalState.isOpenFilterModal}
          onClose={() => {
            dispatch(setIsFilterModal(false));
            const checkIfIncoming = filterList[0].name == "Incoming" && filterList[0].value;
            const checkIfOutgoing = filterList[0].name == "Outgoing" && filterList[0].value;
            const isMissed = filterList[1].value;
            setIsInComingChecked(checkIfIncoming);
            setIsOutgoingChecked(checkIfOutgoing);
            setIsMissedChecked(isMissed);
          }}
          contentClass="filter-content-container"
          className="filter-side-popup"
          heading="Filter"
        >
          <MobileView>
            <div className="status-and-date-picker home-page-datepicker">
              <DatePicker
                onTap={(val) => {
                  setDatePickerOpen(val);
                }}
                isOpen={isDatePickerOpen}
                onDateRangeSelect={(item) => handleDateRangeSelect(item)}
                handleClearButtonClick={handleClearButtonClick}
                handleApplyClick={handleApplyClick}
                maxDate={new Date()}
                dateFormat="mm/dd/yyyy"
                initialSelectedDates={dateState}
              />
            </div>
          </MobileView>
          <div className={isMobile ? "filter-content filter-content-mobile" : "filter-content"}>
            <div>
              <div className="status">Call Type</div>
              <div className="checkbox-container">
                <div className="checkboxes" key="Incoming-checkox">
                  <Checkbox
                    name="incoming"
                    value="Incoming"
                    id="Incoming-checkox"
                    onChange={handleIncomingClick}
                    isChecked={isIncomingChecked}
                  />
                </div>
                <div className="checkboxes" key="Outgoing-checkox">
                  <Checkbox
                    name="outgoing"
                    value="Outgoing"
                    id="Outgoing-checkox"
                    onChange={handleOutgoingClick}
                    isChecked={isOutgoingChecked}
                  />
                </div>
              </div>
              <div className="status">Status</div>
              <div className="checkbox-container">
                <div className="checkboxes" key="Missed-checkox">
                  <Checkbox
                    name="missed"
                    value="Missed"
                    id="Missed-checkox"
                    onChange={handleMissedClick}
                    isChecked={isMissedChecked}
                  />
                </div>
              </div>
            </div>
            <div className="filter-buttons">
              <Button onClick={handleFilterApplyClick} text="Apply" className="green-button apply-button" />
              <Button onClick={handleFilterClearClick} text="Clear" className="white-button clear-button" />
            </div>
          </div>
        </SidePopup>
        <TranscriptionModal
          isOpen={modalState.isOpenTranscriptionModal}
          heading="Kristin Watson Recording Transcripts  |  27 Aug 2022"
          transcript={data}
        />

        <CallSidePopup
          isOpen={modalState.isOpenCallModal}
          heading="Make a Call"
          isDropdownVisible={isDropdownVisible}
          setIsDropdownVisible={setIsDropdownVisible}
        />
      </div>
    </>
  );
};

export default Dashboard;
