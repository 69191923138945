import { default as CommonHeader } from "components/Header/header.component";
import "./header.styles.scss";

interface HeaderProps {
  className?: string;
  patientDetails: any;
  title: string;
}
const Header = (props: HeaderProps) => {
  const { className, patientDetails, title } = props;
  return (
    <CommonHeader className={`task-management-header ${className ? className : ""}`}>
      <div className="patient-details">{patientDetails}</div>
      <div className="task-management-header-title">{title}</div>
    </CommonHeader>
  );
};

export default Header;
