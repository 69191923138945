import { createSlice } from "@reduxjs/toolkit";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { toast } from "react-toastify";
import { ICallState, ICurrentCallDetails } from "state/types/call-slice.type";
import { generateTwilioTokenAsync, getIncomingCallDetails, synchronizeCallsAsync } from "./call.action";
import { getTokensFromCookies } from "../auth/auth.slice";
import { CALLER_TYPES } from "shared/constant/caller-types-constants";
import { getCookie, isEmptyString, setCookie } from "shared/methods/utilityFunctions";

const { twilioAccessToken } = getTokensFromCookies();

const initialState: ICallState = {
  isLoading: false,
  isLoadingIncomingCallDetails: true,
  isError: false,
  twilioAcessToken: twilioAccessToken ?? "",
  currentCall: null,
  currentIncomingCallDetails: null,
  currentIncomingCallNumber: "",
  twilioCallDevice: null,
  currentCallParticipants: [],
  openParticipantsPopup: false,
  participants: [],
  dialerInput: "",
  minutes: 0,
  seconds: 0,
  callStatusText: "Initializing",
  callParticipantsText: "",
  isMinimizeIncomingCall: false,
};

const callSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    saveTwilioCallDevice: (state: any, action) => {
      state.twilioCallDevice = action.payload;
    },
    saveCurrentCall: (state: any, action) => {
      state.currentCall = action.payload;
    },
    saveCurrentCallParticipants: (state: any, action) => {
      state.currentCallParticipants = action.payload;
    },
    setOpenParticipantsPopup: (state: ICallState, action) => {
      state.participants = action.payload.participants;
      state.openParticipantsPopup = action.payload.open;
    },
    setMinutes: (state: any, action) => {
      state.minutes = action.payload;
    },
    setSeconds: (state: any, action) => {
      state.seconds = action.payload;
    },
    incrementMinutes: (state: any) => {
      state.minutes = state.minutes + 1;
    },
    incrementSeconds: (state: any) => {
      state.seconds = state.seconds + 1;
    },
    setCallStatusText: (state: any, action) => {
      state.callStatusText = action.payload;
    },
    setCallParticipantsText: (state: any, action) => {
      state.callParticipantsText = action.payload;
    },
    setDialerInput: (state: ICallState, action) => {
      if (action.payload.type === "input") {
        state.dialerInput = state.dialerInput.concat(action.payload.text);
      } else if (action.payload.type === "change") {
        state.dialerInput = action.payload.text;
      } else {
        state.dialerInput = state.dialerInput.slice(0, state.dialerInput.length - 1);
      }
    },
    clearCall: (state: any) => {
      state.seconds = 0;
      state.minutes = 0;
      state.callStatusText = "Initializing";
      state.callParticipantsText = "";
      state.dialerInput = "";
    },
    clearTwilioToken: (state: any) => {
      state.twilioAcessToken = null;
    },
    setCurrentIncomingCallNumber: (state: ICallState, action) => {
      state.currentIncomingCallNumber = action.payload;
    },
    setIsMinimizeIncomingCall: (state: any, action) => {
      state.isMinimizeIncomingCall = action.payload;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(generateTwilioTokenAsync.pending, (state: any) => {
        state.isLoading = true;
      }),
      builder.addCase(generateTwilioTokenAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.twilioAcessToken = action.payload;
        const authTokens = JSON.parse(getCookie("authTokens") as string);
        const newAuthTokens = {
          ...authTokens,
          twilioAccessToken: action.payload,
        };
        setCookie("authTokens", JSON.stringify(newAuthTokens));
      }),
      builder.addCase(generateTwilioTokenAsync.rejected, (state: any) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getIncomingCallDetails.pending, (state: any) => {
        state.isLoadingIncomingCallDetails = true;
      }),
      builder.addCase(getIncomingCallDetails.fulfilled, (state: any, action) => {
        state.isLoadingIncomingCallDetails = false;
        const { navigator, physician, patient } = action.payload;
        let incomingDetail: ICurrentCallDetails | null = {
          id: "",
          callerName: "",
          callerType: "",
          phoneNumber: "-",
          episode: "",
          practice: "",
          providerName: "",
          primaryNavigator: "",
          phoneType: "",
          intakeId: 0,
          patientId: 0,
          primaryNavigatorCareManagerId: "",
        };
        if (navigator !== null) {
          const { navigatorFirstName, navigatorLastName, navigatorUserId } = navigator;
          incomingDetail = {
            ...incomingDetail,
            id: navigatorUserId,
            callerName:
              navigatorFirstName && navigatorLastName
                ? `${navigatorFirstName} ${navigatorLastName}`
                : state.currentIncomingCallNumber,
            callerType: CALLER_TYPES.NAVIGATOR,
            practice: "-",
          };
        } else if (physician !== null) {
          const { physicianFirstName, physicianLastName, physicianUserId, practice } = physician;
          incomingDetail = {
            ...incomingDetail,
            id: physicianUserId,
            callerName:
              physicianFirstName && physicianLastName
                ? `${physicianFirstName} ${physicianLastName}`
                : state.currentIncomingCallNumber,
            callerType: CALLER_TYPES.PHYSICIAN,
            practice: practice && practice.trim().length > 0 ? practice : "-",
          };
        } else if (patient !== null) {
          const {
            patientUserId,
            patientFirstName,
            patientLastName,
            practice,
            preferredPhoneType,
            preferredPhone,
            procedureName,
            physician: Physician,
            primaryNavigator,
            intakeId,
            patientId,
            primaryNavigatorCareManagerId,
          } = patient;
          incomingDetail = {
            id: patientUserId,
            callerName:
              patientFirstName && patientLastName
                ? `${patientFirstName} ${patientLastName}`
                : state.currentIncomingCallNumber,
            callerType: CALLER_TYPES.PATIENT,
            phoneNumber: preferredPhone,
            episode: procedureName && !isEmptyString(procedureName) ? procedureName : "-",
            practice: practice && !isEmptyString(practice) ? practice : "-",
            providerName: Physician && !isEmptyString(Physician) ? Physician : "-",
            phoneType: preferredPhoneType && !isEmptyString(preferredPhoneType) ? preferredPhoneType : "",
            primaryNavigator: primaryNavigator && !isEmptyString(primaryNavigator) ? primaryNavigator : "-",
            intakeId: intakeId,
            patientId: patientId,
            primaryNavigatorCareManagerId: primaryNavigatorCareManagerId,
          };
        } else {
          incomingDetail = null;
        }
        state.currentIncomingCallDetails = incomingDetail;
      }),
      builder.addCase(getIncomingCallDetails.rejected, (state: any) => {
        state.isLoadingIncomingCallDetails = false;
        state.isError = true;
      }),
      builder.addCase(synchronizeCallsAsync.pending, (state: any) => {}),
      builder.addCase(synchronizeCallsAsync.fulfilled, (state: any) => {}),
      builder.addCase(synchronizeCallsAsync.rejected, (state: any) => {
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      })
    );
  },
});

export default callSlice;
export const {
  clearCall,
  setMinutes,
  incrementMinutes,
  setSeconds,
  incrementSeconds,
  setCallStatusText,
  setCallParticipantsText,
  saveCurrentCall,
  saveTwilioCallDevice,
  saveCurrentCallParticipants,
  setDialerInput,
  clearTwilioToken,
  setOpenParticipantsPopup,
  setCurrentIncomingCallNumber,
  setIsMinimizeIncomingCall,
} = callSlice.actions;
export const getCallState = (state: any): ICallState => state.call;
