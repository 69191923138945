import { useHistory, useParams } from "react-router";
import "./task.styles.scss";
import { Task as TaskSliceProps } from "state/types/task-management-slice.type";
import moment from "moment";
import { useEffect, useState } from "react";
import Days from "components/days/days.component";
import { useDispatch, useSelector } from "react-redux";
import { resetSelections } from "state/feature/risk-assessment/risk-assessment.slice";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";

const Task = (props: TaskSliceProps) => {
  const history = useHistory();
  const { intakeId: intakeIdParams } = useParams<{ intakeId: string }>();
  const { isValidatingIntake } = useSelector(getTaskManagementState);
  const [isSelected, setIsSelected] = useState(false);
  const { name, dueDate, id, patient, snoozeCount, intakeId } = props;
  const dispatch = useDispatch();
  const getDueDateClass = () => {
    const dayDiff = moment().diff(moment(dueDate), "hours");
    if (dayDiff > 24) {
      return "red";
    } else if (dayDiff < 24 && dayDiff >= 0) {
      return "yellow";
    } else {
      return "green";
    }
  };

  const getOverDueSection = () => {
    const dayDiff = moment().diff(moment(dueDate), "days");
    if (dayDiff >= 1) {
      return (
        <div className="right-section">
          <div className="overdue">Overdue</div>
          <Days days={snoozeCount} />
        </div>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (intakeId.toString() === intakeIdParams) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [intakeIdParams, intakeId]);

  const handleOnClick = () => {
    if (!isValidatingIntake) {
      dispatch(resetSelections());
      const taskType = name.split(" ").join("-").toLowerCase();
      history.push(`/task-management/${intakeId}/${taskType}`);
    }
  };

  const getTaskInfoPatientDetails = () => {
    const newPatientDetails = [
      { name: "gender", value: patient.gender },
      { name: "age", value: patient.age },
      { name: "procedureDate", value: patient.procedureDate },
    ]
      .filter((patientDetail) => patientDetail.value !== "" && patientDetail.value !== -1)
      .map((patientDetail) => {
        if (patientDetail.name === "age") {
          return `${patientDetail.value} Yrs`;
        } else if (patientDetail.name === "procedureDate") {
          return `Procedure Date: ${patientDetail.value}`;
        } else {
          return patientDetail.value;
        }
      });
    return newPatientDetails.join(", ");
  };
  return (
    <div id={id} className={`task ${isSelected ? "selected-task" : ""}`} role="button" onClick={handleOnClick}>
      <div className={`task-info-container ${isSelected ? "selected-info" : ""}`}>
        <div className="task-info">
          <div className="name">{patient.name}</div>
          <div className="patient-details">{getTaskInfoPatientDetails()}</div>
          <div className="procedure">
            <span className="procedure-text">Procedure: </span>
            {patient.procedureName}
          </div>
          <div className="task-name">
            <span className="task-name-text">Task: </span>
            {name}
          </div>
        </div>
        <div className="line"></div>
        <div className="bottom-section">
          <div className="due-date">
            <div className="due-date-text">Due Date:&nbsp;</div>
            <div className={`${getDueDateClass()}`}>{dueDate}</div>
          </div>
          {getOverDueSection()}
        </div>
      </div>
    </div>
  );
};

export default Task;
