import React from "react";
import Icon from "../Icon/icon.component";
import StatusPicker from "components/status-picker/status-picker.component";
import "./status-selector.style.scss";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCommonState, setIsOpenLogoutModal } from "state/feature/common/common.slice";
import LeftCaredSold from "../../shared/assets/images/tooltip.svg";
import Avatar from "components/Avatar/avatar.component";
import { OnlineOfflineStatus } from "shared/enums/online-offline.enum";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { getNavigatorAvailabilityStatus } from "shared/methods/utilityFunctions";
import { getAuth } from "state/feature/auth/auth.slice";
import { USER_ROLE } from "shared/constant/role-constant";

const StatusSelectorComponent = ({
  firstName,
  lastName,
  isOpen,
  className,
  setIsOpen,
}: {
  firstName: string;
  lastName: string;
  isOpen: boolean;
  className?: string;
  setIsOpen: () => void;
}) => {
  const dispatch = useDispatch();
  const { featureFlags } = useSelector(getCommonState);
  const { currentUserProfile, onCallLNavigators, holidayList } = useSelector(getNavigatorState);
  const { user } = useSelector(getAuth);
  const history = useHistory();

  const navigateToProfilePage = () => {
    let profileUrl;
    if (user.roles.includes(USER_ROLE.MANAGER) && currentUserProfile && currentUserProfile.managerId) {
      profileUrl = `/navigator-management/profile/${currentUserProfile.managerId}`;
    } else {
      profileUrl = "/profile";
    }

    setIsOpen();
    history.replace(profileUrl);
  };
  return (
    <>
      <img src={LeftCaredSold} className={`left-caret ${isOpen ? "status-is-open" : ""}`} alt="left-caret" />
      <div
        className={`status-selector ${isOpen ? "status-is-open" : ""} ${className ? className : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="status-selector-about">
          <Avatar firstName={firstName} lastName={lastName} />
          <div className="status-selector-about--name">{`${firstName} ${lastName}`}</div>
        </div>
        {featureFlags.inboundCalls && (
          <div
            className="status-picker-component"
            onClick={() => {
              if (
                getNavigatorAvailabilityStatus(currentUserProfile, holidayList, onCallLNavigators) !==
                OnlineOfflineStatus.FORWARDED
              ) {
                setIsOpen();
              }
            }}
          >
            <StatusPicker
              statusText={getNavigatorAvailabilityStatus(currentUserProfile, holidayList, onCallLNavigators)}
            />
          </div>
        )}
        <div className="status-selector-container">
          <div
            onClick={navigateToProfilePage}
            className={`status-selector-profile ${!featureFlags.inboundCalls ? "margin-right-20" : ""}`}
          >
            <div className="status-selector-profile-icon">
              <Icon icon={"navigator-management-icon"} className="navigator-management-icon" size={20} />
            </div>
            <div className="status-selector-profile-text">Profile</div>
          </div>
          <div
            className="status-selector-logout"
            onClick={() => {
              setIsOpen();
              dispatch(setIsOpenLogoutModal(true));
            }}
          >
            <div className="status-selector-logout-icon">
              <Icon className="status-logout-icon" icon="logout" size={20} />
            </div>
            <div className="status-selector-logout-text">Log Out</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StatusSelectorComponent;
