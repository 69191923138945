import Header from "components/Header/header.component";
import { useState, useEffect, useCallback, useMemo } from "react";
import GenericTabs from "components/GenericTabs/generic-tabs.component";
import { TaskManagementTab } from "shared/enums/task-management-tabs.enum";
import { ActiveTabName } from "components/GenericTabs/props";
import CollapseArrow from "shared/assets/images/expandable-section-arrow.svg";
import ExpandArrow from "shared/assets/images/collapse-section-arrow.svg";
import { useSelector } from "react-redux";
import { expandSection, getTaskManagementState, resetTasks } from "state/feature/task-management/task-management.slice";
import { useAppDispatch } from "state/store";
import SearchBox from "components/SearchBox/search-box.component";
import TaskList from "./task-list/task-list.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import { getAuth } from "state/feature/auth/auth.slice";
import { getTasksAsync } from "state/feature/task-management/task-management.action";
import { debounce } from "lodash";
import { TASKS_LIMIT } from "./constant";
import { useParams } from "react-router";
import Loading from "shared/assets/images/loader.gif";
const TaskManagement = () => {
  const dispatch = useAppDispatch();
  const { isTaskListExpanded, tasks, areTasksLoading } = useSelector(getTaskManagementState);
  const [activeTabName, setActiveTabName] = useState<ActiveTabName>(TaskManagementTab.ACTIVE);
  const authState = useSelector(getAuth);
  const [searchText, setSearchText] = useState("");
  const { intakeId } = useParams<{ taskType: string; intakeId: string; sectionName: string }>();

  const taskInitialState = {
    primaryCareManagerId: authState.user.navigatorId,
    offset: 0,
    searchText: "",
    limit: TASKS_LIMIT,
    isSnoozed: false,
  };
  const [tasksRequestPayload, setTaskRequestPayload] = useState(taskInitialState);

  useEffect(
    () => {
      if (intakeId) {
        dispatch(expandSection(false));
      } else {
        dispatch(expandSection(true));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    dispatch(getTasksAsync(tasksRequestPayload));
  }, [dispatch, tasksRequestPayload]);

  const getTasks = useCallback(() => {
    if (activeTabName === TaskManagementTab.ACTIVE) {
      return tasks.active;
    }
    return tasks.snoozed;
  }, [activeTabName, tasks]);

  const changeSearchText = useCallback(
    (text: string) => {
      dispatch(resetTasks());
      setSearchText(text);
      setTaskRequestPayload((prev) => {
        return {
          ...prev,
          offset: 0,
          searchText: text.trim(),
          isSnoozed: activeTabName === TaskManagementTab.SNOOZED,
        };
      });
    },
    [activeTabName, dispatch]
  );

  const debouncedSearch = useMemo(() => {
    return debounce(changeSearchText, 300);
  }, [changeSearchText]);

  const onClickTabName = (tabName: ActiveTabName) => {
    setActiveTabName(tabName);
    setTaskRequestPayload({
      ...taskInitialState,
      searchText: searchText,
      isSnoozed: tabName === TaskManagementTab.SNOOZED,
    });
  };
  const handleSearchText = (searchValue: string) => {
    setSearchText(searchValue);
    debouncedSearch(searchValue);
  };

  const incrementPage = () => {
    setTaskRequestPayload((prev) => {
      return {
        ...prev,
        offset: prev.offset + TASKS_LIMIT,
      };
    });
  };

  return (
    <div className="task-management">
      {isTaskListExpanded && (
        <>
          <div>
            <img
              data-tip
              data-for="Hide Task list"
              src={CollapseArrow}
              className="collapse-section-arrow"
              alt="collapse-arrow"
              onClick={() => dispatch(expandSection(false))}
            />

            <CustomToolTip text={"Hide Task list"} place="right" />
          </div>
        </>
      )}
      {!isTaskListExpanded && (
        <>
          <img
            data-tip
            data-for="Show Task list"
            src={ExpandArrow}
            className="expand-section-arrow"
            alt="expand-arrow"
            onClick={() => {
              dispatch(expandSection(true));
            }}
          />
          <CustomToolTip text={"Show Task list"} place="right" />
        </>
      )}
      <div className={`${isTaskListExpanded ? "expanded" : "collapsed"}`}>
        <Header className="task-management">
          <div className="header-title">Task Board</div>
        </Header>
        <div className="middle-section">
          <GenericTabs
            tabsList={[
              {
                name: TaskManagementTab.ACTIVE,
              },
              {
                name: TaskManagementTab.SNOOZED,
              },
            ]}
            activeTabName={activeTabName}
            setActiveTabName={onClickTabName}
          />
        </div>
        <SearchBox
          icon="cross"
          className="search-box"
          iconClassName="search-icon"
          showIcon={searchText.length > 0}
          SearchBoxSize={22}
          onClick={() => {
            handleSearchText("");
          }}
          onTextChange={handleSearchText}
          placeholder="Search by name"
          text={searchText}
        />
        {areTasksLoading && getTasks().length === 0 ? (
          <div className="empty-state-container loader">
            <img src={Loading} alt="loading" />
          </div>
        ) : !areTasksLoading && getTasks().length === 0 && searchText === "" ? (
          <div className="no-task-found-container">
            <CommonState type={CommonStateType.NO_TASKS_AVAILABLE} />
          </div>
        ) : !areTasksLoading && getTasks().length === 0 && searchText !== "" ? (
          <CommonState type={CommonStateType.SEARCH_TASK_NOT_FOUND} />
        ) : (
          <TaskList tasks={getTasks()} isLoading={areTasksLoading} incrementPage={incrementPage} />
        )}
      </div>
    </div>
  );
};

export default TaskManagement;
