import Icon from "components/Icon/icon.component";
import React from "react";
import "./text-field.styles.scss";
type TextFieldProps = {
  label: string;
  className?: string;
  type: string;
  value: string | number;
  setValue: (label: string, value: string) => void;
  showError?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  onBlur?: () => void;
  placeholder?: string;
  inputClassName?: string;
  isPhoneNumber?: boolean;
  maxLength?: number;
  showErrorIcon?: boolean;
  inputSizeClassName?: string;
};

const TextField = ({
  label,
  className,
  type,
  value,
  setValue,
  showError,
  disabled,
  errorMessage,
  onBlur,
  placeholder,
  inputClassName,
  isPhoneNumber,
  maxLength,
  showErrorIcon = false,
  inputSizeClassName,
}: TextFieldProps) => {
  return (
    <div className={`text-input-field-container ${className ? className : ""}`}>
      <label className="field-title">{label}</label>
      <div className={`field-wrap ${inputSizeClassName ? inputSizeClassName : ""}`}>
        {isPhoneNumber && <input type="text" className="input-field phone-number" value="+1" disabled />}
        <input
          type={type ? type : "text"}
          className={`input-field ${showError ? "red-border" : "blue-border"} ${inputClassName ? inputClassName : ""}`}
          value={value}
          onChange={(event) => setValue(label, event.target.value)}
          onBlur={onBlur}
          min="0"
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
        />
        {showError && errorMessage && (
          <div className="error-wrap">
            {showErrorIcon && <Icon icon="error-message-icon" size={15} className="error-message-icon" />}
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextField;
