import CommonState from "components/common-state/common-state.component";
import CommonNote from "components/risk-assessment-form-inputs/common-note/common-note.component";
import RiskAssessmentFormInputs from "components/risk-assessment-form-inputs/risk-assessment-form-inputs";
import { Pages, Question } from "pages/task-management/risk-assessment/common/risk-assessment.types";
import Section from "pages/task-management/risk-assessment/section/section.component";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionsBySectionsAsync,
  getSectionsByPageAsync,
} from "state/feature/risk-assessment/risk-assessment.action";
import { getRiskAssessmentState } from "state/feature/risk-assessment/risk-assessment.slice";
import AlarmIcon from "shared/assets/images/clock-alarm.svg";
import { CommonStateType } from "components/common-state/common-state.enum";

const LaceQuestions = () => {
  const dispatch = useDispatch();
  const { isLoading, pages } = useSelector(getRiskAssessmentState);

  useEffect(() => {
    if (!pages.lace.sections || pages.lace.sections.length === 0) {
      dispatch(getSectionsByPageAsync({ pageId: Pages.Lace }));
    }
  }, [dispatch, pages.lace.sections]);

  useEffect(() => {
    const controller = new AbortController();
    return () => {
      controller?.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (pages.lace.sections && pages.lace.sections.length > 0 && pages.lace.sectionDetails.length === 0) {
      dispatch(getQuestionsBySectionsAsync({ sections: pages.lace.sections, pageId: Pages.Lace }));
    }
  }, [dispatch, pages.lace.sections, pages.lace.sectionDetails]);

  return (
    <div className="assessment-question-container">
      {(isLoading || pages.lace.isLoading) && <CommonState type={CommonStateType.LOADING} />}
      {!isLoading &&
        pages.lace &&
        pages.lace.sectionDetails &&
        pages.lace.sectionDetails.length > 0 &&
        pages.lace.sectionDetails.map((data: any) => {
          return (
            <Section key={data.sectionId} sectionHeading={""}>
              <>
                {data.questions &&
                  data.questions.length > 0 &&
                  data.questions.map((item: Question, index: any) => {
                    return (
                      <div key={item.questionId + "_" + index} className="question-section">
                        {item.questionType !== "conditional-section" && item.questionType !== "section-title" && (
                          <div className="question-section-title">{item.title}</div>
                        )}
                        <div className="question-section-checkbox-grid">
                          <RiskAssessmentFormInputs
                            questionId={item.questionId}
                            options={item.selectionValues}
                            inputType={item.questionType}
                            selectionType={item.selectionType}
                            className={`checkbox-${item.questionId}`}
                            pageId={Pages.Lace}
                            sectionId={data.sectionId}
                            questions={item.questions}
                            maxLength={item.maxLength}
                            qTitle={item.title}
                            order={item.order}
                            sectionOrder={data.order}
                            noteText={item.note}
                            alertText={item.alert}
                          />
                        </div>
                      </div>
                    );
                  })}
              </>
            </Section>
          );
        })}
    </div>
  );
};

export default LaceQuestions;
