export const LOGIN_FORM = {
  loginButtonTitle: "Login",
  placeholder: "Enter Email",
  label: "EMAIL",
  buttonLabel: "Login",
  passwordLabel: "PASSWORD",
  passwordPlaceholder: "Enter Password",
};

export const HOME_CONSTANTS = {
  remainingTimeSeconds: 600,
  remainingTimeMinutes: 2,
};

export const OTP_CONSTANTS = {
  OTP_Length: 4,
  OTP_Placeholder: "....",
  OTP_TimeRemaining: "Time remaining ",
  OTP_Button: "Verify OTP",
  OTP_Resend: "Resend OTP",
  OTP_Seconds_Check: 10,
};

export const TOAST_MESSAGES = {
  WRONG_OTP: "Invalid OTP. Please enter again",
  EXPIRE_OTP: "OTP has expired. Please click on Resend OTP to get a new one.",
  ERROR_OTP: "Something went wrong. Please try again.",
  USER_NOT_EXIST: "Login failed. Username and Password combination is incorrect.",
  USER_LOCKOUT: "Your account has been locked. Please try again after some time.",
  ERROR: "Something went wrong. Please try again.",
  SESSION_EXPIRED: "Session expired. Please login again.",
  NO_ACCOUNT_EXIST: "No account exists for this email address.",
  TOC_ERROR_INVALID_LOS: "Invalid LOS",
};

export const VALID_PASSWORD_SIZE = 8;
