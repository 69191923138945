import { HeaderColumnInfo } from "./types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getInitialEpisodesTableHeader } from "shared/constant/table";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import "./episodes.styles.scss";
import { getEpisodesAsync } from "state/feature/episodes/episodes.action";
import { batch, useDispatch, useSelector } from "react-redux";
import { resetEpisodesList, resetEpisodesState } from "state/feature/episodes/episodes.slice";
import { PAGE_LIMIT } from "shared/constant/commonConstants";
import { EpisodesRequestPayloadProps } from "state/types/episodes-slice.type";
import { getCommonState } from "state/feature/common/common.slice";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { getAuth } from "state/feature/auth/auth.slice";
import { checkIfFeatureEnabledAsync } from "state/feature/common/common.action";
import { useAppDispatch } from "state/store";
import { useHistory } from "react-router";
import EpisodesPresentation from "./episodes.component";
import { debounce } from "lodash";
import { EpisodesSelectedFilterType } from "components/Modal/EpisodesFilterModal/episodes-filter-modal.types";
import moment from "moment";
import { ITagsSearchDropdownType } from "shared/types/dropdown.type";
const Episodes = () => {
  useAxiosAuthenticated();
  const [headerColumnsInfo, setHeaderColumnsInfo] = useState<HeaderColumnInfo[]>(getInitialEpisodesTableHeader());
  const { featureFlags } = useSelector(getCommonState);
  const { user, isLoading: isLoadingUser } = useSelector(getAuth);
  const history = useHistory();
  const appDispatch = useAppDispatch();
  const [episodeRequestCalled, setEpisodeRequestCalled] = useState(false);

  const [selectedFilterList, setSelectedFilterList] = useState<EpisodesSelectedFilterType>({
    admitDate: [],
    surgeryDate: [],
    status: [
      { key: "NEW", name: "New" },
      { key: "PRO", name: "Processing" },
    ],
    physicians: [],
    tnav: [],
    pnav: [],
  });
  const initialRequestPayload = {
    searchText: "",
    limit: PAGE_LIMIT,
    offset: 0,
    status: [],
    physicians: [],
    patientNavigators: [],
    transitionNavigators: [],
    admitDateFrom: "",
    admitDateTo: "",
    surgeryDateFrom: "",
    surgeryDateTo: "",
    sortColumn: "patientName",
    sortOrder: SortingOrderType.ASC,
  };

  const initialClearFilterRequestPayload = {
    status: [],
    physicians: [],
    offset: 0,
    admitDateFrom: "",
    admitDateTo: "",
    surgeryDateFrom: "",
    surgeryDateTo: "",
    transitionNavigators: [],
    patientNavigators: [],
  };
  const [requestPayload, setRequestPayload] = useState<EpisodesRequestPayloadProps>(() => {
    return { ...initialRequestPayload, status: ["NEW", "PRO"] };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoadingUser && user.navigatorId !== undefined && user.navigatorId !== null) {
      appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "navigatorEpisodeList" })).then((res: any) => {
        if (!res.payload.data) {
          history.push("/not-found");
        }
      });
    }
  }, [appDispatch, user.navigatorId, isLoadingUser, history]);

  useEffect(() => {
    if (
      featureFlags.navigatorEpisodeList ||
      (!isLoadingUser && (user?.navigatorId === undefined || user?.navigatorId == null))
    ) {
      setEpisodeRequestCalled(true);
      if (requestPayload.offset === 0) {
        dispatch(resetEpisodesList());
      }
      dispatch(getEpisodesAsync(requestPayload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, requestPayload, featureFlags.navigatorEpisodeList]);

  const handleApplyClick = (filterList: EpisodesSelectedFilterType) => {
    setSelectedFilterList(filterList);
    const admitDateFrom =
      filterList.admitDate.length > 0 && filterList.admitDate[0]
        ? moment(filterList.admitDate[0].startDate).format("YYYY-MM-DD")
        : "";
    const admitDateTo =
      filterList.admitDate.length > 0 && filterList.admitDate[0]
        ? moment(filterList.admitDate[0].endDate).format("YYYY-MM-DD")
        : "";
    const surgeryDateFrom =
      filterList.surgeryDate.length > 0 && filterList.surgeryDate[0]
        ? moment(filterList.surgeryDate[0].startDate).format("YYYY-MM-DD")
        : "";
    const surgeryDateTo =
      filterList.surgeryDate.length > 0 && filterList.surgeryDate[0]
        ? moment(filterList.surgeryDate[0].endDate).format("YYYY-MM-DD")
        : "";
    const newRequestPayload = {
      offset: 0,
      admitDateFrom,
      admitDateTo,
      surgeryDateFrom,
      surgeryDateTo,
      status: filterList.status.map((val: ITagsSearchDropdownType) => val.key),
      physicians: filterList.physicians.map((val: ITagsSearchDropdownType) => val.key),
      transitionNavigators: filterList.tnav.map((val: ITagsSearchDropdownType) => val.key),
      patientNavigators: filterList.pnav.map((val: ITagsSearchDropdownType) => val.key),
    };
    setRequestPayload((prev) => {
      return {
        ...prev,
        ...newRequestPayload,
      };
    });
  };

  const handleFilterClearClick = () => {
    const newSelectedFilter = { admitDate: [], surgeryDate: [], status: [], physicians: [], tnav: [], pnav: [] };
    setSelectedFilterList(newSelectedFilter);
    setRequestPayload((prev) => {
      return { ...prev, ...initialClearFilterRequestPayload };
    });
  };

  const onIncrementPage = () => {
    setRequestPayload((prev) => {
      return {
        ...prev,
        offset: prev.offset + PAGE_LIMIT,
      };
    });
  };

  const onChangeSort = (key: string, order: SortingOrderType) => {
    setRequestPayload((prev) => {
      return {
        ...prev,
        offset: 0,
        sortColumn: key,
        sortOrder: order,
      };
    });
  };

  const handleColumnSorting = (selectedColumn: HeaderColumnInfo) => {
    const index = headerColumnsInfo.findIndex((x) => x.key === selectedColumn.key);
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (headerColumnsInfo[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeaders = [...headerColumnsInfo];
    tempHeaders.forEach((header) => (header.sortOrder = SortingOrderType.DEFAULT));
    tempHeaders[index].sortOrder = sort;
    batch(() => {
      onChangeSort(selectedColumn.key, sort);
    });
    setHeaderColumnsInfo(tempHeaders);
  };

  useEffect(() => {
    return () => {
      dispatch(resetEpisodesState());
    };
  }, [dispatch]);

  const onChangeSearchText = useCallback((text: string) => {
    setRequestPayload((prev) => {
      return {
        ...prev,
        offset: 0,
        searchText: text.trim(),
      };
    });
  }, []);
  const debouncedSearch = useMemo(() => {
    return debounce(onChangeSearchText, 1000);
  }, [onChangeSearchText]);

  const isFilterSearchApplied = () => {
    return (
      requestPayload.patientNavigators.length > 0 ||
      requestPayload.physicians.length > 0 ||
      requestPayload.searchText.length > 0 ||
      requestPayload.status.length > 0 ||
      requestPayload.admitDateFrom.length > 0 ||
      requestPayload.admitDateTo.length > 0 ||
      requestPayload.surgeryDateFrom.length > 0 ||
      requestPayload.surgeryDateTo.length > 0 ||
      requestPayload.transitionNavigators.length > 0
    );
  };

  const handleRemoveFilterPill = (key: string, value: any) => {
    setSelectedFilterList((prev) => {
      return { ...prev, [key]: [] };
    });
    const newRequestPayloadItemValue = key.toLowerCase().includes("date") ? "" : [];
    const requestPayloadKey = key === "pnav" ? "patientNavigators" : key === "tnav" ? "transitionNavigators" : key;

    setRequestPayload((prev) => {
      if (key.toLowerCase().includes("date")) {
        return { ...prev, offset: 0, surgeryDateFrom: "", surgeryDateTo: "", admitDateFrom: "", admitDateTo: "" };
      }
      return { ...prev, offset: 0, [requestPayloadKey]: newRequestPayloadItemValue };
    });
  };

  const isFilterApplied = () => {
    return (
      requestPayload.patientNavigators.length > 0 ||
      requestPayload.physicians.length > 0 ||
      requestPayload.status.length > 0 ||
      requestPayload.admitDateFrom.length > 0 ||
      requestPayload.admitDateTo.length > 0 ||
      requestPayload.surgeryDateFrom.length > 0 ||
      requestPayload.surgeryDateTo.length > 0 ||
      requestPayload.transitionNavigators.length > 0
    );
  };
  const getShowSearchIcon = useCallback(() => !!requestPayload.searchText, [requestPayload.searchText]);

  return (
    <div id="episodes-container">
      <EpisodesPresentation
        columnHeader={headerColumnsInfo}
        isEpisodeRequestCalled={episodeRequestCalled}
        handleColumnSorting={handleColumnSorting}
        isFilterSearchApplied={isFilterSearchApplied}
        onChangeSearchText={onChangeSearchText}
        onIncrementPage={onIncrementPage}
        debouncedSearch={debouncedSearch}
        getShowSearchIcon={getShowSearchIcon}
        handleRemoveFilterPill={handleRemoveFilterPill}
        handleFilterClearClick={handleFilterClearClick}
        handleApplyClick={handleApplyClick}
        selectedFilterList={selectedFilterList}
        isFilterApplied={isFilterApplied}
      />
    </div>
  );
};
export default Episodes;
