import React, { useEffect } from "react";
import "./toast-caller.styles.scss";
import Icon from "components/Icon/icon.component";
import { Call } from "@twilio/voice-sdk";
import { toast } from "react-toastify";
import { useTwilioClientService } from "shared/services/twilio/twilio-client.service";
import { CallPosition } from "shared/enums/call-position.enum";
import { formatPhoneNumber, getEndDate, getStartDate } from "shared/methods/utilityFunctions";
import {
  getCallState,
  incrementMinutes,
  incrementSeconds,
  setCallParticipantsText,
  setCallStatusText,
  setDialerInput,
  setIsMinimizeIncomingCall,
  setMinutes,
  setSeconds,
} from "state/feature/call/call.slice";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Button/button.component";
import { BrowserView, MobileView } from "react-device-detect";
import useIdleTimerContainer from "../IdleTimeout";
import { getNavigatorCallsAsync } from "state/feature/navigator/navigator.action";
import moment from "moment";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { PAGE_LIMIT } from "shared/constant/commonConstants";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";

const ToastCaller = ({ participants }: { participants: any[] }) => {
  const twilioClient = useTwilioClientService();
  const { currentCall, dialerInput, minutes, seconds, callStatusText, callParticipantsText, isMinimizeIncomingCall } =
    useSelector(getCallState);
  const { currentUserProfile } = useSelector(getNavigatorState);
  const dispatch = useDispatch();
  const { pauseTimer, resumeTimer } = useIdleTimerContainer();

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (currentCall?.status() == Call.State.Open) {
        if (participants && participants.length > 0) {
          dispatch(setCallStatusText("Call with"));
          dispatch(
            setCallParticipantsText(
              participants
                .map((participant) =>
                  participant.id ? `${participant.name}` : `${formatPhoneNumber(participant.phoneNumber)}`
                )
                .toString()
            )
          );
        } else {
          dispatch(setCallStatusText("Unknown Call"));
        }

        if (seconds >= 59) {
          dispatch(setSeconds(0));
          dispatch(incrementMinutes());
        } else {
          dispatch(incrementSeconds());
        }
      } else if ([Call.State.Pending, Call.State.Connecting, Call.State.Ringing].includes(currentCall?.status())) {
        pauseTimer();
        setCallStatusText("Connecting..");
        setCallParticipantsText("");
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [dispatch, seconds, currentCall, participants, pauseTimer]);

  const getMuteUnmuteButton = () => {
    if (currentCall && currentCall.isMuted()) {
      return <Icon className="mute" icon="mute" size={isMinimizeIncomingCall ? 42 : 50} />;
    } else if (currentCall && !currentCall.isMuted()) {
      return <Icon className="mute" icon="unmute" size={isMinimizeIncomingCall ? 42 : 50} />;
    } else {
      return <Icon className="mute" icon="mute" size={isMinimizeIncomingCall ? 42 : 50} />;
    }
  };

  const getSwitchPositionButton = () => {
    if (twilioClient.getCallPosition() == CallPosition.BottomLeft) {
      return (
        <div
          className="swap-position-icon-container"
          onClick={(e) => {
            twilioClient.switchCallPosition(CallPosition.BottomRight);
          }}
        >
          <Icon className="swap-position-icon" icon="position-change-right" size={16} />{" "}
        </div>
      );
    } else {
      return (
        <div
          className="swap-position-icon-container"
          onClick={(e) => {
            twilioClient.switchCallPosition(CallPosition.BottomLeft);
          }}
        >
          <Icon className="swap-position-icon" icon="position-change-left" size={16} />
        </div>
      );
    }
  };

  const disconnectCall = () => {
    const startDate = getStartDate(moment().subtract(7, "day").toDate());
    const endDate = getEndDate(new Date());
    twilioClient.disconnectConference().then(() => {
      setTimeout(() => {
        dispatch(
          getNavigatorCallsAsync({
            navigatorId: currentUserProfile?.id,
            startDate: startDate,
            endDate: endDate,
            callDirection: "",
            callStatus: [],
            searchText: "",
            sortColumn: "calltime",
            sortOrder: SortingOrderType.DESC,
            limit: PAGE_LIMIT,
            offset: 0,
            isNavigatorManager: false,
          })
        );
      });
    }, 200);
    toast.dismiss("call");
    resumeTimer();
  };

  const getDialerInput = () => {
    return dialerInput.split("").join("");
  };

  return (
    <div className="phone-floating-modal-content-container outgoing-call-modal-container">
      {!isMinimizeIncomingCall && <br></br>}
      <div className="incoming-call-details">
        <div className="call-title">
          <div className="call-title--text">
            {isMinimizeIncomingCall && callParticipantsText !== "" ? callParticipantsText : callStatusText}
          </div>
          {!isMinimizeIncomingCall && (
            <div onClick={() => dispatch(setIsMinimizeIncomingCall(!isMinimizeIncomingCall))} className="resize ">
              <Icon className="mute " icon={"minimize"} size={20} />
            </div>
          )}
        </div>
        {!isMinimizeIncomingCall && (
          <>
            <br></br>
            <div className="particiapants-title" data-tooltip={callParticipantsText}>
              {callParticipantsText.length > 30 ? callParticipantsText.substring(0, 30) + " .." : callParticipantsText}
            </div>
          </>
        )}
        {!isMinimizeIncomingCall && (
          <>
            <div className="timer">
              {minutes === 0 && seconds === 0 ? (
                "00:00"
              ) : (
                <>
                  {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </>
              )}
            </div>
            <div className="dialer-input-textbox-container">
              <span className="dialer-input">{getDialerInput()}</span>
              <Button
                buttonSize={23}
                icon="dialer-delete-icon"
                className={`delete-icon ${dialerInput.length === 0 ? "disabled" : ""}`}
                onClick={() => {
                  if (dialerInput.length !== 0) {
                    dispatch(setDialerInput({ type: "remove" }));
                  }
                }}
              />
            </div>
            <div className="dialer-grid">
              {["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"].map((number) => {
                return (
                  <Button
                    text={number}
                    className="number"
                    key={number}
                    onClick={(e: any) => {
                      dispatch(setDialerInput({ type: "input", text: e.target.value }));
                      twilioClient.sendDigits(e.target.value);
                    }}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
      <div className="buttons">
        <BrowserView className="buttons-desktop">
          {/* <div className="video-call-container">
          <Icon className="video-call" icon="video-call" size={50} />
        </div> */}
          {twilioClient.getCallPosition() == CallPosition.BottomRight && <div>{getSwitchPositionButton()}</div>}
          {twilioClient.getCallPosition() == CallPosition.BottomLeft && (
            <div
              data-tooltip="Mute/Unmute"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (currentCall.isMuted()) {
                  currentCall.mute(false);
                } else {
                  currentCall.mute(true);
                }
              }}
            >
              {getMuteUnmuteButton()}
            </div>
          )}
          {isMinimizeIncomingCall && (
            <div
              onClick={() => dispatch(setIsMinimizeIncomingCall(!isMinimizeIncomingCall))}
              className="resize maximize"
            >
              <Icon className="mute " icon={"maximize"} size={18} />
            </div>
          )}
          <div onClick={disconnectCall} data-tooltip="Disconnect call">
            <Icon className="cut-call" icon="cut-call1" size={isMinimizeIncomingCall ? 42 : 67} />
          </div>
          {twilioClient.getCallPosition() == CallPosition.BottomLeft && <div>{getSwitchPositionButton()}</div>}
          {twilioClient.getCallPosition() == CallPosition.BottomRight && (
            <div
              data-tooltip="Mute/Unmute"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (currentCall.isMuted()) {
                  currentCall.mute(false);
                } else {
                  currentCall.mute(true);
                }
              }}
            >
              {getMuteUnmuteButton()}
            </div>
          )}
        </BrowserView>
        <MobileView className="buttons-mobile">
          <div className="video-call-container">
            <Icon className="video-call" icon="video-call" size={50} />
          </div>
          {isMinimizeIncomingCall && (
            <div
              onClick={() => dispatch(setIsMinimizeIncomingCall(!isMinimizeIncomingCall))}
              className="resize maximize"
            >
              <Icon className="mute " icon={"maximize"} size={15} />
            </div>
          )}
          <div onClick={disconnectCall} data-tooltip="Disconnect call">
            <Icon className="cut-call1" icon="cut-call1" size={67} />
          </div>
          <div
            data-tooltip="Mute/Unmute"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (currentCall.isMuted()) {
                currentCall.mute(false);
              } else {
                currentCall.mute(true);
              }
            }}
          >
            {getMuteUnmuteButton()}
          </div>
        </MobileView>
      </div>
    </div>
  );
};

export default ToastCaller;
