import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { useEffect, useState } from "react";
import { EpisodesPresentationProps } from "./types";

import { useDispatch, useSelector } from "react-redux";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import EpisodesTable from "./components/episodes-table/episodes-table.component";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import Button from "components/Button/button.component";
import SearchBox from "components/SearchBox/search-box.component";
import Header from "components/Header/header.component";
import EpisodesFilterModal from "components/Modal/EpisodesFilterModal/episodes-filter-modal.component";
import { setIsFilterModal } from "state/feature/common/common.slice";
import EpisodeFilters from "./components/episode-filters/episode-filters.component";

const EpisodesPresentation = (props: EpisodesPresentationProps) => {
  const {
    columnHeader,
    isEpisodeRequestCalled,
    onChangeSearchText,
    isFilterSearchApplied,
    handleColumnSorting,
    onIncrementPage,
    getShowSearchIcon,
    debouncedSearch,
    handleApplyClick,
    handleFilterClearClick,
    handleRemoveFilterPill,
    selectedFilterList,
    isFilterApplied,
  } = props;
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const dispatch = useDispatch();
  const { isLoading, episodes, isLoadingEpisodeFilterStatus } = useSelector(getEpisodesSlice);

  const [hasScrollbarVisible, setHasScrollbarVisible] = useState(false);
  const updateState = () => {
    const InfiniteScrollInnerDivElement = document.querySelector(
      ".episodes-listing-content .infinite-scroll-component"
    );
    if (InfiniteScrollInnerDivElement) {
      setHasScrollbarVisible(InfiniteScrollInnerDivElement.scrollHeight > InfiniteScrollInnerDivElement.clientHeight);
    }
  };
  useEffect(() => {
    updateState();
    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, [episodes, isLoading]);

  return (
    <>
      <EpisodesFilterModal
        handleFilterApplyClick={handleApplyClick}
        handleFilterClearClick={handleFilterClearClick}
        selectedValues={selectedFilterList}
      />
      <Header className="episodes">
        <div className="header-title">Episodes</div>
        <div className="right-section">
          {isSearching ? (
            <SearchBox
              icon="cross"
              className="search-box"
              iconClassName="search-icon"
              SearchBoxSize={22}
              placeholder="Search by patient name"
              showIcon={getShowSearchIcon()}
              text={searchText}
              onClick={() => {
                setIsSearching(false);
                onChangeSearchText("");
                setSearchText("");
              }}
              onTextChange={(text: string) => {
                setSearchText(text);
                debouncedSearch(text);
              }}
            />
          ) : (
            <div data-tip data-for={"Search"}>
              <Button
                icon="search"
                buttonSize={14}
                className={`search-icon-container ${
                  (episodes.length === 0 && !isFilterSearchApplied()) || isLoading ? "disabled" : ""
                }`}
                iconClassName="search-icon"
                disabled={(episodes.length === 0 && !isFilterSearchApplied()) || isLoading}
                onClick={() => {
                  if (!((episodes.length === 0 && !isFilterSearchApplied()) || isLoading)) {
                    setIsSearching(true);
                  }
                }}
              />
              <CustomToolTip text={"Search"} place="bottom" />
            </div>
          )}
          <div data-tip data-for="Filter">
            <Button
              icon="filter"
              className={`filter-icon-container ${
                isLoading || isLoadingEpisodeFilterStatus || (episodes.length === 0 && !isFilterSearchApplied())
                  ? "disabled"
                  : ""
              }`}
              iconClassName="filter-icon"
              buttonSize={14}
              disabled={episodes.length === 0 || isLoading}
              onClick={() => {
                if (!((episodes.length === 0 && !isFilterSearchApplied()) || isLoading)) {
                  dispatch(setIsFilterModal(true));
                }
              }}
            />
            <CustomToolTip text={"Filter"} place="bottom" />
          </div>
        </div>
      </Header>
      <EpisodeFilters
        handleFilterClearClick={handleFilterClearClick}
        handleRemoveFilterPill={handleRemoveFilterPill}
        isFilterApplied={isFilterApplied}
        selectedFilterList={selectedFilterList}
      />
      {isEpisodeRequestCalled && !isLoading && episodes.length === 0 && !isFilterSearchApplied() ? (
        <CommonState type={CommonStateType.NO_EPISODES_FOUND} />
      ) : (
        <EpisodesTable
          columnHeader={columnHeader}
          currentTableData={episodes}
          hasScrollbarVisible={hasScrollbarVisible}
          isLoading={isLoading}
          handleColumnSorting={handleColumnSorting}
          isFilterSearchApplied={isFilterSearchApplied}
          onIncrementPage={onIncrementPage}
          isFilterApplied={isFilterApplied}
        />
      )}
    </>
  );
};

export default EpisodesPresentation;
