import { Link } from "react-router-dom";
import "./breadcrumb.styles.scss";

const Breadcrumb = ({ pathname }: { pathname: string }) => {
  const individualLinks = pathname.split("/");

  const getBreadCrumbName = (name: string) => {
    if (name === "new-navigator") {
      return "New Navigator";
    }

    if (name === "schedule") {
      return "Set On-call Navigator";
    }

    return name;
  };

  return (
    <nav aria-label="breadcrumb">
      <ul className="breadcrumb">
        {individualLinks.map((name, index) => {
          if (name !== "") {
            return (
              <li
                key={name}
                className={`breadcrumb-item ${index === individualLinks.length - 1 ? "inactive" : "active"}`}
                aria-current="page"
              >
                <Link to={`/${name}`}>{index > 1 ? <div className="name">{getBreadCrumbName(name)}</div> : name}</Link>
              </li>
            );
          }
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
