import { IConfig } from "./config";
/**
 * These values can be updated as needed. These values are only
 * used during local development
 */
export const developmentConfig: IConfig = {
  overrideConfigurationUrl: false,
  messagingBase: "https://esmessagingdev.azurewebsites.net",
  navigatorBase: "https://esnavigatordev.azurewebsites.net",
  telephonyBase: "https://estelephonydev.azurewebsites.net",
  taskManagementBase: "https://localhost:7285",
  authenticationBase: "https://devapppserviceauthenticationf5.azurewebsites.net",
  userManagementBase: "https://devapimanagementf5.azure-api.net/user-management",
  notificationBase: "https://esappnotificationdev.azurewebsites.net",
  efusionBase: "https://devmainvm2.fusion5.us",
  subscriptionKey: "0cbf58e25f784625adc48059e44e7ef8",
  configurationUrl:
    "Endpoint=https://es-dev-ui-app-config.azconfig.io;Id=ajat-l4-s0:Mvr6tJbi5gFO5CfnTwNT;Secret=wNzfZCurW7yVhxCiAKKWtjoH9WRnLIdzOnaL2OgsEoY=",
  countryCode: "+1, +91",
  environment: "qa",
};
