import DropDown from "components/dropdown/dropdown.component";
import { RiskAssessmentContext } from "pages/task-management/risk-assessment/context/risk-assessment.context";
import {
  Pages,
  QuestionType,
  SelectionType,
  SelectionValue,
  Question,
} from "pages/task-management/risk-assessment/common/risk-assessment.types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Pill } from "./pill/pill.component";
import Pills from "./pills/pills.component";
import CommonNote from "./common-note/common-note.component";
import "./risk-assessment-form-inputs.styles.scss";

import Collapse from "components/collapse/collapse.component";
import { useDispatch, useSelector } from "react-redux";
import SingleDatePicker from "components/single-date-picker/single-date-picker.component";
import moment from "moment";
import Icon from "components/Icon/icon.component";
import CheckoxContainer from "./checkbox-container/checkbox-container";
import { isEmptyString } from "shared/methods/utilityFunctions";
import {
  getRiskAssessmentState,
  removeFromUnansweredSections,
  setIsValueChanged,
  setUnansweredSections,
} from "state/feature/risk-assessment/risk-assessment.slice";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";

type RiskAssessmentFormInputProps = {
  sectionId?: string | null;
  questionId?: string;
  inputType?: QuestionType;
  selectionType?: SelectionType;
  className?: string;
  options?: SelectionValue[];
  title?: string | null;
  questions?: Question[];
  pageId?: Pages;
  maxLength?: number;
  qTitle?: string | null;
  order?: number | null;
  sectionOrder?: number | null;
  noteText?: string | null;
  alertText?: string | null;
  isConclusion?: boolean | null;
  placeHolder?: string;
};

const Error = ({ message }: { message?: string }) => {
  return (
    <div className="not-selected-error-message">
      <div>
        <Icon icon="error-message-icon" size={15} />
      </div>
      {message ? <div>{message}</div> : <div>Select an answer from the above options</div>}
    </div>
  );
};

const RiskAssessmentFormInputs = (props: RiskAssessmentFormInputProps) => {
  const { selectedValues, pages, collapsibleSections } = useSelector(getRiskAssessmentState);
  const { patientDetails } = useSelector(getTaskManagementState);
  const dispatch = useDispatch();
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState<any | null>(null);
  const [nextQuestionIdValue, setNextQuestionIdValue] = useState<any | null>(null);
  const { onChangeDays, onChangeAge, onChangeDate, onChangeText, onChangeCheckbox } = useContext(RiskAssessmentContext);
  const {
    questions,
    questionId,
    className,
    inputType,
    selectionType,
    options,
    title,
    sectionId,
    pageId,
    maxLength,
    qTitle,
    order,
    sectionOrder,
    noteText,
    alertText,
    isConclusion,
    placeHolder,
  } = props;

  const getPage = useCallback(() => {
    switch (pageId) {
      case Pages.General:
        return "general";
      case Pages.Lace:
        return "lace";
      case Pages.Closing:
        return "closing";
      case Pages.Additional:
        return "additional";
    }
    return "general";
  }, [pageId]);

  const isSectionValidationEnabled = () => {
    const collapsibleSection = collapsibleSections.find((section) => section.sectionId === sectionId);
    if (!collapsibleSection) {
      return true;
    }
    return collapsibleSection.isDirty;
  };

  const isNextQuestionAvailable = useCallback(() => {
    let isAvailable = false;
    currentSelectedOptions.forEach((option: any) => {
      if (option.nextQuestionId) {
        isAvailable = true;
      }
    });
    return isAvailable;
  }, [currentSelectedOptions]);

  useEffect(() => {
    if (selectedValues && selectedValues.length > 0) {
      const filteredSections = selectedValues.filter((x) => x.sectionId === sectionId);
      if (filteredSections && filteredSections.length > 0) {
        const currentSection = filteredSections[0];
        if (currentSection.answers && Object.keys(currentSection.answers).length > 0) {
          const answer = currentSection.answers[questionId ?? ""];
          setCurrentSelectedOptions(answer);
        } else {
          setCurrentSelectedOptions(null);
        }
      } else {
        setCurrentSelectedOptions(null);
      }
    } else {
      setCurrentSelectedOptions(null);
    }
  }, [selectedValues, sectionId, questionId]);

  useEffect(() => {
    if (patientDetails?.patientId && inputType === "age-selector" && !currentSelectedOptions) {
      onChangeAge(questionId, sectionId, questionId, pageId, order, sectionOrder, patientDetails.age.toString() ?? "0");
    }
  }, [
    patientDetails,
    questionId,
    order,
    pageId,
    sectionId,
    sectionOrder,
    onChangeAge,
    inputType,
    currentSelectedOptions,
  ]);

  useEffect(() => {
    if (currentSelectedOptions) {
      dispatch(
        removeFromUnansweredSections({
          pageId,
          sectionId,
          questionId,
          clearChildQuestions: !isNextQuestionAvailable(),
        })
      );
    }
  }, [currentSelectedOptions, dispatch, pageId, sectionId, questionId, isNextQuestionAvailable]);

  const setUnansweredQuestion = useCallback(async () => {
    if (inputType !== "conditional-section" && inputType !== "section-title") {
      if (
        !currentSelectedOptions ||
        currentSelectedOptions[0]?.value === "" ||
        (questionId === "los" &&
          (currentSelectedOptions[0]?.value === "" ||
            currentSelectedOptions[0]?.value < 1 ||
            currentSelectedOptions[0]?.value > 50))
      ) {
        if (typeof isConclusion !== "boolean" && !isConclusion) {
          dispatch(
            setUnansweredSections({
              pageId,
              sectionId,
              questionId,
            })
          );
        }
      }
    }
  }, [dispatch, pageId, sectionId, questionId, inputType, currentSelectedOptions, isConclusion]);

  useEffect(() => {
    setUnansweredQuestion();
  }, [setUnansweredQuestion]);

  const isChildQuestionVisible = () => {
    return nextQuestionIdValue !== null ? "visible" : "hidden";
  };

  switch (inputType) {
    case "pill":
      return (
        <>
          <div className="question-section-checkbox-grid">
            <Pills options={options}>
              {({ id, title: pillTitle, order: orderEl, nextQuestionId, showSection, conditionalSectionId }) => {
                return (
                  <>
                    <Pill
                      id={id}
                      sectionId={sectionId}
                      title={pillTitle}
                      questionId={questionId}
                      order={orderEl}
                      className={className}
                      selectionType={selectionType}
                      nextQuestionId={nextQuestionId}
                      conditionalSectionId={conditionalSectionId}
                      showSection={showSection}
                      pageId={pageId}
                      currentSelectedOptions={currentSelectedOptions}
                      questionOrder={order}
                      sectionOrder={sectionOrder}
                      setNextQuestionIdValue={(val) => {
                        setNextQuestionIdValue((prev: any) => (prev = val));
                      }}
                    />
                  </>
                );
              }}
            </Pills>
          </div>
          {/** check the question id with the question id in the state and next button is clicked then show this component */}
          {!pages[getPage()].isLoading &&
            !pages[getPage()].isSavingDraft &&
            pages[getPage()].isValidationEnabled &&
            isSectionValidationEnabled() &&
            !currentSelectedOptions && <Error />}
          {questions && questions.length > 0 && (
            <div
              id={`${sectionId}-${questionId}-extra-questions`}
              className={"question-section-checkbox-grid" + isChildQuestionVisible()}
            >
              <div className={`${questions && questions.length > 0 ? "nested-pill-questions" : ""}`}>
                {questions.map(
                  ({
                    questionId: id,
                    questionType,
                    selectionValues,
                    selectionType: selectType,
                    title: questionTitle,
                    note,
                    subTitle,
                    maxLength: mLength,
                    questions: moreQuestions,
                  }) => {
                    const selected = selectedValues
                      .map((values) => Object.entries(values.answers).find((answer) => answer[0] === id && note))
                      .flat()
                      .filter((result) => result !== undefined || null);

                    const isNoteVisible =
                      selectionValues && selectionValues.filter((select) => select.showNote === true);
                    if (currentSelectedOptions?.some((x: any) => x.nextQuestionId === id)) {
                      return (
                        <>
                          <div
                            className={`extra-section-1 ${
                              subTitle && questionType === "pill" ? "extra-section-pill" : ""
                            }`}
                          >
                            <React.Fragment key={`${questionId}_${id}`}>
                              {subTitle && questionType === "pill" && (
                                <div className="pill-option-title">{subTitle}</div>
                              )}
                              <RiskAssessmentFormInputs
                                options={selectionValues}
                                inputType={questionType}
                                selectionType={selectType}
                                className={`${questionType}-${id?.replaceAll(".", "-")}`}
                                questionId={id}
                                title={subTitle}
                                maxLength={mLength}
                                questions={moreQuestions}
                                sectionId={sectionId}
                                pageId={pageId}
                                order={order}
                              />
                            </React.Fragment>
                          </div>
                          {note &&
                            selected &&
                            isNoteVisible &&
                            selected.length > 0 &&
                            isNoteVisible.length > 0 &&
                            selected[1][0].id === isNoteVisible[0].id &&
                            selected[1][0].value === isNoteVisible[0].title && <CommonNote reminder={note} />}
                        </>
                      );
                    }
                  }
                )}
              </div>
            </div>
          )}
        </>
      );

    case "text-area":
      return (
        <div
          className={`question-section-checkbox-grid text-area-main-container ${
            isConclusion ? "full-width-text-area-container" : ""
          }`}
        >
          <div className={`text-area-container ${isConclusion ? "full-width-text-area" : ""}`}>
            {title && <div className="text-area-title">{title}</div>}
            <textarea
              className="text-area"
              id={`textarea`}
              name={`textarea`}
              maxLength={maxLength}
              cols={70}
              rows={10}
              placeholder={placeHolder}
              defaultValue={
                currentSelectedOptions && currentSelectedOptions.length > 0 ? currentSelectedOptions[0].value : ""
              }
              onChange={(event: any) => {
                dispatch(setIsValueChanged(true));
                onChangeText(
                  event.target.value,
                  sectionId ?? "",
                  questionId ?? "",
                  pageId,
                  order,
                  sectionOrder,
                  isConclusion
                );
              }}
            />
          </div>
          <div className={`text-area-container-max-length ${isConclusion ? "right-aligned" : ""}`}>
            {maxLength && <div className="text-area-title">{"Maximum " + maxLength + " characters"}</div>}
          </div>
          {!pages[getPage()].isLoading &&
            !pages[getPage()].isSavingDraft &&
            pages[getPage()].isValidationEnabled &&
            isSectionValidationEnabled() &&
            (!currentSelectedOptions || currentSelectedOptions[0]?.value === "") && (
              <Error message="This field is required" />
            )}
        </div>
      );

    case "dropdown":
      if (selectionType === "single-select") {
        const showNote =
          currentSelectedOptions && currentSelectedOptions.length > 0
            ? options?.find((option) => option.id === currentSelectedOptions[0].id)?.showNote
            : false;
        const showAlert =
          currentSelectedOptions && currentSelectedOptions.length > 0
            ? options?.find((option) => option.id === currentSelectedOptions[0].id)?.showAlert
            : false;

        return (
          <>
            <div className="question-section-checkbox-grid">
              <div className={`${selectionType}-dropdown-container`}>
                {title && <p className="dropdown-title">{title}</p>}
                <DropDown
                  questionId={questionId}
                  sectionId={sectionId}
                  placeholder="Select Value"
                  dropDownMenuItems={
                    options && options.length > 0
                      ? options.map(({ title: dropdownTitle, id, nextQuestionId }) => {
                          return {
                            id,
                            value: dropdownTitle,
                            nextQuestionId,
                          };
                        })
                      : []
                  }
                  defaultValue={
                    currentSelectedOptions && currentSelectedOptions.length > 0 ? currentSelectedOptions[0] : null
                  }
                  idFieldName="id"
                  dropDownBoxClass="dropdown-box"
                  selectionClass="selection-style"
                  dropDownContainerClass="option-dropdown-container"
                  pageId={pageId}
                  questionOrder={order}
                  sectionOrder={sectionOrder}
                />
              </div>
            </div>
            {noteText && showNote && <CommonNote reminder={noteText} />}
            {alertText && showAlert && <CommonNote alert={alertText} />}
            {!pages[getPage()].isLoading &&
              !pages[getPage()].isSavingDraft &&
              pages[getPage()].isValidationEnabled &&
              isSectionValidationEnabled() &&
              !currentSelectedOptions && <Error />}
          </>
        );
      } else {
        return <div></div>;
      }

    case "checkbox":
      return (
        <>
          {title && <div className="checkbox-options-title">{title}</div>}
          <div>
            {options?.map(({ id: optionId, title: checkboxTitle, nextQuestionId }) => (
              <>
                <CheckoxContainer
                  key={`checkbox-${optionId}`}
                  value={checkboxTitle}
                  sectionId={sectionId}
                  name={`${questionId}`}
                  onChange={(e: any) => {
                    if (nextQuestionId) {
                      setNextQuestionIdValue(e.target.checked ? nextQuestionId : null);
                    }
                    onChangeCheckbox(
                      e,
                      optionId,
                      checkboxTitle,
                      sectionId ?? "",
                      questionId ?? "",
                      nextQuestionId,
                      pageId,
                      order,
                      sectionOrder
                    );
                    dispatch(setIsValueChanged(true));
                  }}
                  id={optionId}
                />
                {questions && questions.length > 0 && (
                  <>
                    {questions.map(
                      ({
                        questionId: id,
                        questionType,
                        selectionValues,
                        selectionType: selectType,
                        note,
                        subTitle,
                        maxLength: mLength,
                        questions: moreQuestions,
                      }) => {
                        const selected = selectedValues
                          .map((values) => Object.entries(values.answers).find((answer) => answer[0] === id && note))
                          .flat()
                          .filter((result) => result !== undefined || null);

                        const isNoteVisible =
                          selectionValues && selectionValues.filter((select) => select.showNote === true);

                        if (
                          currentSelectedOptions?.some((x: any) => x.nextQuestionId === id) &&
                          id === nextQuestionId
                        ) {
                          return (
                            <div id={`${sectionId}-${questionId}-extra-questions`} className="checkbox-extra-section">
                              <RiskAssessmentFormInputs
                                key={`checkbox-extra-question-${id}`}
                                questionId={id}
                                options={selectionValues}
                                inputType={questionType}
                                selectionType={selectType}
                                className={`${questionType}-${id?.replaceAll(".", "-")}`}
                                title={subTitle}
                                questions={moreQuestions}
                                sectionId={sectionId}
                                maxLength={mLength}
                                pageId={pageId}
                                order={order}
                                sectionOrder={sectionOrder}
                              />
                              {note &&
                                selected &&
                                isNoteVisible &&
                                selected.length > 0 &&
                                isNoteVisible.length > 0 &&
                                selected[1][0].id === isNoteVisible[0].id &&
                                selected[1][0].value === isNoteVisible[0].title && <CommonNote reminder={note} />}
                            </div>
                          );
                        }
                      }
                    )}
                  </>
                )}
              </>
            ))}
          </div>
          {!pages[getPage()].isLoading &&
            !pages[getPage()].isSavingDraft &&
            pages[getPage()].isValidationEnabled &&
            isSectionValidationEnabled() &&
            !currentSelectedOptions && <Error />}
        </>
      );

    case "days-selector":
      return (
        <div>
          <div className="days-selector-container">
            <input
              name={`${questionId}`}
              type="text"
              className="days-selector"
              onChange={(event) => {
                const re = /^[1-9][0-9]*$/;
                if (isEmptyString(event.target.value) || event.target.value == "0" || re.test(event.target.value)) {
                  dispatch(setIsValueChanged(true));
                  onChangeDays(event, questionId, sectionId, questionId, pageId, order, sectionOrder);
                }
              }}
              maxLength={2}
              value={currentSelectedOptions && currentSelectedOptions.length > 0 ? currentSelectedOptions[0].value : ""}
            />
            <label className="days-selector-label">days</label>
          </div>
          {!pages[getPage()].isLoading &&
            !pages[getPage()].isSavingDraft &&
            pages[getPage()].isValidationEnabled &&
            isSectionValidationEnabled() &&
            (!currentSelectedOptions ||
              (currentSelectedOptions?.length > 0 &&
                (currentSelectedOptions[0]?.value == "" ||
                  currentSelectedOptions[0]?.value < 1 ||
                  currentSelectedOptions[0]?.value > 50))) && (
              <Error
                message={
                  !currentSelectedOptions || currentSelectedOptions[0]?.value == ""
                    ? "This field is required"
                    : "LOS can be minimum 1 and maximum 50"
                }
              />
            )}
        </div>
      );

    case "date-selector":
      return (
        <>
          <div className="days-selector-container">
            {title && <div className="checkbox-options-title">{title}</div>}
            <SingleDatePicker
              defaultDate={
                currentSelectedOptions && currentSelectedOptions.length > 0 ? currentSelectedOptions[0].value : null
              }
              minDate={new Date()}
              maxDate={null}
              isDisabled={false}
              placeholder="Select Date"
              isError={false}
              handleDateChange={(val: any) => {
                dispatch(setIsValueChanged(true));
                onChangeDate(val ? moment(val) : "", sectionId ?? "", questionId ?? "", pageId, order, sectionOrder);
              }}
            />
          </div>
          {!pages[getPage()].isLoading &&
            !pages[getPage()].isSavingDraft &&
            pages[getPage()].isValidationEnabled &&
            isSectionValidationEnabled() &&
            !currentSelectedOptions && <Error />}
        </>
      );

    case "conditional-section":
      return <Collapse heading={qTitle ?? ""} conditionalSectionId={questionId} pageId={pageId} />;

    case "section-title":
      return <div className="section-question-heading">{qTitle}</div>;

    case "age-selector":
      return (
        <div>
          <div className="age-selector-container">
            <input
              name={`${questionId}`}
              type="text"
              disabled
              className="age-selector"
              maxLength={2}
              value={patientDetails?.age ?? "N/A"}
            />
            <label className="age-selector-label">years</label>
          </div>
        </div>
      );
  }

  return <div></div>;
};

export default RiskAssessmentFormInputs;
