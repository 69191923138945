import { createSlice } from "@reduxjs/toolkit";
import { ITaskManagementState } from "state/types/task-management-slice.type";
import {
  getIsIntakeExists,
  getTasksAsync,
  getPatientAndEpisodeDetailsAysnc,
  calculateRisk,
} from "./task-management.action";

const initialState: ITaskManagementState = {
  isLoading: true,
  isRightPanelLoading: true,
  isTaskListExpanded: true,
  tasks: {
    active: [],
    snoozed: [],
  },
  selectedTaskId: "",
  isValidIntake: false,
  allowIntakeAcess: true,
  isValidatingIntake: false,
  patientDetails: null,
  episode: null,
  upcomingTask: [],
  attachments: [],
  activity: [],
  openedTasks: {
    currentOpenTask: [],
    allOpenTasks: [],
  },
  areTasksLoading: false,
};

const taskManagementSlice = createSlice({
  name: "task-management",
  initialState: Object.assign({}, initialState),
  reducers: {
    setIsValidIntake(state, action) {
      state.isValidIntake = action.payload;
    },
    saveTaskId(state, action) {
      state.selectedTaskId = action.payload;
    },
    expandSection(state, action) {
      state.isTaskListExpanded = action.payload;
    },
    resetTasks(state) {
      state.selectedTaskId = "";
      state.isValidIntake = false;
      state.isValidatingIntake = false;
      state.episode = null;
      state.upcomingTask = [];
      state.attachments = [];
      state.activity = [];
      state.tasks = {
        active: [],
        snoozed: [],
      };
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getIsIntakeExists.pending, (state) => {
        state.isValidIntake = false;
        state.isLoading = true;
        state.isValidatingIntake = true;
        state.allowIntakeAcess = true;
      }),
      builder.addCase(getIsIntakeExists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isValidatingIntake = false;
        if (action.payload) {
          state.isValidIntake = true;
          state.patientDetails = action.payload;
        } else {
          state.isValidIntake = false;
        }
      }),
      builder.addCase(getIsIntakeExists.rejected, (state, action) => {
        state.isLoading = false;
        state.isValidIntake = false;
        state.isValidatingIntake = false;
        if ((action.payload as any).status === 403) {
          state.allowIntakeAcess = false;
        }
      }),
      builder.addCase(getTasksAsync.pending, (state) => {
        state.areTasksLoading = true;
      }),
      builder.addCase(getTasksAsync.fulfilled, (state, action) => {
        state.areTasksLoading = false;
        if (action.meta.arg.isSnoozed) {
          const snoozedTasks =
            action.meta.arg.offset === 0 ? action.payload.data : [...state.tasks.snoozed, ...action.payload.data];
          state.tasks = {
            active: [],
            snoozed: snoozedTasks,
          };
        } else {
          const activeTasks =
            action.meta.arg.offset === 0 ? action.payload.data : [...state.tasks.active, ...action.payload.data];
          state.tasks = {
            active: activeTasks,
            snoozed: [],
          };
        }
      }),
      builder.addCase(getTasksAsync.rejected, (state, action) => {
        state.areTasksLoading = false;
        if (action.meta.arg.offset === 0) {
          state.tasks = { active: [], snoozed: [] };
        }
      }),
      builder.addCase(getPatientAndEpisodeDetailsAysnc.pending, (state) => {
        state.isRightPanelLoading = true;
      }),
      builder.addCase(getPatientAndEpisodeDetailsAysnc.fulfilled, (state, action) => {
        state.isRightPanelLoading = false;
        state.episode = action.payload;
      }),
      builder.addCase(getPatientAndEpisodeDetailsAysnc.rejected, (state, action) => {
        state.isRightPanelLoading = false;
        state.episode = null;
      }),
      builder.addCase(calculateRisk.pending, (state) => {}),
      builder.addCase(calculateRisk.fulfilled, (state, action) => {
        if (state.episode) {
          state.episode.statusAndAlerts.riskLevel = action.payload;
        }
      })
    );
  },
});
export const { expandSection, saveTaskId, resetTasks, setIsValidIntake } = taskManagementSlice.actions;
export const getTaskManagementState = (state: any): ITaskManagementState => {
  return state.taskManagement;
};
export default taskManagementSlice;
