import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import "./compose.styles.scss";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { useAppDispatch } from "state/store";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { getAuth } from "state/feature/auth/auth.slice";
import ComposePresentation from "./compose.component";
import { ISelectedDropdownValue } from "state/types/navigator.type";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { defaultPatientValue, GENERAL } from "./compose.constant";
import {
  getPatientContactAsync,
  getPatientNavigatorListAsync,
  getPhysicianContactAsync,
} from "state/feature/messaging/messaging.action";
import { getMessagingState } from "state/feature/messaging/messaging.slice";
import moment from "moment";
import { OpenFrom } from "shared/enums/chat.enum";
import { getCommonState } from "state/feature/common/common.slice";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";
import { CONTACT_LIMIT, PHYSICIAN_LIMIT } from "pages/contact/common/constant";
import { IGetContactsAsyncRequestPayload } from "state/types/contact-slice.type";

export const Compose = forwardRef((props, ref) => {
  useAxiosAuthenticated();
  const dispatch = useAppDispatch();
  const authState = useSelector(getAuth);
  const messagingState = useSelector(getMessagingState);
  const commonState = useSelector(getCommonState);
  const { managerStats } = useSelector(getNavigatorState);
  const { episode } = useSelector(getTaskManagementState);
  const [selectedNavigator, setSelectedNavigator] = useState<ISelectedDropdownValue>({
    key: "",
    name: "",
    label: "",
    value: "",
  });
  const [requestPatientPayload, setRequestPatientPayload] = useState<IGetContactsAsyncRequestPayload>({
    id: "",
    isCareManagerId: true,
    pageSize: CONTACT_LIMIT,
    pageNumber: 1,
    searchText: "",
    sortColumn: "name",
    sortOrder: SortingOrderType.ASC,
    allPatients: true,
  });
  const [requestPhysicianPayload, setRequestPhysicianPayload] = useState<IGetContactsAsyncRequestPayload>({
    id: "",
    isCareManagerId: true,
    pageSize: PHYSICIAN_LIMIT,
    pageNumber: 1,
    searchText: "",
    sortColumn: "name",
    sortOrder: SortingOrderType.ASC,
    patientId: "",
  });
  const [selectedPatient, setSelectedPatient] = useState<ISelectedDropdownValue>(defaultPatientValue);
  const [patientCheckbox, setPatientCheckbox] = useState(false);
  const [selectedPhysicians, setSelectedPhysicians] = useState([]);
  const [isSearchable, setIsSearchable] = useState(false);
  const [physicianSearchText, setPhysicianSearchText] = useState("");

  const onChangeNavigator = (value: ISelectedDropdownValue) => {
    setSelectedNavigator(value);
    setSelectedPhysicians([]);
    setIsSearchable(false);
    setSelectedPatient(defaultPatientValue);
    setPatientCheckbox(false);
    setRequestPatientPayload({
      id: value.key,
      isCareManagerId: true,
      pageSize: CONTACT_LIMIT,
      pageNumber: 1,
      searchText: "",
      sortColumn: "name",
      sortOrder: SortingOrderType.ASC,
    });
  };

  const onChangePatient = (value: any) => {
    setSelectedPatient(value);
    setSelectedPhysicians([]);
    if (value === null || value.key !== GENERAL.toLowerCase()) {
      setIsSearchable(true);
      setPatientCheckbox(false);
    } else {
      setIsSearchable(false);
      setPatientCheckbox(false);
    }
    if (value !== null) {
      setRequestPhysicianPayload({
        id: selectedNavigator.key,
        isCareManagerId: true,
        pageSize: PHYSICIAN_LIMIT,
        pageNumber: 1,
        searchText: "",
        sortColumn: "name",
        sortOrder: SortingOrderType.ASC,
        patientId: value.patientID,
      });
    }
  };

  const onChangePatientSearch = (value: string) => {
    return dispatch(getPatientContactAsync({ ...requestPatientPayload, searchText: value }));
  };

  const onChangePatientCheckox = (value: boolean) => {
    setPatientCheckbox(value);
  };

  const onChangeSelectedPhysicians = (val: any) => {
    setSelectedPhysicians(val);
  };

  const onChangePhysicianSearchText = (value: string) => {
    setPhysicianSearchText(value);
  };

  // All useEffects

  useEffect(() => {
    dispatch(getPatientNavigatorListAsync({ types: ["PNAV"] }));
  }, [dispatch]);

  useEffect(() => {
    if (
      commonState.modal.isOpenChatModal.openFrom === OpenFrom.COMPOSE ||
      commonState.modal.isOpenChatModal.openFrom === OpenFrom.NOTIFICATION_PANEL
    ) {
      onChangeNavigator({
        key: authState.user.navigatorId,
        name: `${authState.user.firstName} ${authState.user.lastName}`,
        label: `${authState.user.firstName} ${authState.user.lastName}`,
        value: `${authState.user.firstName} ${authState.user.lastName}`,
        ...authState.user,
      });
    } else if (commonState.modal.isOpenChatModal.openFrom === OpenFrom.CONTACT) {
      setSelectedNavigator({
        key: managerStats.selectedNavigator.key,
        name: managerStats.selectedNavigator.name,
        label: managerStats.selectedNavigator.name,
        value: managerStats.selectedNavigator.name,
      });
      setSelectedPhysicians([]);
      setIsSearchable(false);
      setSelectedPatient(defaultPatientValue);
      setTimeout(() => setPatientCheckbox(() => true), 700);
      setRequestPatientPayload({
        id: managerStats.selectedNavigator.key,
        isCareManagerId: true,
        pageSize: CONTACT_LIMIT,
        pageNumber: 1,
        searchText: "",
        sortColumn: "name",
        sortOrder: SortingOrderType.ASC,
      });
    } else if (commonState.modal.isOpenChatModal.openFrom === OpenFrom.CONTACT_PHYSICIAN) {
      onChangeNavigator({
        key: managerStats.selectedNavigator.key,
        name: managerStats.selectedNavigator.name,
        label: managerStats.selectedNavigator.name,
        value: managerStats.selectedNavigator.name,
      });
    } else {
      onChangeNavigator({
        id: episode?.patientAndEpisodeDetails.navigator.careManagerId,
        key: episode?.patientAndEpisodeDetails.navigator.careManagerId as string,
        name: `${episode?.patientAndEpisodeDetails.navigator.firstName} ${episode?.patientAndEpisodeDetails.navigator.lastName}`,
        label: `${episode?.patientAndEpisodeDetails.navigator.firstName} ${episode?.patientAndEpisodeDetails.navigator.lastName}`,
        value: `${episode?.patientAndEpisodeDetails.navigator.firstName} ${episode?.patientAndEpisodeDetails.navigator.lastName}`,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !isEmptyString(messagingState.userIdforMessage) &&
      (commonState.modal.isOpenChatModal.openFrom === OpenFrom.CONTACT ||
        commonState.modal.isOpenChatModal.openFrom === OpenFrom.TASK_MANAGEMENT)
    ) {
      onChangePatient({
        key: messagingState.userIdforMessage,
        name: `${messagingState.messageContacts.patientContacts[0].patientFirstName} ${messagingState.messageContacts.patientContacts[0].patientLastName}`,
        label: `${messagingState.messageContacts.patientContacts[0].patientFirstName} ${messagingState.messageContacts.patientContacts[0].patientLastName}`,
        value: `${moment(messagingState.messageContacts.patientContacts[0].patientDOB).format("MM/DD/YYYY")}`,
        ...messagingState.messageContacts.patientContacts[0],
      });
    }

    if (
      !isEmptyString(messagingState.userIdforMessage) &&
      commonState.modal.isOpenChatModal.openFrom === OpenFrom.CONTACT_PHYSICIAN
    ) {
      onChangeSelectedPhysicians([
        {
          key: messagingState.currentMessagePhysician?.physicianUserID,
          name: `${messagingState.currentMessagePhysician?.physicianFirstName} ${messagingState.currentMessagePhysician?.physicianLastName}`,
          value: `${messagingState.currentMessagePhysician?.practiceName}`,
          ...messagingState.currentMessagePhysician,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    messagingState.userIdforMessage,
    messagingState.messageContacts.patientContacts,
    commonState.modal.isOpenChatModal.openFrom,
    messagingState.currentMessagePhysician,
  ]);

  useEffect(() => {
    if (!isEmptyString(selectedNavigator.key) && selectedPatient !== null && !isEmptyString(selectedPatient.key)) {
      if (selectedPatient.key === GENERAL.toLowerCase()) {
        const payload = { ...requestPhysicianPayload, id: selectedNavigator.key, patientId: "0" };
        dispatch(getPhysicianContactAsync(payload));
      } else {
        const payload = {
          ...requestPhysicianPayload,
          id: selectedNavigator.key,
          patientId: selectedPatient.patientID?.toString(),
        };
        dispatch(getPhysicianContactAsync(payload));
      }
    }
  }, [dispatch, selectedPatient, requestPhysicianPayload, selectedNavigator.key]);

  useImperativeHandle(
    ref,
    () => {
      return {
        getSelectedNavigator: () => selectedNavigator,
        getSelectedPatient: () => selectedPatient,
        getSelectedPhysician: () => selectedPhysicians,
        includePatientInParticipationList: () => patientCheckbox,
      };
    },
    [selectedNavigator, selectedPatient, selectedPhysicians, patientCheckbox]
  );

  return (
    <ComposePresentation
      selectedNavigator={selectedNavigator}
      onChangeNavigator={onChangeNavigator}
      selectedPatient={selectedPatient}
      onChangePatient={onChangePatient}
      isSearchable={isSearchable}
      onChangePatientSearch={onChangePatientSearch}
      patientCheckbox={patientCheckbox}
      onChangePatientCheckox={onChangePatientCheckox}
      selectedPhysicians={selectedPhysicians}
      onChangeSelectedPhysicians={onChangeSelectedPhysicians}
      physicianSearchText={physicianSearchText}
      onChangePhysicianSearchText={onChangePhysicianSearchText}
    />
  );
});
