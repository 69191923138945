import Icon from "components/Icon/icon.component";
import Collapsible from "react-collapsible";
import "./collapsible-alerts.styles.scss";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";
import { useSelector } from "react-redux";
import { isEmptyString } from "shared/methods/utilityFunctions";

interface CollapsibleAlertProps {
  isOpen: boolean;
  handleClick: (value: boolean) => void;
}

const CollapsibleAlerts = (props: CollapsibleAlertProps) => {
  const { isOpen, handleClick } = props;
  const { episode } = useSelector(getTaskManagementState);

  return (
    <Collapsible
      open={isOpen}
      handleTriggerClick={() => {
        handleClick(isOpen);
      }}
      classParentString={`alert-collapsible-container ${isOpen ? "is-open" : "not-open"} `}
      trigger={
        <div
          className={`alert-collapsible-container-heading ${
            episode &&
            episode?.statusAndAlerts &&
            episode?.statusAndAlerts.alert &&
            episode?.statusAndAlerts.alert.length > 0
              ? "is-present"
              : "alert-not-present"
          }`}
        >
          Alerts
          {isOpen ? (
            <Icon
              icon="up-arrow"
              size={10}
              className={
                episode &&
                episode?.statusAndAlerts &&
                episode?.statusAndAlerts.alert &&
                episode?.statusAndAlerts.alert.length > 0
                  ? "is-present"
                  : "alert-not-present"
              }
            />
          ) : (
            <Icon
              icon="down-arrow"
              size={10}
              className={
                episode &&
                episode?.statusAndAlerts &&
                episode?.statusAndAlerts.alert &&
                episode?.statusAndAlerts.alert.length > 0
                  ? "is-present"
                  : "alert-not-present"
              }
            />
          )}
        </div>
      }
      triggerDisabled={
        episode &&
        episode?.statusAndAlerts &&
        episode?.statusAndAlerts.alert &&
        episode?.statusAndAlerts.alert.length > 0
          ? false
          : true
      }
    >
      <div className="alert-collapsible-container-content">
        {episode &&
          episode?.statusAndAlerts &&
          episode?.statusAndAlerts.alert &&
          episode?.statusAndAlerts.alert.map((item: any) => {
            return (
              <div className="alert-collapsible-container-content-alert-item">
                <div className="alert-title">{item.alert}</div>
                <div className="alert-detail">{isEmptyString(item.detail) ? "-" : item.detail}</div>
              </div>
            );
          })}
      </div>
    </Collapsible>
  );
};

export default CollapsibleAlerts;
