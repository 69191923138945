import Icon from "../Icon/icon.component";
import { ButtonProps } from "./props";
import "./button.styles.scss";
import ButtonLoader from "components/ButtonLoader/button-loader.component";

const Button = ({
  text,
  className,
  onClick,
  icon,
  iconClassName,
  buttonSize,
  disabled,
  imgChildren,
  showLoader,
}: ButtonProps) => {
  if (imgChildren) {
    return (
      <div className="img-button-container">
        <input type="button" className={`${className}`} onClick={onClick} value={text} disabled={disabled} />
        {imgChildren}
      </div>
    );
  }
  if (icon) {
    return (
      <div onClick={onClick} className={`${className ? className : ""} icon-button`}>
        <Icon icon={icon} className={iconClassName ? iconClassName : ""} size={buttonSize} />
        <span className="text-for-icon">{text}</span>
      </div>
    );
  }

  if (showLoader) {
    return (
      <div className="img-button-container">
        <button className={`${className} ${disabled ? "disabled" : ""}`}>
          <ButtonLoader />
        </button>
      </div>
    );
  }

  return <input type="button" className={`${className}`} onClick={onClick} value={text} disabled={disabled} />;
};

export default Button;
