import { ChangeEvent } from "react";

export type SelectionValue = {
  id: string;
  order: number;
  title: string;
  score?: number | null;
  sectionId?: string | null;
  questions?: Question[];
  note?: string | null;
  conditionalSectionId?: string | null;
  showSection?: boolean;
  nextQuestionId?: string | null;
  showNote: boolean;
  showAlert: boolean;
};

export type QuestionType =
  | "pill"
  | "checkbox"
  | "dropdown"
  | "text-medium"
  | "text-area"
  | "radioButton"
  | "days-selector"
  | "date-selector"
  | "conditional-section"
  | "section-title"
  | "age-selector"
  | "scale"
  | "no-answer"
  | "number";
export type SelectionType = "single-select" | "multi-select" | null;

export type Question = {
  order?: number;
  questionId: string;
  title?: string | null;
  subTitle?: string | null;
  questionType?: QuestionType;
  selectionType?: SelectionType;
  selectionValues?: SelectionValue[];
  note?: string | null;
  questions?: Question[];
  maxLength?: number;
  alert?: string | null;
  selectedRange?: number[];
  stepCount?: number;
  isConclusion?: boolean;
  showAlways?: Question[];
  id?: string;
  placeHolder?: string;
  isRequired?: boolean;
};

export type RiskAssessmentData = {
  id: string;
  sectionId: string;
  sectionTitle: string | null;
  questions: Question[];
};

export type RiskAssessmentProps = {
  onChangeCheckbox: (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    title: string,
    sectionId: string,
    questionId: string,
    nextQuestionId?: string | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => void;
  onChangePill: (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    sectionId?: string | null,
    questionId?: string | null,
    nextQuestionId?: string | null,
    conditionalSectionId?: string | null,
    showSection?: boolean | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => void;
  onChangeDropdown: (
    selectedValue: any,
    sectionId: string,
    questionId: string,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => void;
  onSelectPill: (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    sectionId?: string | null,
    questionId?: string | null,
    nextQuestionId?: string | null,
    conditionalSectionId?: string | null,
    showSection?: boolean | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => void;
  onChangeDays: (
    e: ChangeEvent<HTMLInputElement>,
    id?: string | null,
    sectionId?: string | null,
    questionId?: string | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => void;
  onChangeDate: (
    selectedDate: any,
    sectionId: string,
    questionId: string,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => void;
  onChangeText: (
    text: any,
    sectionId: string,
    questionId: string,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null,
    isConclusion?: boolean | null
  ) => void;
  onChangeAge: (
    id?: string | null,
    sectionId?: string | null,
    questionId?: string | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null,
    value?: string | null
  ) => void;
};

export enum Pages {
  General = 0,
  Lace = 1,
  Additional,
  Closing,
}

export type SaveRiskAssessmentPayload = {
  id: string | null;
  patientID: number;
  intakeID: number;
  createUser: string;
  isSaveDraft: boolean;
  collapsibleSections: Array<string>;
  sectionAnswers: Array<{
    sectionId: string;
    answers: Array<{
      questionId: string;
      selectionValues: Array<{
        id: string;
        value: string;
      }>;
    }>;
  }>;
};

export enum RiskType {
  STANDARD,
  HIGH,
}
