import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { ModalType } from "./props";
import { CSSTransition } from "react-transition-group";
import "./modal.styles.scss";
import { isMobile } from "react-device-detect";

const Modal = ({
  modalHeader,
  modalFooter,
  children,
  isOpen,
  className,
  contentClass,
  animationClass,
  modalRef,
  id,
  openFrom = "",
}: ModalType) => {
  const [isOpenModal, setOpenModal] = useState(isOpen);

  useEffect(() => {
    setOpenModal(isOpen);
  }, [isOpen]);

  return createPortal(
    <CSSTransition
      in={isOpenModal}
      timeout={{ enter: 0, exit: 0.3 }}
      unmountOnExit
      classNames={animationClass ? animationClass : "modal-wrapper"}
    >
      <div
        id={id}
        ref={modalRef}
        className={`modal-container ${isOpen ? "modal-container-open" : "modal-conatiner-close"} ${
          className ? className : ""
        } ${isMobile ? "mobileView" : ""}`}
      >
        <div className={`modal-main ${contentClass ? contentClass : ""}`}>
          {modalHeader && (
            <div className={`modal-header ${openFrom === "compose" ? "compose" : ""}`}>{modalHeader}</div>
          )}
          <div className="modal-body">{children}</div>
          {modalFooter && <div className="modal-footer">{modalFooter}</div>}
        </div>
      </div>
    </CSSTransition>,
    document.querySelector("#modal") as HTMLElement
  );
};

export default Modal;
