import Header from "components/Header/header.component";
import { HeaderColumnInfo } from "./common/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import "./episodes.styles.scss";
import moment from "moment";
import { getEpisodesAsync } from "state/feature/episodes/episodes.action";
import { batch, useDispatch, useSelector } from "react-redux";
import Loading from "shared/assets/images/loader.gif";
import { getEpisodesSlice, resetEpisodesList, resetEpisodesState } from "state/feature/episodes/episodes.slice";
import { PAGE_LIMIT } from "shared/constant/commonConstants";
import { isEmptyString } from "shared/methods/utilityFunctions";
import TableLoader from "components/TableLoader/table-loader.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { getCommonState } from "state/feature/common/common.slice";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { getAuth } from "state/feature/auth/auth.slice";
import { checkIfFeatureEnabledAsync } from "state/feature/common/common.action";
import { useAppDispatch } from "state/store";
import { useHistory } from "react-router";
import { debounce } from "lodash";
import SearchBox from "components/SearchBox/search-box.component";
import Button from "components/Button/button.component";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import Sort from "components/Sort/sort.component";
import Icon from "components/Icon/icon.component";
import { getInitialEpisodesTableHeader } from "shared/constant/table";
import { EpisodesRequestPayloadProps } from "state/types/episodes-slice.type";
const Episodes = () => {
  useAxiosAuthenticated();
  const [headerColumnsInfo, setHeaderColumnsInfo] = useState<HeaderColumnInfo[]>(getInitialEpisodesTableHeader);
  const { featureFlags } = useSelector(getCommonState);
  const { user, isLoading: isLoadingUser } = useSelector(getAuth);
  const history = useHistory();
  const appDispatch = useAppDispatch();
  const { isLoading, episodes } = useSelector(getEpisodesSlice);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [episodeRequestCalled, setEpisodeRequestCalled] = useState(false);
  const [hasScrollbarVisible, setHasScrollbarVisible] = useState(false);
  const initialRequestPayload = {
    searchText: "",
    limit: PAGE_LIMIT,
    offset: 0,
    status: [],
    physicians: [],
    patientNavigators: [],
    transitionNavigators: [],
    admitDateFrom: "",
    admitDateTo: "",
    surgeryDateFrom: "",
    surgeryDateTo: "",
    sortColumn: "patientName",
    sortOrder: SortingOrderType.ASC,
  };
  const [requestPayload, setRequestPayload] = useState<EpisodesRequestPayloadProps>(initialRequestPayload);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoadingUser && user.navigatorId !== undefined && user.navigatorId !== null) {
      appDispatch(checkIfFeatureEnabledAsync({ featureFlagName: "NavigatorEpisodeList" })).then((res: any) => {
        if (!res.payload.data) {
          history.push("/not-found");
        }
      });
    }
  }, [appDispatch, user.navigatorId, isLoadingUser, history]);

  useEffect(() => {
    if (
      featureFlags.navigatorEpisodeList ||
      (!isLoadingUser && (user?.navigatorId === undefined || user?.navigatorId == null))
    ) {
      setEpisodeRequestCalled(true);
      dispatch(getEpisodesAsync(requestPayload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, requestPayload, featureFlags.navigatorEpisodeList]);

  const incrementPage = () => {
    setRequestPayload((prev) => {
      return {
        ...prev,
        offset: prev.offset + PAGE_LIMIT,
      };
    });
  };

  const updateState = () => {
    const InfiniteScrollInnerDivElement = document.querySelector(
      ".episodes-listing-content .infinite-scroll-component"
    );
    if (InfiniteScrollInnerDivElement) {
      setHasScrollbarVisible(InfiniteScrollInnerDivElement.scrollHeight > InfiniteScrollInnerDivElement.clientHeight);
    }
  };

  const displaySortIcons = (el: HeaderColumnInfo) => {
    return el.sortOrder === SortingOrderType.DEFAULT ? (
      <Sort />
    ) : (
      <Icon icon={el.sortOrder === SortingOrderType.ASC ? "sort-top" : "sort-bottom"} size={8} />
    );
  };

  const changeSort = (key: string, order: SortingOrderType) => {
    setRequestPayload((prev) => {
      return {
        ...prev,
        offset: 0,
        sortColumn: key,
        sortOrder: order,
      };
    });
  };

  const handleColumnSorting = (selectedColumn: HeaderColumnInfo) => {
    const index = headerColumnsInfo.findIndex((x) => x.key === selectedColumn.key);
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (headerColumnsInfo[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeaders = [...headerColumnsInfo];
    tempHeaders.forEach((header) => (header.sortOrder = SortingOrderType.DEFAULT));
    tempHeaders[index].sortOrder = sort;
    batch(() => {
      changeSort(selectedColumn.key, sort);
      dispatch(resetEpisodesList());
    });
    setHeaderColumnsInfo(tempHeaders);
  };

  useEffect(() => {
    updateState();
    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, [episodes, isLoading]);

  useEffect(() => {
    return () => {
      dispatch(resetEpisodesState());
    };
  }, [dispatch]);

  const changeSearchText = useCallback(
    (text: string) => {
      appDispatch(resetEpisodesList());
      setRequestPayload((prev) => {
        return {
          ...prev,
          offset: 0,
          searchText: text.trim(),
        };
      });
    },
    [appDispatch]
  );

  const debouncedSearch = useMemo(() => {
    return debounce(changeSearchText, 1000);
  }, [changeSearchText]);

  const isFilterSearchApplied = () => {
    return (
      requestPayload.patientNavigators.length > 0 ||
      requestPayload.physicians.length > 0 ||
      requestPayload.searchText.length > 0 ||
      requestPayload.status.length > 0 ||
      requestPayload.admitDateFrom.length > 0 ||
      requestPayload.admitDateTo.length > 0 ||
      requestPayload.transitionNavigators.length > 0
    );
  };

  return (
    <div id="episodes-container">
      <Header className="episodes">
        <div className="header-title">Episodes</div>
        <div className="right-section">
          {isSearching ? (
            <SearchBox
              icon="cross"
              className="search-box"
              iconClassName="search-icon"
              SearchBoxSize={22}
              placeholder="Search by patient name"
              showIcon={!!requestPayload.searchText}
              onClick={() => {
                setIsSearching(false);
                changeSearchText("");
              }}
              onTextChange={debouncedSearch}
            />
          ) : (
            <div data-tip data-for={"Search"}>
              <Button
                icon="search"
                buttonSize={14}
                className="search-icon-container"
                iconClassName="search-icon"
                onClick={() => setIsSearching(true)}
              />
              <CustomToolTip text={"Search"} place="bottom" />
            </div>
          )}
        </div>
      </Header>
      {episodeRequestCalled && !isLoading && episodes.length === 0 && !isFilterSearchApplied() ? (
        <CommonState type={CommonStateType.NO_EPISODES_FOUND} />
      ) : (
        <div className="episodes-listing-container">
          <div className="episodes-listing-table">
            <div
              className={`episodes-listing-header ${
                hasScrollbarVisible ? "episodes-listing-header-scrollbar-visible" : ""
              }`}
            >
              {headerColumnsInfo.map((el, index) => (
                <div key={`${index}-${el.key}`} className="column">
                  <div
                    className={`column-content ${isLoading ? "column-content-disable-cursor" : ""}`}
                    onClick={() => {
                      if (!isLoading) {
                        handleColumnSorting(el);
                      }
                    }}
                  >
                    <div className="content">{el.name}</div>
                    <span className="sort-icon">{!isLoading && displaySortIcons(el)}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="episodes-listing-content">
              {isLoading && episodes.length === 0 && (
                <div className="empty-state-container loader">
                  <img src={Loading} alt="loading" />
                </div>
              )}
              {!isLoading && isFilterSearchApplied() && episodes.length === 0 && (
                <CommonState type={CommonStateType.EPISODE_NOT_FOUND_ON_SEARCH} />
              )}
              {episodes?.length > 0 ? (
                <InfiniteScroll
                  next={() => {
                    incrementPage();
                  }}
                  hasMore={episodes.length > 0}
                  loader={isLoading ? <TableLoader /> : <></>}
                  height="100%"
                  dataLength={episodes.length}
                >
                  {episodes.map((el, index) => (
                    <div key={`${el.id}--${index}`} onClick={() => {}} className="episodes-listing-row">
                      {Object.entries(el).map(([key, value]) => {
                        {
                          if (key.toLowerCase().includes("id")) {
                            return null;
                          } else if (key === "dob") {
                            return (
                              <div key={`${el.id} ${key}`} className={`column dob`}>
                                <div className="content">
                                  <div>{value}</div>
                                  {value && isEmptyString(value as string) && (
                                    <div className="age">{`${moment().diff(value, "years", false)} years`}</div>
                                  )}
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div key={`${el.id} ${key}`} className="column">
                                <div className="content">{value}</div>
                              </div>
                            );
                          }
                        }
                      })}
                    </div>
                  ))}
                </InfiniteScroll>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Episodes;
