import Button from "components/Button/button.component";
import { useSelector } from "react-redux";
import {
  clearPage,
  enablePageValidation,
  getRiskAssessmentState,
  setActivePage,
  setCollapsibleSectionIsDirty,
  setIsValueChanged,
  setShowPostAcuteTaskCompletionPopUp,
} from "state/feature/risk-assessment/risk-assessment.slice";
import { Pages, SaveRiskAssessmentPayload } from "pages/task-management/risk-assessment/common/risk-assessment.types";
import "./risk-footer.styles.scss";
import { useHistory, useParams } from "react-router";
import { PageName, PageSection } from "shared/enums/page-name.enum";
import SummaryFooter from "../summary-view/summary-footer/summary-footer.component";
import { useCallback, useState } from "react";
import { SelectedValue } from "state/types/risk-assessment-slice.type";
import { saveRiskAssessmentAnswersAsync } from "state/feature/risk-assessment/risk-assessment.action";
import { getAuth } from "state/feature/auth/auth.slice";
import store, { useAppDispatch } from "state/store";
import { toast } from "react-toastify";
import { removeLastDashFromUrl } from "shared/methods/utilityFunctions";
import GenericPopupModal from "components/Modal/GenericPopupModal/generic-popup-modal.component";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";

const RiskAssessmentFooter = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { intakeId } = useParams<{ intakeId: string }>();
  const { taskType, sectionName } = useParams<{ taskType: string; sectionName: string }>();
  const taskTypeLowerCase = taskType && taskType.toLowerCase();
  const sectionNameLowerCase = sectionName && sectionName.toLowerCase();

  const [isSaveDraftModalOpen, setIsSaveDraftModalOpen] = useState(false);
  const [isClearPageModalOpen, setIsClearPageModalOpen] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);

  const { user } = useSelector(getAuth);
  const riskAssessmentState = useSelector(getRiskAssessmentState);
  const { patientDetails, isValidatingIntake, isValidIntake } = useSelector(getTaskManagementState);

  const saveAnswers = useCallback(
    async (isPublish: boolean, saveAndClose?: boolean) => {
      const { selectedValues, id, collapsibleSections, status } = store.getState().riskAssessment;
      const sectionAnswers = selectedValues.map((selectedValue) => {
        return {
          sectionId: selectedValue.sectionId,
          pageId: selectedValue.pageId,
          order: selectedValue.sectionOrder,
          answers: Object.entries(selectedValue.answers).map(([questionId, selectionValues]) => ({
            questionId,
            order: selectionValues[0].order,
            selectionValues: selectionValues,
            collapsibleSectionId: selectionValues[0].collapsibleSectionId,
            isConclusion: selectionValues[0].isConclusion,
          })),
        };
      });

      if (sectionAnswers && sectionAnswers.length > 0) {
        const payload: SaveRiskAssessmentPayload = {
          id,
          patientID: patientDetails?.patientId ? patientDetails?.patientId : 0,
          intakeID: parseInt(intakeId, 10),
          createUser: user.username,
          isSaveDraft: isPublish ? false : !status || status === "draft",
          sectionAnswers,
          collapsibleSections: collapsibleSections.map((x: any) => x.sectionId),
        };

        const response = await dispatch(saveRiskAssessmentAnswersAsync(payload));
        if (response && response.meta.requestStatus === "fulfilled") {
          if (saveAndClose) {
            toast.success("Saved successfully as a Draft.", {
              containerId: "main",
            });
          } else if (isPublish) {
            toast.success("Risk Assessment submitted successfully. Redirecting...", {
              containerId: "main",
            });

            dispatch(setShowPostAcuteTaskCompletionPopUp(true));
            setTimeout(() => history.push(`${removeLastDashFromUrl(location.pathname)}/summary`), 3000);
          }
        }
      }
    },
    [dispatch, history, intakeId, user.username, patientDetails?.patientId]
  );

  const checkIfAtLeastOneQuestionIsAnswered = useCallback(() => {
    const { selectedValues, pages } = riskAssessmentState;
    const currentPage = Object.values(pages).find((pageEl: any) => pageEl.isCurrent);
    const atLeastOneQuestionAnswered =
      selectedValues.find((section: SelectedValue) => section.pageId === (currentPage as any).tab.toString()) !==
      undefined;

    return atLeastOneQuestionAnswered;
  }, [riskAssessmentState]);

  const handleSaveDraftConfirmationModal = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        setIsSaveDraftModalOpen(isOpen);
        return;
      }

      if (checkIfAtLeastOneQuestionIsAnswered() && isOpen) {
        setIsSaveDraftModalOpen(isOpen);
      }
    },
    [checkIfAtLeastOneQuestionIsAnswered]
  );

  const handleClearPageConfirmationModal = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        setIsClearPageModalOpen(isOpen);
        return;
      }
      if (checkIfAtLeastOneQuestionIsAnswered() && isOpen) {
        setIsClearPageModalOpen(isOpen);
      }
    },
    [checkIfAtLeastOneQuestionIsAnswered]
  );

  const onNext = useCallback(
    (nextPage: Pages) => {
      saveAnswers(false);
      dispatch(setActivePage(nextPage));
    },
    [saveAnswers, dispatch]
  );

  const onPrevious = useCallback(
    (previousPage: Pages) => {
      saveAnswers(false);
      dispatch(setActivePage(previousPage));
    },
    [saveAnswers, dispatch]
  );

  const handleSubmitConfirmationModal = useCallback((isOpen: boolean) => {
    setIsSubmitModalOpen(isOpen);
  }, []);

  const isSaveDraftDisabled = () => {
    return !(checkIfAtLeastOneQuestionIsAnswered() && riskAssessmentState.isValueChanged);
  };

  if (isValidIntake && !isValidatingIntake && patientDetails) {
    switch (taskTypeLowerCase) {
      case PageName.RISK_ASSESSMENT.toLowerCase():
        if (!patientDetails.isRiskAssessmentTaskAdded) {
          return <></>;
        } else if (sectionName !== undefined) {
          if (sectionNameLowerCase === PageSection.SUMMARY) {
            return <SummaryFooter />;
          } else if (sectionNameLowerCase === PageSection.RESULT) {
            return <></>;
          } else {
            return <></>;
          }
        }
        return (
          <>
            {isSubmitModalOpen && (
              <GenericPopupModal
                heading="Submit"
                submitButtonTitle="Submit"
                content="Are you sure you want to submit the form?"
                isOpen={isSubmitModalOpen}
                handleCancelClick={() => handleSubmitConfirmationModal(false)}
                handleSubmitClick={() => {
                  handleSubmitConfirmationModal(false);
                  saveAnswers(true);
                }}
                cancelButtonVisible
              />
            )}
            {isSaveDraftModalOpen && (
              <GenericPopupModal
                heading="Save as Draft"
                submitButtonTitle="Confirm"
                content="Do you want save this as a Draft?"
                isOpen={isSaveDraftModalOpen}
                handleCancelClick={() => handleSaveDraftConfirmationModal(false)}
                handleSubmitClick={() => {
                  handleSaveDraftConfirmationModal(false);
                  saveAnswers(false, true);
                }}
                cancelButtonVisible
              />
            )}
            {isClearPageModalOpen && (
              <GenericPopupModal
                heading="Clear selections"
                submitButtonTitle="Clear"
                content="Are you sure you want to clear the selections for this page?"
                isOpen={isClearPageModalOpen}
                handleCancelClick={() => setIsClearPageModalOpen(false)}
                handleSubmitClick={() => {
                  setIsClearPageModalOpen(false);
                  dispatch(clearPage());
                  dispatch(setIsValueChanged(true));
                }}
                cancelButtonVisible
              />
            )}
            <div className="footer-button-container">
              <div className="questions-footer-button-section">
                {riskAssessmentState.pages.general.isCurrent && (
                  <div className="footer-buttons-div-container">
                    <Button
                      text="Save Draft"
                      className="save-close-button"
                      disabled={isSaveDraftDisabled()}
                      onClick={() => {
                        handleSaveDraftConfirmationModal(true);
                      }}
                    />
                    <Button
                      text="Next"
                      className="next-button"
                      disabled={
                        riskAssessmentState.isLoading ||
                        riskAssessmentState.pages.general.sectionDetails.length === 0 ||
                        riskAssessmentState.pages.general.sectionDetails?.[0].questions.length === 0
                      }
                      onClick={() => {
                        onNext(Pages.Lace);
                        dispatch(enablePageValidation(Pages.General));
                        dispatch(setCollapsibleSectionIsDirty({ pageId: Pages.General, isDirty: true }));
                      }}
                    />
                    {riskAssessmentState.pages.general.isValidationEnabled &&
                      riskAssessmentState.pages.general.unAnsweredSections.length > 0 && (
                        <div className="unattempted-questions">There are Unattempted question(s)</div>
                      )}
                  </div>
                )}
                {riskAssessmentState.pages.lace.isCurrent && (
                  <div className="footer-buttons-div-container">
                    <Button
                      text="Save Draft"
                      className="save-close-button"
                      disabled={isSaveDraftDisabled()}
                      onClick={() => {
                        handleSaveDraftConfirmationModal(true);
                      }}
                    />
                    <Button
                      text="Previous"
                      className="previous-button"
                      onClick={() => {
                        onPrevious(Pages.General);
                      }}
                    />
                    <Button
                      text="Next"
                      className="next-button"
                      disabled={
                        riskAssessmentState.isLoading ||
                        riskAssessmentState.pages.lace.sectionDetails.length === 0 ||
                        riskAssessmentState.pages.lace.sectionDetails?.[0].questions.length === 0
                      }
                      onClick={() => {
                        onNext(Pages.Additional);
                        dispatch(enablePageValidation(Pages.Lace));
                        dispatch(setCollapsibleSectionIsDirty({ pageId: Pages.Lace, isDirty: true }));
                      }}
                    />
                    {riskAssessmentState.pages.lace.isValidationEnabled &&
                      riskAssessmentState.pages.lace.unAnsweredSections.length > 0 && (
                        <div className="unattempted-questions">There are Unattempted question(s)</div>
                      )}
                  </div>
                )}
                {riskAssessmentState.pages.additional.isCurrent && (
                  <div className="footer-buttons-div-container">
                    <Button
                      text="Save Draft"
                      className="save-close-button"
                      disabled={isSaveDraftDisabled()}
                      onClick={() => {
                        handleSaveDraftConfirmationModal(true);
                      }}
                    />
                    <Button
                      text="Previous"
                      className="previous-button"
                      onClick={() => {
                        onPrevious(Pages.Lace);
                      }}
                    />
                    <Button
                      text="Next"
                      className="next-button"
                      disabled={
                        riskAssessmentState.isLoading ||
                        riskAssessmentState.pages.additional.sectionDetails.length === 0 ||
                        riskAssessmentState.pages.additional.sectionDetails?.[0].questions.length === 0
                      }
                      onClick={() => {
                        onNext(Pages.Closing);
                        dispatch(enablePageValidation(Pages.Additional));
                        dispatch(setCollapsibleSectionIsDirty({ pageId: Pages.Additional, isDirty: true }));
                      }}
                    />
                    {riskAssessmentState.pages.additional.isValidationEnabled &&
                      riskAssessmentState.pages.additional.unAnsweredSections.length > 0 && (
                        <div className="unattempted-questions">There are unattempted question(s)</div>
                      )}
                  </div>
                )}
                {riskAssessmentState.pages.closing.isCurrent && (
                  <div className="footer-buttons-div-container">
                    <Button
                      text="Save Draft"
                      className="save-close-button"
                      disabled={isSaveDraftDisabled()}
                      onClick={() => {
                        handleSaveDraftConfirmationModal(true);
                      }}
                    />
                    <Button
                      text="Previous"
                      className="previous-button"
                      onClick={() => {
                        onPrevious(Pages.Additional);
                      }}
                    />
                    <Button
                      text="Submit"
                      className="next-button"
                      onClick={() => {
                        dispatch(enablePageValidation(Pages.Closing));
                        dispatch(setCollapsibleSectionIsDirty({ pageId: Pages.Closing, isDirty: true }));
                        handleSubmitConfirmationModal(true);
                      }}
                    />
                    {riskAssessmentState.pages.closing.isValidationEnabled &&
                      riskAssessmentState.pages.closing.unAnsweredSections.length > 0 && (
                        <div className="unattempted-questions">There are unattempted question(s)</div>
                      )}
                  </div>
                )}
                <Button
                  text="Clear"
                  className="no-border-button clear-button"
                  disabled={!checkIfAtLeastOneQuestionIsAnswered()}
                  onClick={() => {
                    handleClearPageConfirmationModal(true);
                  }}
                />
              </div>
            </div>
          </>
        );

      default:
        return <></>;
    }
  } else {
    return <></>;
  }
};

export default RiskAssessmentFooter;
