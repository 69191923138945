import { useSelector } from "react-redux";
import "./patient-and-episode-details.styles.scss";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";
import { getRiskAssessmentState } from "state/feature/risk-assessment/risk-assessment.slice";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import moment from "moment";
import { isEmptyString } from "shared/methods/utilityFunctions";
import CollapsibleAlerts from "./collapsible-alerts/collapsible-alerts.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";

const PatientAndEpisodeDetails = () => {
  const location = useLocation();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { episode, isValidIntake, isRightPanelLoading, patientDetails } = useSelector(getTaskManagementState);
  const [risk, setRisk] = useState("");
  const { isLoading: isLoadingRiskAssessment } = useSelector(getRiskAssessmentState);
  const [highRiskSectionList, setHighRiskSectionList] = useState<string[]>([]);
  const [highRiskSections, setHighRiskSections] = useState(0);

  useEffect(() => {
    const isFooterVisible = document.querySelector(".footer-button-container");
    const patientAndEpisodeDetails = document.querySelector(".patient-and-episode-details");
    const patientAndEpisodeDetailsInfo = document.querySelector(".patient-and-episode-details-info");
    if (isFooterVisible) {
      if (patientAndEpisodeDetails) {
        patientAndEpisodeDetails.classList.add("footer-present");
        patientAndEpisodeDetails.classList.remove("footer-not-present");
      }
      if (patientAndEpisodeDetailsInfo) {
        if (isAlertOpen) {
          patientAndEpisodeDetailsInfo.classList.add(`footer-present-open`);
          patientAndEpisodeDetailsInfo.classList.remove(`footer-present-not-open`);
          patientAndEpisodeDetailsInfo.classList.remove(`footer-not-present-open`);
          patientAndEpisodeDetailsInfo.classList.remove(`footer-not-present-not-open`);
        } else {
          patientAndEpisodeDetailsInfo.classList.add(`footer-present-not-open`);
          patientAndEpisodeDetailsInfo.classList.remove(`footer-present-open`);
          patientAndEpisodeDetailsInfo.classList.remove(`footer-not-present-open`);
          patientAndEpisodeDetailsInfo.classList.remove(`footer-not-present-not-open`);
        }
      }
    } else {
      if (patientAndEpisodeDetails) {
        patientAndEpisodeDetails.classList.add("footer-not-present");
        patientAndEpisodeDetails.classList.remove("footer-present");
      }
      if (patientAndEpisodeDetailsInfo) {
        if (isAlertOpen) {
          patientAndEpisodeDetailsInfo.classList.add(`footer-not-present-open`);
          patientAndEpisodeDetailsInfo.classList.remove(`footer-not-present-not-open`);
          patientAndEpisodeDetailsInfo.classList.remove(`footer-present-open`);
          patientAndEpisodeDetailsInfo.classList.remove(`footer-present-not-open`);
        } else {
          patientAndEpisodeDetailsInfo.classList.add(`footer-not-present-not-open`);
          patientAndEpisodeDetailsInfo.classList.remove(`footer-not-present-open`);
          patientAndEpisodeDetailsInfo.classList.remove(`footer-present-open`);
          patientAndEpisodeDetailsInfo.classList.remove(`footer-present-not-open`);
        }
      }
    }
  }, [
    location.pathname,
    patientDetails?.isRiskAssessmentTaskAdded,
    isValidIntake,
    isLoadingRiskAssessment,
    isAlertOpen,
  ]);

  useEffect(() => {
    if (
      typeof episode?.statusAndAlerts.riskLevel === "object" &&
      episode?.statusAndAlerts.riskLevel &&
      episode?.statusAndAlerts.riskLevel.length > 0
    ) {
      episode?.statusAndAlerts.riskLevel.forEach((sec) => {
        if (sec.score >= sec.riskScore) {
          setHighRiskSectionList((prev) => {
            const copy = new Set([...prev]);
            copy.add(sec.section);
            return Array.from(copy);
          });
          setHighRiskSections((count) => count + 1);
        }
      });
    }
  }, [episode?.statusAndAlerts]);

  const ifPresentAndNotEmpty = (value: string | undefined) => {
    return episode?.patientAndEpisodeDetails && value !== undefined && !isEmptyString(value);
  };

  const getProcedureDetails = () => {
    let procedureDetails = "";
    if (ifPresentAndNotEmpty(episode?.patientAndEpisodeDetails.episodeType)) {
      procedureDetails += episode?.patientAndEpisodeDetails.episodeType;
    }
    if (ifPresentAndNotEmpty(episode?.patientAndEpisodeDetails.surgerySite)) {
      procedureDetails += `, ${episode?.patientAndEpisodeDetails.surgerySite}`;
    }
    return procedureDetails;
  };

  const getRiskLevel = useCallback(() => {
    if (typeof episode?.statusAndAlerts.riskLevel === "string") {
      return episode.statusAndAlerts?.riskLevel;
    } else if (typeof episode?.statusAndAlerts.riskLevel === "object") {
      return highRiskSections > 0 || !patientDetails?.isPlannedEpisode ? "High" : "Standard";
    } else {
      return "-";
    }
  }, [episode?.statusAndAlerts, highRiskSections, patientDetails?.isPlannedEpisode]);

  useEffect(() => {
    if (episode?.statusAndAlerts.riskLevel !== undefined) {
      setRisk(getRiskLevel());
    }
  }, [episode?.statusAndAlerts, episode?.statusAndAlerts.riskLevel, getRiskLevel]);

  useEffect(() => {
    if (episode?.statusAndAlerts.alert && episode?.statusAndAlerts.alert.length > 0) {
      setIsAlertOpen(true);
    } else {
      setIsAlertOpen(false);
    }
  }, [episode?.statusAndAlerts.alert]);

  const getOffTrackDate = () => {
    if (episode?.statusAndAlerts?.offTrack) {
      return moment(episode?.statusAndAlerts?.offTrack).format("MM/DD/YYYY");
    } else {
      return "-";
    }
  };

  const getRiskType = () => {
    if (!patientDetails?.isPlannedEpisode && risk === "Incomplete") {
      return (
        <div>
          High<span className="risk-when-unplanned"> - {risk}</span>
        </div>
      );
    } else {
      return risk;
    }
  };

  return (
    <div className="patient-and-episode-details">
      {isRightPanelLoading ? (
        <div className="loading-container">
          <CommonState type={CommonStateType.LOADING} />
        </div>
      ) : (
        <>
          <CollapsibleAlerts
            isOpen={isAlertOpen}
            handleClick={(value) => {
              setIsAlertOpen(!value);
            }}
          />
          <div className="heading">Patient & Episode Details</div>
          <div className="patient-and-episode-details-info">
            <div className="title">Risk</div>
            <div className="value">
              <div className={`${highRiskSections > 0 || !patientDetails?.isPlannedEpisode ? "risk-level" : ""}`}>
                {isEmptyString(risk) ? "-" : getRiskType()}
              </div>
              {highRiskSections > 0 && <div> {highRiskSectionList.join(",")}</div>}
              {!patientDetails?.isPlannedEpisode && <div>Unplanned</div>}
            </div>
            <div className="title">Off-track</div>
            <div className="value">
              <div className={`${episode?.statusAndAlerts?.status === "OnTrack" ? "" : "off-track"}`}>
                {episode?.statusAndAlerts &&
                episode?.statusAndAlerts?.status &&
                episode?.statusAndAlerts?.status === "OnTrack"
                  ? "No"
                  : getOffTrackDate()}
              </div>
              {episode?.statusAndAlerts?.status !== "OnTrack" && (
                <div className="reason">{episode?.statusAndAlerts?.riskInfo}</div>
              )}
            </div>
            <div className="title">Location</div>
            <div className="value">
              {ifPresentAndNotEmpty(episode?.patientAndEpisodeDetails.location)
                ? episode?.patientAndEpisodeDetails.location
                : "-"}
            </div>
            <div className="title">Coach</div>
            <div className="value">
              {ifPresentAndNotEmpty(episode?.patientAndEpisodeDetails.coach)
                ? episode?.patientAndEpisodeDetails.coach
                : "N/A"}
            </div>
            <div className="title">Episode</div>
            <div className="value">
              {ifPresentAndNotEmpty(episode?.patientAndEpisodeDetails.episode)
                ? episode?.patientAndEpisodeDetails.episode
                : "-"}
            </div>
            <div className="title">Procedure</div>
            <div className="value">
              {ifPresentAndNotEmpty(episode?.patientAndEpisodeDetails.procedure)
                ? episode?.patientAndEpisodeDetails.procedure
                : "N/A"}
            </div>
            <div className="title">Procedure Details</div>
            <div className="value">{isEmptyString(getProcedureDetails()) ? "-" : getProcedureDetails()}</div>
            {episode?.patientAndEpisodeDetails.admitDate && (
              <>
                <div className="title">Admission Date</div>
                <div className="value">{moment(episode?.patientAndEpisodeDetails.admitDate).format("MM/DD/YYYY")}</div>
              </>
            )}
            {episode?.patientAndEpisodeDetails.surgeryDate &&
              episode?.patientAndEpisodeDetails.episodeCare &&
              episode?.patientAndEpisodeDetails.episodeCare.toLowerCase() !== "medical" && (
                <>
                  <div className="title">Procedure Date</div>
                  <div className="value">
                    {moment(episode?.patientAndEpisodeDetails.surgeryDate).format("MM/DD/YYYY")}
                  </div>
                </>
              )}
            {episode?.patientAndEpisodeDetails.programDischargeDate && (
              <>
                <div className="title">Prog. Discharge Date</div>
                <div className="value">
                  {moment(episode?.patientAndEpisodeDetails.programDischargeDate).format("MM/DD/YYYY")}
                </div>
              </>
            )}
            <div className="title">Physician</div>
            <div className="value">
              {episode?.patientAndEpisodeDetails.physician &&
              !isEmptyString(episode?.patientAndEpisodeDetails.physician)
                ? episode?.patientAndEpisodeDetails.physician
                : "N/A"}
            </div>
            <div className="title">Practice</div>
            <div className="value">
              {episode?.patientAndEpisodeDetails.practice && !isEmptyString(episode?.patientAndEpisodeDetails.practice)
                ? episode?.patientAndEpisodeDetails.practice
                : "N/A"}
            </div>
            <div className="title">TOC Plan</div>
            <div className="value">
              {episode?.patientAndEpisodeDetails.toc && Object.keys(episode?.patientAndEpisodeDetails.toc).length > 0
                ? Object.entries(episode?.patientAndEpisodeDetails.toc).map(([toc, visits]) => {
                    return (visits as number) > 0 ? (
                      <div>
                        {toc} - {visits} {toc === "IRF" || toc === "SNF" ? "Days" : "Visits"}
                      </div>
                    ) : null;
                  })
                : "-"}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PatientAndEpisodeDetails;
