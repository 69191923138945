import React from "react";
import { GenericTabsProps } from "./props";
import "./generic-tabs.styles.scss";

const GenericTabs = ({ tabsList, activeTabName, setActiveTabName }: GenericTabsProps) => {
  return (
    <div className="navigatorSection">
      <div className="navigatorTabs">
        {tabsList?.map((singleTab) => (
          <div
            key={singleTab.name}
            className={`tabs ${activeTabName === singleTab.name ? "active" : ""}`}
            onClick={() => setActiveTabName(singleTab.name)}
          >
            {singleTab?.name} {singleTab?.count ? `(${singleTab?.count})` : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GenericTabs;
