import Breadcrumb from "components/Breadcrumb/breadcrumb.component";
import Header from "components/Header/header.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { useSelector } from "react-redux";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import GenericTabs from "components/GenericTabs/generic-tabs.component";
import { EpisodeDetailsTab } from "shared/enums/episodes-details.enum";
import { useState } from "react";
import { ActiveTabName } from "components/GenericTabs/props";
import PatientDetails from "../components/patient-details/patient-details.container";
import Icon from "components/Icon/icon.component";

const EpisodeDetailsPresentation = () => {
  const { isLoadingEpisodeDetails, episodeDetails } = useSelector(getEpisodesSlice);
  const [activeTabName, setActiveTabName] = useState<ActiveTabName>(EpisodeDetailsTab.TASKS);

  const onClickTabName = (tabName: ActiveTabName) => {};

  const renderPresentation = () => {
    if (isLoadingEpisodeDetails) {
      return (
        <div className="loading-container">
          <CommonState type={CommonStateType.LOADING} />
        </div>
      );
    } else if (episodeDetails === null) {
      return <CommonState type={CommonStateType.NO_EPISODE_DETAIL_FOUND} />;
    }
    return (
      <>
        <Header className="episode-details">
          <Breadcrumb pathname={`/episodes/${episodeDetails.patientName}`} />
          <div />
          {episodeDetails.patientUserId === null && (
            <div className="error-wrap">
              <Icon icon="error-message-icon" size={15} className="error-message-icon" />
              <div className="error-message-text">
                Patient details are being updated. Wait for few minutes and
                <span onClick={() => window.location.reload()}> refresh </span>the page.
              </div>
            </div>
          )}
        </Header>

        <PatientDetails />
        <div className="all-filters-section">
          <GenericTabs
            tabsList={[
              {
                name: EpisodeDetailsTab.TASKS,
              },
              { name: EpisodeDetailsTab.PATIENT_DETAILS },
              { name: EpisodeDetailsTab.EPISODE_DETAILS },
              { name: EpisodeDetailsTab.ACTIVITY_HISTORY },
              { name: EpisodeDetailsTab.MISCELLANEOUS },
            ]}
            activeTabName={activeTabName}
            setActiveTabName={onClickTabName}
          />
          <div className="right-section"></div>
        </div>

        <CommonState type={CommonStateType.EPISODE_TASK_COMING_SOON} />
      </>
    );
  };
  return renderPresentation();
};

export default EpisodeDetailsPresentation;
