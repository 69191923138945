import React from "react";
import "./multi-select-dropdown.styles.scss";
import Icon from "components/Icon/icon.component";
import ClickAwayListener from "react-click-away-listener";
import { formatPhoneNumber } from "shared/methods/utilityFunctions";
import { Participant } from "shared/types/participant.type";
import { useSelector } from "react-redux";
import { getContactState } from "state/feature/contact/contact.slice";
import Loading from "shared/assets/images/loader.gif";

export const MultiSelectDropDown = ({
  isSearchListVisible,
  setIsSearchListVisible,
  searchText,
  placeholder,
  handleSearchInput,
  handleOnFocus,
  selectedList,
  isDropdownVisible,
  setIsDropdownVisible,
  deleteItemInSelectedList,
  searchResults,
  addItemInSelectedList,
  HighlightedText,
}: any) => {
  const { navigatorContacts } = useSelector(getContactState);
  const { isPatientContactsLoading, isPhysicianContactsLoading } = navigatorContacts;

  const areContactsLoading = () => isPatientContactsLoading || isPhysicianContactsLoading;

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (isSearchListVisible) {
          setIsSearchListVisible(false);
        }
      }}
    >
      <div className="multi-select-drop-down">
        <div className="multi-select-drop-down-content-container">
          <div className="add-recipient-search-section">
            <div className="input-field-container">
              <div className="input-fields">
                <Icon icon="search-lens" size={20} className="search-lens-icon" />
                <input
                  className="add-recipient-input"
                  placeholder={placeholder}
                  value={searchText}
                  onChange={(event) => {
                    const numericValue = event.target.value.replace(/\D+/g, "");
                    if (numericValue) {
                      handleSearchInput(numericValue);
                    } else {
                      handleSearchInput(event.target.value);
                    }
                  }}
                  onFocus={handleOnFocus}
                />
              </div>
              {selectedList.length > 0 && (
                <div className="selected-dropdown-section">
                  <div
                    className={`selected-dropdown-button ${isDropdownVisible ? "grey-background" : ""}`}
                    onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                  >
                    {isDropdownVisible ? (
                      <>
                        Hide
                        <Icon icon="down-arrow" size={12} className="down-arrow-icon" />
                      </>
                    ) : (
                      <>
                        {selectedList.length} selected
                        <Icon icon="up-arrow" size={12} className="up-arrow-icon" />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {isDropdownVisible && (
            <div className="selected-dropdown">
              {selectedList.map((item: Participant, index: number) => (
                <div key={"dropdown-" + index} className="dropdown-single-item">
                  <div className="detail-section">
                    {item.clientId && item.userId
                      ? `${item.firstName} ${item.lastName}`
                      : `${formatPhoneNumber(item.phoneNumber)}`}
                  </div>
                  <div
                    className="cross-button"
                    onClick={() => {
                      deleteItemInSelectedList(item);
                    }}
                  >
                    <Icon icon="cross-in-circle" size={20} className="cross-in-circle-icon" />
                  </div>
                </div>
              ))}
            </div>
          )}
          {!isDropdownVisible && isSearchListVisible && (
            <div className="search-results-section">
              {areContactsLoading() ? (
                <div className="empty-state-container loader">
                  <img src={Loading} alt="loading" />
                </div>
              ) : null}
              {searchResults && searchResults.length > 0 && !areContactsLoading()
                ? searchResults.map((item: Participant, index: number) => {
                    return (
                      <div
                        key={"search-items-" + index}
                        className="search-items"
                        onClick={() => {
                          addItemInSelectedList(item);
                        }}
                      >
                        {item.userId && item.clientId ? (
                          <div className="name-with-label">
                            <div className="name">
                              <div>{HighlightedText(`${item.firstName} ${item.lastName}`, searchText)}</div>
                              {item.phoneNumber && <div className="phone-number">{`(${item.phoneNumber})`}</div>}
                            </div>
                            <div className="label">
                              {item.phoneNumberDetails && item.phoneNumberDetails.type}
                              {item.phoneNumberDetails && item.phoneNumberDetails.isPreferred && (
                                <span>(preferred)</span>
                              )}
                            </div>
                          </div>
                        ) : (
                          <>
                            <span className="unsaved-add-tag">Add</span>
                            <span className="unsaved-add-tag">{formatPhoneNumber(item.phoneNumber).slice(0, -13)}</span>
                            {HighlightedText(formatPhoneNumber(item.phoneNumber).slice(-13), searchText)}
                          </>
                        )}
                      </div>
                    );
                  })
                : null}
              {!areContactsLoading() && searchResults?.length === 0 ? (
                <div className="search-items">No Results Found</div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};
