import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "./single-date-picker.styles.scss";

type SingleDatePickerProps = {
  handleDateChange: any;
  isError: boolean;
  isDisabled: boolean;
  placeholder: string;
  defaultDate: Date | null | undefined;
  minDate: Date | null | undefined;
  maxDate: Date | null | undefined;
  className?: string;
};

const SingleDatePicker = ({
  handleDateChange,
  isError,
  isDisabled,
  placeholder,
  defaultDate,
  minDate,
  maxDate,
  className,
}: SingleDatePickerProps) => {
  const [startDate, setStartDate] = useState<any>(null);

  useEffect(() => {
    if (defaultDate) {
      setStartDate(new Date(defaultDate));
    } else {
      setStartDate(null);
    }
  }, [defaultDate]);

  const onDateChange = (date: any, e: any) => {
    setStartDate(date);
    handleDateChange(date);
  };

  return (
    <div id="single-date-picker-selector" className={`${isDisabled && className ? className : ""}`}>
      <div>
        <DatePicker
          selected={startDate}
          onChange={onDateChange}
          dateFormat="MM/dd/yyyy"
          className={`date-picker-input ${isError ? "red-border" : ""}`}
          calendarClassName="date-picker-calender"
          monthClassName={() => {
            return "date-picker-month";
          }}
          dayClassName={() => {
            return "date-picker-day";
          }}
          weekDayClassName={() => {
            return "date-picker-week-day";
          }}
          popperPlacement="auto"
          shouldCloseOnSelect
          isClearable
          disabled={isDisabled}
          placeholderText={placeholder}
          minDate={minDate ? addDays(minDate, 1) : null}
          maxDate={maxDate ? maxDate : null}
        />
      </div>
      {isError && <div className="red-color">Please select the date.</div>}
    </div>
  );
};

export default SingleDatePicker;
