import { useParams } from "react-router";
import RiskAssessmentResult from "./result-view/risk-assessment-result.component";
import { PageSection } from "shared/enums/page-name.enum";
import PageNotFound from "pages/errors/page-not-found";
import RiskAssessmentSummary from "./summary-view/risk-assessment-summary.component";
import RiskAssessment from "./risk-assessment.container";

const RiskAssessmentContainer = () => {
  const { sectionName } = useParams<{ sectionName: string }>();

  if (sectionName) {
    if (sectionName === PageSection.RESULT) {
      return <RiskAssessmentResult />;
    } else if (sectionName === PageSection.SUMMARY) {
      return <RiskAssessmentSummary />;
    } else {
      return <PageNotFound />;
    }
  }

  return <RiskAssessment />;
};

export default RiskAssessmentContainer;
