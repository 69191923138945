import { Redirect, useParams } from "react-router";
import { PageSection } from "shared/enums/page-name.enum";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";

const RedirectToTaskManagement = () => {
  useAxiosAuthenticated();
  const { intakeId, sectionName } = useParams<{ intakeId: string; sectionName: string }>();

  if (sectionName === PageSection.RESULT) {
    return <Redirect to={`/task-management/${intakeId}/risk-assessment/result`} />;
  } else if (sectionName === PageSection.SUMMARY) {
    return <Redirect to={`/task-management/${intakeId}/risk-assessment/summary`} />;
  }

  return <Redirect to={`/task-management/${intakeId}`} />;
};

export default RedirectToTaskManagement;
