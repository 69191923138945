import { ChangeEvent, useEffect, useState } from "react";
import AssessmentPresentation from "./assessment.component";
import { useAppDispatch } from "state/store";
import { getAssessmentDataAsync, getPhysicianDataAsync } from "state/feature/assessment/assessment.action";
import { AssessmentContext } from "./context/assessment-context";
import {
  appendSelectedValue,
  getAssessmentState,
  removeAppendedSelectedValue,
  removeSelectedValue,
  resetAssessmentState,
  setScaleValue,
  setSelectedValue,
} from "state/feature/assessment/assessment.slice";
import { useHistory, useParams } from "react-router";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { useSelector } from "react-redux";
import { getCommonState } from "state/feature/common/common.slice";

interface AssessmentProps {
  intakeId: string;
}

const Assessment = (props: AssessmentProps) => {
  useAxiosAuthenticated();
  const { sectionName } = useParams<{ sectionName: string }>();
  const { intakeId } = props;
  const dispatch = useAppDispatch();
  const { isValueChanged } = useSelector(getAssessmentState);
  const [isBlocking, setIsBlocking] = useState(false);
  const { modal } = useSelector(getCommonState);

  const showUnsavedPopupWhenReload = (e: any) => {
    e.returnValue = null;
  };

  useEffect(() => {
    dispatch(getAssessmentDataAsync({ workflowDetailId: sectionName, intakeId }));
  }, [dispatch, intakeId, sectionName]);

  useEffect(() => {
    dispatch(getPhysicianDataAsync());
  }, [dispatch]);

  useEffect(() => {
    if (isValueChanged && !modal.isOpenLogoutModal) {
      window.addEventListener("beforeunload", showUnsavedPopupWhenReload, true);
      setIsBlocking(true);
    } else {
      window.removeEventListener("beforeunload", showUnsavedPopupWhenReload, true);
      setIsBlocking(false);
    }

    return () => {
      window.removeEventListener("beforeunload", showUnsavedPopupWhenReload, true);
    };
  }, [isValueChanged, modal.isOpenLogoutModal]);

  const onChangePill = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    sectionId?: string | null,
    questionId?: string | null,
    nextQuestionId?: string | null,
    conditionalSectionId?: string | null,
    showSection?: boolean | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    const element = document.getElementById(e.target.id) as HTMLInputElement;
    const allOtherRelatedCheckboxes = document.querySelectorAll(
      `.${e.target.classList[1]}`
    ) as NodeListOf<HTMLInputElement>;
    const parentElement = element.parentElement;
    if (parentElement) {
      if (element && element.checked) {
        allOtherRelatedCheckboxes.forEach((item: HTMLInputElement) => {
          if (item === element) {
            parentElement.classList.add("checked");
            dispatch(
              setSelectedValue({
                questionId,
                answer: {
                  [e.target.name]: [
                    {
                      id,
                      value: e.target.value,
                      nextQuestionId,
                      order,
                      collapsibleSectionId: showSection ? conditionalSectionId : null,
                    },
                  ],
                },
              })
            );
          } else {
            item.checked = false;
            item?.parentElement?.classList.remove("checked");
          }
        });
      } else {
        dispatch(
          removeSelectedValue({
            sectionId,
            questionId,
            [e.target.name]: [{ id, value: e.target.value, nextQuestionId }],
          })
        );
        parentElement.classList.remove("checked");
      }
    }
  };

  const onSelectPill = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    sectionId?: string | null,
    questionId?: string | null,
    nextQuestionId?: string | null,
    conditionalSectionId?: string | null,
    showSection?: boolean | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    const element = document.getElementById(e.target.id) as HTMLInputElement;
    const allOtherRelatedCheckboxes = document.querySelectorAll(
      `.${e.target.classList[1]}`
    ) as NodeListOf<HTMLInputElement>;
    const parentElement = element.parentElement;
    if (parentElement) {
      if (element && element.checked) {
        allOtherRelatedCheckboxes.forEach((item: HTMLInputElement) => {
          if (item === element) {
            parentElement.classList.add("checked");
            dispatch(
              appendSelectedValue({
                questionId,
                answer: { [e.target.name]: [{ id, value: e.target.value, nextQuestionId }] },
                pageId,
              })
            );
          }
        });
      } else {
        // TODO: remove appended value
        parentElement.classList.remove("checked");
      }
    }
  };

  const onChangeText = (
    text: string,
    sectionId: string,
    questionId: string,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    if (!text || isEmptyString(text)) {
      dispatch(
        removeSelectedValue({
          questionId,
          answer: {
            [questionId]: [{ id: "", value: text, nextQuestionId: null, order }],
          },
        })
      );
    } else {
      dispatch(
        setSelectedValue({
          questionId,
          answer: {
            [questionId]: [{ id: "", value: text, nextQuestionId: null, order }],
          },
        })
      );
    }
  };

  const onChangeCheckbox = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    title: string,
    sectionId: string,
    questionId: string,
    nextQuestionId?: string | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    if (e.target.checked) {
      dispatch(
        appendSelectedValue({
          questionId,
          answer: { [questionId]: [{ id, value: title, nextQuestionId: nextQuestionId, order }] },
          pageId,
          showSection: false,
        })
      );
    } else {
      dispatch(
        removeAppendedSelectedValue({
          questionId,
          answerId: id,
          nextQuestionId,
          pageId,
        })
      );
    }
  };

  const onChangeDropdown = (
    selectedValue: any,
    sectionId: string,
    questionId: string,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    dispatch(
      setSelectedValue({
        questionId,
        answer: { [questionId]: [{ ...selectedValue, order }] },
        pageId,
      })
    );
  };

  const onChangeDays = (
    e: ChangeEvent<HTMLInputElement>,
    id?: string | null,
    sectionId?: string | null,
    questionId?: string | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    dispatch(
      setSelectedValue({
        questionId,
        answer: { [e.target.name]: [{ id, value: e.target.value, nextQuestionId: null, order }] },
        pageId,
      })
    );
  };

  const onChangeAge = (
    id?: string | null,
    sectionId?: string | null,
    questionId?: string | null,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null,
    value?: string | null
  ) => {
    dispatch(
      setSelectedValue({
        questionId,
        answer: { [questionId ?? ""]: [{ id, value: value, nextQuestionId: null, order }] },
        pageId,
      })
    );
  };

  const onChangeDate = (
    selectedDate: any,
    sectionId: string,
    questionId: string,
    pageId?: number | null,
    order?: number | null,
    sectionOrder?: number | null
  ) => {
    if (!isEmptyString(selectedDate)) {
      dispatch(
        setSelectedValue({
          questionId,
          answer: { [questionId]: [{ id: "", value: selectedDate, nextQuestionId: null, order }] },
          pageId,
        })
      );
    } else {
      dispatch(
        removeSelectedValue({
          questionId,
          answer: { [questionId]: [{ id: "", value: selectedDate, nextQuestionId: null, order }] },
        })
      );
    }
  };

  const onScaleChange = (questionId: string, minValue?: string, maxValue?: string, pageId?: string) => {
    dispatch(
      setScaleValue({
        questionId,
        answer: {
          [questionId]: [
            { id: "", value: minValue, nextQuestionId: null },
            { id: "", value: maxValue, nextQuestionId: null },
          ],
        },
        pageId,
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetAssessmentState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AssessmentContext.Provider
      value={{
        onChangeAge,
        onChangeCheckbox,
        onChangeDate,
        onChangeDropdown,
        onChangeDays,
        onChangePill,
        onChangeText,
        onSelectPill,
        onScaleChange,
      }}
    >
      <AssessmentPresentation isBlocking={isBlocking} />
    </AssessmentContext.Provider>
  );
};

export default Assessment;
