import { useCallback, useEffect, useRef } from "react";
import { clearUser, getAuth, getTokensFromCookies, logout, setIsSessionExpired } from "state/feature/auth/auth.slice";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { refreshTokenAsync } from "state/feature/auth/auth.action";
import { getCommonState, resetCommon } from "state/feature/common/common.slice";
import { resetContacts } from "state/feature/contact/contact.slice";
import { useHistory } from "react-router";
import { useAppDispatch } from "state/store";
import { getLocalStorageItemWithExpiry, setLocalStorageItemWithExpiry } from "shared/methods/utilityFunctions";
import { toast } from "react-toastify";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { clearTwilioToken, getCallState } from "state/feature/call/call.slice";

const TokenExpiryCheck = () => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const history = useHistory();
  const authState = useSelector(getAuth);
  const { modal } = useSelector(getCommonState);
  const callState = useSelector(getCallState);
  const isExpiredCheckInterval = useRef<any>(null);
  const cancelController = useRef<AbortController>();

  const refreshTwilioAccessToken = useCallback(() => {
    const device = callState.twilioCallDevice;
    if (!device.isBusy) {
      dispatch(clearTwilioToken());
    }
  }, [callState.twilioCallDevice, dispatch]);

  useEffect(() => {
    if (!modal.isSessionTimeoutModalVisible) {
      if (authState.isSessionExpired) {
        toast.dismiss();
        toast.error(TOAST_MESSAGES.SESSION_EXPIRED, {
          toastId: "error",
          containerId: "main",
        });
        dispatch(clearUser());
        dispatch(resetCommon());
        dispatch(resetContacts());
        dispatch(logout());
        sessionStorage.removeItem("riskAssessmentAnswers");
        const params = new URLSearchParams();
        params.append("redirect", location.pathname);
        history.push({ pathname: "/", search: params.toString() });
      }
    }
  }, [authState.isSessionExpired, dispatch, history, modal.isSessionTimeoutModalVisible]);

  useEffect(() => {
    return () => {
      cancelController.current?.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    //(async () => {
    isExpiredCheckInterval.current = setInterval(async () => {
      const { jwtToken, refreshToken } = getTokensFromCookies();
      if (jwtToken && refreshToken) {
        try {
          const mappedToken: any = jwt_decode(jwtToken);
          const minutesDiff = moment(new Date(mappedToken.exp * 1000)).diff(moment(new Date()), "minutes", true);
          if (minutesDiff < 1) {
            const isRefreshing = getLocalStorageItemWithExpiry("isRefreshing");
            if (isRefreshing) {
            } else {
              setLocalStorageItemWithExpiry("isRefreshing", true, 10000);
              cancelController.current = new AbortController();
              refreshTwilioAccessToken();
              const newAccessTokenResponse: any = await appDispatch(
                refreshTokenAsync({ token: refreshToken, url: "" })
              ).unwrap();
              setLocalStorageItemWithExpiry("isRefreshing", false, 10000);
              if (newAccessTokenResponse.error) {
                dispatch(clearUser());
                dispatch(resetCommon());
                dispatch(resetContacts());
                dispatch(logout());
                sessionStorage.removeItem("riskAssessmentAnswers");
                const params = new URLSearchParams();
                params.append("redirect", location.pathname);
                history.push({ pathname: "/", search: params.toString() });
              }
            }
          } else {
            dispatch(setIsSessionExpired(false));
          }
        } catch (error) {
          dispatch(setIsSessionExpired(true));
        }
      } else {
        dispatch(setIsSessionExpired(true));
      }
    }, 15000);
    //})();

    return () => {
      clearInterval(isExpiredCheckInterval.current);
    };
  }, [dispatch, appDispatch, history, refreshTwilioAccessToken]);
  return <></>;
};

export default TokenExpiryCheck;
