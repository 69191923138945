export enum CommonStateType {
  ACCESS_DENIED,
  PERMISSION_DENIED,
  LOADING,
  PAGE_NOT_FOUND,
  INTAKE_NOT_FOUND,
  TASK_NOT_ADDED,
  NO_TASKS_AVAILABLE,
  NO_TASK_SELECTED,
  SEARCH_TASK_NOT_FOUND,
  NO_EPISODES_FOUND,
  NO_EPISODE_DETAIL_FOUND,
  EPISODE_TASK_COMING_SOON,
  EPISODE_NOT_FOUND_ON_SEARCH,
}
