import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import SessionTimeOutModal from "components/Modal/SessionTimeoutModal";
import TokenExpiryCheck from "components/token-expiry-check/token-expiry-check.component";

import "./styles.scss";
import SideBarNav from "./side-bar-nav.component";
import { PrivateRouteGuard } from "./guards/private-route-guard.component";
import { PublicRouteGuard } from "./guards/public-route-guard.component";
import { IRoute } from "./route.type";
import { privateRoutes, publicRoutes } from "./constants";
import PageNotFound from "pages/errors/page-not-found";
import { useTwilioClientService } from "shared/services/twilio/twilio-client.service";
import GenericPopupModal from "components/Modal/GenericPopupModal/generic-popup-modal.component";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCallState } from "state/feature/call/call.slice";
import { getCommonState, setShowTwilioPopup } from "state/feature/common/common.slice";
import { isMobile } from "react-device-detect";
import { getAuth } from "state/feature/auth/auth.slice";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import NotificationMobile from "pages/notification/mobile/notification-mobile.container";
import { useSignalRService } from "shared/services/signalr/signalr.service";

const NavWithRouter = withRouter(SideBarNav);
export default function AppRoutes() {
  const signalRClient = useSignalRService();
  const twilioClient = useTwilioClientService();
  const { modal, featureFlags } = useSelector(getCommonState);
  const { user } = useSelector(getAuth);
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  const callState = useSelector(getCallState);
  const { currentUserProfile } = useSelector(getNavigatorState);

  useEffect(() => {
    signalRClient?.startSignalRConnection(user.email);
  }, [signalRClient, user.email]);

  return (
    <Router>
      <Switch>
        <NavWithRouter>
          <div className="app-main-content">
            <Switch>
              <Route
                exact
                key={privateRoutes.length + 1}
                path="/notification"
                render={() =>
                  isMobile && featureFlags.inAppNotifications ? (
                    <PrivateRouteGuard children={<NotificationMobile />} />
                  ) : (
                    <Route component={PageNotFound} />
                  )
                }
              />
              {privateRoutes.map((route: IRoute) => (
                <Route
                  exact
                  key={route.id}
                  path={route.path}
                  render={() => <PrivateRouteGuard children={<route.main />} />}
                />
              ))}
              <Route component={PageNotFound} />
            </Switch>
          </div>
          {!isMobile && currentUserProfile && (
            <GenericPopupModal
              heading={"Welcome " + user.firstName}
              isOpen={(modal.isOpenTwilioPopup || isOpen) && featureFlags.inboundCalls}
              handleSubmitClick={() => {
                if (callState.twilioCallDevice) {
                  twilioClient.registerIncomingEvent();
                }
                setIsOpen(false);
                dispatch(setShowTwilioPopup(false));
              }}
              submitButtonTitle="OK"
              content="Calling feature is enabled"
              handleCancelClick={() => {}}
              cancelButtonVisible={false}
            />
          )}
          <TokenExpiryCheck />
          <SessionTimeOutModal />
        </NavWithRouter>
      </Switch>
      {publicRoutes.map((route: IRoute) => (
        <Route exact key={route.id} path={route.path} render={() => <PublicRouteGuard children={<route.main />} />} />
      ))}
    </Router>
  );
}
