import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import Icon from "components/Icon/icon.component";
import UserManagementModal from "components/Modal/UserManagementModal/user-management-modal.component";
import TextField from "components/TextField/text-field.component";
import {
  EMAIL_ERROR,
  FIRSTNAME_ERROR,
  LASTNAME_ERROR,
  NAVIGATOR_TYPE_DROPDOWN_ITEMS,
  PHONE_ERROR,
} from "pages/user-management/common/user-management.constants";
import { AccountType, NavigatorType, StatusType } from "pages/user-management/common/user-management.enum";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { extractCountryCodeAndFormatFromPhone, validateEmail, validateNumber } from "shared/methods/utilityFunctions";
import { getAuth } from "state/feature/auth/auth.slice";
import {
  getExistingNavigatorByEmailAsync,
  getNavigatorByIdAsync,
  getNavigatorManagersAsync,
  saveNavigatorDetailsAsync,
} from "state/feature/user-management/user-management.action";

import { useAppDispatch } from "state/store";
import "./navigator-form-details.styles.scss";
import {
  NavigatorDetails,
  FormFeildsValidationType,
  NavigatorFormDetailsPropsType,
  SaveNavigatorDetailsPayloadType,
} from "./navigator-form-details.types";
import Loading from "shared/assets/images/loader.gif";
import { getUserManagementState, setUserValueChanged } from "state/feature/user-management/user-management.slice";

export const NavigatorFormDetails = forwardRef(({ userId }: NavigatorFormDetailsPropsType, ref: any) => {
  useAxiosAuthenticated();
  const appDispatch = useAppDispatch();
  const history = useHistory();
  const { managers, usertypes, currentUserDetails, isLoadingNavigatorDataById, isLoadingNavigatorManagers } =
    useSelector(getUserManagementState);
  const initialValidations = {
    email: null,
    firstName: null,
    lastName: null,
    phone: null,
    navigatorType: null,
    manager: null,
  };
  const [navigatorDetails, setNavigatorDetails] = useState<NavigatorDetails>({
    accountType: AccountType.NAVIGATOR,
    id: "",
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    navigatorType: "",
    createdAt: "",
    updatedAt: "",
    careManagerId: "",
    clientID: 0,
    status: StatusType.INACTIVE,
  });
  const [formFeildsValidation, setFormFeildsValidation] = useState<FormFeildsValidationType>(initialValidations);
  const [navigatorManagerDetails, setNavigatorManagerDetails] = useState<{
    isManager: boolean;
    manager: any;
  }>({
    isManager: false,
    manager: null,
  });
  const [isInActiveModalOpen, setIsInActiveModalOpen] = useState<boolean>(false);
  const [searchEmail, setSearchEmail] = useState<string>("");
  const authState = useSelector(getAuth);

  useEffect(() => {
    if (!managers.length) {
      appDispatch(getNavigatorManagersAsync());
    }
  }, [managers, appDispatch]);

  const resetValidationForField = (field: string) => {
    if (!field) {
      setFormFeildsValidation({ ...initialValidations });
    } else {
      setFormFeildsValidation({ ...formFeildsValidation, [field]: null });
    }
  };

  useEffect(() => {
    if (userId && managers.length > 0) {
      appDispatch(getNavigatorByIdAsync(userId));
    }
  }, [userId, managers, appDispatch]);

  useEffect(() => {
    if (userId && currentUserDetails && managers.length > 0) {
      const { id, managerId } = currentUserDetails;
      const manager = managers.find((item) => item.id === managerId);
      const newDetails = {
        isManager: id === managerId,
        manager:
          id === managerId
            ? null
            : {
                id: manager?.id,
                name: [manager?.firstName, manager?.lastName].join(" "),
              },
      };
      setNavigatorManagerDetails(newDetails);
      setNavigatorDetails({ ...navigatorDetails, ...currentUserDetails });
      resetValidationForField("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, currentUserDetails, managers]);

  useEffect(() => {
    if (!userId && currentUserDetails) {
      setNavigatorDetails({ ...navigatorDetails, ...currentUserDetails, status: StatusType.ACTIVE });
    }
    resetValidationForField("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserDetails]);

  const handleStatusToggle = () => {
    if (currentUserDetails) {
      if (navigatorDetails.status === StatusType.ACTIVE) {
        setIsInActiveModalOpen(true);
      } else {
        setNavigatorDetails({ ...navigatorDetails, status: StatusType.ACTIVE });
      }
    }
    appDispatch(setUserValueChanged(true));
  };

  const handleNavigatorManagerDetailsChange = (key: string, value: any) => {
    if (currentUserDetails) {
      const newDetails = { ...navigatorManagerDetails, [key]: value };
      if (key === "isManager" && value) {
        newDetails.manager = null;
        setFormFeildsValidation({ ...formFeildsValidation, ["manager"]: null });
      }
      if (key === "manager") {
        setFormFeildsValidation({ ...formFeildsValidation, [key]: null });
      }
      setNavigatorManagerDetails(newDetails);
      appDispatch(setUserValueChanged(true));
    }
  };

  const handleNavigatorDetails = (key: string, value: any) => {
    if (key === "navigatorType") {
      setFormFeildsValidation({ ...formFeildsValidation, [key]: null });
    }
    setNavigatorDetails({ ...navigatorDetails, [key]: value });
    resetValidationForField(key);
    appDispatch(setUserValueChanged(true));
  };

  const validateFormFields = (key: string, value: any) => {
    const validations = formFeildsValidation;
    const { isManager, manager } = navigatorManagerDetails;
    if (!key) {
      const { email, firstName, lastName, phone, navigatorType } = navigatorDetails;
      if (!email || email.length === 0 || !validateEmail(email)) {
        validations.email = { error: EMAIL_ERROR };
      } else {
        validations.email = null;
      }
      if (!firstName || firstName.length === 0) {
        validations.firstName = { error: FIRSTNAME_ERROR };
      } else {
        validations.firstName = null;
      }
      if (!lastName || lastName.length === 0) {
        validations.lastName = { error: LASTNAME_ERROR };
      } else {
        validations.lastName = null;
      }
      if (!phone || phone.length === 0 || phone.length < 10 || !validateNumber(phone)) {
        validations.phone = { error: PHONE_ERROR };
      } else {
        validations.phone = null;
      }
      if (!navigatorType || navigatorType.length === 0) {
        validations.navigatorType = { error: "Select a type" };
      } else {
        validations.navigatorType = null;
      }

      if (!isManager && (!manager || manager.length === 0)) {
        validations.manager = { error: "Select a manager" };
      } else {
        validations.manager = null;
      }
      setFormFeildsValidation({ ...validations });
      return;
    }

    if (key === "email") {
      if (!value || value.length === 0 || !validateEmail(value)) {
        validations.email = { error: EMAIL_ERROR };
      } else {
        validations.email = null;
        setSearchEmail(value);
      }
      setFormFeildsValidation({ ...validations });
      return;
    }

    if (key === "firstName") {
      if (!value || value.length === 0) {
        validations.firstName = { error: FIRSTNAME_ERROR };
      } else {
        validations.firstName = null;
      }
      setFormFeildsValidation({ ...validations });
      return;
    }

    if (key === "lastName") {
      if (!value || value.length === 0) {
        validations.lastName = { error: LASTNAME_ERROR };
      } else {
        validations.lastName = null;
      }
      setFormFeildsValidation({ ...validations });
      return;
    }

    if (key === "phone") {
      if (!value || value.length === 0 || value.length < 10 || !validateNumber(value)) {
        validations.phone = { error: PHONE_ERROR };
      } else {
        validations.phone = null;
      }
      setFormFeildsValidation({ ...validations });
      return;
    }

    if (key === "navigatorType") {
      if (!value) {
        validations.navigatorType = { error: "Select a type" };
      } else {
        validations.navigatorType = null;
      }
      setFormFeildsValidation({ ...validations });
      return;
    }

    if (key === "manager") {
      if (!isManager) {
        if (!value) {
          validations.manager = { error: "Select a manager" };
        } else {
          validations.manager = null;
        }
        setFormFeildsValidation({ ...validations });
        return;
      }
    }
  };

  useEffect(() => {
    if (searchEmail) {
      appDispatch(getExistingNavigatorByEmailAsync(searchEmail));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchEmail]);

  useImperativeHandle(ref, () => ({
    saveData() {
      const { email, firstName, middleName, lastName, phone, navigatorType, id, careManagerId, clientID } =
        navigatorDetails;
      const { isManager, manager } = navigatorManagerDetails;
      const hasValues = email && firstName && lastName && phone && navigatorType;
      validateFormFields("navigatorType", navigatorType);
      validateFormFields("manager", manager);

      const hasErrors =
        formFeildsValidation.email ||
        formFeildsValidation.firstName ||
        formFeildsValidation.lastName ||
        formFeildsValidation.phone ||
        formFeildsValidation.navigatorType ||
        formFeildsValidation.manager;
      const isValid = hasValues && !hasErrors;
      if (isValid) {
        const managerId = isManager ? "" : manager.id;
        const payload: SaveNavigatorDetailsPayloadType = {
          id: userId ?? "",
          firstName,
          email,
          isActive: navigatorDetails.status === StatusType.ACTIVE,
          middleName: middleName ?? "",
          phoneNumber: `+1${phone.replace(/\D+/g, "")}`,
          lastName,
          clientId: clientID ? clientID : 0,
          recordCreatedBy: authState.user.id,
          recordUpdatedBy: userId ? authState.user.id : "",
          userTypeId: usertypes?.find((type) => type.description === AccountType.NAVIGATOR)?.id,
          navigatorDetail: {
            careManagerId: userId && careManagerId ? careManagerId : id ?? "",
            navigatorType: navigatorType.name === NavigatorType.TRANSITION_NAVIGATOR ? 1 : 0,
            navigatorManagerId: managerId,
            navigatorId: userId && careManagerId ? careManagerId : "",
          },
        };
        appDispatch(saveNavigatorDetailsAsync(payload))
          .unwrap()
          .then(() => history.goBack());
      } else {
        if (!email) {
          validateFormFields("email", email);
        } else {
          validateFormFields("", null);
        }
      }
    },
    isRequestPayloadValid: () => {
      const { email, firstName, lastName, phone, navigatorType } = navigatorDetails;
      const { isManager, manager } = navigatorManagerDetails;
      const hasValues = email && firstName && lastName && phone && navigatorType;
      validateFormFields("navigatorType", navigatorType);
      if (!isManager) {
        validateFormFields("manager", manager);
      }
      const hasErrors =
        formFeildsValidation.email ||
        formFeildsValidation.firstName ||
        formFeildsValidation.lastName ||
        formFeildsValidation.phone ||
        formFeildsValidation.navigatorType ||
        formFeildsValidation.manager;
      return hasValues && !hasErrors;
    },
  }));

  return (
    <>
      {isLoadingNavigatorDataById || isLoadingNavigatorManagers ? (
        <div className="empty-state-container loader">
          <img src={Loading} alt="loading" />
        </div>
      ) : (
        <>
          {isInActiveModalOpen && (
            <UserManagementModal
              heading="Change Status"
              submitButtonTitle={"Yes"}
              cancelButtonTitle={"No"}
              content="Are you sure, you want to set this user’s status to ‘Inactive’?"
              isOpen={isInActiveModalOpen}
              handleCancelClick={() => setIsInActiveModalOpen(false)}
              handleSubmitClick={() => {
                setIsInActiveModalOpen(false);
                setNavigatorDetails({ ...navigatorDetails, status: StatusType.INACTIVE });
              }}
            />
          )}
          <div className="navigator-form-container">
            <div className="form-row">
              <div className="form-col account-type-disabled">
                <TextField
                  label="Account Type"
                  type="text"
                  value={AccountType.NAVIGATOR}
                  setValue={() => {}}
                  inputClassName="account-type-disabled"
                  onBlur={() => {}}
                  disabled={true}
                />
              </div>
              <div className="form-col">
                <TextField
                  label="Email Address"
                  type="email"
                  value={navigatorDetails.email ? navigatorDetails.email : ""}
                  setValue={(el, value) => handleNavigatorDetails("email", value)}
                  className={userId ? "field-temporary-disabled" : ""}
                  inputClassName={`email-input`}
                  onBlur={() => {
                    if (navigatorDetails.email && navigatorDetails.email.length === 0) {
                      setSearchEmail(navigatorDetails.email);
                    }
                    validateFormFields("email", navigatorDetails.email);
                  }}
                  placeholder="Email Address"
                  disabled={userId ? true : false}
                  showError={!!formFeildsValidation?.email?.error}
                  errorMessage={formFeildsValidation?.email?.error}
                  maxLength={100}
                  showErrorIcon={true}
                />
              </div>
              <div className="form-col">
                <label className={`status-switch-label ${!currentUserDetails ? "field-temporary-disabled" : ""}`}>
                  Status
                </label>
                <div className={`toggleContainer ${!currentUserDetails ? "field-temporary-disabled" : ""}`}>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={navigatorDetails.status === StatusType.ACTIVE}
                      onChange={handleStatusToggle}
                    />
                    <span
                      className={`slider round ${navigatorDetails.status === StatusType.ACTIVE ? "active" : ""}`}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="form-row form-row-nav">
              <div className="form-col type-input">
                <label className={`status-switch-label ${!currentUserDetails ? "field-temporary-disabled" : ""}`}>
                  Type
                </label>
                <CustomDropDown
                  placeholder="Select Navigator Type"
                  dropDownMenuItems={NAVIGATOR_TYPE_DROPDOWN_ITEMS}
                  handleValueChanges={(value) => handleNavigatorDetails("navigatorType", value)}
                  value={navigatorDetails.navigatorType}
                  onBlur={() => validateFormFields("navigatorType", navigatorDetails.navigatorType)}
                  idFieldName="key"
                  dropDownBoxClass="navigator-dropdown"
                  selectionClass="navigator-selection"
                  dropDownContainerClass="navigator-dropdown-container"
                  isDisabled={!currentUserDetails}
                />
                {!!formFeildsValidation.navigatorType && (
                  <div className="error-wrap">
                    <Icon icon="error-message-icon" size={15} className="error-message-icon" />
                    {formFeildsValidation.navigatorType.error}
                  </div>
                )}
              </div>
              <div className="form-col is-manager-switch">
                <label className={`status-switch-label ${!currentUserDetails ? "field-temporary-disabled" : ""}`}>
                  Is Manager
                </label>
                <div className={`toggleContainer ${!currentUserDetails ? "field-temporary-disabled" : ""}`}>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={navigatorManagerDetails.isManager}
                      onChange={() =>
                        handleNavigatorManagerDetailsChange("isManager", !navigatorManagerDetails.isManager)
                      }
                    />
                    <span className={`slider round ${navigatorManagerDetails.isManager ? "active" : ""}`}></span>
                  </label>
                </div>
              </div>
              <div className="form-col type-input">
                <label
                  className={`status-switch-label ${
                    !currentUserDetails || navigatorManagerDetails.isManager ? "field-temporary-disabled" : ""
                  }`}
                >
                  Manager
                </label>
                <CustomDropDown
                  placeholder="Select Manager"
                  dropDownMenuItems={managers.map((el) => ({ id: el.id, name: [el.firstName, el.lastName].join(" ") }))}
                  handleValueChanges={(value) => handleNavigatorManagerDetailsChange("manager", value)}
                  value={navigatorManagerDetails.manager}
                  onBlur={() => validateFormFields("manager", navigatorManagerDetails.manager)}
                  idFieldName="id"
                  dropDownBoxClass="navigator-dropdown"
                  selectionClass="navigator-selection"
                  dropDownContainerClass="navigator-dropdown-container"
                  isDisabled={!currentUserDetails || navigatorManagerDetails.isManager}
                />
                {!!formFeildsValidation.manager && (
                  <div className="error-wrap">
                    <Icon icon="error-message-icon" size={15} className="error-message-icon" />
                    {formFeildsValidation.manager.error}
                  </div>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <TextField
                  label="First Name"
                  type="text"
                  value={navigatorDetails.firstName}
                  className={!currentUserDetails ? "field-temporary-disabled" : ""}
                  setValue={(el, value) => handleNavigatorDetails("firstName", value)}
                  inputClassName={`first-name`}
                  onBlur={() => validateFormFields("firstName", navigatorDetails.firstName)}
                  placeholder="First Name"
                  disabled={!currentUserDetails}
                  showErrorIcon={true}
                  showError={!!formFeildsValidation?.firstName?.error}
                  errorMessage={formFeildsValidation?.firstName?.error}
                  maxLength={30}
                />
              </div>
              <div className="form-col">
                <TextField
                  label="Middle Name (Optional)"
                  type="text"
                  value={navigatorDetails.middleName ?? ""}
                  setValue={(el, value) => handleNavigatorDetails("middleName", value)}
                  className={!currentUserDetails ? "field-temporary-disabled" : ""}
                  inputClassName={`middle-name`}
                  onBlur={() => {}}
                  placeholder="Middle Name"
                  disabled={!currentUserDetails}
                  maxLength={30}
                />
              </div>
              <div className="form-col">
                <TextField
                  label="Last Name"
                  type="text"
                  value={navigatorDetails.lastName}
                  setValue={(el, value) => handleNavigatorDetails("lastName", value)}
                  className={!currentUserDetails ? "field-temporary-disabled" : ""}
                  inputClassName={`last-name`}
                  onBlur={() => validateFormFields("lastName", navigatorDetails.lastName)}
                  placeholder="Last Name"
                  disabled={!currentUserDetails}
                  showError={!!formFeildsValidation?.lastName?.error}
                  errorMessage={formFeildsValidation?.lastName?.error}
                  showErrorIcon={true}
                  maxLength={30}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col phone-col">
                <div className="phone-container">
                  <TextField
                    label="Phone"
                    type="tel"
                    onBlur={() =>
                      validateFormFields("phone", extractCountryCodeAndFormatFromPhone(navigatorDetails.phone))
                    }
                    value={
                      Number(extractCountryCodeAndFormatFromPhone(navigatorDetails.phone)) === 0 ||
                      isNaN(Number(extractCountryCodeAndFormatFromPhone(navigatorDetails.phone)))
                        ? ""
                        : Number(extractCountryCodeAndFormatFromPhone(navigatorDetails.phone))
                    }
                    setValue={(el, value) => handleNavigatorDetails("phone", value)}
                    inputClassName={`phone-input `}
                    className={`phone-container-content ${!currentUserDetails ? "field-temporary-disabled" : ""}`}
                    showError={!!formFeildsValidation.phone}
                    placeholder={"Phone"}
                    isPhoneNumber
                    maxLength={10}
                    disabled={!currentUserDetails}
                  />
                  {!!formFeildsValidation.phone && (
                    <div className="error-wrap">
                      {<Icon icon="error-message-icon" size={15} className="error-message-icon" />}
                      {formFeildsValidation.phone.error}
                    </div>
                  )}
                </div>
              </div>
              {userId ? (
                <>
                  {navigatorDetails.createdAt && (
                    <div className="form-col">
                      <TextField
                        label="Created"
                        type="text"
                        value={navigatorDetails.createdAt}
                        setValue={() => {}}
                        inputClassName="created-disabled"
                        onBlur={() => {}}
                        disabled={true}
                      />
                    </div>
                  )}
                  {navigatorDetails.updatedAt ? (
                    <div className="form-col">
                      <TextField
                        label="Modified"
                        type="text"
                        value={navigatorDetails.updatedAt}
                        setValue={() => {}}
                        inputClassName="updated-disabled"
                        onBlur={() => {}}
                        disabled={true}
                      />
                    </div>
                  ) : (
                    <div className="form-col"></div>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
});
